import { useStoreMain } from 'components/Stores/StoreMain';
import EditRepairShopView from 'views/RepairShop/EditRepairShop';
import RepairShopView from 'views/RepairShop/ViewRepairShop';
import CoupleExternalView from 'views/SearchList/ViewCoupleExternal';
import ReviewListView from 'views/SearchList/ViewReviewList';
import SearchListView from 'views/SearchList/ViewSearchList';
import { FC } from 'react';
import { createMemoryRouter, RouteObject } from 'react-router';
import { GlobalRouterProvider } from './DEP_GlobalRouter';

const RootRoute: FC = () => {
  const storeMain = useStoreMain();

  if (storeMain.assignment?.repairShopID === null) {
    return <SearchListView />;
  }

  return <RepairShopView />;
};

const routes: RouteObject[] = [
  {
    path: '',
    element: <GlobalRouterProvider />,
    children: [
      {
        path: '/',
        element: <RootRoute />,
      },
      {
        path: '/list',
        element: <SearchListView />,
      },
      {
        path: '/reviewlist',
        element: <ReviewListView />,
      },
      {
        path: '/coupleexternal',
        element: <CoupleExternalView />,
      },
      {
        path: '/repairshop/:id?/:history?',
        element: <RepairShopView />,
      },
      {
        path: '/editrepairshop/:id?',
        element: <EditRepairShopView />,
      },
    ],
  },
];

const appRouter = createMemoryRouter(routes);

export default appRouter;
