import {
  FC,
  PropsWithChildren,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import keyMirror from 'keymirror';
import { css, styled } from 'styled-components';
import * as constants from 'globalStyles/constants';

export const ScrollbarFlags = {
  Padding: keyMirror({
    Main: null,
    None: null,
  }),
};

const Wrapper = styled.div`
  position: relative;
  &&&& {
    display: flex;
    flex-direction: column;
  }
  width: 100%;
  height: 100%;
`;

const Scroll = styled.div`
  flex: 1;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 15px;
    height: 15px;
  }

  &::-webkit-scrollbar-track {
    background-color: ${constants.background_light};
    border: solid 1px transparent;

    &:vertical {
      border-left: 1px solid ${constants.border_dark};
      border-right: 1px solid ${constants.border_dark};
    }
    &:horizontal {
      border-top: 1px solid ${constants.border_dark};
      border-bottom: 1px solid ${constants.border_dark};
    }
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px 10px rgba(0, 0, 0, 0.3);
    border: solid 4px transparent;
    border-radius: 10000px;

    &:hover {
      box-shadow: inset 0 0 10px 10px rgba(0, 0, 0, 0.5);
    }

    &:active {
      box-shadow: inset 0 0 10px 10px rgba(0, 0, 0, 0.7);
    }
  }
`;

interface ScrollInnerPadderProps {
  $mainPadderRight: boolean;
  $mainPadderLeft: boolean;
}

const ScrollInnerPadder = styled.div<ScrollInnerPadderProps>`
  width: 100%;
  height: auto;

  ${({ $mainPadderRight }) =>
    $mainPadderRight &&
    css`
      padding-right: ${constants.large_distance};
    `}

  ${({ $mainPadderLeft }) =>
    $mainPadderLeft &&
    css`
      padding-left: ${constants.large_distance};
    `}
`;

const ShadowTop = styled.div<{ $visible: boolean }>`
  mix-blend-mode: luminosity;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: ${constants.scroll_fade_height};
    background: linear-gradient(
      to top,
      ${constants.transparant_light} 0%,
      ${constants.background_light} 100%
    );
    pointer-events: none;
    transition: opacity 0.1s;
    opacity: 0;

    ${({ $visible }) =>
      $visible &&
      css`
        opacity: 1;
      `}
  }
`;

const ShadowBottom = styled.div<{ $visible: boolean }>`
  mix-blend-mode: luminosity;
  position: sticky;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 0;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: ${constants.scroll_fade_height};
    width: 100%;

    background: linear-gradient(
      to bottom,
      ${constants.transparant_light} 0%,
      ${constants.background_light} 100%
    );
    pointer-events: none;
    transition: opacity 0.1s;
    opacity: 0;

    ${({ $visible }) =>
      $visible &&
      css`
        opacity: 1;
      `}
  }
`;

const ScrollInnerPadderChildrenWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

interface Props extends PropsWithChildren {
  rightPadding?: (typeof ScrollbarFlags.Padding)[keyof typeof ScrollbarFlags.Padding];
  leftPadding?: (typeof ScrollbarFlags.Padding)[keyof typeof ScrollbarFlags.Padding];

  /** The element will use 'flex: 1;' instead of 'width: 100%; height: 100%;' */
  useFlex?: boolean;

  noShadeTop?: boolean;
  noShadeBottom?: boolean;
  className?: string;
}

const ScrollArea: FC<Props> = ({
  children,
  rightPadding = ScrollbarFlags.Padding.None,
  leftPadding = ScrollbarFlags.Padding.None,
  useFlex = false,
  noShadeTop = false,
  noShadeBottom = false,
  className,
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [shadeStart, setShadeStart] = useState(false);
  const [shadeEnd, setShadeEnd] = useState(false);

  useLayoutEffect(() => {
    const scrollElement = scrollRef.current;

    if (!scrollElement) return;

    const updateScrollState = (element: HTMLDivElement) => {
      const scrollPercentage =
        element.scrollTop / (element.scrollHeight - element.clientHeight - 5);

      setShadeStart(scrollPercentage > 0);
      setShadeEnd(scrollPercentage < 1);
    };

    const scrollListner = (eve: Event) => {
      if (!(eve.currentTarget instanceof HTMLDivElement)) return;
      updateScrollState(eve.currentTarget);
    };

    scrollElement.addEventListener('scroll', scrollListner);
    updateScrollState(scrollElement);

    return () => {
      scrollElement.removeEventListener('scroll', scrollListner);
    };
  }, []);

  const canScroll =
    !!scrollRef.current &&
    scrollRef.current.scrollHeight > scrollRef.current.clientHeight;

  const content = (
    <Scroll className={className} ref={scrollRef}>
      <ShadowTop $visible={!noShadeTop && shadeStart && canScroll} />
      <ScrollInnerPadder
        $mainPadderRight={rightPadding === ScrollbarFlags.Padding.Main}
        $mainPadderLeft={leftPadding === ScrollbarFlags.Padding.Main}
      >
        <ScrollInnerPadderChildrenWrapper>
          {children}
        </ScrollInnerPadderChildrenWrapper>
      </ScrollInnerPadder>
      <ShadowBottom $visible={!noShadeBottom && shadeEnd && canScroll} />
    </Scroll>
  );

  if (useFlex) return content;

  return <Wrapper>{content}</Wrapper>;
};

export default ScrollArea;
