import * as constants from 'globalStyles/constants';
import { css } from 'styled-components';
import {
  vcenter_container,
  elipsis_overflow,
  small_info_text,
  rightside_vcenter_container,
} from 'globalStyles/general-structures';

const name_width = '275px';
const address_width = '200px';
const department_width = '72px';
const insurance_width = '150px';
const brand_width = '162px';
const distance_width = '72px';
// const name_review_width = '260px';
const name_review_width = '232px';
const address_review_width = '172px';
const last_changed_width = '104px';
const changed_by_width = '190px';
const status_width = '140px';
const distance_review_width = '60px';

const repairShopListGlobalStyle = css`
  .search-list-row {
    .name {
      width: ${name_width};
      padding-left: ${constants.medium_distance};
      padding-right: ${constants.small_distance};
      line-height: 18px;
      overflow: hidden;
      height: 100%;
      ${vcenter_container};
      &.review {
        width: ${name_review_width};
      }
    }
    .address {
      width: ${address_width};
      padding-right: ${constants.small_distance};
      &.review {
        width: ${address_review_width};
        padding-right: 0;
      }
      .line1 {
        width: 100%;
        ${elipsis_overflow};
      }
      .line2 {
        width: 100%;
        ${small_info_text};
        ${elipsis_overflow};
      }
    }
    .department {
      width: ${department_width};
    }
    .insurance {
      height: 100%;
      width: ${insurance_width};
      display: inline-flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .company-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 5px;
      }
    }
    .brand {
      width: ${brand_width};
      padding-right: ${constants.small_distance};
    }
    .distance {
      width: ${distance_width};
      text-align: right;
      overflow: hidden;
      height: 100%;
      ${vcenter_container};
      &.review {
        width: ${distance_review_width};
      }
      &.loading {
        opacity: ${constants.main_transparent};
      }
    }
    // Station view
    .last-changed {
      width: ${last_changed_width};
      text-align: center;
    }
    .changed-by {
      width: ${changed_by_width};
      padding-left: ${constants.medium_distance};
    }
    .status {
      width: ${status_width};
      padding-left: 30px;
      &.bold {
        font-weight: bold;
      }
    }
    .header-right {
      margin-right: 14px;
      ${rightside_vcenter_container};
    }
    .header-item {
      height: 100%;
      ${vcenter_container};
    }
    .underline {
      border-bottom: 1px dashed ${constants.main_yellow};
    }
  }
`;

export default repairShopListGlobalStyle;
