// #region [IMPORTS]

import { Component } from 'react';
import { RouterProvider } from 'react-router';

import {
  initializeIFrameListener,
  exitRegister,
  IFrameWindowType,
} from 'general/IFrameCommunication';
import StoreMain from 'components/Stores/StoreMain';
import StoreError from 'components/Stores/StoreError';
import ErrorContainer from 'components/ErrorContainer';

import { ApplicationLoader } from 'components/Loader/Loader';
import TabletHeader from 'components/Header/TabletHeader';
import { PopupContainer } from 'components/Popup/PopupComponents';
import GlobalStyles from 'globalStyles/main';
import appRouter from 'views/routes';

const getStateFromStores = () => ({
  mainStore: StoreMain.getState(),
});

interface Props {}

class RepairShopApp extends Component<
  Props,
  ReturnType<typeof getStateFromStores>
> {
  constructor(props: Props) {
    super(props);

    this.state = getStateFromStores();

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    // Special changeListener: RepairShopApp should only re-render once
    initializeIFrameListener(this.onChange);
  }
  onChange() {
    this.setState(getStateFromStores());
  }

  render() {
    const { mainStore } = this.state;

    let appContent = (
      <div>
        <ApplicationLoader />
        <ErrorContainer basicDataHasLoaded={false} />
      </div>
    );

    let appClassName = 'repair-shop-app';

    if (mainStore !== null) {
      const { frameType } = mainStore.settings!;

      const isPopup = frameType === IFrameWindowType.Popup;
      const isTablet = frameType === IFrameWindowType.Tablet;

      if (isTablet) {
        appClassName += ' is-tablet';
      }

      appContent = (
        <div>
          {isTablet && <TabletHeader />}

          <RouterProvider router={appRouter} />

          <ErrorContainer basicDataHasLoaded />
        </div>
      );

      if (isPopup) {
        appContent = (
          <PopupContainer
            close={exitRegister}
            headerText="Verkstadsregister"
            repairShopApp
          >
            {appContent}
          </PopupContainer>
        );
      }
    }

    return (
      <div className={appClassName}>
        <GlobalStyles />
        {appContent}
      </div>
    );
  }
}

StoreError.init();

export default RepairShopApp;
