/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * My Title
 * OpenAPI spec version: 1.0.0
 */

export type InfoTextType = (typeof InfoTextType)[keyof typeof InfoTextType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InfoTextType = {
  LockInfo: 1,
  AlarmInfo: 2,
  PassCode: 3,
  Other: 4,
  PhoneNumber: 5,
  FaxNumber: 6,
  Email: 7,
  ContactPerson: 8,
  MobilePhoneNumber: 9,
  Comment: 10,
  Speciality: 11,
  Website: 12,
  OutletChain: 13,
  InsuranceInfo: 14,
  VacationDates: 15,
} as const;
