import { Component } from 'react';
import PropTypes from 'prop-types';

import Button from '../../Button/Button';

import { PopupContainer } from '../PopupComponents';

class DiscardChangesPopup extends Component {
  render() {
    const { settings, discardChanges } = this.props;
    const { close } = settings;

    return (
      <PopupContainer
        close={close}
        headerText="Du har osparade ändringar"
        small
      >
        <div className="popup-buttons-box">
          <Button yellow wLarge hMain text="Stanna kvar" onClick={close} />
          <div className="medium-padder-h"></div>
          <Button
            black
            wLarge
            hMain
            text="Släng ändringar"
            onClick={discardChanges}
          />
        </div>
      </PopupContainer>
    );
  }
}
DiscardChangesPopup.propTypes = {
  settings: PropTypes.object.isRequired,
  discardChanges: PropTypes.func.isRequired,
};

export default DiscardChangesPopup;
