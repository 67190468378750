const createMapPoint = (fill: string = '#ff0000') => {
  const template = `<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="6" cy="6" r="6" fill="${fill}"/>
</svg>
`;

  const b64 = `data:image/svg+xml;charset=UTF-8;base64,${window.btoa(
    template
  )}`;

  return b64;
};

export default createMapPoint;
