import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ScrollArea from '../Scrollbar';
import { DepartmentText } from '../Utility/UtilityComponents';

import { Images, DropdownItemHeight, CarBrandSpec } from 'general/Constants';
import styled from 'styled-components';

const MyScrollArea = styled(ScrollArea)`
  /* For some reason, iOS Safari lets its children overflow the parent element's border-radius. */
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

class FiltersItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isListOpen: false,
      animate: false,
    };

    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.collapseList = this.collapseList.bind(this);
    this.toggleList = this.toggleList.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.text !== nextProps.text) {
      this.collapseList();
      this.setState({ animate: true });

      // Reset animation classname after completion (1s)
      setTimeout(() => {
        this.setState({ animate: false });
      }, 1000);
    }
  }

  handleClickOutside() {
    this.collapseList();
  }

  collapseList() {
    this.setState({ isListOpen: false });
  }
  toggleList() {
    this.setState({ isListOpen: !this.state.isListOpen });
  }

  render() {
    const {
      text,
      placeholder,
      items,
      selectItem,
      marginLeft,
      marginRight,
      assignmentBrandId, // CarBrand Special:
      basicData, // DepartmentType Special:
    } = this.props;
    const { isListOpen } = this.state;

    const itemClass = classNames({
      'filters-item clickable': true,
      'margin-left': marginLeft,
      'margin-right': marginRight,
      open: isListOpen,
    });

    const wrapperClass = classNames({
      'main-dropdown-wrapper': true,
      'filter-dropdown-frame': true,
      open: isListOpen,
    });

    const allowEmptying = text !== null && placeholder !== null;

    let count = items.sortedKeys.length;
    if (allowEmptying) {
      count += 1;
    }

    return (
      <div
        className={classNames({
          [itemClass]: true,
          'filter-animation': this.state.animate,
        })}
      >
        <div className="head">
          {text === null ? (
            <div className="placeholder">{placeholder}</div>
          ) : (
            <div className="text">{text}</div>
          )}
          <div className="right-icon">
            <img
              src={isListOpen ? Images.MenuArrowUp : Images.MenuArrowDown}
              alt="V"
              className="arrow-icon-v"
            />
          </div>
        </div>
        <div className={wrapperClass}>
          <div className="head-overlay" onClick={this.toggleList}></div>
          {isListOpen && (
            <div
              style={
                count < 11 ? { height: 1 + DropdownItemHeight * count } : {}
              }
              className="list-container ten"
            >
              <MyScrollArea>
                {allowEmptying && (
                  <div
                    className="main-item dropdown clickable"
                    onClick={() => {
                      selectItem(null);
                      this.toggleList();
                    }}
                  >
                    <div className="placeholder">(töm filter)</div>
                  </div>
                )}
                {items.sortedKeys.map((key) => {
                  const mainItem = (
                    <div
                      className="main-item dropdown clickable"
                      key={key}
                      onClick={() => {
                        selectItem(key);
                        this.toggleList();
                      }}
                    >
                      {basicData ? (
                        <DepartmentText
                          departmentID={key}
                          basicData={basicData}
                        />
                      ) : (
                        <div>{items.getValue(key)}</div>
                      )}
                    </div>
                  );

                  if (assignmentBrandId === key) {
                    // Special case: print multiple rows to allow specifying Authorized or Warranty
                    return (
                      <div key={key}>
                        {mainItem}
                        <div
                          className="main-item dropdown clickable"
                          onClick={() => {
                            selectItem(key, CarBrandSpec.Authorized);
                            this.toggleList();
                          }}
                        >
                          <div>{items.getValue(key)} (Aukt.)</div>
                        </div>
                        <div
                          className="main-item dropdown clickable"
                          onClick={() => {
                            selectItem(key, CarBrandSpec.Warranty);
                            this.toggleList();
                          }}
                        >
                          <div>{items.getValue(key)} (VSG)</div>
                        </div>
                      </div>
                    );
                  }

                  return mainItem;
                })}
              </MyScrollArea>
            </div>
          )}
        </div>
      </div>
    );
  }
}
FiltersItem.propTypes = {
  text: PropTypes.string,
  placeholder: PropTypes.string,
  items: PropTypes.object.isRequired,
  selectItem: PropTypes.func.isRequired,
  marginLeft: PropTypes.bool,
  marginRight: PropTypes.bool,
  assignmentBrandId: PropTypes.number,
  basicData: PropTypes.object,
};
FiltersItem.defaultProps = {
  marginLeft: false,
  marginRight: false,
  assignmentBrandId: null,
  placeholder: null,
  text: null,
  basicData: undefined,
};
export default FiltersItem;
