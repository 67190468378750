import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from '../Button/Button';
import ScrollArea, { ScrollbarFlags } from '../Scrollbar';
import { getOnlyActiveOrAllExternal } from 'general/UtilityFunctions';
import { InsuranceCompanyType } from 'general/TsEnums.gen';

export const AssistancePackageInternalId = -101;

export class InsuranceInfoByCompany extends Component {
  render() {
    const { insuranceID } = this.props;

    const baseUrl =
      'https://assist.assistancekaren.se/AK/PdaWeb/Content/DownloadResources/';
    const folksam = 'http://www.folksam.se/anmal-skada/verkstadssok';
    const gjensidige = `${baseUrl}gjensidige20161214.pdf`;
    const assistanceList = `${baseUrl}mobilitetsgarantier20160313.pdf`;

    // NOTE: Hardcoded id-matches used to display insurance info
    let content = null;
    switch (insuranceID) {
      case InsuranceCompanyType.DinaForsakringar:
        content = (
          <div className="item">
            <div className="paragraph-header">
              Assistancekåren verkstadsstyr
            </div>
            <div className="paragraph">
              Omfattas skadan ska bilen gå till närmsta av Dina Försäkringar
              avtalade verkstad som kan hantera bilmärket och skadetypen.
            </div>
            <div className="paragraph-header">Dina Försäkringar Skåne:</div>
            <div className="paragraph">
              Bilen ska gå till närmsta av Dina Skåne avtalade verkstad.
              Verkstadslistan ligger på Kontoret under ”Bra att ha”.
            </div>
            <div className="info">
              Övriga Dina Försäkringar bolag – Ingen uppgift
            </div>
          </div>
        );
        break;

      case InsuranceCompanyType.Folksam:
        content = (
          <div className="item">
            <div className="paragraph-header">
              Assistancekåren verkstadsstyr
            </div>
            <div className="paragraph">
              Omfattas skadan ska bilen gå till en av Folksam specifikt för
              märke och skadetyp avtalad verkad utifrån Folksams verkstadswebb.
            </div>
            <div>
              <a target="_blank" rel="noopener noreferrer" href={folksam}>
                www.folksam.se/anmal-skada/verkstadssok
              </a>
            </div>
          </div>
        );
        break;

      case InsuranceCompanyType.Gjensidige:
        content = (
          <div className="item">
            <div className="paragraph-header">
              Assistancekåren verkstadsstyr
            </div>
            <div className="paragraph">
              Bilen ska bärgas till närmsta lämpliga av Gjensidige angiven
              ”förtroendeverkstad” markerad i blått i verkstadslistan, förutsatt
              att bärgarens totala körsträcka inte överskrider 50km.
            </div>
            <div className="paragraph">
              Saknas blå verkstad ska bilen gå till en för märket auktoriserad
              verkstad.
            </div>
            <div className="paragraph">
              Saknas auktoriserad verkstad ska bilen bärgas till en verkstad
              markerat i gult i verkstadslistan.
            </div>
            <div className="paragraph">
              • Verkstadslistan ligger på Kontoret under ”Bra att ha”
            </div>
            <div>
              <a target="_blank" rel="noopener noreferrer" href={gjensidige}>
                Visa verkstadslista
              </a>
            </div>
          </div>
        );
        break;

      case InsuranceCompanyType.IF:
        content = (
          <div className="item">
            <div className="paragraph-header">
              SOS International verkstadsstyr
            </div>
            <div className="paragraph">
              Om ett IF-ärende inkommer direkt till stationen ska det skickas
              till SOS för att sedan returneras med verkstadsstyrning och
              försäkringsomfattning.
            </div>
            <div className="paragraph">
              Om uppdraget inte returneras inom ett par minuter kontakta SOS så
              att inget har blivit fel.
            </div>
            <div className="paragraph-header">
              Bärgarnas prioriterade lina: 0771-913913
            </div>
            <div className="paragraph">
              Har inte SOS avtal med detta IF-koncept ska uppdraget returneras
              till stationen och SOS ska inte ringas ut till ett annat bolag.
            </div>
            <div className="paragraph">
              Om frågor kring verkstadsstyrning eller andra oklarheter uppstår,
              kontakta SOS.
            </div>
          </div>
        );
        break;

      case InsuranceCompanyType.Lansforsakringar:
        content = (
          <div className="item">
            <div className="paragraph-header">
              Assistancekåren verkstadsstyr
            </div>
            <div className="paragraph">
              Omfattas skadan ska bilen gå till en av Länsförsäkringarna avtalad
              verkstad.
            </div>
          </div>
        );
        break;

      case InsuranceCompanyType.SolidForsakring:
        content = (
          <div className="item">
            <div className="paragraph-header">
              Assistancekåren och SOS Int. verkstadsstyr
            </div>
            <div className="paragraph">
              Solid assistanser ska gå till närmsta lämpliga verkstad
            </div>
            <div className="paragraph">
              Solids verkstadsassistanser ska alltid gå till respektive verkstad
              (ex. Autoexperten assistans, Bosch assistans osv.)
            </div>
          </div>
        );
        break;

      case InsuranceCompanyType.TryggHansa:
        content = (
          <div className="item">
            <div className="paragraph-header">
              Assistancekårens Servicecenter verkstadsstyr
            </div>
            <div className="paragraph">
              Om frågor kring verkstadsstyrning uppstår, kontakta Servicecentret
            </div>
            <div className="paragraph-header">
              <b>Bärgarnas prioriterade lina: 010-550 10 86</b>
            </div>
            <div className="paragraph">
              Skulle det inte gå att nå Servicecentret kan du följa den
              specifika information Trygg Hansa har för verkstäder.
            </div>
            <div className="paragraph">
              • Informationen kan var föråldrad, men saknas specifik information
              bör det tolkas som att Trygg Hansa uppdrag ej får gå till
              verkstaden.
            </div>
            <div className="paragraph">
              • Prio 1 verkstad som hanterar uppdragets bilmärke och skadetyp
              ska alltid väljas om avståndet tillåter - Max 30km mellan
              skadeplats och verkstad.
            </div>
            <div className="paragraph">
              • Prio 2 verkstad som hanterar uppdragets bilmärke och skadetyp
              kan väljas om närmaste Prio 1 ligger för långt bort.
            </div>
          </div>
        );
        break;

      case AssistancePackageInternalId: // Special Case: Assistanspaket
        content = (
          <div className="item">
            <a target="_blank" rel="noopener noreferrer" href={assistanceList}>
              Visa mobilitetsgarantier
            </a>
          </div>
        );
        break;

      default:
        content = (
          <div className="item">
            <div className="info">
              Ingen information kan hämtas för det valda försäkringsbolaget.
            </div>
          </div>
        );
        break;
    }

    return <div className="popup-small-info-box">{content}</div>;
  }
}
InsuranceInfoByCompany.propTypes = {
  insuranceID: PropTypes.number.isRequired,
};

export class AssignmentInsuranceInfo extends Component {
  render() {
    const {
      close,
      insuranceName,
      repairShopName,
      inhouseInsuranceInfo,
      externalInsurance,
      insuranceID,
      openRepairShopID,
    } = this.props;

    const filteredExternals = getOnlyActiveOrAllExternal(externalInsurance);
    const count = filteredExternals.length;

    const externalListing = (
      <div>
        <div className="header2">Info {insuranceName} har för verkstaden</div>

        <div className="popup-small-info-box">
          {count > 1 && (
            <div className="item">
              <div className="mini-padder-v"></div>
              OBS: {insuranceName} har {count} unika kopplingar till verkstaden
              (alla är listade)
            </div>
          )}
          {filteredExternals.map((external) => (
            <div key={external.id}>
              <div className="text">
                <div className="item">
                  <div className="name">Generell info:</div>
                  <div>{external.generalInfo}</div>
                </div>
                {external.isActive ? (
                  <div className="item">
                    <div className="name">Bilmärken:</div>
                    <div>{external.carBrandInfo}</div>
                  </div>
                ) : (
                  <div className="item">
                    <div className="name warning">
                      {insuranceName} har inte en aktiv koppling till
                      verkstaden. Troligen samarbetar {insuranceName} inte med
                      verkstaden.
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    );

    return (
      <div className="assignment-insurance-info">
        <div className="column">
          <ScrollArea rightPadding={ScrollbarFlags.Padding.Main}>
            <div className="header1">Verkstad: {repairShopName}</div>

            <div className="break-line"></div>

            {count > 0 ? (
              externalListing
            ) : (
              <div className="header2 missing">
                OBS: Vi saknar info från {insuranceName}. Intern försäkringsinfo
                för {insuranceName} gäller.
              </div>
            )}

            <div className="break-line"></div>

            {inhouseInsuranceInfo !== null ? (
              <div>
                <div className="header2">
                  Intern försäkringsinfo för verkstaden
                </div>
                <div className="text">{inhouseInsuranceInfo}</div>
              </div>
            ) : (
              <div className="header2 missing">
                Verkstaden har ingen intern försäkringsinfo
              </div>
            )}
          </ScrollArea>
        </div>

        <div className="column">
          <ScrollArea leftPadding={ScrollbarFlags.Padding.Main}>
            <div className="top">
              <div className="header1">{insuranceName}: Allmänt</div>
              <div className="break-line"></div>
              <InsuranceInfoByCompany insuranceID={insuranceID} />
            </div>
            {openRepairShopID && (
              <div className="bottom">
                <Button black padMedium hMain text="Avbryt" onClick={close} />
                <div className="medium-padder-h"></div>
                <Button
                  yellow
                  padMedium
                  hMain
                  text="Fortsätt till verkstad"
                  linkTo={`repairshop/${openRepairShopID}`}
                />
              </div>
            )}
          </ScrollArea>
        </div>
      </div>
    );
  }
}
AssignmentInsuranceInfo.propTypes = {
  close: PropTypes.func.isRequired,
  repairShopName: PropTypes.string.isRequired,
  insuranceName: PropTypes.string.isRequired,
  insuranceID: PropTypes.number.isRequired,
  inhouseInsuranceInfo: PropTypes.string,
  externalInsurance: PropTypes.array,
  openRepairShopID: PropTypes.number,
};
AssignmentInsuranceInfo.defaultProps = {
  inhouseInsuranceInfo: null,
  externalInsurance: [],
  openRepairShopID: null,
};
