import { Component } from 'react';
import PropTypes from 'prop-types';

import { Actions } from 'general/Actions';

import Button from '../../Button/Button';

import { PopupContainer } from '../PopupComponents';
import ScrollArea from '../../Scrollbar';

class ConnectInsuranceToAssistPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedID: null,
      connections: JSON.parse(
        JSON.stringify(
          props.mainStore.basicData.insuranceSettings
            .assistToRepairShop_InsuranceIDs
        )
      ),
    };
  }

  render() {
    const { settings, mainStore } = this.props;
    const { close, back } = settings;
    const { allRepairShopInsuranceCompanies, assistInsuranceCompanies } =
      mainStore.basicData.insuranceSettings;
    const { connections, selectedID } = this.state;

    const connectionsList = (
      <div className="insurance-settings">
        <div className="header-area">
          <div className="assist-insurance">Assist-fsb</div>
          <div className="insurance-settings-rightside">
            <div className="repair-shop-insurance">Verkstadsregister-fsb</div>
          </div>
        </div>

        <div className="main-list-container">
          <ScrollArea>
            <div className="list-top-info-box green">
              Ändringar slår igenom för alla användare då de laddar om sidan.
            </div>
            {assistInsuranceCompanies.sortedKeys.map((assistIcID) => {
              let rsIcID = null;
              if (connections[assistIcID]) {
                rsIcID = connections[assistIcID];
              }

              return (
                <div
                  className="main-item alternate-shade clickable"
                  key={assistIcID}
                  onClick={() => this.setState({ selectedID: assistIcID })}
                >
                  <div
                    className="assist-insurance"
                    title={assistInsuranceCompanies.getValue(assistIcID)}
                  >
                    {assistInsuranceCompanies.getValue(assistIcID)}
                  </div>
                  <div className="insurance-settings-rightside">
                    <div className="repair-shop-insurance">
                      {rsIcID === null ? (
                        <div className="no-connection">(ingen koppling)</div>
                      ) : (
                        allRepairShopInsuranceCompanies.getValue(rsIcID)
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </ScrollArea>
        </div>
      </div>
    );

    const selection = (
      <div className="insurance-settings">
        <div className="header-area">
          <div className="name">
            Välj koppling till: {assistInsuranceCompanies.getValue(selectedID)}
          </div>
        </div>
        <div className="main-list-container">
          <ScrollArea>
            <div
              className="main-item alternate-shade clickable"
              onClick={() => {
                connections[selectedID] = null;
                this.setState({ connections, selectedID: null });
              }}
            >
              <div className="name no-connection">(ingen koppling)</div>
            </div>
            {allRepairShopInsuranceCompanies.sortedKeys.map((rsIcID) => {
              return (
                <div
                  className="main-item alternate-shade clickable"
                  key={rsIcID}
                  onClick={() => {
                    connections[selectedID] = rsIcID;
                    this.setState({ connections, selectedID: null });
                  }}
                >
                  <div
                    className="name"
                    title={allRepairShopInsuranceCompanies.getValue(rsIcID)}
                  >
                    {allRepairShopInsuranceCompanies.getValue(rsIcID)}
                  </div>
                </div>
              );
            })}
          </ScrollArea>
        </div>
      </div>
    );

    return (
      <PopupContainer
        close={close}
        back={back}
        headerText="Koppla försäkringsbolag från Assist"
        large
      >
        {selectedID == null ? connectionsList : selection}
        <div className="popup-buttons-box">
          <Button
            yellow
            wLarge
            hMain
            text="Spara ändringar"
            onClick={() => Actions.connectInsuranceToAssist(connections, back)}
            loadingOnClick
          />
          <div className="medium-padder-h"></div>
          <Button black wLarge hMain text="Släng ändringar" onClick={back} />
        </div>
      </PopupContainer>
    );
  }
}

ConnectInsuranceToAssistPopup.propTypes = {
  settings: PropTypes.object.isRequired,
  mainStore: PropTypes.object.isRequired,
};

export default ConnectInsuranceToAssistPopup;
