import { strCompare } from 'general/UtilityFunctions';
import { InsuranceAbilityModel } from 'general/api/models';
import { FC } from 'react';
import styled from 'styled-components';
import * as constants from 'globalStyles/constants';
import { useStoreMain } from 'components/Stores/StoreMain';
import ScrollArea from 'components/Scrollbar';
import { Checkmark } from 'components/Popup/AllPopups/RepairShopExternalInsurancePopup/InsuranceAbilitiesTable';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Table = styled.table`
  width: 100%;
  border-spacing: 0;

  tr {
    margin: 0;
    padding: 0;

    &:nth-child(even) {
      background-color: ${constants.background_shade};
    }

    &:first-child {
      position: sticky;
      top: 0;
      background-color: ${constants.background_light};

      td,
      th {
        padding-top: ${constants.small_distance};
        padding-bottom: ${constants.small_distance};
        border-bottom: 2px solid ${constants.border_dark};
      }
    }

    th,
    td {
      padding: 5px 2px;

      &:first-child {
        padding-left: ${constants.small_distance};
      }

      &:last-child {
        padding-right: ${constants.small_distance};
      }
    }
  }
`;

interface Props {
  insuranceCompanyId: number;
  insuranceAbilities: InsuranceAbilityModel[];
}

const InsuranceCompanyAbilityView: FC<Props> = ({
  insuranceCompanyId,
  insuranceAbilities,
}) => {
  const { basicData } = useStoreMain();

  const insuranceAbilitiesByCarBrand = insuranceAbilities
    .filter(
      (insuranceAbility) =>
        insuranceAbility.insuranceCompanyId === insuranceCompanyId
    )
    .reduce(
      (acc, insuranceAbility) => {
        if (!(insuranceAbility.carBrand in acc)) {
          acc[insuranceAbility.carBrand] = [];
        }

        acc[insuranceAbility.carBrand].push(insuranceAbility);

        return acc;
      },
      {} as Record<string, InsuranceAbilityModel[]>
    );

  return (
    <Wrapper>
      <ScrollArea noShadeTop>
        <Table>
          <tbody>
            <tr>
              <th>Märke</th>
              <th title="Avdelning">Avd.</th>
              <th title="Vagnskadegaranti">VSG</th>
              <th title="Auktoriserad">Aukt.</th>
              <th title="Prioritet">Prio</th>
            </tr>

            {Object.entries(insuranceAbilitiesByCarBrand)
              .sort(([carBrandA], [carBrandB]) =>
                strCompare(carBrandA, carBrandB)
              )
              .map(([carBrand, insuranceAbilities]) => {
                const departmentIds = [
                  ...new Set(
                    insuranceAbilities
                      .map(
                        (insuranceAbility) =>
                          insuranceAbility.repairShopDepartmentTypeID
                      )
                      .filter(
                        (department): department is number =>
                          department !== null && department !== undefined
                      )
                  ),
                ];

                return (
                  <tr key={carBrand}>
                    <td>{carBrand}</td>

                    <td
                      title={departmentIds
                        .map((departmentId) =>
                          basicData.departments.getValue(departmentId)
                        )
                        .sort(strCompare)
                        .join(', ')}
                    >
                      {departmentIds
                        .map((departmentId) =>
                          basicData.departmentsShort.getValue(departmentId)
                        )
                        .sort(strCompare)
                        .join(', ')}
                    </td>

                    <td>
                      {insuranceAbilities.some(
                        (insuranceAbility) =>
                          insuranceAbility.wagonDamageWarranty
                      ) && <Checkmark />}
                    </td>

                    <td>
                      {insuranceAbilities.some(
                        (insuranceAbility) => insuranceAbility.authorized
                      ) && <Checkmark />}
                    </td>

                    <td>
                      {[
                        ...new Set(
                          insuranceAbilities.map(
                            (insuranceAbility) => insuranceAbility.prio
                          )
                        ),
                      ]
                        .sort()
                        .join(', ')}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </ScrollArea>
    </Wrapper>
  );
};

export default InsuranceCompanyAbilityView;
