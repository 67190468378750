import { Library, Loader } from '@googlemaps/js-api-loader';
import { useStoreMain } from 'components/Stores/StoreMain';
import { useEffect, useState } from 'react';

let googlemapsLoader: Loader;

export type LibLookup<Lib extends Library> = {
  core: google.maps.CoreLibrary;
  maps: google.maps.MapsLibrary;
  places: google.maps.PlacesLibrary;
  geocoding: google.maps.GeocodingLibrary;
  routes: google.maps.RoutesLibrary;
  marker: google.maps.MarkerLibrary;
  geometry: google.maps.GeometryLibrary;
  elevation: google.maps.ElevationLibrary;
  streetView: google.maps.StreetViewLibrary;
  journeySharing: google.maps.JourneySharingLibrary;
  drawing: google.maps.DrawingLibrary;
  visualization: google.maps.VisualizationLibrary;
}[Lib];

const useGoogleMapsLibrary = <Lib extends Library>(
  libName: Lib
): {
  isLoading: boolean;
  library: LibLookup<Lib> | null;
} => {
  const mainStore = useStoreMain();
  const [isLoading, setIsLoading] = useState(true);
  const [library, setLibrary] = useState<LibLookup<Lib> | null>(null);

  if (!googlemapsLoader) {
    googlemapsLoader = new Loader({
      apiKey: mainStore.settings.googleMapsKey,
      version: 'quarterly',
    });
  }

  useEffect(() => {
    const loadLibrary = async () => {
      setIsLoading(true);
      setLibrary(
        (await googlemapsLoader.importLibrary(libName)) as LibLookup<Lib>
      );
      setIsLoading(false);
    };

    loadLibrary();
  }, [libName]);

  return {
    isLoading,
    library,
  };
};

export default useGoogleMapsLibrary;
