import { Component } from 'react';
import PropTypes from 'prop-types';

import { InsuranceInfoByCompany } from '../../Information/InsuranceInfo';

import { PopupContainer } from '../PopupComponents';

class InsuranceInfoPopup extends Component {
  render() {
    const { settings, headerText } = this.props;
    const { close, back } = settings;

    return (
      <PopupContainer close={close} back={back} headerText={headerText} medium>
        <InsuranceInfoByCompany {...this.props} />
      </PopupContainer>
    );
  }
}
InsuranceInfoPopup.propTypes = {
  settings: PropTypes.object.isRequired,
  headerText: PropTypes.string.isRequired,
};

export default InsuranceInfoPopup;
