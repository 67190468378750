import { BasicDataStructure } from './api/models';

// Testing switch - blocks console, and sets all js production settings
export const IsProduction = process.env.NODE_ENV !== 'development';

export interface AppAction {
  type: string;
}

type AppActionHandler<A extends AppAction> = (action: A) => void;

class Dispatcher {
  registeredActionHandlers: AppActionHandler<any>[] = [];

  register<A extends AppAction>(actionHandler: AppActionHandler<A>) {
    this.registeredActionHandlers.push(actionHandler);
  }

  dispatch<A extends AppAction>(action: A) {
    this.registeredActionHandlers.forEach((handler) => {
      handler(action);
    });
  }
}

export const AppDispatcher = new Dispatcher();

// Factory: takes a C# dictionary<id-key: int, name-value: string>
// returns a two way map object with easy access to all keys and values
export const basicDataStructure = (data: BasicDataStructure) => {
  const keyValueMap = data.dict!;
  const sortedKeys = data.sortedKeys;

  return {
    getValue: (key: `${number}` | number) => keyValueMap[key],
    hasKey: (key: `${number}`) => key in keyValueMap,
    sortedKeys,
  };
};

// Console
export const consoleLog = (obj: any, title: string, color: string) => {
  if (!IsProduction) {
    /* eslint no-console: ["error", { allow: ["log"] }] */
    console.log(
      `%c   ${title}   `,
      `background-color: ${color}; color: #050505;`,
      '\n',
      obj
    );
  }
};

consoleLog(
  'The RepairShopRegister is running in DebugMode - If this is a production environment: 1. Check your InitialRequest.iFrameSrc 2. Check RepairShopRegister.Build.cs',
  'OBS!   In Debug Mode   OBS!',
  '#FB82F2'
);
