import { css } from 'styled-components';
import * as constants from 'globalStyles/constants';
import { header3, small_info_text } from 'globalStyles/general-structures';

const commentListGlobalStyle = css`
  .comments-missing-header {
    opacity: ${constants.light_transparent};
  }

  .comments-header {
    display: inline-block;
    margin-bottom: 20px;
  }

  .edit-add-comment {
    float: right;
    margin-top: ${constants.small_distance};
  }

  .comment-head {
    height: 17px;
    width: 100%;
    margin-bottom: ${constants.small_distance};
    vertical-align: bottom;
    .left {
      display: inline-block;
      ${header3}
    }
    .right {
      float: right;
      text-align: right;
      margin-right: 30px;
      margin-top: 2px;
      ${small_info_text}
    }
  }

  .empty-comment-head {
    opacity: ${constants.light_transparent};
    ${header3}
  }

  .comment-text {
    margin-right: calc(
      ${constants.large_distance} + ${constants.medium_distance}
    );
    white-space: pre-wrap;
  }
`;

export default commentListGlobalStyle;
