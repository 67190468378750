import GlobalRouter from 'views/routes/DEP_GlobalRouter';
import {
  PositionType,
  DefaultFallbackPosition,
  Images,
  CarBrandDependentDepartments,
  SearchMode,
} from './Constants';
import { StatusType, InfoTextType } from './TsEnums.gen';
import {
  BasicDataModel,
  BasicDataStructure,
  ExternalPreview,
  RepairShopModel,
} from './api/models';
import { MainStoreState } from 'components/Stores/StoreMain';

export const navigateToStartList = (searchMode: SearchMode) => {
  if (searchMode === SearchMode.Review) {
    GlobalRouter.push('reviewlist/');
  } else if (searchMode === SearchMode.CoupleExternal) {
    GlobalRouter.push('coupleexternal/');
  } else {
    GlobalRouter.push('list/');
  }
};

export const uglifyLink = (link: string) => {
  if (link.startsWith('http://')) {
    return link;
  }
  if (link.startsWith('https://')) {
    return link;
  }
  return `http://${link}`;
};

export const prettifyLink = (link: string) => {
  if (link.startsWith('http://')) {
    return link.substring(7);
  }
  if (link.startsWith('https://')) {
    return link.substring(8);
  }
  return link;
};

type Item = { keyID: number; removeOnUpdate?: boolean };

export const getListIndexByKeyID = <I extends Item>(
  dataList: I[],
  keyID: number
) => {
  return dataList.findIndex((val) => val.keyID === keyID);
};

export const getListByKeyID = <I extends Item>(
  dataList: I[],
  keyID: number
) => {
  return dataList.filter(
    (item) => item.keyID === keyID && !item.removeOnUpdate
  );
};

export const getItemByKeyID = <I extends Item>(
  dataList: I[],
  keyID: number
): I | null => {
  return getListByKeyID(dataList, keyID)[0] || null;
};

export const hasKeyID = <I extends Item>(dataList: I[], keyID: number) => {
  return getItemByKeyID(dataList, keyID) !== null;
};

export const getOnlyActiveOrAllExternal = <Ext extends ExternalPreview>(
  externalInsurance: Ext[]
) => {
  const activeList = externalInsurance.filter((item) => item.isActive);

  if (activeList.length > 0) {
    return activeList;
  }

  return externalInsurance;
};

export const cleanDateNow = () => {
  return new Date().toISOString();
};

export const toDateStr = (utcDateString: string) => {
  const localDate = new Date(utcDateString);

  const YYYY = localDate.getFullYear();
  const MM = (localDate.getMonth() + 1).toString().padStart(2, '0');
  const DD = localDate.getDate().toString().padStart(2, '0');

  return `${YYYY}-${MM}-${DD}`;
};

export const toTimeStr = (utcDateString: string) => {
  const localDate = new Date(utcDateString);

  const HH = localDate.getHours().toString().padStart(2, '0');
  const mm = localDate.getMinutes().toString().padStart(2, '0');

  return `${HH}:${mm}`;
};

export const toDateTimeStr = (utcDateString: string) => {
  const YYYYMMDD = toDateStr(utcDateString);
  const HHmm = toTimeStr(utcDateString);

  return `${YYYYMMDD} ${HHmm}`;
};

// Special department handling
export const isDepartmentCarBrandDependent = (departmentID: number): boolean =>
  departmentID in CarBrandDependentDepartments;

export const isRepairShopCarBrandDependent = (repairShop: RepairShopModel) => {
  const { departments } = repairShop;

  if (departments.length === 0) {
    // By default a repairShop is treated as car brand dependent
    return true;
  }

  for (let i = 0; i < departments.length; i += 1) {
    if (isDepartmentCarBrandDependent(departments[i].keyID)) {
      return true;
    }
  }

  return false;
};

// Input parsing
export const inputParseIntDefault0 = (input: string) => {
  const parsableInput = input.replace(/[^\d]/g, '');

  let iInput = 0;
  if (parsableInput !== '') {
    iInput = parseInt(parsableInput, 10);
  }

  if (isNaN(iInput)) {
    iInput = 0;
  }

  return {
    displayValue: parsableInput,
    returnValue: iInput,
  };
};

export const getInfoName = (
  infoTextType: InfoTextType,
  lowercase = false,
  plural = false
) => {
  let text = '';

  switch (infoTextType) {
    case InfoTextType.PhoneNumber:
      text = 'Telefonnummer';
      break;
    case InfoTextType.Email:
      text = 'E-post';
      break;
    case InfoTextType.Website:
      text = 'Hemsida';
      break;
    case InfoTextType.LockInfo:
      text = 'Lås';
      break;
    case InfoTextType.AlarmInfo:
      text = 'Larm';
      break;
    case InfoTextType.PassCode:
      text = 'Kod';
      break;
    case InfoTextType.Comment:
      text = plural ? 'Kommentarer' : 'Kommentar';
      break;
    case InfoTextType.InsuranceInfo:
      text = 'Försäkringsinfo';
      break;
    default:
      break;
  }

  if (lowercase) {
    text = text.toLowerCase();
  }

  return text;
};

export const getStatusTypeString = (status: StatusType) => {
  switch (status) {
    case StatusType.Duplicate:
      return 'Dublett';
    case StatusType.Removed:
      return 'Borttagen';
    case StatusType.NotReviewed:
      return 'Ej granskad';
    case StatusType.Reviewed:
      return 'Granskad';
    case StatusType.ProposedRemove:
      return 'Borttagen (stängd)';
    case StatusType.ProposedDuplicate:
      return 'Borttagen (dublett)';
    default:
      return 'Status';
  }
};

export const getPositionData = (
  positionType: PositionType,
  mainStoreState: MainStoreState
) => {
  // Utility funktion: get well structured possition state
  if (mainStoreState.availablePositionTypes.indexOf(positionType) === -1) {
    return null;
  }

  switch (positionType) {
    case PositionType.Assignment:
      return {
        latitude: mainStoreState.assignment.position.latitude,
        longitude: mainStoreState.assignment.position.longitude,
        description: 'Uppdragets position',
        icon: Images.PinCar,
        iconFlat: Images.PinCarFlat,
        positionType,
      };
    case PositionType.Custom:
      return {
        latitude: mainStoreState.customPosition!.latitude,
        longitude: mainStoreState.customPosition!.longitude,
        description: 'Utplacerad position',
        icon: Images.PinCustom,
        iconFlat: Images.PinCustomFlat,
        positionType,
      };
    case PositionType.User:
      return {
        latitude: mainStoreState.userInfo.userPosition.latitude,
        longitude: mainStoreState.userInfo.userPosition.longitude,
        description: 'Din bils position',
        icon: Images.PinTruck,
        iconFlat: Images.PinTruckFlat,
        positionType,
      };
    case PositionType.Station:
      return {
        latitude: mainStoreState.userInfo.mainStationPosition.latitude,
        longitude: mainStoreState.userInfo.mainStationPosition.longitude,
        description: 'Stationens position',
        icon: Images.PinStation,
        iconFlat: Images.PinStationFlat,
        positionType,
      };
    default:
      return {
        latitude: DefaultFallbackPosition.latitude,
        longitude: DefaultFallbackPosition.longitude,
        description: 'Landets mittpunkt',
        icon: Images.PinStation,
        iconFlat: Images.PinStationFlat,
        positionType,
      };
  }
};
export const getOwnPositionData = (mainStoreState: MainStoreState) => {
  // Filter out what position with fallback is tied to the user
  if (mainStoreState.availablePositionTypes.indexOf(PositionType.User) > -1) {
    return getPositionData(PositionType.User, mainStoreState);
  } else if (
    mainStoreState.availablePositionTypes.indexOf(PositionType.Station) > -1
  ) {
    return getPositionData(PositionType.Station, mainStoreState);
  }

  return getPositionData(PositionType.DefaultFallback, mainStoreState);
};
export const getActivePositionData = (mainStoreState: MainStoreState) =>
  getPositionData(mainStoreState.activePositionType, mainStoreState);

export const separateSearchFilters = (
  searchString: string,
  carBrands: MainStoreState['basicData']['carBrands'],
  insuranceCompanies: MainStoreState['basicData']['insuranceCompanies'],
  departments: MainStoreState['basicData']['departments']
) => {
  const searchStrings = getCleanSplit(searchString);
  const filteredSearchStrings: string[] = [];
  let brandID = null;
  let insuranceID = null;
  let departmentID = null;

  let matchFound = false;
  searchStrings.forEach((subString) => {
    matchFound = false;

    // Match the substring with each car brand
    carBrands.sortedKeys.forEach((key) => {
      if (isMatchingFilter(subString, carBrands.getValue(key))) {
        brandID = key;
        matchFound = true;
      }
    });

    // Match the substring with each insurance company
    insuranceCompanies.sortedKeys.forEach((key) => {
      if (isMatchingFilter(subString, insuranceCompanies.getValue(key))) {
        insuranceID = key;
        matchFound = true;
      }
    });

    // Match the substring with each department
    departments.sortedKeys.forEach((key) => {
      if (isMatchingFilter(subString, departments.getValue(key))) {
        departmentID = key;
        matchFound = true;
      }
    });

    if (!matchFound) {
      filteredSearchStrings.push(subString);
    }
  });

  return {
    filteredSearchStrings,
    brandID,
    insuranceID,
    departmentID,
  };
};

export const getDistanceString = (distanceKm: number) => {
  if (distanceKm === null) {
    return '';
  } else if (distanceKm < 1) {
    return `${Math.round(distanceKm * 1000)} m`;
  } else if (distanceKm < 10) {
    return `${(Math.round(distanceKm * 10) / 10).toFixed(1)} km`;
  }

  return `${Math.round(distanceKm)} km`;
};

/* eslint no-restricted-properties: [2, { 'object': 'Math', 'property': '' }] */
export const precisionRound = (number: number, precision: number) => {
  const factor = Math.pow(10, precision);
  const tempNumber = number * factor;
  const roundedTempNumber = Math.round(tempNumber);
  return roundedTempNumber / factor;
};

export const strCompare = (a: string, b: string) =>
  a.toLowerCase().localeCompare(b.toLowerCase());

export const createCookie = (
  name: string,
  value: string | number,
  days: number
) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value}${expires}; path=/`;
};

export const readCookie = (name: string) => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
};

export const tryParseInt = (str: string | null, defaultValue: number) => {
  let retValue = defaultValue;
  if (str !== null) {
    if (str.length > 0) {
      if (!isNaN(Number(str))) {
        retValue = parseInt(str, 10);
      }
    }
  }
  return retValue;
};

// Private
const getCleanSplit = (text: string) =>
  getCleanString(text)
    .split(/[ ,|]+/)
    .filter(Boolean);
const getCleanString = (text: string) =>
  text.replace(/[Ëë]/g, 'e').toLowerCase();

const isMatchingFilter = (searchSubString: string, filterString: string) => {
  let matchFound = false;
  getCleanSplit(filterString).forEach((filterSubString) => {
    if (filterSubString === searchSubString) {
      matchFound = true;
    }
  });

  return matchFound;
};
