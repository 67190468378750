import { css } from 'styled-components';
import * as constants from 'globalStyles/constants';
import { vcenter_container } from 'globalStyles/general-structures';

const checkBoxGlobalStyle = css`
  .main-checkbox {
    width: ${constants.checkbox_size};
    height: ${constants.checkbox_size};
    box-shadow: ${constants.extra_subtle_box_shadow};
    border-radius: ${constants.main_border_radius};
    text-align: center;
    overflow: hidden;
    position: relative;

    ${vcenter_container}

    &.radio {
      border-radius: 10px;
      .center-point {
        background-color: ${constants.dark_grey};
        width: 5px;
        height: 5px;
        border-radius: 3px;
        position: absolute;
        top: 7px;
        left: 7px;
      }
    }
    &.inactive {
      background-color: ${constants.background_inactive};
      border: 1px solid ${constants.border_dark};
      cursor: inherit;
    }
    &.active {
      background-color: ${constants.background_light};
      border: 1px solid ${constants.border_dark};
    }
    &.active-checked {
      background-color: ${constants.main_yellow};
      border: 1px solid ${constants.border_yellow};
    }
    .check-icon {
      height: 14px;
      width: 14px;
      position: absolute;
      top: 3px;
      left: 3px;
    }
  }

  .checkbox-outer-padding {
    padding: ${constants.small_distance};
    &.active {
      cursor: pointer;
    }
  }
`;

export default checkBoxGlobalStyle;
