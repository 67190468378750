import styled from 'styled-components';
import * as constants from 'globalStyles/constants';
import { Images } from 'general/Constants';

const Select = styled.select`
  padding: 0.5rem;
  border: 1px solid ${constants.border_dark};
  border-radius: ${constants.main_border_radius};
  box-shadow: ${constants.input_box_shadow};
  appearance: none;
  background-color: ${constants.background_light};
  background-image: url(${Images.MenuArrowDown});
  background-repeat: no-repeat;
  background-position: right 0.5em center;
  background-size: 1em;
`;

export default Select;
