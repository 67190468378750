import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '../Button/Button';
import { toDateTimeStr } from 'general/UtilityFunctions';
import { Images } from 'general/Constants';

export default class CommentList extends Component {
  render() {
    const { comments, handleClickAdd, handleRemove, allowEdit } = this.props;
    const hasComments = comments.length > 0;

    const sortByModifeied = (a, b) => {
      if (a.dateModified < b.dateModified) {
        return 1;
      }
      if (a.dateModified > b.dateModified) {
        return -1;
      }
      return 0;
    };

    const header = classNames({
      'comments-header': true,
      'standard-header2': true,
      'comments-missing-header': !hasComments,
    });

    return (
      <div>
        <div className={header}>
          {hasComments ? 'Kommentarer' : 'Inga kommentarer...'}
        </div>
        {allowEdit && (
          <Button
            yellow
            wMain
            hSmall
            text="Kommentera"
            className="edit-add-comment"
            onClick={() => handleClickAdd('')}
          />
        )}

        {hasComments && (
          <div>
            {comments.sort(sortByModifeied).map((comment) => {
              if (!comment.removeOnUpdate) {
                return (
                  <div className="main-card" key={comment.editsIdentifier}>
                    {allowEdit && (
                      <div
                        className="input-rightside-button"
                        onClick={() => handleRemove(comment)}
                      >
                        <img
                          src={Images.TrashCanGrey}
                          alt="Remove"
                          className="remove-icon"
                        />
                      </div>
                    )}
                    <div className="comment-head">
                      <div className="left">{comment.modifiedBy}</div>
                      <div className="right">
                        {toDateTimeStr(comment.dateModified)}
                      </div>
                    </div>
                    <div className="comment-text">{comment.value}</div>
                  </div>
                );
              }
              return null;
            })}
          </div>
        )}
      </div>
    );
  }
}

CommentList.propTypes = {
  comments: PropTypes.array.isRequired,
  handleClickAdd: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
  allowEdit: PropTypes.bool,
};

CommentList.defaultProps = {
  allowEdit: true,
};
