import { Component } from 'react';
import PropTypes from 'prop-types';

import { Actions } from 'general/Actions';

import Button from '../../Button/Button';
import Checkbox from '../../Checkbox/Checkbox';

import { PopupContainer } from '../PopupComponents';

class AssignmentDataToRepairShopPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      brandAdded: false,
      insuranceAdded: false,
    };

    this.toggleBrandAdded = this.toggleBrandAdded.bind(this);
    this.toggleInsuranceAdded = this.toggleInsuranceAdded.bind(this);
  }

  toggleBrandAdded() {
    this.setState({ brandAdded: !this.state.brandAdded });
  }

  toggleInsuranceAdded() {
    this.setState({ insuranceAdded: !this.state.insuranceAdded });
  }

  render() {
    const {
      settings,
      conitnueSelect,
      name,
      addBrandId,
      addInsuranceId,
      basicData,
    } = this.props;
    const { close } = settings;
    const { carBrands, insuranceCompanies } = basicData;
    const { insuranceOptions } = basicData.insuranceSettings;
    const { brandAdded, insuranceAdded } = this.state;

    const lockedInsurance =
      addInsuranceId !== null &&
      insuranceOptions[addInsuranceId].lockedOnRepairShop;

    let assignmentInfo = 'Ditt uppdrag har';
    let repairShopInfo = `Den här inställningen saknas för ${name}.`;
    let extraInfo = '';
    if (addBrandId !== null) {
      assignmentInfo += ` bilmärket: ${carBrands.getValue(addBrandId)}`;

      if (addInsuranceId !== null) {
        assignmentInfo += ` och försäkringsbolaget: ${insuranceCompanies.getValue(
          addInsuranceId
        )}`;
        repairShopInfo = `Dessa inställningar saknas för ${name}.`;
      }

      extraInfo =
        'OBS! Spara endast bilmärke om uppdraget gäller Motor, Plåt eller Tungt fordon. ';
    } else if (addInsuranceId !== null) {
      assignmentInfo += ` försäkringsbolaget: ${insuranceCompanies.getValue(
        addInsuranceId
      )}`;
    }
    assignmentInfo += '.';

    if (lockedInsurance) {
      extraInfo += `${insuranceCompanies.getValue(
        addInsuranceId
      )} är låst från att tas bort eller läggas till på verkstäder. `;
      extraInfo += 'Kontakta system-admin om ändringar behöver göras. ';
    }

    return (
      <PopupContainer
        close={close}
        headerText="Vill du komplettera verkstaden?"
        medium
      >
        <div className="popup-line">{assignmentInfo}</div>
        <div className="small-padder-v"></div>
        <div className="popup-line">{repairShopInfo}</div>
        <div className="small-padder-v"></div>
        {addBrandId !== null && (
          <div className="popup-line">
            <Checkbox
              checked={brandAdded}
              outerPadding
              handleClick={this.toggleBrandAdded}
            />
            <div>Spara</div> <b>{carBrands.getValue(addBrandId)}</b>{' '}
            <div>på verkstaden</div>
          </div>
        )}
        {addInsuranceId !== null && (
          <div className="popup-line">
            <Checkbox
              checked={insuranceAdded}
              outerPadding
              handleClick={this.toggleInsuranceAdded}
              inactive={lockedInsurance}
            />
            <div>Spara</div>{' '}
            <b>{insuranceCompanies.getValue(addInsuranceId)}</b>{' '}
            <div>på verkstaden</div>
          </div>
        )}
        {extraInfo !== '' && (
          <div>
            <div className="small-padder-v"></div>
            <div className="popup-line">{extraInfo}</div>
          </div>
        )}
        <div className="popup-buttons-box">
          <Button
            yellow
            wLarge
            hMain
            text={brandAdded || insuranceAdded ? 'Spara' : 'Gå vidare'}
            loadingOnClick
            onClick={() => {
              if (brandAdded || insuranceAdded) {
                if (brandAdded) {
                  Actions.toggleCarBrand(addBrandId);
                }
                if (insuranceAdded) {
                  Actions.toggleInsuranceCompany(addInsuranceId);
                }

                Actions.saveRepairShop(conitnueSelect);
              } else {
                conitnueSelect();
              }
            }}
          />
        </div>
      </PopupContainer>
    );
  }
}
AssignmentDataToRepairShopPopup.propTypes = {
  settings: PropTypes.object.isRequired,
  conitnueSelect: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  addBrandId: PropTypes.number,
  addInsuranceId: PropTypes.number,
  basicData: PropTypes.object.isRequired,
};
AssignmentDataToRepairShopPopup.defaultProps = {
  addBrandId: null,
  addInsuranceId: null,
};

export default AssignmentDataToRepairShopPopup;
