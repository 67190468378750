import { FC, useRef } from 'react';

import { useStoreMain } from 'components/Stores/StoreMain';
import {
  isRepairShopCarBrandDependent,
  hasKeyID,
  getItemByKeyID,
} from 'general/UtilityFunctions';

import ScrollArea, { ScrollbarFlags } from 'components/Scrollbar';
import Checkbox from 'components/Checkbox/Checkbox';

import { RepairShopModel } from 'general/api/models';
import { Actions } from 'general/Actions';
import {
  AllBrandsId,
  AuthorizedDepartments,
  WarrantyDepartments,
} from 'general/Constants';
import { PopupTypeStr } from 'components/Popup/PopupTypes';
import { PopupType } from 'components/Popup/Popup';
import styled from 'styled-components';
import * as constants from 'globalStyles/constants';

const Wrapper = styled.div`
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Head = styled.div`
  padding: 0 ${constants.small_distance};
`;

interface Props {
  repairShop: RepairShopModel;
  editMode?: boolean;
  setPopup: (popup: PopupTypeStr, popupProps?: any) => void;
}

const AKCarBrandsView: FC<Props> = ({ repairShop, editMode, setPopup }) => {
  const { basicData } = useStoreMain();
  const { carBrands } = basicData;

  const openAuthRecommendationOnce = useRef(true);
  const openWarrantyRecommendationOnce = useRef(true);

  const carBrandDependent = isRepairShopCarBrandDependent(repairShop);

  const lacksAuthDepartments = AuthorizedDepartments.some(
    (key) => !hasKeyID(repairShop.departments, key)
  );
  const lacksWarrantyDepartments = WarrantyDepartments.some(
    (key) => !hasKeyID(repairShop.departments, key)
  );

  return (
    <Wrapper>
      <Head>
        <div className="second-head">
          <span>Bilmärken</span>
          <span className="header-extra-info">
            {repairShop.carBrands.length} av {carBrands.sortedKeys.length}
          </span>
          <div className="brand-rightside">
            <div className="auth" title="Auktoriserad">
              Aukt.
            </div>
            <div className="vsg" title="Vagnskadegaranti">
              VSG
            </div>
          </div>
        </div>
      </Head>

      <Wrapper>
        <ScrollArea
          rightPadding={ScrollbarFlags.Padding.None}
          leftPadding={ScrollbarFlags.Padding.None}
        >
          {!editMode && (
            <>
              {!carBrandDependent && repairShop.carBrands.length > 0 && (
                <div className="list-top-info-box red">
                  OBS! Verkstaden saknar verkstadstyp där bilmärken har
                  betydelse. Verkstadstyper som är märkesstyrda är: Motor, Plåt,
                  MC och Tunga fordon.
                </div>
              )}
              {!carBrandDependent && repairShop.carBrands.length === 0 && (
                <div className="list-top-info-box green">
                  Verkstaden har enbart verkstadstyper där bilmärken ej har
                  betydelse.
                </div>
              )}
            </>
          )}

          {carBrands.sortedKeys.map((key) => {
            const carBrand = getItemByKeyID(repairShop.carBrands, key);

            if (!editMode && !carBrand) return null;

            return (
              <div className="main-item alternate-shade" key={key}>
                {editMode && (
                  <Checkbox
                    checked={!!carBrand}
                    handleClick={() => Actions.toggleCarBrand(key)}
                    outerPadding
                  />
                )}
                <div className={editMode ? undefined : 'item-left'}>
                  {carBrands.getValue(key)}
                </div>
                {!!carBrand && (
                  <div className="brand-rightside">
                    <div className="auth">
                      <Checkbox
                        checked={carBrand.isAuthorized}
                        handleClick={
                          editMode
                            ? () => {
                                if (
                                  !carBrand.isAuthorized &&
                                  openAuthRecommendationOnce.current &&
                                  lacksAuthDepartments
                                ) {
                                  openAuthRecommendationOnce.current = false;
                                  setPopup(
                                    PopupType.RecommendRepairShopDepartmentsPopup,
                                    {
                                      repairShop,
                                      basicData,
                                      auth: true,
                                    }
                                  );
                                }
                                Actions.toggleAuthorized(key);
                              }
                            : undefined
                        }
                        outerPadding={editMode}
                        inactive={!editMode || key === AllBrandsId}
                      />
                    </div>
                    <div className="vsg">
                      <Checkbox
                        checked={carBrand.hasWarranty}
                        handleClick={() => {
                          if (
                            !carBrand.hasWarranty &&
                            openWarrantyRecommendationOnce.current &&
                            lacksWarrantyDepartments
                          ) {
                            openWarrantyRecommendationOnce.current = false;
                            setPopup(
                              PopupType.RecommendRepairShopDepartmentsPopup,
                              {
                                repairShop,
                                basicData,
                                warranty: true,
                              }
                            );
                          }
                          Actions.toggleWarranty(key);
                        }}
                        outerPadding={editMode}
                        inactive={!editMode || key === AllBrandsId}
                      />
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </ScrollArea>
      </Wrapper>
    </Wrapper>
  );
};

export default AKCarBrandsView;
