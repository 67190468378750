import React, { Component } from 'react';
import PropTypes from 'prop-types';
import keyMirror from 'keymirror';

import AddCommentPopup from './AllPopups/AddCommentPopup';
import AssignmentDataToRepairShopPopup from './AllPopups/AssignmentDataToRepairShopPopup';
import AssignmentInfoPopup from './AllPopups/AssignmentInfoPopup';
import AssignmentInsuranceInfoPopup from './AllPopups/AssignmentInsuranceInfoPopup';
import DiscardChangesPopup from './AllPopups/DiscardChangesPopup';
import ErrorPopup from './AllPopups/ErrorPopup';
import FindRepairShopPopup from './AllPopups/FindRepairShopPopup';
import GeneralRegisterInfoPopup from './AllPopups/GeneralRegisterInfoPopup';
import InsuranceInfoPopup from './AllPopups/InsuranceInfoPopup';
import InsuranceInfoListPopup from './AllPopups/InsuranceInfoListPopup';
import MasterInfoPopup from './AllPopups/MasterInfoPopup';
import RemoveItemPopup from './AllPopups/RemoveItemPopup';
import RemoveShopPopup from './AllPopups/RemoveShopPopup';
import RepairShopExternalInsurancePopup from './AllPopups/RepairShopExternalInsurancePopup';
import ReviewInfoPopup from './AllPopups/ReviewInfoPopup';
import SetAddressFromMapPopup from './AllPopups/SetAddressFromMapPopup';
import SetInitialDepartmentPopup from './AllPopups/SetInitialDepartmentPopup';
import MidlandPopup from './AllPopups/MidlandPopup';
import AdminOptionsPopup from './AllPopups/AdminOptionsPopup';
import CoupleExternalConfirmPopup from './AllPopups/CoupleExternalConfirmPopup';
import EditExternalInfoPopup from './AllPopups/EditExternalInfoPopup';
import RecommendRepairShopDepartmentsPopup from './AllPopups/RecommendRepairShopDepartmentsPopup';
import UpdateInsuranceSettingsPopup from './AllPopups/UpdateInsuranceSettingsPopup';
import ConnectInsuranceToAssistPopup from './AllPopups/ConnectInsuranceToAssistPopup';
import MissingPositionInfoPopup from './AllPopups/MissingPositionInfoPopup';

/**
 * @typedef { import("./PopupTypes").PopupTypeStr } PopupTypeStr
 * @typedef { import("./PopupTypes").PopupSettings } PopupSettings
 * @typedef { import("./PopupTypes").PopupProps } PopupProps
 */

/** Master function that updates the view state to display the desired popup
 * @argument {React.Component<any, { popupProps: PopupProps }>} viewInstance The popup's parent component (which keeps the state)
 * @argument {PopupTypeStr} popupType
 * @argument {Object | undefined} customProps
 */
export const setPopup = (viewInstance, popupType, customProps = {}) => {
  const close = () => {
    viewInstance.setState({ popupProps: {} });
  };

  const reOpenThisPopup = () => {
    setPopup(viewInstance, popupType, customProps);
  };

  /** @type {PopupSettings['openOtherPopup']} */
  const openOtherPopup = (nextPopupType, nextCustomProps = {}) => {
    const nextPopupPropsWithBack = Object.assign({}, nextCustomProps, {
      settings: Object.assign(
        {},
        {
          back: reOpenThisPopup,
        },
        nextCustomProps.settings
      ),
    });

    setPopup(viewInstance, nextPopupType, nextPopupPropsWithBack);
  };

  /** @type {PopupProps} */
  const popupProps = Object.assign({}, customProps, {
    settings: Object.assign(
      {},
      {
        popupType,
        close,
        openOtherPopup,
      },
      customProps.settings
    ),
  });

  viewInstance.setState({ popupProps });
};

/**
 * @returns {{ popupState: PopupProps | null, openPopup: (popupType: PopupTypeStr, customProps?: Object | undefined) => void }}
 */
export const usePopupState = () => {
  /**
   * @type {[PopupProps | null, React.Dispatch<React.SetStateAction<PopupProps | null>>]}
   */
  const [popupState, setPopupState] = React.useState(null);

  /**
   * @argument {PopupTypeStr} popupType
   * @argument {Object | undefined} customProps
   */
  const openPopup = (popupType, customProps = {}) => {
    const close = () => {
      setPopupState(null);
    };

    const reOpenThisPopup = () => {
      openPopup(popupType, customProps);
    };

    /** @type {PopupSettings['openOtherPopup']} */
    const openOtherPopup = (nextPopupType, nextCustomProps = {}) => {
      const nextPopupPropsWithBack = {
        ...nextCustomProps,
        settings: {
          back: reOpenThisPopup,
          ...nextCustomProps.settings,
        },
      };

      openPopup(nextPopupType, nextPopupPropsWithBack);
    };

    /** @type {PopupProps} */
    const popupProps = Object.assign({}, customProps, {
      settings: Object.assign(
        {},
        {
          popupType,
          close,
          openOtherPopup,
        },
        customProps.settings
      ),
    });

    setPopupState(popupProps);
  };

  return {
    popupState,
    openPopup,
  };
};

export class Popup extends Component {
  render() {
    const { settings } = this.props;

    if (!settings || !settings.popupType) {
      return null;
    }

    let popup = null;

    switch (settings.popupType) {
      case PopupType.AddCommentPopup:
        popup = <AddCommentPopup {...this.props} />;
        break;

      case PopupType.AssignmentDataToRepairShopPopup:
        popup = <AssignmentDataToRepairShopPopup {...this.props} />;
        break;

      case PopupType.AssignmentInfoPopup:
        popup = <AssignmentInfoPopup {...this.props} />;
        break;

      case PopupType.AssignmentInsuranceInfoPopup:
        popup = <AssignmentInsuranceInfoPopup {...this.props} />;
        break;

      case PopupType.DiscardChangesPopup:
        popup = <DiscardChangesPopup {...this.props} />;
        break;

      case PopupType.ErrorPopup:
        popup = <ErrorPopup {...this.props} />;
        break;

      case PopupType.FindRepairShopPopup:
        popup = <FindRepairShopPopup {...this.props} />;
        break;

      case PopupType.GeneralRegisterInfoPopup:
        popup = <GeneralRegisterInfoPopup {...this.props} />;
        break;

      case PopupType.InsuranceInfoPopup:
        popup = <InsuranceInfoPopup {...this.props} />;
        break;

      case PopupType.InsuranceInfoListPopup:
        popup = <InsuranceInfoListPopup {...this.props} />;
        break;

      case PopupType.MasterInfoPopup:
        popup = <MasterInfoPopup {...this.props} />;
        break;

      case PopupType.RemoveItemPopup:
        popup = <RemoveItemPopup {...this.props} />;
        break;

      case PopupType.RemoveShopPopup:
        popup = <RemoveShopPopup {...this.props} />;
        break;

      case PopupType.RepairShopExternalInsurancePopup:
        popup = <RepairShopExternalInsurancePopup {...this.props} />;
        break;

      case PopupType.ReviewInfoPopup:
        popup = <ReviewInfoPopup {...this.props} />;
        break;

      case PopupType.SetAddressFromMapPopup:
        popup = <SetAddressFromMapPopup {...this.props} />;
        break;

      case PopupType.SetInitialDepartmentPopup:
        popup = <SetInitialDepartmentPopup {...this.props} />;
        break;

      case PopupType.MidlandPopup:
        popup = <MidlandPopup {...this.props} />;
        break;

      case PopupType.AdminOptionsPopup:
        popup = <AdminOptionsPopup {...this.props} />;
        break;

      case PopupType.CoupleExternalConfirmPopup:
        popup = <CoupleExternalConfirmPopup {...this.props} />;
        break;

      case PopupType.EditExternalInfoPopup:
        popup = <EditExternalInfoPopup {...this.props} />;
        break;

      case PopupType.RecommendRepairShopDepartmentsPopup:
        popup = <RecommendRepairShopDepartmentsPopup {...this.props} />;
        break;

      case PopupType.UpdateInsuranceSettingsPopup:
        popup = <UpdateInsuranceSettingsPopup {...this.props} />;
        break;

      case PopupType.ConnectInsuranceToAssistPopup:
        popup = <ConnectInsuranceToAssistPopup {...this.props} />;
        break;

      case PopupType.MissingPositionInfoPopup:
        popup = <MissingPositionInfoPopup {...this.props} />;
        break;

      default:
        popup = null;
        break;
    }

    return popup;
  }
}
Popup.propTypes = {
  settings: PropTypes.object,
};
Popup.defaultProps = {
  settings: null,
};

export const PopupType = keyMirror({
  AddCommentPopup: null,
  AssignmentDataToRepairShopPopup: null,
  AssignmentInfoPopup: null,
  AssignmentInsuranceInfoPopup: null,
  DiscardChangesPopup: null,
  ErrorPopup: null,
  FindRepairShopPopup: null,
  GeneralRegisterInfoPopup: null,
  InsuranceInfoPopup: null,
  InsuranceInfoListPopup: null,
  MasterInfoPopup: null,
  RemoveItemPopup: null,
  RemoveShopPopup: null,
  RepairShopExternalInsurancePopup: null,
  ReviewInfoPopup: null,
  SetAddressFromMapPopup: null,
  SetInitialDepartmentPopup: null,
  MidlandPopup: null,
  AdminOptionsPopup: null,
  CoupleExternalConfirmPopup: null,
  EditExternalInfoPopup: null,
  RecommendRepairShopDepartmentsPopup: null,
  UpdateInsuranceSettingsPopup: null,
  ConnectInsuranceToAssistPopup: null,
  MissingPositionInfoPopup: null,
});
