import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { StatusType } from 'general/TsEnums.gen';
import { getDistanceString, toDateStr } from 'general/UtilityFunctions';
import GlobalRouter from 'views/routes/DEP_GlobalRouter';

export default class ReviewListRow extends Component {
  render() {
    const { shop, index, isLast } = this.props;

    const rowClass = classNames({
      'search-list-row': true,
      'main-item': true,
      clickable: true,
      shade: index % 2 === 1,
      last: index % 2 === 0 && isLast,
    });

    const hasProposedRemove =
      shop.status === StatusType.ProposedRemove ||
      shop.status === StatusType.ProposedDuplicate;

    let boldStatus = false;
    if (hasProposedRemove || shop.status === StatusType.NotReviewed) {
      boldStatus = true;
    }

    const statusClass = classNames({
      status: true,
      bold: boldStatus,
    });

    let historyLink = '/history';
    if (
      // User must view the removed shop and either accept removal or restore the shop
      hasProposedRemove ||
      // No history to show
      shop.changeLogCount === 0 ||
      // The shop has never been reviewed or changed by an admin
      (shop.dateReviewed === null && shop.status === StatusType.Reviewed)
    ) {
      historyLink = '';
    }

    return (
      <div
        className={rowClass}
        onClick={() => {
          GlobalRouter.push(`repairshop/${shop.id}${historyLink}`);
        }}
      >
        <div className="name review" title={`ID: ${shop.id}`}>
          <div>{shop.name}</div>
        </div>
        <div className="address review">
          <div className="line1">{shop.city}</div>
          <div className="line2">{shop.address}</div>
        </div>
        <div className="changed-by">{shop.modifiedBy}</div>
        <div className="last-changed">{toDateStr(shop.dateModified)}</div>
        <div className={statusClass}>{shop.reviewStatus}</div>
        <div className="right-absolute">
          <div className="distance review">
            {getDistanceString(shop.distanceKm)}
          </div>
        </div>
      </div>
    );
  }
}
ReviewListRow.propTypes = {
  shop: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  isLast: PropTypes.bool.isRequired,
};
