import * as constants from 'globalStyles/constants';
import { css } from 'styled-components';
import {
  vcenter_container,
  small_info_text,
} from 'globalStyles/general-structures';

const utilityComponentsGlobalStyle = css`
  .department-text-wrapper {
    width: auto;
    height: auto;
    ${vcenter_container};
    .character {
      min-width: 5px;
      position: relative;
      text-align: center;
      .underline {
        position: absolute;
        top: 16px;
        left: 0;
        height: 1px;
        width: 100%;
        background-color: ${constants.main_black};
      }
    }
  }

  .directionContainer {
    margin-left: calc(${constants.large_distance} * 2);
    height: 100%;
    ${vcenter_container};
    .icon {
      height: 66.666%;
    }
    .texts {
      margin-left: ${constants.small_distance};
      height: 100%;
      width: 150px;
      position: relative;
    }
    .main-text {
      margin-top: 9px;
      width: 100%;
      font-weight: bold;
    }
    .sub-text {
      width: 100%;
      position: relative;
      top: -3px;
      &:last-of-type {
        top: -6px;
      }
      ${small_info_text};
    }
  }

  .get-driving-direction-button {
    margin-left: calc(${constants.large_distance} * 2);
    width: 150px;
  }
`;

export default utilityComponentsGlobalStyle;
