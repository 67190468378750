import EditInfoTexts from 'components/EditInfoTexts/EditInfoTexts';
import { PopupType } from 'components/Popup/Popup';
import { PopupTypeStr } from 'components/Popup/PopupTypes';
import ScrollArea, { ScrollbarFlags } from 'components/Scrollbar';
import { useStoreMain } from 'components/Stores/StoreMain';
import { useStoreRepairShop } from 'components/Stores/StoreRepairShop';
import { Actions } from 'general/Actions';
import { DepartmentType } from 'general/TsEnums.gen';
import {
  getInfoName,
  getItemByKeyID,
  getListByKeyID,
  hasKeyID,
  toDateTimeStr,
} from 'general/UtilityFunctions';
import {
  InfoTextModel,
  InfoTextType,
  InsuranceCompanyModel,
} from 'general/api/models';
import { FC, useRef } from 'react';
import InsuranceRow from './InsuranceRow';

const br = new Array(500).join(' ');

interface Props {
  setPopup: (popup: PopupTypeStr, popupProps?: any) => void;
  editMode?: boolean;
}

const InsurancesPanel: FC<Props> = ({ setPopup, editMode }) => {
  const mainStore = useStoreMain();
  const repairShopStore = useStoreRepairShop();

  const openInsuranceEngineRecommendationOnce = useRef(true);
  const openInsurancePlateRecommendationOnce = useRef(true);

  const { userInfo, basicData } = mainStore;
  const {
    insuranceCompanies,
    insuranceSettings: { insuranceOptions },
  } = basicData;
  const { repairShop } = repairShopStore;

  if (!repairShop) {
    return null;
  }

  const openRemoveInfoPopup = (infoText: InfoTextModel) => {
    setPopup(PopupType.RemoveItemPopup, {
      headerText: `Vill du ta bort ${getInfoName(infoText.keyID, true)}?`,
      infoText: infoText.value,
      handleRemove: (close: () => void) => {
        Actions.setInfoText('', infoText);
        close();
      },
    });
  };

  const handleToggleInsuranceAllCarBrandsEngine = (
    insurance: InsuranceCompanyModel
  ) => {
    if (
      !insurance.allowAllCarBrandsEngine &&
      openInsuranceEngineRecommendationOnce.current &&
      !hasKeyID(repairShop.departments, DepartmentType.Engine)
    ) {
      openInsuranceEngineRecommendationOnce.current = false;
      setPopup(PopupType.RecommendRepairShopDepartmentsPopup, {
        repairShop,
        basicData,
        insuranceEngine: true,
      });
    }
    Actions.toggleAllCarBrandsEngine(insurance.keyID);
  };

  const handleToggleInsuranceAllCarBrandsPlate = (
    insurance: InsuranceCompanyModel
  ) => {
    if (
      !insurance.allowAllCarBrandsPlate &&
      openInsurancePlateRecommendationOnce.current &&
      !hasKeyID(repairShop.departments, DepartmentType.Plate)
    ) {
      openInsurancePlateRecommendationOnce.current = false;
      setPopup(PopupType.RecommendRepairShopDepartmentsPopup, {
        repairShop,
        basicData,
        insurancePlate: true,
      });
    }
    Actions.toggleAllCarBrandsPlate(insurance.keyID);
  };

  let insuranceCount = 0;
  insuranceCompanies.sortedKeys.forEach((key) => {
    if (hasKeyID(repairShop.insuranceCompanies, key)) {
      insuranceCount += 1;
    }
  });

  // Prepped display info texts
  const inhouseInsuranceInfo = getItemByKeyID(
    repairShop.infoTexts,
    InfoTextType.InsuranceInfo
  );

  return (
    <div className="column">
      <div className="column-head">
        <div className="second-head">
          <span>Försäkringar</span>
          <span className="header-extra-info">
            {insuranceCount} av {insuranceCompanies.sortedKeys.length}
          </span>
          <div
            title="Försäkringsbolaget tillåter att verkstaden hanterar alla bilmärken för Motor / Plåt-skador"
            className="insurance-rightside"
          >
            <div className="framing">
              <div className="text">Alla bilmärken</div>
            </div>
            <div className="engine header">Motor</div>
            <div className="plate header">Plåt</div>
          </div>
        </div>
      </div>
      <div className="main-list-container">
        <ScrollArea
          rightPadding={ScrollbarFlags.Padding.Main}
          leftPadding={ScrollbarFlags.Padding.Main}
        >
          <div>
            {insuranceCompanies.sortedKeys.map((insuranceCompanyKey) => {
              const insurance = getItemByKeyID(
                repairShop.insuranceCompanies,
                insuranceCompanyKey
              );
              const lockEdit =
                !editMode ||
                (insuranceOptions[insuranceCompanyKey].lockedOnRepairShop &&
                  !userInfo.access.canAdmin);

              return (
                <InsuranceRow
                  key={insuranceCompanyKey}
                  editMode={!!editMode}
                  lockEdit={lockEdit}
                  insurance={insurance}
                  insuranceCompanyKey={insuranceCompanyKey}
                  insuranceCompanyName={insuranceCompanies.getValue(
                    insuranceCompanyKey
                  )}
                  repairShop={repairShop}
                  setPopup={setPopup}
                  onToggleAllCarBrandsEngine={
                    handleToggleInsuranceAllCarBrandsEngine
                  }
                  onToggleAllCarBrandsPlate={
                    handleToggleInsuranceAllCarBrandsPlate
                  }
                />
              );
            })}
          </div>

          {editMode ? (
            <div className="edit-line">
              <div className="input-header">Intern försäkringsinfo</div>
              <EditInfoTexts
                infoTexts={getListByKeyID(
                  repairShop.infoTexts,
                  InfoTextType.InsuranceInfo
                )}
                infoTextType={InfoTextType.InsuranceInfo}
                placeholder={`+ Lägg till intern försäkringsinfo${br}- - - -${br}T.ex:${br}- - - -${br}Ej plåt Trygg Hansa${br}Gjensidige: Prio 1 Motor`}
                handleRemove={openRemoveInfoPopup}
                textArea
                className="edit-info-textarea"
              />
            </div>
          ) : (
            <>
              <div className="medium-padder-v"></div>

              {inhouseInsuranceInfo !== null ? (
                <div
                  className="main-card"
                  title={`Senast ändrad av ${
                    inhouseInsuranceInfo.modifiedBy
                  } | ${toDateTimeStr(inhouseInsuranceInfo.dateModified)}`}
                >
                  <div className="comment-head">
                    <div className="left">Intern försäkringsinfo</div>
                  </div>
                  <div className="comment-text">
                    {inhouseInsuranceInfo.value}
                  </div>
                </div>
              ) : (
                <div className="main-card">
                  <div className="empty-comment-head">
                    Ingen intern försäkringsinfo
                  </div>
                </div>
              )}
            </>
          )}
        </ScrollArea>
      </div>
    </div>
  );
};

export default InsurancesPanel;
