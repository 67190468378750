import * as constants from 'globalStyles/constants';
import { css } from 'styled-components';
import {
  vcenter_container,
  rightside_vcenter_container,
} from 'globalStyles/general-structures';

const searchListMapGlobalStyle = css`
  .search-list-map {
    width: 100%;
    height: 100%;
    position: relative;
    .left-column {
      width: 28%;
      height: 100%;
      display: inline-block;
      padding-left: ${constants.large_distance};
    }
    .right-column {
      width: 72%;
      height: 100%;
      display: inline-block;
      padding: ${constants.large_distance};
      vertical-align: top;
      .inner {
        width: 100%;
        height: 100%;
        overflow: hidden;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
      }
    }
    .position-row {
      width: 100%;
      height: ${constants.main_item_height};
      padding-left: 4px;
      border-bottom: 1px solid ${constants.background_shade};
      ${vcenter_container};
      .faded {
        opacity: 0.333;
      }
      .description {
        font-weight: bold;
      }
      .right {
        .icon {
          height: 66.666%;
        }
        ${rightside_vcenter_container};
      }
    }
    .search-info {
      text-align: center;
      width: 90%;
      margin-left: 5%;
      margin-top: ${constants.large_distance};
      opacity: ${constants.main_transparent};
      font-weight: bold;
    }
    .matches-list {
      width: 100%;
      height: 286px;
      .name {
        width: 195px;
      }
    }
    .select-position-info {
      position: absolute;
      width: 772px;
      top: 4px;
      text-align: center;
      color: ${constants.green_text};
      font-size: ${constants.small_text};
      font-weight: bold;
    }
  }

  @keyframes map-row-animation {
    0% {
      opacity: 0.001;
      background-color: ${constants.main_yellow};
      border-color: ${constants.main_yellow};
    }
    33% {
      opacity: 1;
      background-color: ${constants.main_yellow};
      border-color: ${constants.main_yellow};
    }
    100% {
      background-color: ${constants.pure_white};
      border-color: ${constants.main_green};
    }
  }

  .map-row-animation {
    opacity: 1;
    animation-name: map-row-animation;
    animation-duration: 1.3s;
  }
`;

export default searchListMapGlobalStyle;
