import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ScrollArea, { ScrollbarFlags } from '../Scrollbar';
import { getFilteredHistory } from './HistoryUtility';
import renderChangeRow from './HistoryChangeRow';
import { StatusType, ChangeLogType } from 'general/TsEnums.gen';
import { toDateTimeStr } from 'general/UtilityFunctions';

export default class RepairShopHistory extends Component {
  render() {
    const { mainStore, repairShop, resetHandlerList, toggleChangeReset } =
      this.props;
    const { basicData } = mainStore;
    const changeLogOperations = getFilteredHistory(
      repairShop.changeLogOperations
    );

    return (
      <div className="list-area no-padding">
        <ScrollArea
          rightPadding={ScrollbarFlags.Padding.Main}
          leftPadding={ScrollbarFlags.Padding.Main}
        >
          {changeLogOperations.length === 0 && (
            <div>
              <div className="special-case-no-history">
                Ändringshistorik för verkstaden kunde inte hämtas (inga större
                ändringar har genomförts).
              </div>
              {repairShop.status === StatusType.NotReviewed && (
                <div className="special-case-no-history-review">
                  Verkstaden bör ändå granskas och godkännas.
                </div>
              )}
            </div>
          )}
          {changeLogOperations.map((operation) => {
            let countAsReviewed = operation.isReviewed;
            let reviewdText = 'Godkänd';

            if (
              operation.changeLogs.some(
                (change) => change.changeType === ChangeLogType.Reviewed
              )
            ) {
              // LEGACY: Check for instances where review date is inaccurate
              countAsReviewed = true;
            }
            if (
              operation.changeLogs.every(
                (change) =>
                  change.changeType === ChangeLogType.ExternalCarBrandInfo ||
                  change.changeType === ChangeLogType.ExternalGeneralInfo
              )
            ) {
              // Auto sync will update only external info (allways count as reviewed)
              countAsReviewed = true;
              reviewdText += ' (auto)';
            }

            return (
              <div
                key={operation.operationGuid}
                className="repair-shop-history-card"
              >
                <div className="card-header">
                  <div>
                    <div
                      className={
                        countAsReviewed ? 'green-marker-dot' : 'red-marker-dot'
                      }
                    ></div>
                    <div className="review-text">
                      {countAsReviewed ? reviewdText : 'Behöver godkännas'}
                    </div>
                  </div>
                  <div className="right">
                    <div className="user-date-text">{operation.createdBy}</div>
                    <div className="user-date-text">
                      {toDateTimeStr(operation.dateCreated)}
                    </div>
                  </div>
                </div>
                <div className="changes-list">
                  <div className="sub-card-header">
                    <div className="change-info">Ändring</div>
                    <div className="change-from-to">Från</div>
                    <div className="change-from-to">Till</div>
                    <div className="change-reset">Återställ</div>
                  </div>
                  <div>
                    {operation.changeLogs.map((change) =>
                      renderChangeRow(
                        change,
                        resetHandlerList.findIndex(
                          (reset) => reset.change.id === change.id
                        ) !== -1,
                        toggleChangeReset,
                        basicData,
                        repairShop
                      )
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </ScrollArea>
      </div>
    );
  }
}
RepairShopHistory.propTypes = {
  mainStore: PropTypes.object.isRequired,
  repairShop: PropTypes.object.isRequired,
  resetHandlerList: PropTypes.array.isRequired,
  toggleChangeReset: PropTypes.func.isRequired,
};
