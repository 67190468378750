import { Component } from 'react';
import PropTypes from 'prop-types';

import Button from '../../Button/Button';

import { PopupContainer } from '../PopupComponents';

class CoupleExternalConfirmPopup extends Component {
  render() {
    const { settings, confirm, onClose, external, repairShop } = this.props;
    const close = () => {
      onClose();
      settings.close();
    };

    return (
      <PopupContainer
        close={close}
        headerText="Vill du godkänna kopplingen?"
        small
      >
        <div className="popup-small-info-box">
          <div className="item" title={`ID: ${repairShop.id}`}>
            <div className="name">Verkstad i registret:</div>
            <div>
              {repairShop.name} | {repairShop.address} | {repairShop.city}
            </div>
          </div>
          <div
            className="item"
            title={`Externt ID: ${external.externalRepairShopID}`}
          >
            <div className="name">Extern verkstadsinfo:</div>
            <div>{external.generalInfo}</div>
          </div>
        </div>
        <div className="popup-buttons-box">
          <Button
            yellow
            wLarge
            hMain
            text="Ja, godkänn"
            loadingOnClick
            onClick={() => {
              confirm(close);
            }}
          />
          <div className="medium-padder-h"></div>
          <Button black wLarge hMain text="Nej, avbryt" onClick={close} />
        </div>
      </PopupContainer>
    );
  }
}
CoupleExternalConfirmPopup.propTypes = {
  settings: PropTypes.object.isRequired,
  confirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  external: PropTypes.object.isRequired,
  repairShop: PropTypes.object.isRequired,
};

export default CoupleExternalConfirmPopup;
