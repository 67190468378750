// Manage review searches

// #region [Imports]

import assign from 'object-assign';
import { EventEmitter } from 'events';

import { RepairShopAPI } from 'general/API';
import { Actions } from 'general/Actions';
import { AppDispatcher, consoleLog } from 'general/General';
import {
  strCompare,
  createCookie,
  readCookie,
  tryParseInt,
} from 'general/UtilityFunctions';
import {
  ActionTypes,
  ReviewListSort,
  ListDisplayCap,
  CHANGE_EVENT,
} from 'general/Constants';

// #endregion Imports

// #region Initial State

const cookieDaysExpire = 360;
const stationCookie = 'AK.VR.Review.Station';
const distanceCookie = 'AK.VR.Review.Distance';
const filterCookie = 'AK.VR.Review.Filter';

const getDefaultState = (coreData) => {
  const distanceKm = getCookieIntValue(distanceCookie, 5);

  let stationID = coreData.userInfo.stations.sortedKeys[0];
  const storedStation = getCookieIntValue(stationCookie, stationID);
  if (
    stationID !== storedStation &&
    storedStation in coreData.userInfo.stations.dict
  ) {
    stationID = storedStation;
  }

  let filter = coreData.basicData.reviewFilters.sortedKeys[0];
  const storedFilter = getCookieIntValue(filterCookie, filter);
  if (
    filter !== storedFilter &&
    storedFilter in coreData.basicData.reviewFilters.dict
  ) {
    filter = storedFilter;
  }

  return {
    searchRequest: {
      stationID,
      distanceKm,
      filter,
    },
    searchState: getInitialSearchState(),
    searchResult: [],
  };
};
const getInitialSearchState = () => ({
  searchLoading: true,
  bookmarkedScrollPosition: 0,
  displayCount: ListDisplayCap,
  reviewListSort: ReviewListSort.Distance,
  sortDescending: false,
});

const getCookieIntValue = (cookieName, defaultValue) => {
  const cookieValue = readCookie(cookieName);
  if (cookieValue !== null) {
    return tryParseInt(cookieValue, defaultValue);
  }
  return defaultValue;
};

/** @type {ReturnType<typeof getDefaultState>} */
let state = null;

// #endregion Initial State

const StoreReviewList = assign({}, EventEmitter.prototype, {
  // #region Init

  init(coreData) {
    state = getDefaultState(coreData);
  },

  // #endregion Init

  // #region Search

  sendReviewSearchRequest(displayBookmarkIndex = 0) {
    state.searchState.searchLoading = true;
    state.searchState.displayCount = ListDisplayCap + displayBookmarkIndex;

    if (displayBookmarkIndex === 0) {
      // Reset bookmark
      state.searchState.bookmarkedScrollPosition = 0;
    }

    state.searchResult = [];
    this.emitChange();

    const searchRequest = {
      searchTerms: state.searchRequest.filteredSearchStrings,
      stationID: state.searchRequest.stationID,
      distanceKm: state.searchRequest.distanceKm * 10,
      filter: state.searchRequest.filter,
    };

    RepairShopAPI.reviewSearchRepairShops(
      searchRequest,
      Actions.getReviewSearchResult
    );
  },

  getReviewSearchResult(searchResult) {
    state.searchResult = searchResult;
    state.searchState.searchLoading = false;

    this.applyReviewListSort();
  },

  extendReviewSearchList() {
    state.searchState.displayCount += ListDisplayCap * 2;
    this.emitChange();
  },

  bookmarkReviewSearchScroll(scrollPosition) {
    state.searchState.bookmarkedScrollPosition = scrollPosition;
    this.emitChange();
  },

  // #endregion Search

  // #region Search Parameters

  setStationFilter(stationID) {
    if (stationID !== state.searchRequest.stationID) {
      state.searchRequest.stationID = stationID;
      createCookie(stationCookie, stationID, cookieDaysExpire);
      this.sendReviewSearchRequest();
    }
  },

  setReviewDistance(distanceKm) {
    if (distanceKm !== state.searchRequest.distanceKm) {
      state.searchRequest.distanceKm = distanceKm;
      createCookie(distanceCookie, distanceKm, cookieDaysExpire);
      this.sendReviewSearchRequest();
    }
  },

  setReviewFilter(filter) {
    if (filter !== state.searchRequest.filter) {
      state.searchRequest.filter = filter;
      createCookie(filterCookie, filter, cookieDaysExpire);
      this.sendReviewSearchRequest();
    }
  },

  // #endregion Search Parameters

  // #region Frontend Sort

  setReviewListSort(reviewListSort) {
    if (reviewListSort !== state.searchState.reviewListSort) {
      state.searchState.reviewListSort = reviewListSort;
      state.searchState.sortDescending = false;
    } else {
      // Toggle sort order
      state.searchState.sortDescending = !state.searchState.sortDescending;
    }
    state.searchState.displayCount = ListDisplayCap;
    this.applyReviewListSort();
  },

  applyReviewListSort() {
    // Apply sorting to list
    switch (state.searchState.reviewListSort) {
      case ReviewListSort.Name:
        state.searchResult.sort((a, b) => strCompare(a.name, b.name));
        break;

      case ReviewListSort.Address:
        state.searchResult.sort((a, b) => strCompare(a.city, b.city));
        break;

      case ReviewListSort.DateModified:
        state.searchResult.sort((a, b) =>
          strCompare(a.dateModified, b.dateModified)
        );
        break;

      case ReviewListSort.ModifiedBy:
        state.searchResult.sort((a, b) =>
          strCompare(a.modifiedBy, b.modifiedBy)
        );
        break;

      case ReviewListSort.Status:
        state.searchResult.sort((a, b) =>
          strCompare(a.reviewStatus, b.reviewStatus)
        );
        break;

      default: // Distance
        state.searchResult.sort((a, b) => a.distanceKm - b.distanceKm);
        break;
    }

    if (state.searchState.sortDescending) {
      state.searchResult.reverse();
    }

    this.emitChange();
  },

  // #endregion Frontend Sort

  // #region Store Base

  emitChange() {
    consoleLog(state, 'Store Update: Review List', '#99FFCC');
    this.emit(CHANGE_EVENT);
  },

  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  },

  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  },

  getState() {
    return state;
  },

  // #endregion Store Base
});

// #region Action Listener

StoreReviewList.dispatchToken = AppDispatcher.register((action) => {
  switch (action.type) {
    case ActionTypes.SEND_REVIEW_SEARCH_REQUEST:
      StoreReviewList.sendReviewSearchRequest(action.displayBookmarkIndex);
      break;

    case ActionTypes.GET_REVIEW_SEARCH_RESULTS:
      StoreReviewList.getReviewSearchResult(action.searchResult);
      break;

    case ActionTypes.EXTEND_REVIEW_SEARCH_LIST:
      StoreReviewList.extendReviewSearchList();
      break;

    case ActionTypes.BOOKMARK_REVIEW_SEARCH_SCROLL:
      StoreReviewList.bookmarkReviewSearchScroll(action.scrollPosition);
      break;

    case ActionTypes.SET_STATION_FILTER:
      StoreReviewList.setStationFilter(action.stationID);
      break;

    case ActionTypes.SET_REVIEW_DISTANCE:
      StoreReviewList.setReviewDistance(action.distanceKm);
      break;

    case ActionTypes.SET_REVIEW_FILTER:
      StoreReviewList.setReviewFilter(action.filter);
      break;

    case ActionTypes.SET_REVIEW_LIST_SORT:
      StoreReviewList.setReviewListSort(action.reviewListSort);
      break;

    default:
      break; // Do nothing
  }
});

// #endregion Action Listener

export default StoreReviewList;
