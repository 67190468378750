import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from '../Button/Button';

import { Images } from 'general/Constants';

export class PopupContainer extends Component {
  render() {
    const {
      children,
      close,
      back,
      headerText,
      scrollable,
      noMargins,
      small,
      medium,
      large,
      pagesize,
      repairShopApp,
      input,
    } = this.props;

    const boxClass = classNames({
      'main-popup-box centered-level': true,
      'active-input': input,
    });

    const contentClass = classNames({
      'popup-content-small': small,
      'popup-content-medium': medium,
      'popup-content-large': large,
      'popup-content-pagesize': pagesize,
      'popup-content-repair-shop-app': repairShopApp,
    });

    const contentPadderClass = classNames({
      'content-padder-fill': true,
      'content-padder-v': !noMargins && !repairShopApp,
      'content-padder-h': !noMargins && !scrollable && !repairShopApp,
    });

    return (
      <div className="main-popup">
        <div className="popup-background" onClick={close}></div>
        {repairShopApp && <div className="repair-shop-app-padder"></div>}
        <div className={boxClass}>
          <PopupHead text={headerText} close={close} back={back} />
          <div className={contentClass}>
            <div className={contentPadderClass}>{children}</div>
          </div>
        </div>
      </div>
    );
  }
}
PopupContainer.propTypes = {
  // Base
  children: PropTypes.any,
  close: PropTypes.func,
  back: PropTypes.func,
  headerText: PropTypes.string.isRequired,
  // Standard sizes
  small: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  pagesize: PropTypes.bool,
  repairShopApp: PropTypes.bool,
  // Special
  input: PropTypes.bool,
  scrollable: PropTypes.bool,
  noMargins: PropTypes.bool,
};
PopupContainer.defaultProps = {
  // Base
  children: undefined,
  close: undefined,
  back: undefined,
  // Standard sizes
  small: false,
  medium: false,
  large: false,
  pagesize: false,
  repairShopApp: false,
  // Special
  input: false,
  scrollable: false,
  noMargins: false,
};

export class PopupHead extends Component {
  render() {
    const { text, close, back } = this.props;

    return (
      <div className="main-popup-head">
        <div className={close ? '' : 'centered'}>{text}</div>
        <div className="right-buttons">
          {back && (
            <Button
              outline
              hSmall
              footerMargin
              square
              icon={Images.MenuArrowLeft}
              onClick={back}
            />
          )}
          {close && (
            <Button
              outline
              hSmall
              square
              icon={Images.XBlack}
              loadingOnClick
              onClick={close}
            />
          )}
        </div>
      </div>
    );
  }
}
PopupHead.propTypes = {
  text: PropTypes.string.isRequired,
  close: PropTypes.func,
  back: PropTypes.func,
};
PopupHead.defaultProps = {
  close: undefined,
  back: undefined,
};
