import * as constants from 'globalStyles/constants';
import { css } from 'styled-components';
import {
  vcenter_single_level,
  header2,
  vcenter_container,
  rightside_vcenter_container,
  elipsis_overflow,
  header3,
  header4,
} from 'globalStyles/general-structures';

const page_inner_height = `calc(${constants.page_height} * 0.92 - ${constants.large_item_height})`;

const popupGlobalStyle = css`
  .main-popup,
  .main-popup .popup-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .is-tablet {
    .main-popup {
      .active-input {
        margin-bottom: calc(${constants.ipad_height} / 2);
      }
    }
  }

  .main-popup {
    text-align: center;
    z-index: 5;
    ${vcenter_single_level};
    .popup-background {
      content: '';
      background: ${constants.popup_background};
    }
    .main-popup-box {
      width: auto;
      height: auto;
      position: relative;
      background-color: ${constants.background_light};
      border-radius: ${constants.main_border_radius};
      box-shadow: ${constants.heavy_box_shadow};
      text-align: left;
      margin: ${constants.mini_distance};
      .popup-content-pagesize {
        width: calc(${constants.page_width} * 0.92);
        height: ${page_inner_height};
      }
      .popup-content-large {
        width: ${constants.large_popup_width};
        height: auto;
      }
      .popup-content-medium {
        width: ${constants.medium_popup_width};
        height: auto;
      }
      .popup-content-small {
        width: ${constants.small_popup_width};
        height: auto;
      }
      .popup-content-repair-shop-app {
        width: ${constants.page_width};
        height: ${constants.page_height};
        border-radius: 0 0 ${constants.main_border_radius}
          ${constants.main_border_radius};
        overflow: hidden;
      }
      .content-padder-fill {
        width: 100%;
        height: 100%;
      }
      .content-padder-v {
        padding-top: ${constants.large_distance};
        padding-bottom: ${constants.large_distance};
      }
      .content-padder-h {
        padding-left: ${constants.large_distance};
        padding-right: ${constants.large_distance};
      }
    }
    .main-popup-head {
      display: block;
      height: ${constants.large_item_height};
      padding-left: ${constants.large_distance};
      padding-right: calc(
        (${constants.large_item_height} - ${constants.small_item_height}) / 2
      );
      background-color: ${constants.main_yellow};
      box-shadow: ${constants.yellow_header_box_shadow};
      overflow: hidden;
      border-top-right-radius: ${constants.main_border_radius};
      border-top-left-radius: ${constants.main_border_radius};
      ${header2};
      ${vcenter_container};
      text-align: left;
      .centered {
        text-align: center;
        width: 100%;
      }
      .right-buttons {
        ${rightside_vcenter_container};
      }
    }
    .two-button-footer {
      height: ${constants.small_item_height};
      ${vcenter_container};
    }
    // Make sure repair-shop-app has overflow scroll in all locations
    .repair-shop-app-padder {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      min-width: calc(${constants.page_width} + 2 * ${constants.mini_distance});
      min-height: calc(
        ${constants.page_height} + ${constants.large_item_height} + 2 *
          ${constants.mini_distance}
      );
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  .comment-textarea {
    width: 100%;
    height: 100px;
  }

  .set-address-from-map-popup {
    .info-text {
      font-size: ${constants.small_text};
      padding-bottom: ${constants.large_distance};
    }
    .popup-button {
      position: relative;
      height: 70px;
      border-top: 1px solid ${constants.background_shade};
      &:last-of-type {
        border-bottom: 1px solid ${constants.background_shade};
      }
    }
    .button-info {
      position: absolute;
      top: 14px;
      left: 0;
      right: 0;
      font-size: ${constants.small_text};
      opacity: ${constants.main_transparent};
      text-align: center;
    }
    .address {
      position: absolute;
      top: 34px;
      left: 0;
      right: 0;
      font-size: ${constants.small_text};
      text-align: center;
      .main {
        margin-left: ${constants.large_distance};
        margin-right: ${constants.medium_distance};
        font-weight: bold;
        font-size: ${constants.standard_text};
        max-width: 53%;
        ${elipsis_overflow};
      }
      .second {
        width: 30%;
        text-align: left;
        ${elipsis_overflow};
      }
    }
  }

  .error-popup-text {
    text-align: center;
    width: 100%;
    margin-bottom: ${constants.small_distance};
  }

  .remove-popup-info {
    text-align: center;
    width: 100%;
    margin-bottom: ${constants.large_distance};
    padding-bottom: ${constants.large_distance};
    border-bottom: 1px solid ${constants.border_shade};
    .info-text {
      width: 100%;
      ${header2};
    }
    .faded-info {
      max-height: 55px;
      overflow: hidden;
      position: relative;
      .bottom-fader {
        mix-blend-mode: luminosity;
        position: absolute;
        top: calc(55px - ${constants.large_distance});
        left: 0;
        right: 0;
        height: ${constants.large_distance};
        background: linear-gradient(
          to bottom,
          ${constants.transparant_light} 0%,
          ${constants.background_light} 100%
        );
      }
    }
  }

  .wide-popup-button {
    width: 210px;
    margin-bottom: ${constants.medium_distance};
    &.last {
      margin-bottom: 0;
    }
  }

  .popup-line {
    width: 100%;
    ${vcenter_container};
  }

  .centered-popup-line {
    width: 100%;
    text-align: center;
    ${vcenter_container};
  }

  .popup-horizontal-divider {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid ${constants.border_shade};
    display: block;
  }

  .popup-info-list-container {
    height: auto;
    width: 100%;
    .popup-item {
      padding-left: ${constants.medium_distance};
      text-align: left;
    }
  }

  .special-case-no-history {
    margin-top: ${constants.large_distance};
    ${header3};
  }

  .special-case-no-history-review {
    margin-top: 5px;
    ${header4};
  }

  .select-department-popup {
    border-top: 1px solid ${constants.border_shade};
    .text {
      font-weight: bold;
    }
  }

  .popup-buttons-box {
    margin-top: ${constants.large_distance};
    width: 100%;
    text-align: center;
    &.small-margin {
      margin-top: ${constants.small_distance};
    }
    ${vcenter_container};
  }

  .external-insurance-admin-list {
    .inner-list-area {
      height: calc(
        ${page_inner_height} - ${constants.second_head_height} -
          ${constants.large_distance}
      );
    }
    .general-info {
      width: 700px;
      padding-left: ${constants.medium_distance};
      ${elipsis_overflow};
    }
  }

  .insurance-settings {
    margin-top: -${constants.medium_distance};
    ${vcenter_container};
    .header-area {
      width: 100%;
      height: ${constants.main_item_height};
      position: relative;
      border-bottom: 1px solid ${constants.border_shade};
      ${header4};
      ${vcenter_container};
    }
    .name {
      padding-left: ${constants.medium_distance};
    }
    .assist-insurance {
      ${elipsis_overflow};
      width: 308px;
      padding-left: ${constants.medium_distance};
    }
    .insurance-settings-rightside {
      ${rightside_vcenter_container};
      .lock {
        width: 110px;
        text-align: center;
      }
      .disable {
        width: 110px;
        text-align: center;
        margin-right: ${constants.small_distance};
      }
      .repair-shop-insurance {
        width: 140px;
        text-align: right;
        margin-right: calc(${constants.large_distance} + 4px);
      }
    }
    .no-connection {
      opacity: ${constants.light_transparent};
    }
  }
`;

export default popupGlobalStyle;
