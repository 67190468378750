import { InsuranceAbilityModel } from 'general/api/models';

interface CarBrandAbility {
  carBrand: string;
  isAuthorized: boolean;
  priority: number[];
  hasWagonDamageWarranty: boolean;
  departments: number[];
}

interface SummarizedInsuranceAbility {
  insuranceCompanyId: number;
  /** If all CarBrandAbility's have differing prio, then this is null. */
  priority: number | null;
  carBrandAbilities: CarBrandAbility[];
}

const useSummarizedInsuranceAbilities = (
  insuranceAbilities: InsuranceAbilityModel[]
): SummarizedInsuranceAbility[] => {
  const abilitiesByInsuranceCompanyId = new Map<
    number,
    InsuranceAbilityModel[]
  >();

  insuranceAbilities.forEach((insuranceAbility) => {
    const abilityList = abilitiesByInsuranceCompanyId.get(
      insuranceAbility.insuranceCompanyId
    );

    if (!abilityList) {
      abilitiesByInsuranceCompanyId.set(insuranceAbility.insuranceCompanyId, [
        insuranceAbility,
      ]);

      return;
    }

    abilityList.push(insuranceAbility);
  });

  return [...abilitiesByInsuranceCompanyId.entries()].map(
    ([insuranceCompanyId, abilities]): SummarizedInsuranceAbility => {
      const abilitiesByCarBrand = new Map<string, InsuranceAbilityModel[]>();

      abilities.forEach((ability) => {
        const abilityList = abilitiesByCarBrand.get(ability.carBrand);

        if (!abilityList) {
          abilitiesByCarBrand.set(ability.carBrand, [ability]);

          return;
        }

        abilityList.push(ability);
      });

      return {
        insuranceCompanyId,
        priority: abilities.some(
          (ability) => ability.prio !== abilities[0].prio
        )
          ? null
          : abilities[0].prio,

        carBrandAbilities: [...abilitiesByCarBrand.entries()].map(
          ([carBrand, ability]): CarBrandAbility => ({
            carBrand: carBrand,
            isAuthorized: ability.some((ability) => ability.authorized),
            priority: [...new Set(ability.map((ability) => ability.prio))],
            departments: [
              ...new Set(
                ability.map(
                  (ability) => ability.repairShopDepartmentTypeID ?? 0
                )
              ),
            ],
            hasWagonDamageWarranty: ability[0].wagonDamageWarranty,
          })
        ),
      };
    }
  );
};

export default useSummarizedInsuranceAbilities;
