import { Component } from 'react';
import PropTypes from 'prop-types';

import { Actions } from 'general/Actions';

import Button from '../../Button/Button';

import { PopupContainer } from '../PopupComponents';
import { ContainedLoader } from '../../Loader/Loader';
import StoreSearchList from '../../Stores/StoreSearchList';
import GlobalRouter from 'views/routes/DEP_GlobalRouter';

const getDefaultMidlandState = () => ({
  searchListStore: StoreSearchList.getState(),
});

class MidlandPopup extends Component {
  constructor(props) {
    super(props);

    this.handleClosePopup = this.handleClosePopup.bind(this);

    this.state = getDefaultMidlandState();
    if (this.state.searchListStore.midland.valid) {
      Actions.sendMidlandRequest();
    }
  }

  handleClosePopup() {
    Actions.closeMidlandPopup();
    this.props.settings.close();
  }

  render() {
    const midland = this.state.searchListStore.midland;

    return (
      <PopupContainer close={this.handleClosePopup} headerText="Midland" small>
        <div>
          {!midland.valid && <div>{midland.errorMessage}</div>}
          {midland.valid && midland.loading && (
            <div>
              <div>Söker efter Midlandverkstad...</div>
              <div style={{ textAlign: 'center', marginTop: '10px' }}>
                <ContainedLoader />
              </div>
            </div>
          )}
          {midland.valid && !midland.loading && (
            <div>
              <b>Verkstad:</b> {midland.result.repairShopDescription} <br />{' '}
              <br />
              <b>Meddelande:</b> {midland.result.message}
              <br /> <br />
              <b>Beräknad totalsträcka:</b>{' '}
              {midland.result.calculatedTotalDistance}
              <br />
            </div>
          )}
        </div>

        <div className="popup-buttons-box">
          {!midland.loading &&
            midland.valid &&
            midland.result.validRepairShop &&
            midland.result.repairShopID !== null && (
              <Button
                className="margin-right"
                yellow
                wMain
                hMain
                text="Gå till verkstad"
                onClick={() => {
                  GlobalRouter.push(
                    `repairshop/${midland.result.repairShopID}`
                  );
                  this.handleClosePopup();
                }}
              />
            )}
          <Button
            yellow
            wMain
            hMain
            text="Stäng"
            onClick={this.handleClosePopup}
            disabled={midland.loading}
          />
        </div>
      </PopupContainer>
    );
  }
}
MidlandPopup.propTypes = {
  settings: PropTypes.object.isRequired,
};

export default MidlandPopup;
