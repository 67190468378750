import { Component } from 'react';
import PropTypes from 'prop-types';

import { getOwnPositionData } from 'general/UtilityFunctions';

import Button from '../../Button/Button';

import { PopupContainer } from '../PopupComponents';

class MissingPositionInfoPopup extends Component {
  render() {
    const { settings, mainStore, onClose } = this.props;
    const { close, back } = settings;

    const ownPosition = getOwnPositionData(mainStore);

    const customClose = () => {
      close();
      onClose();
    };

    return (
      <PopupContainer
        close={customClose}
        back={back}
        headerText="Välj sökposition från Karta"
        small
      >
        <div className="popup-small-info-box">
          <div className="item">
            <div className="name">OBS! Uppdraget har ingen position!</div>
            <div className="small-padder-v"></div>
            <div>Verkstadssökningen utgår från {ownPosition.description}.</div>
            <div className="small-padder-v"></div>
            <div>Du kan välja en annan sökposition om du klickar på Karta.</div>
          </div>
        </div>
        <div className="main-padder-v"></div>
        <div className="popup-horizontal-divider"></div>
        <div className="popup-buttons-box">
          <Button yellow hMain wMain text="OK" onClick={customClose} />
        </div>
      </PopupContainer>
    );
  }
}
MissingPositionInfoPopup.propTypes = {
  settings: PropTypes.object.isRequired,
  mainStore: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default MissingPositionInfoPopup;
