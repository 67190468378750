import React from 'react';
import ReactDOM from 'react-dom/client';
import initReactFastclick from 'react-fastclick';
import RepairShopApp from './RepairShopApp';

import 'core-js';

const root = ReactDOM.createRoot(document.getElementById('react-body')!);
root.render(
  <React.StrictMode>
    <RepairShopApp />
  </React.StrictMode>
);

initReactFastclick();
