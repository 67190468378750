import Select from 'components/Select';
import { useStoreMain } from 'components/Stores/StoreMain';
import { FC } from 'react';
import styled from 'styled-components';
import * as constants from 'globalStyles/constants';
import { Images } from 'general/Constants';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${constants.small_distance};
  padding: ${constants.mini_distance} ${constants.small_distance};
  background-color: ${constants.background_green_marker};

  select {
    flex: 1;
  }

  option:not([value='null']) {
    background: ${constants.background_green_marker};
  }
`;

const Icon = styled.img`
  width: 1.4em;
  height: 1.4em;
`;

interface Props {
  selectableInsuranceCompanyIds: number[];
  selectedInsuranceCompanyId: number | null;
  onSelectInsuranceCompany: (id: number | null) => void;
}

export const ViewSwitcher: FC<Props> = ({
  selectableInsuranceCompanyIds,
  onSelectInsuranceCompany,
  selectedInsuranceCompanyId,
}) => {
  const { basicData } = useStoreMain();

  return (
    <Wrapper>
      <Icon
        src={Images.DocumentIcon}
        title="Extern försäkringsinfo tillgänglig"
        className="icon"
      />

      <Select
        value={selectedInsuranceCompanyId ?? 'null'}
        onChange={(e) =>
          onSelectInsuranceCompany(
            e.target.value === 'null' ? null : Number(e.target.value)
          )
        }
      >
        <option value="null">Assistancekåren</option>

        {selectableInsuranceCompanyIds.map((insuranceCompanyId) => (
          <option key={insuranceCompanyId} value={insuranceCompanyId}>
            {basicData.insuranceCompanies.getValue(insuranceCompanyId)}
          </option>
        ))}
      </Select>
    </Wrapper>
  );
};

export default ViewSwitcher;
