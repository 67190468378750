import { css } from 'styled-components';
import {
  border_dark,
  pure_white,
  main_border_radius,
  input_box_shadow,
  medium_distance,
  small_distance,
  dark_grey,
} from './constants';

const input_button_padding = '39px';

const pageOverrides = css`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
    font-family: inherit;
    font-size: inherit;
    color: inherit;
    line-height: inherit;
  }

  input[data-css='override'] {
    margin: 0;
    min-height: 0;
    box-shadow: none;
    border: 1px solid ${border_dark};
    border-radius: ${main_border_radius};
    outline: none;
    background-color: ${pure_white};
    background-image: none;
    &:focus {
      box-shadow: ${input_box_shadow};
    }
    &.has-rightside-button {
      padding-right: ${input_button_padding};
    }
  }

  textarea[data-css='override'] {
    margin: 0;
    min-height: 0;
    box-shadow: none;
    border: 1px solid ${border_dark};
    border-radius: ${main_border_radius};
    outline: none;
    background-color: ${pure_white};
    background-image: none;
    resize: none;
    padding-left: ${medium_distance};
    padding-right: ${medium_distance};
    padding-top: ${small_distance};
    padding-bottom: ${small_distance};
    &:focus {
      box-shadow: ${input_box_shadow};
    }
    &.has-rightside-button {
      padding-right: ${input_button_padding};
    }
  }

  .gmnoprint {
    display: none;
  }

  *::-ms-clear {
    display: none;
  }

  .search-area {
    ::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: ${dark_grey};
    }
    :-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: ${dark_grey};
      opacity: 1;
    }
    ::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: ${dark_grey};
      opacity: 1;
    }
    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: ${dark_grey};
    }
    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: ${dark_grey};
    }
  }
`;

export default pageOverrides;
