import React, { Component } from 'react';

import Button from '../Button/Button';
import { exitRegister } from 'general/IFrameCommunication';

import { Images } from 'general/Constants';

export default class TabletHeader extends Component {
  render() {
    return (
      <div className="ipad-header">
        <div className="inner">
          <img
            src={Images.Header}
            className="header-image"
            alt="Assistancekåren"
          />
          <div
            className="close-button-container clickable"
            onClick={exitRegister}
          >
            <Button outline hMini square icon={Images.XBlack} loadingOnClick />
          </div>
        </div>
      </div>
    );
  }
}
