import { useEffect } from 'react';
import useGoogleMapsMap from './useGoogleMapsMap';

const useGoogleMapsOnClick = (
  map: ReturnType<typeof useGoogleMapsMap>,
  onClick?: (coordinate: { lat: number; lng: number }) => void,
  type?: 'click' | 'rightclick'
) => {
  const listenToEvent = type ?? 'click';

  useEffect(() => {
    if (!map) return;

    let listener: google.maps.MapsEventListener | undefined = undefined;

    if (onClick) {
      listener = map.addListener(
        listenToEvent,
        (mapsMouseEvent: {
          latLng: google.maps.LatLngLiteral | google.maps.LatLng;
        }) => {
          if ('latLng' in mapsMouseEvent && mapsMouseEvent.latLng) {
            const lat = mapsMouseEvent.latLng.lat;
            const lng = mapsMouseEvent.latLng.lng;

            if (typeof lat === 'number' && typeof lng === 'number') {
              onClick({ lat, lng });
            } else if (typeof lat === 'function' && typeof lng === 'function') {
              onClick({
                lat: lat(),
                lng: lng(),
              });
            }
          }
        }
      );
    }

    return () => {
      listener?.remove();
    };
  }, [map, onClick, listenToEvent]);
};

export default useGoogleMapsOnClick;
