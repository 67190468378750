import { ChangeLogType } from 'general/TsEnums.gen';
import {
  RemovedStatusListing,
  DuplicateStatusListing,
} from 'general/Constants';

export const changeLogValueConsts = {
  falseStr: 'False',
  trueStr: 'True',
};

export const getFilteredHistory = (opperations) => {
  let filteredOpperations = JSON.parse(JSON.stringify(opperations));
  let keepFirstReview = true;

  for (let i = filteredOpperations.length - 1; i >= 0; i -= 1) {
    filteredOpperations[i].changeLogs = filterOutHiddenChanges(
      filteredOpperations[i],
      keepFirstReview
    );

    if (
      filteredOpperations[i].changeLogs.findIndex(
        (change) =>
          change.changeType === ChangeLogType.Reviewed ||
          change.changeType === ChangeLogType.Status
      ) !== -1
    ) {
      keepFirstReview = false;
    }
  }

  filteredOpperations = filteredOpperations.filter(filterByNonEmpty);
  return filteredOpperations;
};

export const valueHasSignificance = (value) => {
  if (value) {
    return value !== changeLogValueConsts.falseStr;
  }
  return false;
};

const filterOutHiddenChanges = (opperation, keepFirstReview) => {
  const filteredChanges = [];
  let change = null;
  let valid = true;

  for (let i = 0; i < opperation.changeLogs.length; i += 1) {
    change = opperation.changeLogs[i];

    valid =
      valueHasSignificance(change.oldValue) ||
      valueHasSignificance(change.newValue);

    if (change.changeType === ChangeLogType.Status) {
      valid =
        // If newValue is any form of removed, show change as removal
        change.newValue in RemovedStatusListing ||
        change.newValue in DuplicateStatusListing ||
        // If oldValue was any form of removed, show change as restored
        change.oldValue in RemovedStatusListing ||
        change.oldValue in DuplicateStatusListing;
    } else if (change.changeType === ChangeLogType.Reviewed) {
      valid = keepFirstReview;
    } else if (
      change.changeType === ChangeLogType.ModelAdded ||
      change.changeType === ChangeLogType.ModelRemoved ||
      change.changeType === ChangeLogType.InfoText ||
      change.changeType === ChangeLogType.ExternalInsuranceCompany ||
      change.changeType === ChangeLogType.ExternalIsActive
    ) {
      valid = false;
    }

    if (valid) {
      filteredChanges.push(change);
    }
  }

  return filteredChanges;
};

const filterByNonEmpty = (opperation) => opperation.changeLogs.length > 0;
