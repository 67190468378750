import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

let sessionToken: google.maps.places.AutocompleteSessionToken;
const placeByIdCache: Record<
  string,
  google.maps.places.PlaceResult | undefined
> = {};

export interface AutoCompletePlaceResult {
  autoCompleteResult: google.maps.places.AutocompletePrediction;
  placeResult?: google.maps.places.PlaceResult;
}

const useAutoCompletePlacesSearch = (
  placesLib: google.maps.PlacesLibrary,
  attributionsElement: React.RefObject<HTMLDivElement>,
  searchString: string,
  includePlacesSearch: boolean = false,
  onSearchResultsChanged?: (searchResults: AutoCompletePlaceResult[]) => void
) => {
  const placesService = useRef<google.maps.places.PlacesService | null>(null);
  const autoCompleteService = useMemo(
    () => new placesLib.AutocompleteService(),
    []
  );

  const [searchResults, _setSearchResults] = useState<
    AutoCompletePlaceResult[]
  >([]);

  const setSearchResults = (searchResults: AutoCompletePlaceResult[]) => {
    _setSearchResults(searchResults);
    onSearchResultsChanged?.(searchResults);
  };

  useLayoutEffect(() => {
    if (!sessionToken) sessionToken = new placesLib.AutocompleteSessionToken();

    if (attributionsElement.current) {
      placesService.current = new placesLib.PlacesService(
        attributionsElement.current
      );
    }
  }, []);

  useEffect(() => {
    const timeoutRef = setTimeout(() => {
      search(searchString);
    }, 300);

    return () => {
      clearTimeout(timeoutRef);
    };
  }, [searchString]);

  const search = async (searchString: string) => {
    const autoCompleteResults = await new Promise<
      google.maps.places.AutocompletePrediction[]
    >((resolve, reject) => {
      autoCompleteService.getPlacePredictions(
        {
          input: searchString,
          componentRestrictions: {
            country: 'se',
          },
          sessionToken,
        },
        (autoCompleteResults, status) => {
          if (
            status === placesLib.PlacesServiceStatus.OK ||
            status === placesLib.PlacesServiceStatus.ZERO_RESULTS
          ) {
            resolve(autoCompleteResults ?? []);
          } else {
            reject(status);
          }
        }
      );
    });

    setSearchResults(
      autoCompleteResults.map((autoCompleteResult) => ({ autoCompleteResult }))
    );

    if (!includePlacesSearch) return;

    setSearchResults(
      await Promise.all(
        autoCompleteResults.map(
          async (autoCompleteResult): Promise<AutoCompletePlaceResult> => {
            let placeResult = placeByIdCache[autoCompleteResult.place_id];

            if (!placeResult) {
              placeByIdCache[autoCompleteResult.place_id] = placeResult =
                await new Promise<google.maps.places.PlaceResult | undefined>(
                  (resolve, reject) => {
                    if (!placesService.current) {
                      resolve(undefined);
                      return;
                    }

                    placesService.current.getDetails(
                      {
                        placeId: autoCompleteResult.place_id,
                        fields: ['address_component', 'geometry'],
                        sessionToken,
                      },
                      (placeResult, placeStatus) => {
                        if (
                          placeResult &&
                          placeStatus === placesLib.PlacesServiceStatus.OK
                        ) {
                          resolve(placeResult);
                        } else {
                          reject(placeStatus);
                        }
                      }
                    );
                  }
                );
            }

            return {
              autoCompleteResult,
              placeResult,
            };
          }
        )
      )
    );
  };

  return { searchResults, placesService };
};

export default useAutoCompletePlacesSearch;
