import { Component } from 'react';
import PropTypes from 'prop-types';

import { GeneralRegisterInfo } from '../../Information/GeneralInfo';

import { PopupContainer } from '../PopupComponents';

class GeneralRegisterInfoPopup extends Component {
  render() {
    const { settings } = this.props;
    const { close, back } = settings;

    return (
      <PopupContainer
        close={close}
        back={back}
        headerText="Verkstadsregister - Information"
        large
        scrollable
      >
        <GeneralRegisterInfo {...this.props} />
      </PopupContainer>
    );
  }
}
GeneralRegisterInfoPopup.propTypes = {
  settings: PropTypes.object.isRequired,
};

export default GeneralRegisterInfoPopup;
