import { FC, useState } from 'react';
import {
  hasKeyID,
  getListByKeyID,
  getInfoName,
} from 'general/UtilityFunctions';
import { Actions } from 'general/Actions';

import Button from 'components/Button/Button';
import ScrollArea, { ScrollbarFlags } from 'components/Scrollbar';
import Checkbox from 'components/Checkbox/Checkbox';
import CommentList from 'components/CommentList/CommentList';
import ControlledInput from 'components/Input/InputComponent';
import AddressInput from 'components/Input/AddressInput';
import EditInfoTexts from 'components/EditInfoTexts/EditInfoTexts';

import { PopupType, PopupTypeStr } from 'components/Popup/Popup';
import { Images } from 'general/Constants';
import { InfoTextType, DepartmentType } from 'general/TsEnums.gen';
import { useStoreRepairShop } from 'components/Stores/StoreRepairShop';
import { useStoreMain } from 'components/Stores/StoreMain';
import { InfoTextModel } from 'general/api/models';

interface Props {
  inputAccepted: boolean;
  setPopup: (popup: PopupTypeStr, popupProps?: any) => void;
}

const EditGeneralInfoPanel: FC<Props> = ({ inputAccepted, setPopup }) => {
  const { basicData } = useStoreMain();
  const { repairShop } = useStoreRepairShop();

  const { departments } = basicData;

  // Used to clarify that no light vehicles departments should be used (default if only dep. is heavy)
  const [allowLightAndHeavy, setAllowLightAndHeavy] = useState(false);

  if (repairShop === null) {
    return null;
  }

  const hasPosition = repairShop.latitude !== null;

  // Special case: Heavy department only
  const hasHeavyDepartment = hasKeyID(
    repairShop.departments,
    DepartmentType.HeavyVehicles
  );

  const hasOnlyHeavy =
    hasHeavyDepartment &&
    repairShop.departments.length === 1 && // Only heavy
    !allowLightAndHeavy;

  const openRemoveInfoPopup = (infoText: InfoTextModel) => {
    setPopup(PopupType.RemoveItemPopup, {
      headerText: `Vill du ta bort ${getInfoName(infoText.keyID, true)}?`,
      infoText: infoText.value,
      handleRemove: (close: () => void) => {
        Actions.setInfoText('', infoText);
        close();
      },
    });
  };

  return (
    <div className="column main-info">
      <ScrollArea
        rightPadding={ScrollbarFlags.Padding.Main}
        leftPadding={ScrollbarFlags.Padding.Main}
      >
        <div className="edit-line">
          <div className="input-header">Adress</div>
          <AddressInput
            value={repairShop.address}
            displayRequired={!inputAccepted}
            onLocationPicked={(location) => {
              Actions.setAddress(location.address);
              Actions.setCity(location.city);
              Actions.setZip(location.zip);
              Actions.setLatitude(location.position?.latitude);
              Actions.setLongitude(location.position?.longitude);
            }}
            onContinueSearchInMap={(searchString) => {
              setPopup(PopupType.FindRepairShopPopup, { searchString });
            }}
          />
        </div>

        <div className="edit-line split-line">
          <div className="input-header">Postnummer</div>
          <ControlledInput
            value={repairShop.zip}
            handleChange={Actions.setZip}
            placeholder="Fyll i postnr."
            displayRequired={!inputAccepted}
          />
        </div>

        <div className="edit-line split-line right">
          <div className="input-header">Postort</div>
          <ControlledInput
            value={repairShop.city}
            handleChange={Actions.setCity}
            placeholder="Fyll i postort"
            displayRequired={!inputAccepted}
          />
        </div>

        <div className="edit-line">
          <Button
            yellow
            hMain
            padMedium
            icon={Images.TargetCoordinate}
            text="Karta"
            className="find-coordinate-button"
            onClick={() => {
              setPopup(PopupType.FindRepairShopPopup);
            }}
          />

          <div className="edit-position-info-container">
            {!inputAccepted && !hasPosition && (
              <div className="red-marker-dot"></div>
            )}
            <div
              className={`info-text ${
                !inputAccepted && !hasPosition ? 'red' : ''
              }`}
            >
              Tryck på Karta för att
              {hasPosition ? ' ändra position' : ' välja position'}.
            </div>
          </div>
        </div>

        <div className="edit-line department">
          <div className="input-header full-line">Lätta fordon</div>
          {departments.sortedKeys.map((key) => {
            if (key !== DepartmentType.HeavyVehicles) {
              return (
                <div key={key} className="department-container">
                  <Checkbox
                    checked={hasKeyID(repairShop.departments, key)}
                    handleClick={() => Actions.toggleDepartment(key)}
                    outerPadding
                    inactive={hasOnlyHeavy}
                  />
                  <div className="department-text">
                    {departments.getValue(key)}
                  </div>
                </div>
              );
            }
            return null;
          })}
        </div>
        <div className="edit-line department tight">
          <div className="input-header full-line">Tunga fordon</div>
          <div className="department-container">
            <Checkbox
              checked={hasHeavyDepartment && !hasOnlyHeavy}
              handleClick={() => {
                setAllowLightAndHeavy(true);
                if (!hasOnlyHeavy) {
                  // (If OnlyHeavy, keep heavy but allow light)
                  Actions.toggleDepartment(DepartmentType.HeavyVehicles);
                }
              }}
              outerPadding
            />
            <div className="department-text">Tunga & lätta</div>
          </div>
          <div className="department-container">
            <Checkbox
              checked={hasOnlyHeavy}
              handleClick={() => {
                if (hasOnlyHeavy) {
                  // Has OnlyHeavy: Remove heavy
                  Actions.toggleDepartment(DepartmentType.HeavyVehicles);
                } else {
                  // Has LightAndHeavy or OnlyLight: set OnlyHeavy
                  setAllowLightAndHeavy(false);

                  // Remove all departments
                  departments.sortedKeys.forEach((key) => {
                    if (hasKeyID(repairShop.departments, key)) {
                      Actions.toggleDepartment(key);
                    }
                  });

                  // Add heavy
                  Actions.toggleDepartment(DepartmentType.HeavyVehicles);
                }
              }}
              outerPadding
            />
            <div className="department-text">Enbart tunga</div>
          </div>
        </div>

        <div className="edit-line">
          <div className="input-header">Telefonnummer</div>
          <EditInfoTexts
            infoTexts={getListByKeyID(
              repairShop.infoTexts,
              InfoTextType.PhoneNumber
            )}
            infoTextType={InfoTextType.PhoneNumber}
            placeholder="+ Lägg till telefonnummer"
            handleRemove={openRemoveInfoPopup}
            type="tel"
          />
        </div>

        <div className="edit-line">
          <div className="input-header">
            E-post
            <div className="right">Prioriterad</div>
          </div>
          <EditInfoTexts
            infoTexts={getListByKeyID(repairShop.infoTexts, InfoTextType.Email)}
            infoTextType={InfoTextType.Email}
            placeholder="+ Lägg till e-post"
            handleRemove={openRemoveInfoPopup}
            type="email"
            showPrioCheckbox
          />
          <div className="description">
            Följesedlar går i första hand till prioriterade e-postadresser.
          </div>
        </div>

        <div className="edit-line">
          <div className="input-header">Hemsida</div>
          <EditInfoTexts
            infoTexts={getListByKeyID(
              repairShop.infoTexts,
              InfoTextType.Website
            )}
            infoTextType={InfoTextType.Website}
            placeholder="+ Lägg till hemsida"
            handleRemove={openRemoveInfoPopup}
            type="url"
          />
        </div>

        <div className="edit-line">
          <div className="input-header">Lås</div>
          <EditInfoTexts
            infoTexts={getListByKeyID(
              repairShop.infoTexts,
              InfoTextType.LockInfo
            )}
            infoTextType={InfoTextType.LockInfo}
            placeholder="+ Lägg till lås"
            handleRemove={openRemoveInfoPopup}
          />
        </div>

        <div className="edit-line split-line">
          <div className="input-header">Larm</div>
          <EditInfoTexts
            infoTexts={getListByKeyID(
              repairShop.infoTexts,
              InfoTextType.AlarmInfo
            )}
            infoTextType={InfoTextType.AlarmInfo}
            placeholder="+ Lägg till larm"
            handleRemove={openRemoveInfoPopup}
          />
        </div>

        <div className="edit-line split-line right">
          <div className="input-header">Kod</div>
          <EditInfoTexts
            infoTexts={getListByKeyID(
              repairShop.infoTexts,
              InfoTextType.PassCode
            )}
            infoTextType={InfoTextType.PassCode}
            placeholder="+ Lägg till kod"
            handleRemove={openRemoveInfoPopup}
          />
        </div>

        <div className="edit-line">
          <div className="input-header">Semestertider</div>
          <EditInfoTexts
            infoTexts={getListByKeyID(
              repairShop.infoTexts,
              InfoTextType.VacationDates
            )}
            infoTextType={InfoTextType.VacationDates}
            placeholder="+ Lägg till semestertider"
            handleRemove={openRemoveInfoPopup}
          />
        </div>

        <div className="separator"></div>
        <CommentList
          comments={getListByKeyID(repairShop.infoTexts, InfoTextType.Comment)}
          handleClickAdd={() => {
            setPopup(PopupType.AddCommentPopup, {
              editView: true,
              scrollTrigger: () => {},
            });
          }}
          handleRemove={openRemoveInfoPopup}
        />
      </ScrollArea>
    </div>
  );
};

export default EditGeneralInfoPanel;
