import * as constants from 'globalStyles/constants';
import { css } from 'styled-components';
import { vcenter_container } from 'globalStyles/general-structures';

const findRepairShopMapGlobalStyle = css`
  .find-repair-shop-map {
    width: 100%;
    height: 100%;
    position: relative;
    .map-input-container {
      top: ${constants.large_distance};
      left: ${constants.large_distance};
      position: absolute;
      .map-input {
        width: 275px;
      }
    }
    .repairShopMapCloseButton {
      position: absolute;
      z-index: 1;
      right: ${constants.large_distance};
      bottom: ${constants.large_distance};
    }
    .repairShopPositionInfo {
      width: auto;
      height: ${constants.main_item_height};
      background-color: ${constants.pure_white};
      position: absolute;
      right: calc(
        ${constants.medium_distance} + ${constants.large_distance} +
          ${constants.main_button_width}
      );
      bottom: ${constants.large_distance};
      z-index: 1;
      border: 1px solid ${constants.dark_shade};
      font-size: ${constants.small_text};
      ${vcenter_container};
      .red-map-point {
        width: 12px;
        height: 12px;
        border-radius: 6px;
        margin-left: ${constants.small_distance};
        background-color: ${constants.main_red};
        border: 1px solid ${constants.border_red};
      }
      .text {
        width: 100px;
        text-align: left;
        opacity: ${constants.dark_transparent};
        margin-left: ${constants.small_distance};
        margin-right: ${constants.small_distance};
      }
    }
    .bottomButtonContainer {
      position: absolute;
      bottom: ${constants.large_distance};
      left: ${constants.large_distance};
      width: 1px;
      z-index: 1;
      .wide-button {
        width: 165px;
        font-size: ${constants.small_text};
        position: relative;
        text-align: left;
        margin-top: ${constants.medium_distance};
        span {
          width: 115px;
          margin-left: ${constants.small_distance};
        }
      }
      .button-map-marker {
        width: 30px;
        height: 86%;
        margin-left: 6px;
      }
      .newPositionInfo {
        width: 155px;
        height: ${constants.main_item_height};
        background-color: ${constants.pure_white};
        position: absolute;
        bottom: 0;
        left: 175px;
        font-size: ${constants.small_text};
        border-radius: ${constants.main_border_radius};
        .tooltipArrowContainer {
          .tooltipArrow {
            position: absolute;
            top: 0;
            left: -22px;
            width: ${constants.main_item_height};
            height: ${constants.main_item_height};
          }
          .tooltopAntiArrow {
            position: absolute;
            top: calc((${constants.main_item_height} - 22px) / 2);
            left: 0;
            height: 22px;
            background-color: white;
            z-index: 2;
            width: 2px;
          }
        }
        .newPositionInfoText {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border: 1px solid ${constants.dark_shade};
          border-radius: ${constants.main_border_radius};
          background-color: ${constants.pure_white};
          text-align: left;
          padding-left: ${constants.small_distance};
          ${vcenter_container};
          .text {
            opacity: ${constants.dark_transparent};
          }
        }
      }
    }
    .mapContainer {
      width: 100%;
      height: 100%;
    }
  }
`;

export default findRepairShopMapGlobalStyle;
