/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * My Title
 * OpenAPI spec version: 1.0.0
 */

export type StatusType = (typeof StatusType)[keyof typeof StatusType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StatusType = {
  Duplicate: 1,
  Removed: 2,
  NotReviewed: 3,
  Reviewed: 4,
  ProposedRemove: 5,
  ProposedDuplicate: 6,
} as const;
