// #region [IMPORTS]

import React, { Component } from 'react';
import classNames from 'classnames';

import { Actions } from 'general/Actions';
import StoreMain from 'components/Stores/StoreMain';
import StoreSearchList from 'components/Stores/StoreSearchList';
import { strCompare } from 'general/UtilityFunctions';

import Button from 'components/Button/Button';
import Checkbox from 'components/Checkbox/Checkbox';
import { ContentLoader } from 'components/Loader/Loader';
import ControlledInput from 'components/Input/InputComponent';
import { Popup, PopupType, setPopup } from 'components/Popup/Popup';

import { Images, SearchMode } from 'general/Constants';
import withRouter from 'views/routes/DEP_withRouter';

// #endregion

// #region [1] Default State

const getStateFromStores = () => ({
  mainStore: StoreMain.getState(),
  searchListStore: StoreSearchList.getState(),
});

const getDefaultState = () =>
  Object.assign({}, getStateFromStores(), {
    selectedExternal: null,
    selectedRepairShop: null,
    popupProps: {},
    showRemoved: false,
  });

// #endregion

class CoupleExternalView extends Component {
  // #region [2] Constructor

  constructor() {
    super();

    this.state = getDefaultState();

    this.onChange = this.onChange.bind(this);
    this.selectExternal = this.selectExternal.bind(this);
    this.selectRepairShop = this.selectRepairShop.bind(this);
    this.confirmSelection = this.confirmSelection.bind(this);
    this.selectShowRemoved = this.selectShowRemoved.bind(this);

    Actions.setSearchMode(SearchMode.CoupleExternal);
    Actions.requestMiscoupledExternal();
  }

  // #endregion

  // #region [4] Actions

  componentDidMount() {
    StoreMain.addChangeListener(this.onChange);
    StoreSearchList.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    StoreMain.removeChangeListener(this.onChange);
    StoreSearchList.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState(getStateFromStores());
  }

  selectExternal(selectedExternal) {
    this.setState({ selectedExternal }, this.confirmSelection);
  }

  selectRepairShop(selectedRepairShop) {
    this.setState({ selectedRepairShop }, this.confirmSelection);
  }

  selectShowRemoved() {
    this.setState((prevState) => ({
      showRemoved: !prevState.showRemoved,
    }));
  }

  confirmSelection() {
    const { selectedExternal, selectedRepairShop } = this.state;
    if (selectedExternal && selectedRepairShop) {
      setPopup(this, PopupType.CoupleExternalConfirmPopup, {
        repairShop: selectedRepairShop,
        external: selectedExternal,
        confirm: (close) => {
          Actions.coupleRepairShopToExternal(
            selectedRepairShop.id,
            selectedExternal.id,
            close
          );
        },
        onClose: () => {
          this.selectExternal(null);
          this.selectRepairShop(null);
        },
      });
    }
  }

  // #endregion

  // #region [1] Render

  render() {
    // #region [1] Data and basic Conditions

    const {
      popupProps,
      mainStore,
      searchListStore,
      selectedExternal,
      selectedRepairShop,
      showRemoved,
    } = this.state;
    const { insuranceCompanies } = mainStore.basicData;
    const { externalInsurance, externalLoading } =
      searchListStore.externalState;
    const { searchString } = searchListStore.searchRequest;
    const { searchLoading } = searchListStore.searchState;
    const { matches, remaining } = searchListStore.searchResult;

    let externalInsuranceList = externalInsurance;

    if (!showRemoved && externalInsurance !== null) {
      externalInsuranceList = externalInsurance.filter(
        (shop) => shop.coupledRepairShopStatusID === null
      );
    }
    // #endregion

    // #region [2] Build own repairShops list content

    let searchContent = null;
    if (searchString.length < 3) {
      searchContent = (
        <div className="list-area">
          <div className="second-head medium-padding">
            <div>Sök verkstad i registret (minst 3 tecken)</div>
          </div>
        </div>
      );
    } else if (searchLoading) {
      searchContent = (
        <div className="list-area">
          <ContentLoader />
        </div>
      );
    } else if (matches.length + remaining.length === 0) {
      searchContent = (
        <div className="list-area">
          <div className="second-head medium-padding">
            <div>Sökningen gav inga resultat</div>
          </div>
        </div>
      );
    } else {
      const list = matches.concat(remaining);
      list.sort((a, b) => strCompare(a.name, b.name));

      searchContent = (
        <div className="list-area">
          <div className="standard-scroll-area relative">
            <div className="second-head medium-padding">
              <div>Verkstäder i registret ({list.length}st)</div>
            </div>
            {list.map((shop, index) => {
              const rowClass = classNames({
                'search-list-row': true,
                'main-item': true,
                clickable: true,
                shade: index % 2 === 1,
                last: index % 2 === 0 && index === list.length - 1,
              });

              return (
                <div
                  className={rowClass}
                  key={shop.id}
                  onClick={() => {
                    this.selectRepairShop(shop);
                  }}
                >
                  <div className="name" title={`ID: ${shop.id}`}>
                    <div>{shop.name}</div>
                  </div>
                  <div className="address">
                    <div className="line1">{shop.city}</div>
                    <div className="line2">{shop.address}</div>
                  </div>
                  <Checkbox
                    checked={
                      selectedRepairShop && selectedRepairShop.id === shop.id
                    }
                    radio
                    outerPadding
                  />
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    // #endregion

    // #region [3] Build external repairShops list content

    let externalContent = null;
    if (externalLoading) {
      externalContent = (
        <div className="list-area">
          <ContentLoader />
        </div>
      );
    } else if (externalInsuranceList.length === 0) {
      externalContent = (
        <div className="list-area">
          <div className="second-head medium-padding">
            <div>Alla externa verkstäder har kopplats</div>
          </div>
        </div>
      );
    } else {
      const list = externalInsuranceList.slice();
      list.sort((a, b) => strCompare(a.generalInfo, b.generalInfo));

      externalContent = (
        <div className="list-area">
          <div className="standard-scroll-area relative">
            <div className="second-head medium-padding">
              <div>
                Externa verkstäder som saknar koppling ({list.length}st)
              </div>
            </div>
            {list.map((external, index) => {
              const rowClass = classNames({
                'search-list-row': true,
                'main-item': true,
                clickable: true,
                shade: index % 2 === 1,
                last: index % 2 === 0 && index === list.length - 1,
              });

              const text = `${
                external.generalInfo
              } (${insuranceCompanies.getValue(external.keyID)})`;

              return (
                <div className={rowClass} key={external.id}>
                  <div
                    className="info-line"
                    title={text}
                    onClick={() => {
                      this.selectExternal(external);
                    }}
                  >
                    {text}
                  </div>
                  <Checkbox
                    checked={
                      selectedExternal && selectedExternal.id === external.id
                    }
                    radio
                    outerPadding
                    handleClick={() => {
                      this.selectExternal(external);
                    }}
                  />
                  <Button
                    yellow
                    hSmall
                    padSmall
                    text="Sök"
                    onClick={() => {
                      // Take name | city
                      const str = external.generalInfo.trim();

                      const address = str.split('|')[1].trim().split(' ')[0];

                      const parts = str.split(' ');
                      const city = parts[parts.length - 1].trim();

                      Actions.setSearchString(`${address} ${city}`);
                      this.selectRepairShop(null);
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    // #endregion

    // #region [4] Return react tree

    return (
      <div className="main-page-container search-list-view">
        <div className="main-content-container">
          <div className="main-head">
            <div className="search-area">
              <ControlledInput
                value={searchString}
                handleChange={Actions.setSearchString}
                autoInputTimer={1333}
                placeholder="Sök verkstad"
                enableQuickClear
                style={{ backgroundImage: `url(${Images.SearchGlass})` }}
                className="search-field-input wide"
                noEditingButton
              />
            </div>
            <div className="couple-external-info">
              För att koppla en extern verkstat till registret: klicka på
              verkstads-raderna som matchar i kolumnerna nedan.
            </div>
            <div
              onClick={() => {
                this.selectShowRemoved();
              }}
              style={{ marginLeft: '100px' }}
            >
              <span>Visa borttagna</span>
              <Checkbox checked={showRemoved} radio outerPadding />
            </div>
          </div>
          <div className="left-col">{searchContent}</div>
          <div className="right-col">{externalContent}</div>
        </div>

        <div className="main-footer">
          <Button
            yellow
            wMain
            hLarge
            footerMargin
            text="Ny verkstad"
            onClick={Actions.createNewRepairShopTemplate}
          />
          <Button
            yellow
            wMain
            hLarge
            footerMargin
            text="Info"
            onClick={() => {
              setPopup(this, PopupType.MasterInfoPopup, {
                mainStore,
              });
            }}
          />
          <Button yellow wMain hLarge text="Startsidan" linkTo="list/" />
        </div>

        <Popup {...popupProps} />
      </div>
    );

    // #endregion
  }
  // #endregion
}

export default withRouter(CoupleExternalView);
