import { Component } from 'react';
import PropTypes from 'prop-types';

import { Actions } from 'general/Actions';

import Button from '../../Button/Button';
import Checkbox from '../../Checkbox/Checkbox';

import { PopupContainer } from '../PopupComponents';
import ScrollArea from '../../Scrollbar';

class UpdateInsuranceSettingsPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      insuranceOptions: JSON.parse(
        JSON.stringify(
          props.mainStore.basicData.insuranceSettings.insuranceOptions
        )
      ),
    };
  }

  render() {
    const { settings, mainStore } = this.props;
    const { close, back } = settings;
    const insuranceCompanies =
      mainStore.basicData.insuranceSettings.allRepairShopInsuranceCompanies;
    const { insuranceOptions } = this.state;

    return (
      <PopupContainer
        close={close}
        back={back}
        headerText="Inställningar för försäkringsbolag"
        large
      >
        <div className="insurance-settings">
          <div className="header-area">
            <div className="name">Försäkring:</div>
            <div className="insurance-settings-rightside">
              <div className="lock">Lås ändringar</div>
              <div className="disable">Dölj i registret</div>
            </div>
          </div>

          <div className="main-list-container">
            <ScrollArea>
              <div className="list-top-info-box green">
                Ändringar slår igenom för alla användare då de laddar om sidan.
              </div>
              <div>
                {insuranceCompanies.sortedKeys.map((key) => {
                  const lockedOnRepairShop =
                    insuranceOptions[key].lockedOnRepairShop;
                  const isDisabled = insuranceOptions[key].isDisabled;

                  return (
                    <div className="main-item alternate-shade" key={key}>
                      <div className="name">
                        {insuranceCompanies.getValue(key)}
                      </div>
                      <div className="insurance-settings-rightside">
                        <div className="lock">
                          <Checkbox
                            checked={lockedOnRepairShop}
                            handleClick={() => {
                              insuranceOptions[key].lockedOnRepairShop =
                                !lockedOnRepairShop;
                              this.setState(insuranceOptions);
                            }}
                            outerPadding
                          />
                        </div>
                        <div className="disable">
                          <Checkbox
                            checked={isDisabled}
                            handleClick={() => {
                              insuranceOptions[key].isDisabled = !isDisabled;
                              this.setState(insuranceOptions);
                            }}
                            outerPadding
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </ScrollArea>
          </div>
        </div>
        <div className="popup-buttons-box">
          <Button
            yellow
            wLarge
            hMain
            text="Spara ändringar"
            onClick={() =>
              Actions.updateInsuranceSettings(insuranceOptions, back)
            }
            loadingOnClick
          />
          <div className="medium-padder-h"></div>
          <Button black wLarge hMain text="Släng ändringar" onClick={back} />
        </div>
      </PopupContainer>
    );
  }
}

UpdateInsuranceSettingsPopup.propTypes = {
  settings: PropTypes.object.isRequired,
  mainStore: PropTypes.object.isRequired,
};

export default UpdateInsuranceSettingsPopup;
