import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from '../Button/Button';
import ScrollArea, { ScrollbarFlags } from '../Scrollbar';
import { CarBrandSpec } from 'general/Constants';
import { DepartmentText } from '../Utility/UtilityComponents';

export class AssignmentInfo extends Component {
  render() {
    const { assignment, carBrands, allowLinkToRepairShop } = this.props;
    const {
      address,
      city,
      locationDescription,
      requestedDropOffLocation,
      carBrandID,
      warrantyInsuranceCompanyName,
      defaultInsuranceCompanyName,
      assistancePackageName,
      repairShopName,
      repairShopID,
      carBrandSpec,
    } = assignment;

    let carBrandText = null;
    if (carBrandID !== null) {
      carBrandText = carBrands.getValue(carBrandID);

      if (carBrandSpec === CarBrandSpec.Authorized) {
        carBrandText += ' (Aukt. krävs)';
      } else if (carBrandSpec === CarBrandSpec.Warranty) {
        carBrandText += ' (VSG krävs)';
      }
    }

    return (
      <div className="popup-small-info-box">
        <div className="item">
          <div className="name">Skadeaddress: </div>
          <div>{address === null ? '-' : address}</div>
        </div>
        <div className="item">
          <div className="name">Ort: </div>
          <div>{city === null ? '-' : city}</div>
        </div>
        <div className="item">
          <div className="name">Platsbeskrivning: </div>
          <div>{locationDescription === null ? '-' : locationDescription}</div>
        </div>
        {carBrandText !== null && (
          <div className="item">
            <div className="name">Bilmärke: </div>
            <div>{carBrandText}</div>
          </div>
        )}
        <div className="item">
          <div className="name">Försäkringsbolag: </div>
          <div>
            {defaultInsuranceCompanyName === null
              ? '-'
              : defaultInsuranceCompanyName}
          </div>
        </div>
        {warrantyInsuranceCompanyName !== null && (
          <div className="item">
            <div className="name">Vagnskadegarantibolag: </div>
            <div>{warrantyInsuranceCompanyName}</div>
          </div>
        )}
        {assistancePackageName !== null && (
          <div className="item">
            <div className="name">Assistancepaket: </div>
            <div>{assistancePackageName}</div>
          </div>
        )}
        {requestedDropOffLocation !== null && (
          <div className="item">
            <div className="name">Önskad transport till: </div>
            <div>{requestedDropOffLocation}</div>
          </div>
        )}
        {repairShopID !== null && (
          <div>
            <div className="item" title={repairShopName}>
              <div className="name">Inskriven verkstad: </div>
              <div className="repair-shop-name">{repairShopName}</div>
              {allowLinkToRepairShop && (
                <div className="rightside-button">
                  <Button
                    yellow
                    padSmall
                    hSmall
                    text="Öppna"
                    linkTo={`repairshop/${repairShopID}`}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
AssignmentInfo.propTypes = {
  assignment: PropTypes.object.isRequired,
  carBrands: PropTypes.object.isRequired,
  allowLinkToRepairShop: PropTypes.bool,
};
AssignmentInfo.defaultProps = {
  allowLinkToRepairShop: true,
};

export class GeneralRegisterInfo extends Component {
  render() {
    const { basicData } = this.props;

    return (
      <div className="popup-small-info-box page-height">
        <ScrollArea
          rightPadding={ScrollbarFlags.Padding.Main}
          leftPadding={ScrollbarFlags.Padding.Main}
        >
          <div className="item">
            <div className="paragraph-header">
              I sökrutan kan du söka efter:
            </div>
            <div className="paragraph">
              <div className="bulleted-line">• Verkstadsnamn</div>
              <div className="bulleted-line">• Ort</div>
              <div className="bulleted-line">• Adress</div>
              <div className="bulleted-line">• Riktnummer</div>
            </div>
            <div className="paragraph">
              Vill du använda fler sökparametrar samtidigt behöver du bara
              separera söktexten med mellanslag.
            </div>
            <div className="paragraph">
              Med hjälp av filterknapparna till höger kan du också filtrera fram
              verkstäder baserat på bilmärke, försäkringsbolag och verkstadstyp.
            </div>
            <div className="paragraph">
              Verkstadstyp beskriver vilka skador och fordonstyper verkstaden
              hanterar.
            </div>

            <div className="paragraph-header">Verkstadstyper:</div>
            <div className="paragraph">
              {basicData.departments.sortedKeys.map((key) => (
                <div key={key} className="bulleted-line">
                  <div>•&nbsp;</div>
                  <DepartmentText departmentID={key} basicData={basicData} />
                </div>
              ))}
            </div>
            <div className="paragraph">
              Verkstadstyper i listan markeras med typens första bokstav.
            </div>

            <div className="paragraph-header">Avstånd till verkstad</div>
            <div className="paragraph">
              Verkstadslistan sorteras alltid efter avståndet mellan verkstad
              och sökningens utgångspunkt. Sökningens utgångspunkt kan ändras
              från: Karta.
            </div>

            <div className="paragraph-header">Matchande verkstäder</div>
            <div className="paragraph">
              Verkstäder som matchar alla valda filter hamnar alltid överst i
              listan (oavsett avstånd) och blir grönmarkerade.
            </div>

            <div className="paragraph-header">Karta</div>
            <div className="paragraph">
              Från Kartan kan du välja vilken position sökningen ska utgå från.
              Klickar du på kartan placerar du ut en egen sökposition.
            </div>
            <div className="paragraph">
              Kartan ritar också ut var matchande verkstäder befinner sig.
            </div>

            <div className="paragraph-header">
              Lägg till ny verkstad i registret
            </div>
            <div className="paragraph">
              Klicka på knappen: Ny verkstad för att lägga till en ny verkstad i
              registret.
            </div>

            <div className="paragraph-header">
              Redigera verkstad - bra att veta
            </div>
            <div className="paragraph">
              Intern försäkringsinfo visas för användare som vill välja
              verkstaden för ett aktivt uppdrag. Finns det viktig information
              för specifika försäkringar kopplat till verkstaden bör den skrivas
              in här.
            </div>
            <div className="paragraph">
              Klickar du på knappen Karta när du redigerar verkstaden får du
              full kontroll över hur verkstadens position väljs och du får också
              hjälp med att fylla i rätt adressrader.
            </div>
            <div className="paragraph">
              Behöver du skriva en kort kommentar kopplad till en specifik
              e-postadress kan du skriva det direkt i textfältet för E-post.
              Separera e-postadressen och kommentaren med mellanslag. Ex:
              <div className="bulleted-line">
                • info@bilmek.se (motorskador)
              </div>
            </div>
          </div>
        </ScrollArea>
      </div>
    );
  }
}
GeneralRegisterInfo.propTypes = {
  basicData: PropTypes.object.isRequired,
};

export class ReviewInfo extends Component {
  render() {
    return (
      <div className="popup-small-info-box">
        <div className="item">
          <div className="paragraph-header">Redigerade verkstäder</div>
          <div className="paragraph">
            Verkstäder som redigeras flaggas som ogranskade i registret. Som
            granskare får du överblick över vilka förändringar som gjorts och
            får även möjlighet att godkänna eller återställa verkstaden.
          </div>
          <div className="paragraph-header">Granska - verkstadslista</div>
          <div className="paragraph">
            Visar verkstäder i inskrivet område kring dina stationer. Du kan
            ställa in filter på station och verkstäders granskningsstatus.
          </div>
          <div className="paragraph">
            Klickar du på en verkstadsrad visas verkstadens ändringshistorik.
          </div>
          <div className="paragraph-header">
            Verkstadslistan kan sorteras efter:
          </div>
          <div className="paragraph">
            <div className="bulleted-line">• Namn</div>
            <div className="bulleted-line">• Ort</div>
            <div className="bulleted-line">
              • Användare som senast ändrat verkstaden
            </div>
            <div className="bulleted-line">• Datum för senaste ändringen</div>
            <div className="bulleted-line">• Verkstaden status</div>
            <div className="bulleted-line">• Avstånd från vald station</div>
          </div>
          <div className="paragraph-header">Ändringshistorik för verkstad</div>
          <div className="paragraph">
            Verkstadens ändringshistorik visas sorterat efter datum och
            användare som utförde ändringarna.
          </div>
          <div className="paragraph">
            De flesta ändringsrader kan Återställas - det innebär att värdet
            under fältet Från skrivs in i verkstaden på nytt.
          </div>
          <div className="paragraph">
            Är verkstaden ogranskad bör du från ändringshistoriken: Godkänna,
            Återställa eller Redigera verkstaden - Alla dessa åtgärder leder
            till att verkstaden får Godkänd status.
          </div>
          <div className="paragraph">
            Borttagna verkstäder visas så som de såg ut före borttagning. Du får
            alternativen Godkänn borttagning och Återställ verkstad.
          </div>
        </div>
      </div>
    );
  }
}
