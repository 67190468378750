/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * My Title
 * OpenAPI spec version: 1.0.0
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import type {
  SearchResultModel,
  SearchRequestModel,
  ReviewSearchPreviewModel,
  ReviewSearchRequestModel,
  RepairShopModel,
  IdRequestModel,
  GeographicPlaceModel,
  PositionModel,
  AddressRequestModel,
  DrivingDistanceModel,
  DistanceRequestModel,
  CoupleExternalRequestModel,
  ExternalInsuranceModel,
  MidlandRepairShopResponse,
  MidlandRepairShopRequest,
  InsuranceSettingsRequest,
  ConnectInsuranceToAssistRequest,
} from './models';

export const repairShopSearch = <TData = AxiosResponse<SearchResultModel>>(
  searchRequestModel: SearchRequestModel,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(`/RepairShop/Search`, searchRequestModel, options);
};
export const repairShopReviewSearch = <
  TData = AxiosResponse<ReviewSearchPreviewModel[]>,
>(
  reviewSearchRequestModel: ReviewSearchRequestModel,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(
    `/RepairShop/ReviewSearch`,
    reviewSearchRequestModel,
    options
  );
};
export const repairShopGet = <TData = AxiosResponse<RepairShopModel>>(
  idRequestModel: IdRequestModel,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(`/RepairShop/Get`, idRequestModel, options);
};
export const repairShopSave = <TData = AxiosResponse<RepairShopModel>>(
  repairShopModel: RepairShopModel,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(`/RepairShop/Save`, repairShopModel, options);
};
export const repairShopGetAddressFromPosition = <
  TData = AxiosResponse<GeographicPlaceModel>,
>(
  positionModel: PositionModel,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(
    `/RepairShop/GetAddressFromPosition`,
    positionModel,
    options
  );
};
export const repairShopFindMatchingAddresses = <
  TData = AxiosResponse<GeographicPlaceModel[]>,
>(
  addressRequestModel: AddressRequestModel,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(
    `/RepairShop/FindMatchingAddresses`,
    addressRequestModel,
    options
  );
};
export const repairShopGetDrivingDistance = <
  TData = AxiosResponse<DrivingDistanceModel>,
>(
  distanceRequestModel: DistanceRequestModel,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(
    `/RepairShop/GetDrivingDistance`,
    distanceRequestModel,
    options
  );
};
export const repairShopCoupleExternalToRepairShop = <
  TData = AxiosResponse<string>,
>(
  coupleExternalRequestModel: CoupleExternalRequestModel,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(
    `/RepairShop/CoupleExternalToRepairShop`,
    coupleExternalRequestModel,
    options
  );
};
export const repairShopGetMiscoupledExternalInsuranceModels = <
  TData = AxiosResponse<ExternalInsuranceModel[]>,
>(
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(
    `/RepairShop/GetMiscoupledExternalInsuranceModels`,
    undefined,
    options
  );
};
export const repairShopSearchMidlandRepairShop = <
  TData = AxiosResponse<MidlandRepairShopResponse>,
>(
  midlandRepairShopRequest: MidlandRepairShopRequest,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(
    `/RepairShop/SearchMidlandRepairShop`,
    midlandRepairShopRequest,
    options
  );
};
export const repairShopUpdateInsuranceSettings = <
  TData = AxiosResponse<string>,
>(
  insuranceSettingsRequest: InsuranceSettingsRequest,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(
    `/RepairShop/UpdateInsuranceSettings`,
    insuranceSettingsRequest,
    options
  );
};
export const repairShopConnectInsuranceToAssist = <
  TData = AxiosResponse<string>,
>(
  connectInsuranceToAssistRequest: ConnectInsuranceToAssistRequest,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(
    `/RepairShop/ConnectInsuranceToAssist`,
    connectInsuranceToAssistRequest,
    options
  );
};
