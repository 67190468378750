// Error wrapper management

// #region Imports

import assign from 'object-assign';
import { EventEmitter } from 'events';

import { AppDispatcher, consoleLog } from 'general/General';
import { navigateToStartList } from 'general/UtilityFunctions';
import { Actions } from 'general/Actions';
import { ActionTypes, CHANGE_EVENT } from 'general/Constants';

// #endregion Imports

// #region Initial State

const getDefaultState = () => ({
  error: null,
  hasError: false,
  description: '',
  errorCount: 0,
});

let state = null;

// #endregion Initial State

const StoreError = assign({}, EventEmitter.prototype, {
  // #region Init

  init() {
    // Overwrite window.onerror
    window.onerror = (msg, url, lineNo, columnNo, error) => {
      const string = msg.toLowerCase();
      const substring = 'script error';

      if (string.indexOf(substring) === -1) {
        consoleLog(
          { msg, url, lineNo, columnNo, error },
          'Felinformation',
          '#F1F1F1'
        );
      }

      Actions.setError(error, 'Ett okänt applikationsfel har inträffat.');
      return false;
    };

    state = getDefaultState();
  },

  // #endregion Init

  // #region Error Management

  setError(error, description) {
    if (description.indexOf('<silent>') === -1) {
      state.hasError = true;
      state.errorCount += 1;
      state.error = error;
      state.description = description;
      this.emitChange();
    }
  },

  clearError() {
    state.hasError = false;

    if (state.errorCount < 2) {
      // Atempt to move to start list: Useful if reparShopView for the selected shop is broken
      navigateToStartList(false);
    } else if (state.errorCount < 3) {
      // Atempt to reload page: Useful if a store is permanently broken
      window.location.reload();
    } else {
      // Leave repairShopRegister (necessary in App if register repeatedly crashes on initial load)
      const thisPage = window.location.href;

      if (window.history.length > 1) {
        window.history.back();
      }

      setTimeout(() => {
        if (window.location.href === thisPage) {
          window.location = window.location.origin;
        }
      }, 1000);
    }

    this.emitChange();
  },

  // #endregion Error Management

  // #region Store Base

  emitChange() {
    consoleLog(this.getState(), 'Store Update: Error', '#FF837A');
    this.emit(CHANGE_EVENT);
  },
  addChangeListener(callback) {
    this.on(CHANGE_EVENT, callback);
  },
  removeChangeListener(callback) {
    this.removeListener(CHANGE_EVENT, callback);
  },

  getState() {
    return state;
  },

  // #endregion Store Base
});

// #region Action Listener

StoreError.dispatchToken = AppDispatcher.register((action) => {
  switch (action.type) {
    case ActionTypes.SET_ERROR:
      StoreError.setError(action.error, action.description);
      break;

    case ActionTypes.CLEAR_ERROR:
      StoreError.clearError();
      break;

    default:
      break; // Do nothing
  }
});

// #endregion Action Listener

export default StoreError;
