const fontSize = 30;

const createMapPin = (text: string, fill: string = '#191919') => {
  const template = `<svg width="87" height="101" viewBox="0 0 87 101" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M58.69 33.73C40.4 35.79 22.68 46.42 18 57.39C13.49 68.15 32.92 101 32.92 101C32.92 101 78.62 64.78 84.47 52.61C90.53 40 78.41 31.51 58.69 33.73Z" fill="black" fill-opacity="0.3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M33.08 9.71104e-05C28.7447 -0.0104199 24.4498 0.833402 20.4407 2.48335C16.4317 4.13329 12.787 6.55702 9.71496 9.61605C6.64293 12.6751 4.20376 16.3094 2.53683 20.3115C0.869899 24.3135 0.00786964 28.6048 0 32.9401C0 51.1301 33.08 100.94 33.08 100.94C33.08 100.94 66.16 51.0901 66.16 32.9401C66.1521 28.6048 65.2901 24.3135 63.6232 20.3115C61.9562 16.3094 59.5171 12.6751 56.445 9.61605C53.373 6.55702 49.7283 4.13329 45.7193 2.48335C41.7102 0.833402 37.4153 -0.0104199 33.08 9.71104e-05Z" fill="${fill}"/>
<circle cx="33" cy="33" r="24" fill="white"/>
<text fill="black" xml:space="preserve" text-anchor="middle" text-align="center" x="33" y="${
    33 + fontSize / 2 - 4
  }" style="white-space: pre" font-family="sans-serif" font-size="${fontSize}"  letter-spacing="0em">${text}</text>
</svg>
`;

  const b64 = `data:image/svg+xml;charset=UTF-8;base64,${window.btoa(
    template
  )}`;

  return b64;
};

export default createMapPin;
