import { Component } from 'react';
import PropTypes from 'prop-types';

import { getListByKeyID, getItemByKeyID } from 'general/UtilityFunctions';

import { PopupContainer } from '../PopupComponents';
import { PopupType } from '../Popup';
import { InfoTextType } from 'general/TsEnums.gen';
import { ExternalyConnectedInsuranceCompanies } from 'general/Constants';

class MasterInfoPopup extends Component {
  render() {
    const { settings, mainStore, repairShop } = this.props;
    const { close, openOtherPopup } = settings;

    const { assignment, userInfo, basicData } = mainStore;
    const { carBrands, insuranceCompanies } = basicData;

    const showAssignmentInfo = assignment.isActive;
    const showAssignmentInsuranceInfo =
      showAssignmentInfo &&
      repairShop !== null &&
      assignment.insuranceCompanyID !== null &&
      assignment.insuranceCompanyID in ExternalyConnectedInsuranceCompanies;

    let inhouseInsuranceInfo = null;
    let externalInsurance = null;
    if (showAssignmentInsuranceInfo) {
      const internal = getItemByKeyID(
        repairShop.infoTexts,
        InfoTextType.InsuranceInfo
      );
      if (internal) {
        inhouseInsuranceInfo = internal.value;
      }

      externalInsurance = getListByKeyID(
        repairShop.externalInsurance,
        assignment.insuranceCompanyID
      );
    }

    let allowLinkToRepairShop = true;
    if (repairShop !== null) {
      allowLinkToRepairShop = repairShop.id !== assignment.repairShopID;
    }

    return (
      <PopupContainer close={close} headerText="Visa information:" small>
        <div className="popup-info-list-container">
          <div className="popup-horizontal-divider"></div>
          <div>
            {showAssignmentInfo && (
              <div
                className="main-item alternate-shade clickable popup-item"
                onClick={() => {
                  openOtherPopup(PopupType.AssignmentInfoPopup, {
                    assignment,
                    carBrands,
                    allowLinkToRepairShop,
                  });
                }}
              >
                <div>Ditt aktiva uppdrag</div>
              </div>
            )}
            {showAssignmentInsuranceInfo && (
              <div
                className="main-item alternate-shade clickable popup-item"
                onClick={() => {
                  openOtherPopup(PopupType.AssignmentInsuranceInfoPopup, {
                    insuranceID: assignment.insuranceCompanyID,
                    insuranceName: insuranceCompanies.getValue(
                      assignment.insuranceCompanyID
                    ),
                    repairShopName: repairShop.name,
                    inhouseInsuranceInfo,
                    externalInsurance,
                  });
                }}
              >
                <div>Verkstadens försäkring-uppdrag-info</div>
              </div>
            )}
            <div
              className="main-item alternate-shade clickable popup-item"
              onClick={() => {
                openOtherPopup(PopupType.InsuranceInfoListPopup, {
                  mainStore,
                });
              }}
            >
              <div>Försäkringsbolag: allmän info</div>
            </div>
            <div
              className="main-item alternate-shade clickable popup-item"
              onClick={() => {
                openOtherPopup(PopupType.GeneralRegisterInfoPopup, {
                  basicData,
                });
              }}
            >
              <div>Verkstadsregistret: allmän info</div>
            </div>
            {userInfo.access.canReview && (
              <div
                className="main-item alternate-shade clickable popup-item"
                onClick={() => {
                  openOtherPopup(PopupType.ReviewInfoPopup);
                }}
              >
                <div>Granska verkstäder: allmän info</div>
              </div>
            )}
          </div>
        </div>
      </PopupContainer>
    );
  }
}
MasterInfoPopup.propTypes = {
  settings: PropTypes.object.isRequired,
  mainStore: PropTypes.object.isRequired,
  repairShop: PropTypes.object,
};
MasterInfoPopup.defaultProps = {
  repairShop: null,
};

export default MasterInfoPopup;
