import { FC, useEffect, useMemo } from 'react';
import useGoogleMapsLibrary from './useGoogleMapsLibrary';

export interface Marker {
  lat: string | number;
  lng: string | number;
  onClick?: () => void;
  onDragEnd?(coords: google.maps.LatLngLiteral): void;
  id?: string;
  title?: string;

  icon?: google.maps.Icon | google.maps.Symbol;
  opacity?: number;
  zIndex?: number;
}
interface Props {
  map: google.maps.Map;
  marker: Marker;
}

const GoogleMapsMarker: FC<Props> = ({ map, marker: markerSettings }) => {
  const { library: markerLib } = useGoogleMapsLibrary('marker');

  const marker = useMemo(
    () =>
      markerLib &&
      new markerLib.Marker({
        position: {
          lat: Number(markerSettings.lat),
          lng: Number(markerSettings.lng),
        },
      }),
    [markerLib]
  );

  // Attach marker to map
  useEffect(() => {
    marker?.setMap(map);

    return () => {
      marker?.setMap(null);
    };
  }, [map, marker]);

  // Update marker icon
  useEffect(() => {
    marker?.setIcon(markerSettings.icon);
  }, [marker, markerSettings.icon]);

  // Update marker position
  useEffect(() => {
    marker?.setPosition({
      lat: Number(markerSettings.lat),
      lng: Number(markerSettings.lng),
    });
  }, [marker, markerSettings.lat, markerSettings.lng]);

  // Update marker title
  useEffect(() => {
    marker?.setTitle(markerSettings.title);
  }, [marker, markerSettings.title]);

  // Update marker zindex
  useEffect(() => {
    marker?.setZIndex(markerSettings.zIndex);
  }, [marker, markerSettings.zIndex]);

  // Update marker opacity
  useEffect(() => {
    marker?.setOpacity(markerSettings.opacity);
  }, [marker, markerSettings.opacity]);

  // Update marker onClick
  useEffect(() => {
    if (!markerSettings.onClick) return;

    const listener = marker?.addListener('click', markerSettings.onClick);

    return () => {
      listener?.remove();
    };
  }, [marker, markerSettings.onClick]);

  // Update marker onDragEnd
  useEffect(() => {
    if (!markerSettings.onDragEnd) return;

    const onDragEnd = markerSettings.onDragEnd;
    marker?.setDraggable(true);
    const listener = marker?.addListener(
      'dragend',
      (eve: { latLng: google.maps.LatLngLiteral | google.maps.LatLng }) => {
        const lat = eve.latLng.lat;
        const lng = eve.latLng.lng;

        if (typeof lat === 'number' && typeof lng === 'number') {
          onDragEnd({ lat, lng });
        } else if (typeof lat === 'function' && typeof lng === 'function') {
          onDragEnd({
            lat: lat(),
            lng: lng(),
          });
        }
      }
    );

    return () => {
      listener?.remove();
      marker?.setDraggable(false);
    };
  }, [marker, markerSettings.onDragEnd]);

  return null;
};

export default GoogleMapsMarker;
