import keyMirror from 'keymirror';

import {
  DepartmentType,
  StatusType,
  InsuranceCompanyType,
} from './TsEnums.gen';

import CheckboxCheckBlack from 'images/CheckboxCheckBlack.svg';
import CheckboxCheckGrey from 'images/CheckboxCheckGrey.svg';
import EditPenBlack from 'images/EditPenBlack.svg';
import EditPenWhite from 'images/EditPenWhite.svg';
import RemoveItemGrey from 'images/RemoveItemGrey.svg';
import SearchGlass from 'images/SearchGlass.svg';
import TargetCoordinate from 'images/TargetCoordinate.svg';
import TrashCanBlack from 'images/TrashCanBlack.svg';
import TrashCanGrey from 'images/TrashCanGrey.svg';
import TrashCanWhite from 'images/TrashCanWhite.svg';
import LoaderGears from 'images/LoaderGears.svg';
import LoaderSmall from 'images/LoaderSmall.svg';
import LoaderSmallWhite from 'images/LoaderSmallWhite.svg';
import PinYellow from 'images/PinYellow.svg';
import PinYellowFlat from 'images/PinYellowFlat.svg';
import PinGreen from 'images/PinGreen.svg';
import PinGreenFlat from 'images/PinGreenFlat.svg';
import PinTruck from 'images/PinTruck.svg';
import PinTruckFlat from 'images/PinTruckFlat.svg';
import PinCar from 'images/PinCar.svg';
import PinCarFlat from 'images/PinCarFlat.svg';
import PinStation from 'images/PinStation.svg';
import PinStationFlat from 'images/PinStationFlat.svg';
import PinCustom from 'images/PinCustom.svg';
import PinCustomFlat from 'images/PinCustomFlat.svg';
import DocumentIcon from 'images/DocumentIcon.svg';
import MenuArrowUp from 'images/MenuArrowUp.svg';
import MenuArrowRight from 'images/MenuArrowRight.svg';
import MenuArrowDown from 'images/MenuArrowDown.svg';
import MenuArrowLeft from 'images/MenuArrowLeft.svg';
import XBlack from 'images/XBlack.svg';
import black_logo from 'images/black-logo.png';

// Actions
export const CHANGE_EVENT = 'change';
export const ActionTypes = keyMirror({
  // Main
  SET_SEARCH_MODE: null,
  SET_CUSTOM_POSITION: null,
  SET_ACTIVE_POSITION_TYPE: null,
  // Manage Search and Filter state
  SEND_SEARCH_REQUEST: null,
  GET_SEARCH_RESULTS: null,
  EXTEND_SEARCH_LIST: null,
  BOOKMARK_SEARCH_SCROLL: null,
  SET_CAR_BRAND_FILTER: null,
  SET_INSURANCE_COMPANY_FILTER: null,
  SET_DEPARTMENT_FILTER: null,
  SET_SEARCH_STRING: null,
  TOGGLE_SHOW_REMOVED: null,
  // Review search
  SEND_REVIEW_SEARCH_REQUEST: null,
  GET_REVIEW_SEARCH_RESULTS: null,
  EXTEND_REVIEW_SEARCH_LIST: null,
  BOOKMARK_REVIEW_SEARCH_SCROLL: null,
  SET_STATION_FILTER: null,
  SET_REVIEW_DISTANCE: null,
  SET_REVIEW_FILTER: null,
  SET_REVIEW_LIST_SORT: null,
  // Get RepairShop
  REQUEST_REPAIRSHOP: null,
  CREATE_NEW_REPAIRSHOP_TEMPLATE: null,
  GET_REPAIRSHOP_RESULT: null,
  // Update / Create repairShop
  SAVE_REPAIRSHOP: null,
  REMOVE_REPAIRSHOP: null,
  RESURRECT_REPAIRSHOP: null,
  DISCARD_REPAIRSHOP_CHANGES: null,
  // Edit RepairShop
  SET_NAME: null,
  SET_ADDRESS: null,
  SET_ZIP: null,
  SET_CITY: null,
  SET_LATITUDE: null,
  SET_LONGITUDE: null,
  SET_INFO_TEXT: null,
  TOGGLE_INFO_TEXT_IS_PRIORITIZED: null,
  ADD_INFO_TEXT: null,
  TOGGLE_DEPARTMENT: null,
  TOGGLE_CAR_BRAND: null,
  TOGGLE_AUTHORIZED: null,
  TOGGLE_WARRANTY: null,
  TOGGLE_INSURANCE_COMPANY: null,
  TOGGLE_ALL_CAR_BRANDS_ENGINE: null,
  TOGGLE_ALL_CAR_BRANDS_PLATE: null,
  SET_EXTERNAL_CAR_BRAND_INFO: null,
  DROP_EXTERNAL_FROM_REPAIRSHOP: null,
  // Midland
  SEND_MIDLAND_REQUEST: null,
  SET_MIDLAND_RESULTS: null,
  CLOSE_MIDLAND_POPUP: null,
  // Google maps
  REQUEST_CUSTOM_LOCATION: null,
  GET_CUSTOM_LOCATION_RESULT: null,
  FIND_MATCHING_ADDRESS: null,
  GET_MATCHING_ADDRESSES_RESULT: null,
  REQUEST_DRIVING_DIRECTIONS_UPDATE: null,
  GET_DRIVING_DIRECTIONS_RESULT: null,
  // External Connections
  REQUEST_MISCOUPLED_EXTERNAL: null,
  GET_MISCOUPLED_EXTERNAL_RESULT: null,
  COUPLE_REPAIR_SHOP_TO_EXTERNAL: null,
  // Error
  SET_ERROR: null,
  CLEAR_ERROR: null,
  // Admin Edits
  CONNECT_INSURANCE_TO_ASSIST: null,
  UPDATE_INSURANCE_SETTINGS: null,
});

export const Images = {
  CheckboxCheckBlack: CheckboxCheckBlack,
  CheckboxCheckGrey: CheckboxCheckGrey,
  EditPenBlack: EditPenBlack,
  EditPenWhite: EditPenWhite,
  RemoveItemGrey: RemoveItemGrey,
  SearchGlass: SearchGlass,
  TargetCoordinate: TargetCoordinate,
  TrashCanBlack: TrashCanBlack,
  TrashCanGrey: TrashCanGrey,
  TrashCanWhite: TrashCanWhite,
  LoaderGears: LoaderGears,
  LoaderSmall: LoaderSmall,
  LoaderSmallWhite: LoaderSmallWhite,
  PinYellow: PinYellow,
  PinYellowFlat: PinYellowFlat,
  PinGreen: PinGreen,
  PinGreenFlat: PinGreenFlat,
  PinTruck: PinTruck,
  PinTruckFlat: PinTruckFlat,
  PinCar: PinCar,
  PinCarFlat: PinCarFlat,
  PinStation: PinStation,
  PinStationFlat: PinStationFlat,
  PinCustom: PinCustom,
  PinCustomFlat: PinCustomFlat,
  DocumentIcon: DocumentIcon,
  MenuArrowUp: MenuArrowUp,
  MenuArrowRight: MenuArrowRight,
  MenuArrowDown: MenuArrowDown,
  MenuArrowLeft: MenuArrowLeft,
  XBlack: XBlack,
  Header: black_logo,
};

// Frontend specific consts
export const DropdownItemHeight = 39;
export const MainItemHeight = 44;
export const ListDisplayCap = 50;
// Geographical center of Sweden
export const DefaultFallbackPosition = {
  latitude: 62.3875,
  longitude: 16.325556,
};

export const SwedenBounds = {
  north: 69.059,
  south: 55.339,
  west: 10.593,
  east: 24.177,
};

// Frontend store/state management
export enum PositionType {
  Assignment = 'Assignment',
  Custom = 'Custom',
  User = 'User',
  Station = 'Station',
  DefaultFallback = 'DefaultFallback',
}
export enum ReviewListSort {
  Name = 'Name',
  Address = 'Address',
  DateModified = 'DateModified',
  ModifiedBy = 'ModifiedBy',
  Status = 'Status',
  Distance = 'Distance',
}

// Enums
export enum CarBrandSpec {
  None = 1,
  Authorized = 2,
  Warranty = 3,
}

// DepartmentTypes special case management
export const CarBrandDependentDepartments = keyMirror({
  [DepartmentType.Engine]: null,
  [DepartmentType.Plate]: null,
  [DepartmentType.HeavyVehicles]: null,
  [DepartmentType.MotorCycle]: null,
});
export const AuthorizedDepartments = [
  DepartmentType.Engine,
  DepartmentType.Tire,
  DepartmentType.Glass,
];
export const WarrantyDepartments = [DepartmentType.Plate];

// Allbil: Car brand special id
export const AllBrandsId = 1;

// Special ID values
export const IdIsNew = -1;
export const IdNotInitialized = -2;

// Status special case management
export const RemovedStatusListing = keyMirror({
  [StatusType.Removed]: null,
  [StatusType.ProposedRemove]: null,
});

export const DuplicateStatusListing = keyMirror({
  [StatusType.Duplicate]: null,
  [StatusType.ProposedDuplicate]: null,
});

export const ActiveStatusListing = keyMirror({
  [StatusType.NotReviewed]: null,
  [StatusType.Reviewed]: null,
});

export const ProposedRemoveStatusListing = keyMirror({
  [StatusType.ProposedRemove]: null,
  [StatusType.ProposedDuplicate]: null,
});

export const ExternalyConnectedInsuranceCompanies = keyMirror({
  [InsuranceCompanyType.TryggHansa]: null,
  [InsuranceCompanyType.Gjensidige]: null,
  [InsuranceCompanyType.DinaForsakringar]: null,
  [InsuranceCompanyType.Midland]: null,
});

export const InsuranceCompaniesWithInsuranceAbilities = keyMirror({
  [InsuranceCompanyType.TryggHansa]: null,
});

export enum SearchMode {
  Main = 'Main',
  Review = 'Review',
  CoupleExternal = 'CoupleExternal',
}
