import { Component } from 'react';
import PropTypes from 'prop-types';

import { toDateTimeStr, navigateToStartList } from 'general/UtilityFunctions';
import { Actions } from 'general/Actions';
import StoreMain from 'components/Stores/StoreMain';
import StoreRepairShop from 'components/Stores/StoreRepairShop';

import Button from 'components/Button/Button';
import { PageLoader } from 'components/Loader/Loader';
import ControlledInput from 'components/Input/InputComponent';
import FindRepairShopMap from 'components/GoogleMaps/FindRepairShopMap';

import { Popup, PopupType, setPopup } from 'components/Popup/Popup';
import { Images, IdIsNew } from 'general/Constants';
import GlobalRouter from 'views/routes/DEP_GlobalRouter';
import withRouter from 'views/routes/DEP_withRouter';
import InsurancesPanel from './InsurancesPanel';
import CarBrandsPanel from './CarBrandsPanel';
import EditGeneralInfoPanel from './EditGeneralInfoPanel';

function getStateFromStores() {
  return {
    mainStore: StoreMain.getState(),
    repairShopStore: StoreRepairShop.getState(),
  };
}
function getDefaultState() {
  return Object.assign({}, getStateFromStores(), {
    id: -1,
    inputAccepted: true,
    saveIsLoading: false,
    popupProps: {},
  });
}

class EditRepairShopView extends Component {
  constructor(props) {
    super(props);

    this.state = getDefaultState();

    this.onChange = this.onChange.bind(this);
    this.validateShop = this.validateShop.bind(this);

    // On load view actions
    if (this.props.params.id) {
      // Load repairShop by Url:id (string)
      this.state.id = parseInt(this.props.params.id, 10);
      Actions.requestRepairShop(this.state.id);
    }
  }

  componentDidMount() {
    StoreMain.addChangeListener(this.onChange);
    StoreRepairShop.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    StoreMain.removeChangeListener(this.onChange);
    StoreRepairShop.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState(getStateFromStores(), () => this.validateShop(false));
  }

  validateShop(forceValidOnCheck) {
    const { repairShop } = this.state.repairShopStore;

    if (
      repairShop === null ||
      !repairShop.name ||
      !repairShop.address ||
      !repairShop.zip ||
      !repairShop.city ||
      repairShop.latitude === null ||
      repairShop.longitude === null
    ) {
      if (forceValidOnCheck) {
        this.setState({ inputAccepted: false });
      }

      return false;
    }

    this.setState({ inputAccepted: true });
    return true;
  }

  render() {
    const {
      inputAccepted,
      popupProps,
      saveIsLoading,
      mainStore,
      repairShopStore,
      id,
    } = this.state;
    const { searchMode } = mainStore;
    const { repairShop, unalteredRepairShop, repairShopIsChanged } =
      repairShopStore;

    const isNew = repairShop !== null && repairShop.id === IdIsNew;
    const shouldBeLoading =
      repairShop === null || (!isNew && id !== repairShop.id);

    if (shouldBeLoading) {
      return <PageLoader />;
    }

    return (
      <div className="main-page-container repair-shop-view">
        <div className="main-content-container">
          <div className="main-head">
            <div className="title">
              <ControlledInput
                value={repairShop.name}
                handleChange={Actions.setName}
                placeholder="Namn på verkstaden"
                className="edit-header-input"
                displayRequired={!inputAccepted}
              />
            </div>
            {isNew ? (
              <div className="edit-title-info wide">
                Skapar ny verkstad. (Kontrollera gärna att dublett med fel namn
                eller adress ej existerar.)
              </div>
            ) : (
              <div className="edit-title-info">
                Redigerar verkstad: {unalteredRepairShop.name} (ID:{' '}
                {repairShop.id})
              </div>
            )}
            {repairShop.id >= 0 && (
              <div className="head-rightside">
                <div className="remove-info">
                  Ta bort verkstad som stängt eller finns som dublett i
                  registret!
                </div>
                <Button
                  black
                  hMain
                  square
                  icon={Images.TrashCanWhite}
                  onClick={() => {
                    setPopup(this, PopupType.RemoveShopPopup, {
                      name: repairShop.name,
                      searchMode: mainStore.searchMode,
                    });
                  }}
                />
              </div>
            )}
          </div>

          <div className="info-area">
            <EditGeneralInfoPanel
              inputAccepted={inputAccepted}
              setPopup={(popupType, popupProps) =>
                setPopup(this, popupType, popupProps)
              }
            />

            <CarBrandsPanel
              repairShop={repairShop}
              editMode
              setPopup={(popupType, popupProps) =>
                setPopup(this, popupType, popupProps)
              }
            />

            <InsurancesPanel
              editMode
              setPopup={(popupType, popupProps) =>
                setPopup(this, popupType, popupProps)
              }
            />
          </div>
        </div>

        <div className="main-footer">
          {!inputAccepted && (
            <div className="footer-info-container">
              <div className="inner required-fields-info">
                <div className="red-marker-dot"></div>
                <div className="text">Fyll i alla nödvändiga fält</div>
                <div className="red-marker-dot"></div>
              </div>
            </div>
          )}
          <Button
            yellow
            wMain
            hLarge
            footerMargin
            text={isNew ? 'Skapa' : 'Spara'}
            disabled={!repairShopIsChanged || !inputAccepted}
            loading={saveIsLoading}
            onClick={() => {
              if (this.validateShop(true)) {
                this.setState({ saveIsLoading: true });
                Actions.saveRepairShop((repairShopResult) => {
                  GlobalRouter.push(`repairshop/${repairShopResult.id}`);
                });
              }
            }}
          />
          <Button
            black
            wMain
            hLarge
            text="Avbryt"
            onClick={() => {
              const onCancel = () => {
                if (isNew) {
                  navigateToStartList(searchMode);
                } else {
                  GlobalRouter.push(`repairshop/${repairShop.id}`);
                }
              };

              if (repairShopIsChanged) {
                setPopup(this, PopupType.DiscardChangesPopup, {
                  discardChanges: () => {
                    Actions.discardRepairShopChanges();
                    onCancel();
                  },
                });
              } else {
                onCancel();
              }
            }}
          />

          {!isNew && (
            <div className="last-edited-field">
              Senast ändrad av {repairShop.modifiedBy}
              <br />
              {toDateTimeStr(repairShop.dateModified)}
            </div>
          )}
        </div>

        <Popup {...popupProps} />
      </div>
    );
  }
}

EditRepairShopView.propTypes = {
  params: PropTypes.any.isRequired,
};

export default withRouter(EditRepairShopView);
