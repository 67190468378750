import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Images } from 'general/Constants';

const getValueString = (value) => {
  const clean = value || '';
  return clean.toString();
};

export default class ControlledInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayValue: getValueString(this.props.value),
      returnValue: this.props.value,
      lastRecordeValue: this.props.value,
      timestamp: Date.now(),
      isEditing: false,
    };

    this.autoInputTimeout = null;
    this.insertValueTimeout = null;

    this.updateValues = this.updateValues.bind(this);
    this.startAutoInputTimer = this.startAutoInputTimer.bind(this);
    this.handleKey = this.handleKey.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
  }

  componentDidMount() {
    if (this.props.focusOnMount === true) {
      this.handleBlur();
      this.inputRef.focus();
    }
  }

  componentWillUpdate() {
    const incommingValue = this.props.value;
    const currentValue = this.state.returnValue;

    if (
      (incommingValue || currentValue) && // either hasContent
      currentValue !== incommingValue &&
      !this.state.isEditing
    ) {
      if (this.insertValueTimeout !== null) {
        clearTimeout(this.insertValueTimeout);
      }

      const newVal = getValueString(incommingValue);
      this.updateValues(newVal, Date.now());
      // console.log('%c   TMPLOG: this.props   ', 'background-color: #c4f287; color: #050505;', '\n', this.props);
      // console.log('%c   TMPLOG: this.state   ', 'background-color: #c4f287; color: #050505;', '\n', this.state);
      this.insertValueTimeout = setTimeout(() => {}, 66);
    }
  }

  componentWillUnmount() {
    // All timeouts that effect state must be canceled upon unmounting
    if (this.autoInputTimeout !== null) {
      clearTimeout(this.autoInputTimeout);
    }
    if (this.insertValueTimeout !== null) {
      clearTimeout(this.insertValueTimeout);
    }
  }

  updateValues(text, timestamp) {
    if (timestamp > this.state.timestamp) {
      let setState = {};

      if (this.props.restrict !== null) {
        setState = this.props.restrict(text, this.state.displayValue);
      } else {
        setState = {
          displayValue: text,
          returnValue: text.trim(),
        };
      }

      setState.timestamp = timestamp;

      this.setState(setState);
    }
  }

  startAutoInputTimer() {
    if (this.props.autoInputTimer > 0) {
      if (this.autoInputTimeout !== null) {
        clearTimeout(this.autoInputTimeout);
      }
      this.autoInputTimeout = setTimeout(() => {
        this.props.handleChange(this.state.returnValue);
        this.autoInputTimeout = null;
      }, this.props.autoInputTimer);
    }
  }

  handleKey(event) {
    if (!this.props.textArea && event.key === 'Enter') {
      event.preventDefault();
      this.inputRef.blur();
      this.setState({ isEditing: false });
    } else {
      this.setState({ isEditing: true });
    }
  }

  handleBlur() {
    this.props.handleChange(this.state.returnValue);
    const setState = {
      isEditing: false,
    };

    if (this.props.clearOnInput) {
      setState.displayValue = '';
      setState.returnValue = '';
    }

    this.setState(setState);
  }

  render() {
    const {
      placeholder,
      className,
      style,
      textArea,
      restrict,
      displayRequired,
      children,
      enableQuickClear,
      handleRemove,
      type,
      noEditingButton,
    } = this.props;
    const { displayValue, returnValue, isEditing } = this.state;

    let showPlaceHolder = placeholder;
    if (displayValue) {
      showPlaceHolder = '';
    }

    const inputProps = {
      className,
      style,
      ref: (input) => {
        this.inputRef = input;
      },
      placeholder: showPlaceHolder,
      type,
      value: displayValue,
      onChange: (event) => {
        this.updateValues(event.target.value, Date.now());
        this.startAutoInputTimer();
      },
      onKeyDown: this.handleKey,
      onBlur: this.handleBlur,
      'data-css': 'override',
    };

    const showRequired =
      displayRequired &&
      (restrict !== null ? displayValue === '' : returnValue === '');
    const showInputButton = isEditing && !showRequired && !noEditingButton;
    const showRemove =
      isEditing === false && handleRemove !== null && displayValue !== '';
    const showQuickClear = enableQuickClear && displayValue !== '';

    if (showRequired || showRemove || showQuickClear || showInputButton) {
      inputProps.className += ' has-rightside-button';
    }

    return (
      <div className="input-field-wrapper">
        {textArea ? <textarea {...inputProps} /> : <input {...inputProps} />}
        {children}
        {showQuickClear && (
          <div
            className="input-rightside-button"
            onClick={() => {
              this.updateValues('', Date.now());
              this.props.handleChange('');
            }}
          >
            <img
              src={Images.RemoveItemGrey}
              alt="Remove"
              className="remove-icon"
            />
          </div>
        )}
        {showRemove && (
          <div className="input-rightside-button" onClick={handleRemove}>
            <img
              src={Images.TrashCanGrey}
              alt="Remove"
              className="remove-icon"
            />
          </div>
        )}
        {showInputButton && (
          <div
            className="input-rightside-button shaded"
            onClick={this.handleBlur}
          >
            <div>OK</div>
          </div>
        )}
        {showRequired && (
          <div className="input-required-background">
            <div className="red-marker-dot"></div>
          </div>
        )}
      </div>
    );
  }
}

ControlledInput.defaultProps = {
  value: '',
  placeholder: '',
  clearOnInput: false,
  className: 'edit-field-input',
  style: {},
  textArea: false,
  restrict: null,
  displayRequired: false,
  children: false,
  enableQuickClear: false,
  handleRemove: null,
  autoInputTimer: 0,
  noEditingButton: false,
  type: 'text',
  focusOnMount: false,
};

ControlledInput.propTypes = {
  value: PropTypes.any,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  enableQuickClear: PropTypes.bool,
  handleRemove: PropTypes.func,
  autoInputTimer: PropTypes.number,
  clearOnInput: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  textArea: PropTypes.bool,
  restrict: PropTypes.func,
  displayRequired: PropTypes.bool,
  children: PropTypes.any,
  noEditingButton: PropTypes.bool,
  type: PropTypes.string,
  focusOnMount: PropTypes.bool.isRequired,
};
