import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Actions } from 'general/Actions';
import Button from '../../Button/Button';
import ControlledInput from '../../Input/InputComponent';
import { PopupContainer } from '../PopupComponents';
import { InfoTextType } from 'general/TsEnums.gen';

class AddCommentPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
    };
  }

  render() {
    const { settings, editView, scrollTrigger } = this.props;
    const { close } = settings;

    return (
      <PopupContainer
        close={close}
        headerText="Lägg till kommentar"
        small
        input
      >
        <ControlledInput
          placeholder="Skriv kommentar..."
          value={this.state.value}
          handleChange={(value) => this.setState({ value })}
          autoInputTimer={1} // Special iPad fix (onBlur fails when pressing a button)
          textArea
          className="comment-textarea"
          focusOnMount
        />
        <div className="popup-buttons-box">
          <Button
            yellow
            wLarge
            hMain
            text="Spara"
            loadingOnClick
            onClick={() => {
              setTimeout(() => {
                Actions.addInfoText(this.state.value, InfoTextType.Comment);

                if (editView) {
                  scrollTrigger();
                  close();
                } else {
                  // Update repairShop on input
                  Actions.saveRepairShop(close);
                }
              }, 33);
            }}
          />
          <div className="medium-padder-h"></div>
          <Button black wLarge hMain text="Avbryt" onClick={close} />
        </div>
      </PopupContainer>
    );
  }
}

AddCommentPopup.propTypes = {
  settings: PropTypes.object.isRequired,
  editView: PropTypes.bool,
  scrollTrigger: PropTypes.func,
};
AddCommentPopup.defaultProps = {
  editView: false,
  scrollTrigger: undefined,
};

export default AddCommentPopup;
