import AddressInput from 'components/Input/AddressInput';
import { GeographicPlaceModelWithIndex } from 'components/Stores/StoreRepairShop';
import { Actions } from 'general/Actions';
import { precisionRound } from 'general/UtilityFunctions';
import {
  GeographicPlaceModel,
  PositionModel,
  RepairShopModel,
} from 'general/api/models';
import { FC, useEffect, useRef, useState } from 'react';
import useGoogleMapsMap from '../lib/useGoogleMapsMap';
import { Popup, PopupType, usePopupState } from 'components/Popup/Popup';
import Button from 'components/Button/Button';
import withGoogleMapsLibrary from '../lib/withGoogleMapsLibrary';
import { Images } from 'general/Constants';
import { MapPin, getBounds } from '../MapUtility';
import createMapPin from '../markerIcons/createMapPin';
import GoogleMapsMarker from '../lib/GoogleMapsMarker';
import createMapPoint from '../markerIcons/createMapPoint';
import useGoogleMapsOnClick from '../lib/useGoogleMapsOnClick';
import { RepairShopAPI } from 'general/API';

const saveAddress = (loaction: GeographicPlaceModel) => {
  Actions.setAddress(loaction.address);
  Actions.setZip(loaction.zip);
  Actions.setCity(loaction.city);
};

const handleMapClick = (values: { lat: number; lng: number }) => {
  Actions.requestCustomLocation(
    precisionRound(values.lat, 7),
    precisionRound(values.lng, 7)
  );
};

const getPins = (
  userPosition: PositionModel,
  locations: GeographicPlaceModelWithIndex[],
  customLocation: GeographicPlaceModel | null
) => {
  // Create the list of pins to print (with the correct depth sorting and all properties fixed)
  const pins: Omit<MapPin, 'id'>[] = locations.map((item) => ({
    key: item.completeInfo,
    lat: item.position.latitude,
    lng: item.position.longitude,
    icon: createMapPin(String(item.index), '#FFDC2A'),
  }));
  if (userPosition !== null) {
    pins.push({
      key: 'truck-pin',
      lat: userPosition.latitude,
      lng: userPosition.longitude,
      icon: Images.PinTruck,
    });
  }
  if (customLocation !== null) {
    pins.push({
      key: 'custom-pin',
      lat: customLocation.position.latitude,
      lng: customLocation.position.longitude,
      icon: Images.PinCustom,
    });
  }
  pins.sort((a, b) => b.lat - a.lat);

  return pins;
};

interface Props {
  shop: RepairShopModel;
  customLocation: GeographicPlaceModel | null;
  userPosition: PositionModel;
  initialSearchString?: string;
  close(): void;
}

const FindRepairShopMap: FC<Props> = withGoogleMapsLibrary(
  ['core'],
  ({
    shop,
    customLocation,
    userPosition,
    initialSearchString,
    close,
    coreLib,
  }) => {
    const { openPopup, popupState } = usePopupState();
    const [locations, setLocations] = useState<GeographicPlaceModelWithIndex[]>(
      []
    );
    const [searchString, setSearchString] = useState(
      initialSearchString || shop.address
    );

    const mapDiv = useRef<HTMLDivElement>(null);
    const map = useGoogleMapsMap(mapDiv, {
      minZoom: 4,
      maxZoom: 19,
      fullscreenControl: false,
      gestureHandling: 'greedy',
    });
    useGoogleMapsOnClick(map, handleMapClick);

    const mapPins = getPins(userPosition, locations, customLocation);

    useEffect(() => {
      if (map) {
        map.fitBounds(
          getBounds(
            shop.latitude !== null && shop.longitude !== null
              ? [
                  {
                    lat: shop.latitude,
                    lng: shop.longitude,
                  },
                  ...mapPins,
                ]
              : mapPins,
            coreLib
          )
        );
      }
    }, [!!map]);

    const showItemOnMap = (lat: number, lng: number) => {
      map?.setCenter({
        lat: lat,
        lng: lng,
      });
      map?.setZoom(12);
    };

    const saveLocation = (
      location: GeographicPlaceModel,
      autoSaveAddress: boolean
    ) => {
      showItemOnMap(location.position.latitude, location.position.longitude);
      setSearchString(location.address);

      if (autoSaveAddress) {
        saveAddress(location);
      } else {
        checkNewProposedAddress(location);
      }
      Actions.setLatitude(location.position.latitude);
      Actions.setLongitude(location.position.longitude);
    };

    const saveTruckLocation = (userPosition: PositionModel) => {
      Actions.setLatitude(userPosition.latitude);
      Actions.setLongitude(userPosition.longitude);
      showItemOnMap(userPosition.latitude, userPosition.longitude);

      RepairShopAPI.getAddressFromPosition(
        { latitude: userPosition.latitude, longitude: userPosition.longitude },
        (data) => {
          if (data) {
            checkNewProposedAddress(data);
          }
        }
      );
    };

    const checkNewProposedAddress = (location: GeographicPlaceModel) => {
      let addressDiffer = false;
      if (
        shop.address !== null &&
        shop.address !== '' &&
        shop.address !== location.address
      ) {
        addressDiffer = true;
      }
      if (
        shop.city !== null &&
        shop.city !== '' &&
        shop.city !== location.city
      ) {
        addressDiffer = true;
      }
      if (shop.zip !== null && shop.zip !== '' && shop.zip !== location.zip) {
        addressDiffer = true;
      }
      if (addressDiffer) {
        const currentAddress = {
          address: shop.address,
          zip: shop.zip,
          city: shop.city,
        };
        const suggestedAddress = {
          address: location.address,
          zip: location.zip,
          city: location.city,
        };

        openPopup(PopupType.SetAddressFromMapPopup, {
          currentAddress,
          suggestedAddress,
          saveAddress,
        });
      } else {
        saveAddress(location);
      }
    };

    return (
      <div className="find-repair-shop-map">
        <AddressInput
          value={searchString}
          map
          onLocationPicked={(location) => saveLocation(location, true)}
          includePlacesSearch
          showResultNumbers
          onSearchResultsChanged={(l) =>
            setLocations(
              l.map((item, index) => ({ ...item, index: index + 1 }))
            )
          }
        />

        {shop.latitude !== null && (
          <div
            className="repairShopPositionInfo clickable"
            onClick={() => {
              showItemOnMap(shop.latitude, shop.longitude);
            }}
          >
            <div className="red-map-point"></div>
            <div className="text">Verkstadens sparade position</div>
          </div>
        )}
        <div className="repairShopMapCloseButton">
          <Button yellow wMain hMain text="Klar" onClick={close} />
        </div>
        <div className="bottomButtonContainer">
          {userPosition !== null && (
            <Button
              yellow
              hMain
              className="wide-button"
              onClick={() => {
                saveTruckLocation(userPosition);
              }}
            >
              <img
                src={Images.PinTruckFlat}
                alt="ico"
                className="button-map-marker"
              />
              <span>Spara bilens position</span>
            </Button>
          )}
          <Button
            yellow
            disabled={customLocation === null}
            hMain
            className="wide-button"
            onClick={() => {
              customLocation !== null && saveLocation(customLocation, false);
            }}
          >
            <img
              src={Images.PinCustomFlat}
              alt="ico"
              className="button-map-marker"
            />
            <span>Spara utplacerad position</span>
          </Button>
          {customLocation === null && (
            <div className="newPositionInfo">
              <div className="tooltipArrowContainer">
                <div className="tooltipArrow">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0, 0, 100, 100"
                    preserveAspectRatio="none"
                  >
                    <polygon
                      points="100,100 100,0 100,0 0,50"
                      style={{ fill: '#fff', stroke: '#666' }}
                    />
                  </svg>
                </div>
                <div className="tooltopAntiArrow"></div>
              </div>
              <div className="newPositionInfoText">
                <div className="text">
                  Tryck på kartan för att lägga till en ny position
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="mapContainer" ref={mapDiv}>
          {map && shop.latitude !== null && shop.longitude !== null && (
            <GoogleMapsMarker
              map={map}
              marker={{
                lat: shop.latitude,
                lng: shop.longitude,
                icon: {
                  url: createMapPoint(),
                  scaledSize: new coreLib.Size(12, 12),
                  anchor: new coreLib.Point(12, 6),
                },
              }}
            />
          )}

          {map &&
            mapPins.map((pin) => (
              <GoogleMapsMarker
                key={pin.key}
                map={map}
                marker={{
                  lat: pin.lat,
                  lng: pin.lng,
                  icon: {
                    url: pin.icon,
                    scaledSize: new coreLib.Size(55, 55),
                  },
                }}
              />
            ))}
        </div>

        <Popup {...popupState} />
      </div>
    );
  }
);

export default FindRepairShopMap;
