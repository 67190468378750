import { FC, PropsWithChildren, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import * as constants from 'globalStyles/constants';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div<{ warning?: boolean }>`
  &&& {
    font-weight: bold;
    margin-bottom: 5px;
    border-bottom: 1px solid ${constants.border_shade};

    ${({ warning }) =>
      warning &&
      css`
        opacity: ${constants.main_transparent};
        color: ${constants.red_text};
      `}
  }
`;

interface Props extends PropsWithChildren {
  header: ReactNode;
  headerWarning?: boolean;
}

const InfoBoxItem: FC<Props> = ({ header, headerWarning, children }) => {
  return (
    <Wrapper>
      <Header warning={headerWarning}>{header}</Header>

      {children}
    </Wrapper>
  );
};

export default InfoBoxItem;
