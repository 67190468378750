import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ContainedLoader } from '../Loader/Loader';
import GlobalRouter from 'views/routes/DEP_GlobalRouter';

export default class Button extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  render() {
    const {
      onClick,
      linkTo,
      disabled,
      loadingOnClick,
      text,
      icon,
      className,
      children,
      yellow,
      black,
      white,
      red,
      outline,
      hMain,
      hLarge,
      hSmall,
      hMini,
      wMain,
      wLarge,
      wSmall,
      wFull,
      square,
      padMedium,
      padSmall,
      footerMargin,
    } = this.props;

    const loading = this.state.loading || this.props.loading;

    let buttonClass = classNames({
      'button-component': true,
      clickable: true,
      disabled,
      yellow,
      black,
      white,
      red,
      outline,
      square,
      'w-main': wMain,
      'w-large': wLarge,
      'w-full': wFull,
      'w-small': wSmall,
      'h-main': hMain,
      'h-large': hLarge,
      'h-small': hSmall,
      'h-mini': hMini,
      'inner-padding-medium': padMedium,
      'inner-padding-small': padSmall,
      'footer-margin-right': footerMargin,
    });

    if (className) {
      buttonClass += ` ${className}`;
    }

    if (loading) {
      return (
        <div className={buttonClass}>
          <ContainedLoader white={black} />
        </div>
      );
    }

    return (
      <div
        className={buttonClass}
        onClick={(event) => {
          if (!disabled && !loading) {
            if (loadingOnClick || linkTo) {
              this.setState({ loading: true });
            }

            if (linkTo) {
              if (onClick) {
                onClick(event);
              }
              GlobalRouter.push(linkTo);
            } else if (onClick) {
              onClick(event);
            }
          }
        }}
      >
        {icon && <img src={icon} alt="ico" className="inner-icon" />}
        {icon && text && <div className="mini-padder-h"></div>}
        {text && <div>{text}</div>}
        {children}
      </div>
    );
  }
}

// NOTE: use className and children for all custom variations
Button.propTypes = {
  // Base functionallity
  onClick: PropTypes.func,
  linkTo: PropTypes.string,
  disabled: PropTypes.bool,
  loadingOnClick: PropTypes.bool,
  loading: PropTypes.bool,
  // Appearance
  text: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
  // Standard colors
  yellow: PropTypes.bool,
  black: PropTypes.bool,
  white: PropTypes.bool,
  red: PropTypes.bool,
  outline: PropTypes.bool,
  // Standard heights
  hLarge: PropTypes.bool,
  hMain: PropTypes.bool,
  hSmall: PropTypes.bool,
  hMini: PropTypes.bool,
  // Standard widths
  wMain: PropTypes.bool,
  wLarge: PropTypes.bool,
  wSmall: PropTypes.bool,
  wFull: PropTypes.bool,
  square: PropTypes.bool,
  // Standard side paddings
  padMedium: PropTypes.bool,
  padSmall: PropTypes.bool,
  footerMargin: PropTypes.bool,
};

Button.defaultProps = {
  // Base functionallity
  onClick: undefined,
  linkTo: undefined,
  disabled: false,
  loadingOnClick: false,
  loading: false,
  // Appearance
  text: undefined,
  icon: undefined,
  className: undefined,
  children: undefined,
  // Standard colors
  yellow: false,
  black: false,
  white: false,
  red: false,
  outline: false,
  // Standard heights
  hLarge: false,
  hMain: false,
  hSmall: false,
  hMini: false,
  // Standard widths
  wMain: false,
  wLarge: false,
  wSmall: false,
  wFull: false,
  square: false,
  // Standard side paddings and margins
  padMedium: false,
  padSmall: false,
  footerMargin: false,
};
