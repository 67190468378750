import React from 'react';

import {
  hasKeyID,
  getListIndexByKeyID,
  getListByKeyID,
  getItemByKeyID,
} from 'general/UtilityFunctions';
import { Actions } from 'general/Actions';
import Checkbox from '../Checkbox/Checkbox';
import {
  InfoTextType,
  ChangeLogType,
  getChangeLogTypeDisplayName,
  getInfoTextTypeDisplayName,
} from 'general/TsEnums.gen';
import {
  RemovedStatusListing,
  DuplicateStatusListing,
} from 'general/Constants';
import { valueHasSignificance, changeLogValueConsts } from './HistoryUtility';

const en = 'en';
const ett = 'ett';

const nothing = '---';
const active = 'Aktiv';

const renderChangeRow = (
  change,
  inResetList,
  toggleReset,
  basicData,
  repairShop
) => {
  const { carBrands, insuranceCompanies, departments } = basicData;

  // Funcion used to call correct store action with parameters to reset change (reset not alowed if null)
  let resetAction = null;

  let grammaticAttribute = en;
  const added = !valueHasSignificance(change.oldValue);
  const removed = !valueHasSignificance(change.newValue);

  let changeString = getChangeLogTypeDisplayName(change.changeType);

  let to = change.newValue;
  let from = change.oldValue;

  if (removed) {
    to = nothing;
  } else if (to === changeLogValueConsts.trueStr) {
    to = active;
  }
  if (added) {
    from = nothing;
  } else if (from === changeLogValueConsts.trueStr) {
    from = active;
  }

  // Special action: update infoText
  let infoTextType = null;

  let allowToggle = false;
  let parsedInt = 0;
  let index = 0;

  switch (change.changeType) {
    case ChangeLogType.RepairShopName:
      grammaticAttribute = ett;
      if (!added) {
        resetAction = () => {
          Actions.setName(change.oldValue);
        };
      }
      break;

    case ChangeLogType.YardAddress:
      if (!added) {
        resetAction = () => {
          Actions.setAddress(change.oldValue);
        };
      }
      break;

    case ChangeLogType.YardCity:
      if (!added) {
        resetAction = () => {
          Actions.setCity(change.oldValue);
        };
      }
      break;

    case ChangeLogType.YardZip:
      if (!added) {
        resetAction = () => {
          Actions.setZip(change.oldValue);
        };
      }
      break;

    case ChangeLogType.LockInfoLegacy:
      grammaticAttribute = ett;
      infoTextType = InfoTextType.LockInfo;
      break;

    case ChangeLogType.AlarmInfoLegacy:
      grammaticAttribute = ett;
      infoTextType = InfoTextType.AlarmInfo;
      break;

    case ChangeLogType.PassCodeInfoLegacy:
      infoTextType = InfoTextType.PassCode;
      break;

    case ChangeLogType.PhoneNumberLegacy:
      grammaticAttribute = ett;
      infoTextType = InfoTextType.PhoneNumber;
      break;

    case ChangeLogType.EmailLegacy:
      infoTextType = InfoTextType.Email;
      break;

    case ChangeLogType.CommentLegacy:
      infoTextType = InfoTextType.Comment;
      break;

    case ChangeLogType.WebsiteLegacy:
      infoTextType = InfoTextType.Website;
      break;

    case ChangeLogType.InsuranceCompanyInfoLegacy:
      infoTextType = InfoTextType.InsuranceInfo;
      break;

    case ChangeLogType.InfoTextValue:
      infoTextType = change.keyID;
      changeString = getInfoTextTypeDisplayName(infoTextType);

      if (
        infoTextType === InfoTextType.PhoneNumber ||
        infoTextType === InfoTextType.AlarmInfo ||
        infoTextType === InfoTextType.LockInfo
      ) {
        grammaticAttribute = ett;
      }

      break;

    case ChangeLogType.InsuranceCompany:
      grammaticAttribute = ett;

      if (added) {
        parsedInt = parseInt(change.newValue, 10);
        to = insuranceCompanies.getValue(parsedInt);
        allowToggle = hasKeyID(repairShop.insuranceCompanies, parsedInt);
      } else {
        parsedInt = parseInt(change.oldValue, 10);
        from = insuranceCompanies.getValue(parsedInt);
        allowToggle = !hasKeyID(repairShop.insuranceCompanies, parsedInt);
      }

      if (allowToggle) {
        resetAction = () => {
          Actions.toggleInsuranceCompany(parsedInt);
        };
      }
      break;

    case ChangeLogType.InsuranceCompanyActiveLegacy:
      grammaticAttribute = ett;

      if (added) {
        to = insuranceCompanies.getValue(change.keyID);
        allowToggle = hasKeyID(repairShop.insuranceCompanies, change.keyID);
      } else {
        from = insuranceCompanies.getValue(change.keyID);
        allowToggle = !hasKeyID(repairShop.insuranceCompanies, change.keyID);
      }

      if (allowToggle) {
        resetAction = () => {
          Actions.toggleInsuranceCompany(change.keyID);
        };
      }
      break;

    case ChangeLogType.CarBrand:
      grammaticAttribute = ett;

      if (added) {
        parsedInt = parseInt(change.newValue, 10);
        to = carBrands.getValue(parsedInt);
        allowToggle = hasKeyID(repairShop.carBrands, parsedInt);
      } else {
        parsedInt = parseInt(change.oldValue, 10);
        from = carBrands.getValue(parsedInt);
        allowToggle = !hasKeyID(repairShop.carBrands, parsedInt);
      }

      if (allowToggle) {
        resetAction = () => {
          Actions.toggleCarBrand(parsedInt);
        };
      }
      break;

    case ChangeLogType.CarBrandWarranty:
      grammaticAttribute = '';
      changeString = `Bilmärke ${carBrands.getValue(change.keyID)} - ändrat`;

      index = getListIndexByKeyID(repairShop.carBrands, change.keyID);
      allowToggle = index !== -1;

      if (added) {
        to = 'VSG';
        allowToggle = allowToggle && repairShop.carBrands[index].hasWarranty;
      } else {
        from = 'VSG';
        allowToggle = allowToggle && !repairShop.carBrands[index].hasWarranty;
      }

      if (allowToggle) {
        resetAction = () => {
          Actions.toggleWarranty(change.keyID);
        };
      }
      break;

    case ChangeLogType.CarBrandAuthorized:
      grammaticAttribute = '';
      changeString = `Bilmärke ${carBrands.getValue(change.keyID)} - ändrat`;

      index = getListIndexByKeyID(repairShop.carBrands, change.keyID);
      allowToggle = index !== -1;

      if (added) {
        to = 'Aukt.';
        allowToggle = allowToggle && repairShop.carBrands[index].isAuthorized;
      } else {
        from = 'Aukt.';
        allowToggle = allowToggle && !repairShop.carBrands[index].isAuthorized;
      }

      if (allowToggle) {
        resetAction = () => {
          Actions.toggleAuthorized(change.keyID);
        };
      }
      break;

    case ChangeLogType.Department:
      if (added) {
        parsedInt = parseInt(change.newValue, 10);
        to = departments.getValue(parsedInt);
        allowToggle = hasKeyID(repairShop.departments, parsedInt);
      } else {
        parsedInt = parseInt(change.oldValue, 10);
        from = departments.getValue(parsedInt);
        allowToggle = !hasKeyID(repairShop.departments, parsedInt);
      }

      if (allowToggle) {
        resetAction = () => {
          Actions.toggleDepartment(parsedInt);
        };
      }
      break;

    case ChangeLogType.Latitude:
      if (!added) {
        resetAction = () => {
          Actions.setLatitude(parseFloat(change.oldValue));
        };
      }
      break;

    case ChangeLogType.Longitude:
      if (!added) {
        resetAction = () => {
          Actions.setLongitude(parseFloat(change.oldValue));
        };
      }
      break;

    case ChangeLogType.Status:
      parsedInt = parseInt(change.newValue, 10);

      // Proposed Remove?
      if (parsedInt in DuplicateStatusListing) {
        from = active;
        to = 'Borttagen (dubblett)';
      } else if (parsedInt in RemovedStatusListing) {
        from = active;
        to = 'Borttagen (stängd)';
      } else {
        // Restored?
        parsedInt = parseInt(change.oldValue, 10);
        if (parsedInt in DuplicateStatusListing) {
          from = 'Borttagen (dubblett)';
          to = 'Återställd i registret';
        } else if (parsedInt in RemovedStatusListing) {
          from = 'Borttagen (stängd)';
          to = 'Återställd i registret';
        }
      }
      break;

    case ChangeLogType.Reviewed:
      grammaticAttribute = '';
      changeString = 'Status - ändrad';
      from = 'Aldrig godkänd';
      to = 'Godkänd';
      break;

    case ChangeLogType.ExternalCarBrandInfo:
      grammaticAttribute = '';
      changeString = `Extern bilmärkesinfo - ${insuranceCompanies.getValue(
        change.keyID
      )}`;
      break;

    case ChangeLogType.ExternalGeneralInfo:
      grammaticAttribute = '';
      changeString = `Extern generell info - ${insuranceCompanies.getValue(
        change.keyID
      )}`;
      break;

    case ChangeLogType.ExternalRepairShopID:
      changeString = 'Extern koppling';

      if (added) {
        to = insuranceCompanies.getValue(change.keyID);
      } else {
        from = insuranceCompanies.getValue(change.keyID);
      }
      break;

    case ChangeLogType.InfoIsPrioritized:
      infoTextType = change.keyID;
      changeString = `Prio för ${getInfoTextTypeDisplayName(infoTextType)}`;

      if (
        infoTextType === InfoTextType.PhoneNumber ||
        infoTextType === InfoTextType.AlarmInfo ||
        infoTextType === InfoTextType.LockInfo
      ) {
        grammaticAttribute = ett;
      }

      if (added) {
        to = 'Prioriterad';
      } else {
        from = 'Prioriterad';
      }
      break;

    case ChangeLogType.InsuranceAllBrandsEngine: {
      grammaticAttribute = '';
      changeString = `${insuranceCompanies.getValue(change.keyID)} - ändrat`;

      const insurance = getItemByKeyID(
        repairShop.insuranceCompanies,
        change.keyID
      );
      allowToggle = false;

      if (added) {
        to = 'Alla bilmärken: Motor';
        allowToggle = insurance && insurance.allowAllCarBrandsEngine;
      } else {
        from = 'Alla bilmärken: Motor';
        allowToggle = insurance && !insurance.allowAllCarBrandsEngine;
      }

      if (allowToggle) {
        resetAction = () => {
          Actions.toggleAllCarBrandsEngine(change.keyID);
        };
      }
      break;
    }
    case ChangeLogType.InsuranceAllBrandsPlate: {
      grammaticAttribute = '';
      changeString = `${insuranceCompanies.getValue(change.keyID)} - ändrat`;

      const insurance = getItemByKeyID(
        repairShop.insuranceCompanies,
        change.keyID
      );
      allowToggle = false;

      if (added) {
        to = 'Alla bilmärken: Plåt';
        allowToggle = insurance && insurance.allowAllCarBrandsPlate;
      } else {
        from = 'Alla bilmärken: Plåt';
        allowToggle = insurance && !insurance.allowAllCarBrandsPlate;
      }

      if (allowToggle) {
        resetAction = () => {
          Actions.toggleAllCarBrandsPlate(change.keyID);
        };
      }
      break;
    }
    default: {
      // Do nothing
      break;
    }
  }

  // Create reset action for all info texts
  if (infoTextType !== null) {
    // Check if the text exist
    let oldIndex = -1;
    const infoTexts = getListByKeyID(repairShop.infoTexts, infoTextType);

    if (change.oldValue && infoTexts.length > 0) {
      oldIndex = infoTexts.findIndex((info) => info.value === change.oldValue);
    }

    if (removed) {
      // Re-add old text (only if it doesn't currently exist)
      if (oldIndex === -1) {
        resetAction = () => {
          Actions.addInfoText(change.oldValue, infoTextType);
        };
      }
    } else {
      // Remove or change existing text (don't allow duplicates of oldValue)
      let newIndex = -1;
      if (change.newValue && infoTexts.length > 0) {
        newIndex = infoTexts.findIndex(
          (info) => info.value === change.newValue
        );
      }

      if (newIndex !== -1 && oldIndex === -1) {
        resetAction = () => {
          Actions.setInfoText(change.oldValue, infoTexts[newIndex]);
        };
      }
    }
  }

  // Complete changeString
  if (grammaticAttribute === en) {
    if (added) {
      changeString = `${changeString} - tillagd`;
    } else if (removed) {
      changeString = `${changeString} - borttagen`;
    } else {
      changeString = `${changeString} - ändrad`;
    }
  } else if (grammaticAttribute === ett) {
    if (added) {
      changeString = `${changeString} - tillagt`;
    } else if (removed) {
      changeString = `${changeString} - borttaget`;
    } else {
      changeString = `${changeString} - ändrat`;
    }
  }

  return (
    <div key={change.id} className="main-item alternate-shade dynamic-height">
      <div className="change-item change-info">{changeString}</div>
      <div className="change-item change-from-to">{from}</div>
      <div className="change-item change-from-to">{to}</div>
      <div className="change-reset row-padding">
        <Checkbox
          checked={inResetList}
          handleClick={() => {
            if (resetAction !== null) {
              toggleReset({ change, resetAction });
            }
          }}
          outerPadding
          inactive={resetAction === null}
        />
      </div>
    </div>
  );
};

export default renderChangeRow;
