// #region [IMPORTS]

import { consoleLog, IsProduction } from './General';

import StoreMain from 'components/Stores/StoreMain';
import StoreRepairShop from 'components/Stores/StoreRepairShop';
import StoreReviewList from 'components/Stores/StoreReviewList';
import StoreSearchList from 'components/Stores/StoreSearchList';

import { RepairShopAPI } from './API';
import { Actions } from './Actions';

/**
 * @typedef {import('./api/models').SiteLoadCoreDataModel} SiteLoadCoreDataModel
 */

// #endregion

export const IFrameWindowType = {
  Framed: 'Framed',
  Tablet: 'Tablet',
  Popup: 'Popup',
};

export const IFrameActionType = {
  Parent_IFrameHasOpened: 'Parent_IFrameHasOpened',
  IFrame_LoadCoreData: 'IFrame_LoadCoreData',
  Parent_ExitIFrame: 'Parent_ExitIFrame',
};

let onInitializationCompleted = null;

const receiveMessage = (event) => {
  let action;
  try {
    action = JSON.parse(event.data);
  } catch (error) {
    // Ignore webpackOk message
    if (typeof event.data === 'object' && event.data?.type === 'webpackOk')
      return;

    console.error('IFrame has recieved a message that is not a string', event);
    return;
  }

  consoleLog(action, 'IFrame has recieved a message', '#A0A000');

  if (action.type === IFrameActionType.IFrame_LoadCoreData) {
    /** @param {SiteLoadCoreDataModel} coreData */
    const initialize = (coreData) => {
      // Initializations
      consoleLog(coreData, 'Initialize Core Data', '#08FFEE');

      StoreMain.init(coreData);
      StoreReviewList.init(coreData);
      StoreSearchList.init(coreData);
      StoreRepairShop.init();

      onInitializationCompleted();
    };

    RepairShopAPI.loadCoreData(action.request, initialize);
  }
};

const sendMessage = (action) => {
  consoleLog(action, 'IFrame has sent a message', '#A0A000');

  try {
    window.parent.postMessage(JSON.stringify(action), '*');
  } catch (error) {
    Actions.setError(
      error,
      `Kommunikationen mellan verkstadsregistret och din applikation misslyckades - (${error}).`
    );
  }
};

const getIFrameHasOpenedActionTemplate = () => ({
  type: IFrameActionType.Parent_IFrameHasOpened,
});

const getExitIFrameActionTemplate = () => ({
  type: IFrameActionType.Parent_ExitIFrame,
  selectedRepairShop: null,
});

export const initializeIFrameListener = (initializeSuccess) => {
  if (inIFrame()) {
    onInitializationCompleted = initializeSuccess;

    if (window.addEventListener) {
      window.addEventListener('message', receiveMessage, false);
    } else {
      window.attachEvent('onmessage', receiveMessage);
    }

    sendMessage(getIFrameHasOpenedActionTemplate());
  } else if (IsProduction) {
    // Set RepairShopRegister site to blank if not connected to via IFrame
    document.getElementById('react-body').innerHTML =
      'Koppla upp via en av Assistancekårens övriga applikationer (t.ex. Kontoret) för att få tillgång till verkstadsregistret.';
    document.getElementById('react-body').style.paddingTop = `${
      0.5 * window.innerHeight - 43
    }px`;
    document.getElementById('react-body').style.textAlign = 'center';
  } else {
    initializeSuccess();
  }
};

export const exitRegister = () => {
  const action = getExitIFrameActionTemplate();
  executeExit(action);
};

export const selectRepairShopAndExitRegister = (repairShop) => {
  const action = getExitIFrameActionTemplate();
  action.selectedRepairShop = repairShop;
  executeExit(action);
};

const executeExit = (action) => {
  if (window.addEventListener) {
    window.removeEventListener('message', receiveMessage, false);
  } else {
    window.detachEvent('onmessage', receiveMessage);
  }

  sendMessage(action);
};

const inIFrame = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};
