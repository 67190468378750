import * as constants from 'globalStyles/constants';
import { css } from 'styled-components';
import {
  rightside_vcenter_container,
  small_info_text,
  vcenter_container,
} from 'globalStyles/general-structures';

const searchListViewGlobalStyle = css`
  .search-list-view {
    .search-area {
      margin-left: ${constants.large_distance};
    }
    .requested-dropoff {
      font-size: ${constants.small_text};
      width: 200px;
      height: auto;
      margin-left: ${constants.medium_distance};
      .head {
        width: 100%;
        font-weight: bold;
        margin-bottom: 2px;
      }
      .text {
        width: 100%;
        max-height: 45px;
      }
    }
    .head-rightside {
      position: absolute;
      right: 0;
      top: 0;
      margin-right: ${constants.large_distance};
      margin-top: ${constants.small_distance};
      ${rightside_vcenter_container};
      height: ${constants.main_head_height};
    }
    .station-distance-info {
      width: 143px;
      text-align: right;
      margin-right: ${constants.small_distance};
      ${small_info_text};
    }
    .distance-info {
      position: absolute;
      top: 0;
      right: ${constants.medium_distance};
      height: ${constants.second_head_height};
      min-width: 185px;
      text-align: right;
      z-index: 1;
      ${vcenter_container};
      .icon {
        height: 66.666%;
      }
      .text {
        width: 163px;
        text-align: right;
        margin-right: ${constants.small_distance};
        color: ${constants.pure_black};
        ${small_info_text};
      }
    }
    .matching {
      background-color: ${constants.background_green_marker};
    }
    .remaining {
      background-color: ${constants.background_yellow_marker};
    }
    .map-area {
      height: calc(
        ${constants.main_content_height} - ${constants.main_head_height}
      );
      width: 100%;
    }
    .left-col {
      width: 50%;
      border-right: 1px solid ${constants.border_shade};
      display: inline-block;
      vertical-align: top;
      .search-list-row {
        .name {
          width: 222px;
        }
        .address {
          width: 180px;
          padding-right: 0;
        }
      }
    }
    .right-col {
      width: 50%;
      display: inline-block;
      vertical-align: top;
      .info-line {
        padding-left: ${constants.medium_distance};
        width: 342px;
        max-height: 34px;
        overflow: hidden;
      }
    }
    .couple-external-info {
      width: 363px;
      margin-left: ${constants.medium_distance};
      opacity: ${constants.dark_transparent};
    }
    .release-settings {
      position: absolute;
      top: 5px;
      right: calc(${constants.small_distance} * 2);
      width: 225px;
      text-align: right;
      font-size: 10px;
      line-height: 13px;
      opacity: ${constants.light_transparent};
    }
  }
`;

export default searchListViewGlobalStyle;
