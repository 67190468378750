import { FC } from 'react';

import { PopupContainer } from '../PopupComponents';
import { PopupProps } from '../Popup';
import FindRepairShopMap from 'components/GoogleMaps/FindRepairShopMap';
import { useStoreMain } from 'components/Stores/StoreMain';
import { useStoreRepairShop } from 'components/Stores/StoreRepairShop';

interface Props extends PopupProps {
  searchString?: string;
}

const FindRepairShopPopup: FC<Props> = ({ settings, searchString }) => {
  const { close } = settings;

  const { userInfo } = useStoreMain();
  const { userPosition } = userInfo;

  const { repairShop, customLocation } = useStoreRepairShop();

  return (
    <PopupContainer
      close={close}
      headerText="Välj verkstadens position"
      pagesize
      noMargins
    >
      {repairShop && (
        <FindRepairShopMap
          shop={repairShop}
          customLocation={customLocation}
          userPosition={userPosition}
          close={close}
          initialSearchString={searchString}
        />
      )}
    </PopupContainer>
  );
};

export default FindRepairShopPopup;
