import { CachedDrivingDistanceModel } from './API';
import {
  ActionTypes,
  PositionType,
  ReviewListSort,
  SearchMode,
} from './Constants';
import { AppAction, AppDispatcher, consoleLog } from './General';
import { DepartmentType } from './TsEnums.gen';
import {
  CarBrandSpec,
  ExternalInsuranceModel,
  GeographicPlaceModel,
  InfoTextModel,
  InfoTextType,
  InsuracenSettingsAssistToRepairShopInsuranceIDs,
  InsuracenSettingsInsuranceOptions,
  MidlandRepairShopResponse,
  PositionModel,
  RepairShopModel,
  ReviewSearchPreviewModel,
  SearchResultModel,
  StatusType,
} from './api/models';

const dispatch = (action: AppAction) => {
  consoleLog(action, '   Action   ', '#FFBB77');
  AppDispatcher.dispatch(action);
};

const ActionsWithoutDispatch = {
  //  General

  setSearchMode(searchMode: keyof typeof SearchMode) {
    return {
      type: ActionTypes.SET_SEARCH_MODE,
      searchMode,
    };
  },

  setCustomPosition(position: PositionModel) {
    return {
      type: ActionTypes.SET_CUSTOM_POSITION,
      position,
    };
  },

  setActivePositionType(positionType: PositionType) {
    return {
      type: ActionTypes.SET_ACTIVE_POSITION_TYPE,
      positionType,
    };
  },

  //  Manage Search and Filter state

  sendSearchRequest(displayBookmarkIndex = 0) {
    return {
      type: ActionTypes.SEND_SEARCH_REQUEST,
      displayBookmarkIndex,
    };
  },

  getSearchResult(searchResult: SearchResultModel) {
    return {
      type: ActionTypes.GET_SEARCH_RESULTS,
      searchResult,
    };
  },

  extendSearchList() {
    return {
      type: ActionTypes.EXTEND_SEARCH_LIST,
    };
  },

  bookmarkSearchScroll(scrollPosition: number) {
    return {
      type: ActionTypes.BOOKMARK_SEARCH_SCROLL,
      scrollPosition,
    };
  },

  setCarBrandFilter(
    brandID: number,
    carBrandSpec: CarBrandSpec = CarBrandSpec.None
  ) {
    return {
      type: ActionTypes.SET_CAR_BRAND_FILTER,
      brandID,
      carBrandSpec,
    };
  },

  setInsuranceCompanyFilter(insuranceID: number) {
    return {
      type: ActionTypes.SET_INSURANCE_COMPANY_FILTER,
      insuranceID,
    };
  },

  setDepartmentFilter(departmentID: number) {
    return {
      type: ActionTypes.SET_DEPARTMENT_FILTER,
      departmentID,
    };
  },

  setSearchString(searchString: string) {
    return {
      type: ActionTypes.SET_SEARCH_STRING,
      searchString,
    };
  },

  toggleShowRemoved() {
    return {
      type: ActionTypes.TOGGLE_SHOW_REMOVED,
    };
  },

  //  Review List

  sendReviewSearchRequest(displayBookmarkIndex = 0) {
    return {
      type: ActionTypes.SEND_REVIEW_SEARCH_REQUEST,
      displayBookmarkIndex,
    };
  },

  getReviewSearchResult(searchResult: ReviewSearchPreviewModel[]) {
    return {
      type: ActionTypes.GET_REVIEW_SEARCH_RESULTS,
      searchResult,
    };
  },

  extendReviewSearchList() {
    return {
      type: ActionTypes.EXTEND_REVIEW_SEARCH_LIST,
    };
  },

  bookmarkReviewSearchScroll(scrollPosition: number) {
    return {
      type: ActionTypes.BOOKMARK_REVIEW_SEARCH_SCROLL,
      scrollPosition,
    };
  },

  setStationFilter(stationID: number) {
    return {
      type: ActionTypes.SET_STATION_FILTER,
      stationID,
    };
  },

  setReviewDistance(distanceKm: number) {
    return {
      type: ActionTypes.SET_REVIEW_DISTANCE,
      distanceKm,
    };
  },

  setReviewFilter(filter: number | null) {
    return {
      type: ActionTypes.SET_REVIEW_FILTER,
      filter,
    };
  },

  setReviewListSort(reviewListSort: keyof typeof ReviewListSort) {
    return {
      type: ActionTypes.SET_REVIEW_LIST_SORT,
      reviewListSort,
    };
  },

  //  Get RepairShop

  requestRepairShop(
    repairShopID: number,
    onSuccess: null | ((repairshop: RepairShopModel) => void) = null
  ) {
    return {
      type: ActionTypes.REQUEST_REPAIRSHOP,
      repairShopID,
      onSuccess,
    };
  },

  createNewRepairShopTemplate() {
    return {
      type: ActionTypes.CREATE_NEW_REPAIRSHOP_TEMPLATE,
    };
  },

  getRepairShopResult(repairShop: RepairShopModel) {
    return {
      type: ActionTypes.GET_REPAIRSHOP_RESULT,
      repairShop,
    };
  },

  //  Update / Create RepairShop

  saveRepairShop(onSuccess: (() => void) | null = null) {
    return {
      type: ActionTypes.SAVE_REPAIRSHOP,
      onSuccess,
    };
  },

  removeRepairShop(
    status: StatusType,
    onSuccess: ((repairshop: RepairShopModel) => void) | null = null
  ) {
    return {
      type: ActionTypes.REMOVE_REPAIRSHOP,
      status,
      onSuccess,
    };
  },

  resurrectRepairShop(onSuccess = null) {
    return {
      type: ActionTypes.RESURRECT_REPAIRSHOP,
      onSuccess,
    };
  },

  discardRepairShopChanges() {
    return {
      type: ActionTypes.DISCARD_REPAIRSHOP_CHANGES,
    };
  },

  //  Edit repairShop

  setName(name: string) {
    return {
      type: ActionTypes.SET_NAME,
      name,
    };
  },

  setAddress(address: string) {
    return {
      type: ActionTypes.SET_ADDRESS,
      address,
    };
  },

  setZip(zip: string) {
    return {
      type: ActionTypes.SET_ZIP,
      zip,
    };
  },

  setCity(city: string) {
    return {
      type: ActionTypes.SET_CITY,
      city,
    };
  },

  setLatitude(latitude: number) {
    return {
      type: ActionTypes.SET_LATITUDE,
      latitude,
    };
  },

  setLongitude(longitude: number) {
    return {
      type: ActionTypes.SET_LONGITUDE,
      longitude,
    };
  },

  addInfoText(
    text: string,
    infoTextType: (typeof InfoTextType)[keyof typeof InfoTextType]
  ) {
    return {
      type: ActionTypes.ADD_INFO_TEXT,
      text,
      infoTextType,
    };
  },

  setInfoText(text: string, infoText: InfoTextModel) {
    return {
      type: ActionTypes.SET_INFO_TEXT,
      text,
      infoText,
    };
  },

  toggleInfoTextIsPrioritized(infoText: InfoTextModel) {
    return {
      type: ActionTypes.TOGGLE_INFO_TEXT_IS_PRIORITIZED,
      infoText,
    };
  },

  toggleDepartment(
    departmentType: (typeof DepartmentType)[keyof typeof DepartmentType]
  ) {
    return {
      type: ActionTypes.TOGGLE_DEPARTMENT,
      departmentType,
    };
  },

  toggleCarBrand(brandID: number) {
    return {
      type: ActionTypes.TOGGLE_CAR_BRAND,
      brandID,
    };
  },

  toggleAuthorized(brandID: number) {
    return {
      type: ActionTypes.TOGGLE_AUTHORIZED,
      brandID,
    };
  },

  toggleWarranty(brandID: number) {
    return {
      type: ActionTypes.TOGGLE_WARRANTY,
      brandID,
    };
  },

  toggleInsuranceCompany(insuranceID: number) {
    return {
      type: ActionTypes.TOGGLE_INSURANCE_COMPANY,
      insuranceID,
    };
  },

  toggleAllCarBrandsEngine(insuranceID: number) {
    return {
      type: ActionTypes.TOGGLE_ALL_CAR_BRANDS_ENGINE,
      insuranceID,
    };
  },

  toggleAllCarBrandsPlate(insuranceID: number) {
    return {
      type: ActionTypes.TOGGLE_ALL_CAR_BRANDS_PLATE,
      insuranceID,
    };
  },

  setExternalCarBrandInfo(text: string, externalID: number) {
    return {
      type: ActionTypes.SET_EXTERNAL_CAR_BRAND_INFO,
      text,
      externalID,
    };
  },

  dropExternalFromRepairShop(externalID: number) {
    return {
      type: ActionTypes.DROP_EXTERNAL_FROM_REPAIRSHOP,
      externalID,
    };
  },

  //  Manage Midland

  sendMidlandRequest() {
    return {
      type: ActionTypes.SEND_MIDLAND_REQUEST,
    };
  },

  setMidlandResult(searchResult: MidlandRepairShopResponse) {
    return {
      type: ActionTypes.SET_MIDLAND_RESULTS,
      searchResult,
    };
  },

  closeMidlandPopup() {
    return {
      type: ActionTypes.CLOSE_MIDLAND_POPUP,
    };
  },

  //  Google maps

  requestCustomLocation(latitude: number, longitude: number) {
    return {
      type: ActionTypes.REQUEST_CUSTOM_LOCATION,
      latitude,
      longitude,
    };
  },

  getCustomLocationResult(location: GeographicPlaceModel) {
    return {
      type: ActionTypes.GET_CUSTOM_LOCATION_RESULT,
      location,
    };
  },

  findMatchingAddresses(addressString: string) {
    return {
      type: ActionTypes.FIND_MATCHING_ADDRESS,
      addressString,
    };
  },

  getMatchingAddressesResults(locations: GeographicPlaceModel[]) {
    return {
      type: ActionTypes.GET_MATCHING_ADDRESSES_RESULT,
      locations,
    };
  },

  requestDrivingDirectionsUpdate(
    repairShopID: number,
    position: PositionModel
  ) {
    return {
      type: ActionTypes.REQUEST_DRIVING_DIRECTIONS_UPDATE,
      repairShopID,
      position,
    };
  },

  getDrivingDirectionsResult(
    result: CachedDrivingDistanceModel,
    repairShopID: number
  ) {
    return {
      type: ActionTypes.GET_DRIVING_DIRECTIONS_RESULT,
      result,
      repairShopID,
    };
  },

  //  Manage External Connections

  requestMiscoupledExternal() {
    return {
      type: ActionTypes.REQUEST_MISCOUPLED_EXTERNAL,
    };
  },

  getMiscoupledExternalResult(externalInsurance: ExternalInsuranceModel[]) {
    return {
      type: ActionTypes.GET_MISCOUPLED_EXTERNAL_RESULT,
      externalInsurance,
    };
  },

  coupleRepairShopToExternal(
    repairShopID: number,
    externalInsuranceID: number,
    onSuccess: () => void
  ) {
    return {
      type: ActionTypes.COUPLE_REPAIR_SHOP_TO_EXTERNAL,
      repairShopID,
      externalInsuranceID,
      onSuccess,
    };
  },

  //  Error Handling

  setError(error: unknown, description: string) {
    return {
      type: ActionTypes.SET_ERROR,
      error,
      description,
    };
  },

  clearError() {
    return { type: ActionTypes.CLEAR_ERROR };
  },

  //  Admin Updates

  connectInsuranceToAssist(
    connections: InsuracenSettingsAssistToRepairShopInsuranceIDs,
    onSuccess: (response: string) => void
  ) {
    return {
      type: ActionTypes.CONNECT_INSURANCE_TO_ASSIST,
      connections,
      onSuccess,
    };
  },

  updateInsuranceSettings(
    insuranceOptions: InsuracenSettingsInsuranceOptions,
    onSuccess: (response: string) => void
  ) {
    return {
      type: ActionTypes.UPDATE_INSURANCE_SETTINGS,
      insuranceOptions,
      onSuccess,
    };
  },
};

export type ActionReturns = ReturnType<
  (typeof ActionsWithoutDispatch)[keyof typeof ActionsWithoutDispatch]
>;

export const Actions = (
  Object.keys(ActionsWithoutDispatch) as (keyof typeof ActionsWithoutDispatch)[]
).reduce(
  (acc, key) => {
    const action = ActionsWithoutDispatch[key];
    acc[key] = (...args) => {
      dispatch((action as any)(...args));
    };
    return acc;
  },
  {} as {
    [key in keyof typeof ActionsWithoutDispatch]: (
      ...params: Parameters<(typeof ActionsWithoutDispatch)[key]>
    ) => void;
  }
);
