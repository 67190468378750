import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Actions } from 'general/Actions';
import StoreError from './Stores/StoreError';
import { Popup, PopupType, setPopup } from './Popup/Popup';

import { exitRegister } from 'general/IFrameCommunication';

const getStateFromStores = () => ({
  errorStore: StoreError.getState(),
});

const getDefaultState = () =>
  Object.assign({}, getStateFromStores(), {
    popupProps: {},
  });

export default class ErrorContainer extends Component {
  constructor(props) {
    super(props);

    this.state = getDefaultState();

    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    StoreError.addChangeListener(this.onChange);
  }
  componentWillUnmount() {
    StoreError.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState(getStateFromStores(), () => {
      const { basicDataHasLoaded } = this.props;
      const { title, description } = this.state.errorStore;

      setPopup(this, PopupType.ErrorPopup, {
        onClose: Actions.clearError,
        title,
        description,
        basicDataHasLoaded,
        exitRegister,
      });
    });
  }

  render() {
    const { popupProps } = this.state;
    const { hasError } = this.state.errorStore;

    if (hasError) {
      return <Popup {...popupProps} />;
    }

    return null;
  }
}

ErrorContainer.propTypes = {
  basicDataHasLoaded: PropTypes.bool.isRequired,
};
