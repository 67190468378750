import React, { FC } from 'react';
import classNames from 'classnames';
import { getDistanceString } from 'general/UtilityFunctions';
import { ActiveStatusListing } from 'general/Constants';
import { getDepartmentTypeDisplayName } from 'general/TsEnums.gen';
import styled from 'styled-components';
import { SearchPreviewModel } from 'general/api/models';
import { CachedDrivingDistanceModel } from 'general/API';
import useSummarizedInsuranceAbilities from './useSummarizedInsuranceAbilities';
import InsuranceCell from './InsuranceCell';

const Strike = styled.span`
  text-decoration: line-through;
`;

interface Props {
  shop: SearchPreviewModel;
  index: number;
  openRepairShop: (shop: SearchPreviewModel) => void;
  isLast: boolean;
  isMatching?: boolean;
  drivingDistanceMode?: boolean;
  drivingDirections?: CachedDrivingDistanceModel | null;

  onInsuranceCellMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
  onInsuranceCellMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
}

const SearchListRow: FC<Props> = ({
  shop,
  index,
  openRepairShop,
  isLast,
  isMatching,
  drivingDistanceMode,
  drivingDirections,

  onInsuranceCellMouseEnter,
  onInsuranceCellMouseLeave,
}) => {
  const insuranceAbilities = useSummarizedInsuranceAbilities(
    shop.insuranceAbilities
  );

  const rowClass = classNames({
    'search-list-row': true,
    'main-item': true,
    clickable: true,
    shade: index % 2 === 1,
    last: index % 2 === 0 && isLast,
  });

  const { distanceText, titleText } = (() => {
    if (!drivingDistanceMode)
      return {
        distanceText: <div>{getDistanceString(shop.distanceKm)}</div>,
        titleText: 'Avstånd fågelvägen',
      };

    if (!drivingDirections)
      return {
        distanceText: <Strike>({getDistanceString(shop.distanceKm)})</Strike>,
        titleText: 'OBS! Körsträcka ej laddad, (visar avstånd för fågelvägen)',
      };

    return {
      distanceText: (
        <div>{getDistanceString(drivingDirections.drivingDistanceKm)}</div>
      ),
      titleText:
        'Körtid: ' +
        drivingDirections.drivingTime +
        '  |  Körsträcka: ' +
        getDistanceString(drivingDirections.drivingDistanceKm) +
        '  |  Fågelväg: ' +
        getDistanceString(drivingDirections.linearDistanceKm),
    };
  })();

  const distanceClass = classNames({
    distance: true,
    loading: drivingDistanceMode && drivingDirections === null,
  });

  const departmentInfo = shop.departments
    .map((id) => getDepartmentTypeDisplayName(id))
    .join(', ');

  return (
    <div
      className={rowClass}
      onClick={() => {
        openRepairShop(shop);
      }}
    >
      {isMatching && <div className="left-border-highlight green"></div>}
      {!(shop.status in ActiveStatusListing) && (
        <div className="left-border-highlight red"></div>
      )}
      <div className="name" title={`ID: ${shop.id}`}>
        <div>{shop.name}</div>
      </div>
      <div className="address">
        <div className="line1">{shop.city}</div>
        <div className="line2">{shop.address}</div>
      </div>
      <div className="department" title={departmentInfo}>
        {shop.departmentText}
      </div>
      <InsuranceCell
        summarizedAbilities={insuranceAbilities}
        searchPreview={shop}
        onMouseEnter={onInsuranceCellMouseEnter}
        onMouseLeave={onInsuranceCellMouseLeave}
      />
      <div className="brand">{shop.carBrandText}</div>
      <div className="right-absolute">
        <div className={distanceClass} title={titleText}>
          {distanceText}
        </div>
      </div>
    </div>
  );
};

export default SearchListRow;
