import * as constants from 'globalStyles/constants';
import { css } from 'styled-components';
import { vcenter_container } from 'globalStyles/general-structures';

const loaderGlobalStyle = css`
  .application-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    .background {
      width: 100%;
      height: 100%;
      background: ${constants.popup_background};
    }
    .main {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      text-align: center;
      ${vcenter_container};
    }
  }

  .main-content-centered {
    text-align: center;
    ${vcenter_container};
  }

  .loader-full-container {
    width: 100%;
    height: 100%;
    text-align: center;
    ${vcenter_container};
  }

  .loader-overlay-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    padding: ${constants.medium_distance};
    .inner {
      width: 100%;
      height: 100%;
      background-color: ${constants.background_light};
      background-color: color-mix(
        in srgb,
        ${constants.background_light},
        transparent 20%
      );
      text-align: center;
      ${vcenter_container};
    }
  }

  .loader-small-container {
    width: 100%;
    margin-top: ${constants.mini_distance};
    text-align: center;
  }

  .contained-loader {
    width: 100%;
    height: 100%;
    ${vcenter_container};
    object {
      height: 75%;
    }
  }

  @keyframes loader-fadein-animation {
    0% {
      opacity: 0;
    }
    25% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .loader-fadein-animation {
    animation-name: loader-fadein-animation;
    animation-duration: 0.6s;
  }
`;

export default loaderGlobalStyle;
