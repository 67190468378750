import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Images } from 'general/Constants';

const smallLoader = (
  <object type="image/svg+xml" data={Images.LoaderSmall}>
    <div>...</div>
  </object>
);
const smallLoaderWhite = (
  <object type="image/svg+xml" data={Images.LoaderSmallWhite}>
    <div>...</div>
  </object>
);

export class ApplicationLoader extends Component {
  render() {
    return (
      <div className="application-overlay">
        <div className="background"></div>
        <div className="main">{smallLoaderWhite}</div>
      </div>
    );
  }
}

export class PageLoader extends Component {
  render() {
    return (
      <div className="main-page-container search-list-view">
        <div className="main-content-container main-content-centered">
          <ContentLoader />
        </div>
      </div>
    );
  }
}

export class ContentLoader extends Component {
  render() {
    const { overlay } = this.props;

    const loader = (
      <object
        type="image/svg+xml"
        data={Images.LoaderGears}
        className="loader-fadein-animation"
      >
        <div>Loading...</div>
      </object>
    );

    if (overlay) {
      return (
        <div className="loader-overlay-container">
          <div className="inner">{loader}</div>
        </div>
      );
    }

    return <div className="loader-full-container">{loader}</div>;
  }
}
ContentLoader.propTypes = {
  overlay: PropTypes.bool,
};
ContentLoader.defaultProps = {
  overlay: false,
};

export class SmallLoader extends Component {
  render() {
    return <div className="loader-small-container">{smallLoader}</div>;
  }
}
export class ContainedLoader extends Component {
  render() {
    const { white } = this.props;

    return (
      <div className="contained-loader">
        {white ? smallLoaderWhite : smallLoader}
      </div>
    );
  }
}
ContainedLoader.propTypes = {
  white: PropTypes.bool,
};
ContainedLoader.defaultProps = {
  white: false,
};
