import { Component } from 'react';
import PropTypes from 'prop-types';

import Button from '../../Button/Button';

import { PopupContainer } from '../PopupComponents';

class RemoveItemPopup extends Component {
  render() {
    const { settings, headerText, infoText, handleRemove } = this.props;
    const { close } = settings;

    return (
      <PopupContainer close={close} headerText={headerText} small>
        <div className="remove-popup-info">
          <div className="faded-info">
            <div className="info-text">{infoText}</div>
            <div className="bottom-fader"></div>
          </div>
        </div>
        <div className="popup-buttons-box">
          <Button
            yellow
            wLarge
            hMain
            text="Ja, ta bort"
            loadingOnClick
            onClick={() => {
              handleRemove(close);
            }}
          />
          <div className="medium-padder-h"></div>
          <Button black wLarge hMain text="Nej, avbryt" onClick={close} />
        </div>
      </PopupContainer>
    );
  }
}
RemoveItemPopup.propTypes = {
  settings: PropTypes.object.isRequired,
  headerText: PropTypes.string.isRequired,
  infoText: PropTypes.string.isRequired,
  handleRemove: PropTypes.func.isRequired,
};

export default RemoveItemPopup;
