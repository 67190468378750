import { FC, useEffect, useRef } from 'react';
import ScrollArea from 'components/Scrollbar';
import Checkbox from 'components/Checkbox/Checkbox';
import { Images, PositionType } from 'general/Constants';
import { getOwnPositionData, getPositionData } from 'general/UtilityFunctions';
import { Actions } from 'general/Actions';
import { MainStoreState } from 'components/Stores/StoreMain';
import { SearchListStoreState } from 'components/Stores/StoreSearchList';
import { SearchPreviewModel } from 'general/api/models';
import classNames from 'classnames';

const renderSearchPositionRow = (
  positionData: ReturnType<typeof getPositionData>,
  activePositionType: PositionType
) => (
  <div className="position-row">
    <Checkbox
      checked={activePositionType === positionData!.positionType}
      handleClick={() => {
        if (activePositionType !== positionData!.positionType) {
          Actions.setActivePositionType(positionData!.positionType);
          Actions.sendSearchRequest();
        }
      }}
      radio
      outerPadding
    />
    <div className="description">{positionData!.description}</div>

    <div className="right">
      <img src={positionData!.iconFlat} alt="ico" className="icon" />
    </div>
  </div>
);

interface Props {
  mainStore: MainStoreState;
  searchListStore: SearchListStoreState;
  focusedRepairShopId: number | null;

  onOpenRepairShop(shop: SearchPreviewModel): void;
}

const SearchListMapLeftColumn: FC<Props> = ({
  mainStore,
  searchListStore,
  focusedRepairShopId,
  onOpenRepairShop,
}) => {
  const { activePositionType } = mainStore;
  const { brandID, insuranceID, departmentID } = searchListStore.searchRequest;
  const { matches, matchesHeader } = searchListStore.searchResult;

  const matchesRefsByShopId = useRef<{
    [key: number]: HTMLDivElement | null | undefined;
  }>({});

  const assignmentPosition = getPositionData(
    PositionType.Assignment,
    mainStore
  );
  const ownPosition = getOwnPositionData(mainStore);
  const customPosition = getPositionData(PositionType.Custom, mainStore);

  const hasMatches = searchListStore.searchResult.matches.length > 0;
  const hasFilters =
    brandID !== null || insuranceID !== null || departmentID !== null;

  useEffect(() => {
    if (focusedRepairShopId === null) return;

    const node = matchesRefsByShopId.current[focusedRepairShopId];
    if (!node) return;

    node.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [focusedRepairShopId]);

  return (
    <div className="left-column">
      <div className="second-head medium-padding">
        <div>Sökningen utgår från:</div>
      </div>
      {assignmentPosition !== null ? (
        renderSearchPositionRow(assignmentPosition, activePositionType)
      ) : (
        <div className="position-row">
          <Checkbox radio inactive outerPadding />
          <div className="faded">Uppdragets position</div>

          <div className="right faded">
            <img src={Images.PinCarFlat} alt="ico" className="icon" />
          </div>
        </div>
      )}
      {renderSearchPositionRow(ownPosition, activePositionType)}
      {customPosition !== null ? (
        renderSearchPositionRow(customPosition, activePositionType)
      ) : (
        <div className="position-row">
          <Checkbox radio inactive outerPadding />
          <div className="faded">Utplacerad position</div>

          <div className="right faded">
            <img src={Images.PinCustomFlat} alt="ico" className="icon" />
          </div>
        </div>
      )}
      {hasMatches && (
        <div>
          <div className="second-head medium-padding">
            <div>{matchesHeader}</div>
          </div>
        </div>
      )}{' '}
      {!hasMatches && hasFilters && (
        <div className="search-info">
          Inga matchande verkstäder nära sökningens utgångspunkt
        </div>
      )}{' '}
      {!hasFilters && (
        <div className="search-info">
          Fyll i filter (t.ex. bilmärke) för att visa sökresultat i kartan
        </div>
      )}
      {hasMatches && (
        <div className="matches-list">
          <ScrollArea>
            {matches.map((shop, index) => (
              <div
                className="main-item alternate-shade clickable"
                key={shop.id}
                ref={(node) => {
                  matchesRefsByShopId.current[shop.id] = node;
                }}
                onClick={() => {
                  onOpenRepairShop(shop);
                }}
              >
                <div
                  className={classNames({
                    'map-location-circle': true,
                    green: true,
                    'map-row-animation': focusedRepairShopId === shop.id,
                  })}
                >
                  <div className="pin-text">{index + 1}</div>
                </div>
                <div className="name">{shop.name}</div>
              </div>
            ))}
          </ScrollArea>
        </div>
      )}
    </div>
  );
};

export default SearchListMapLeftColumn;
