import { Component } from 'react';
import PropTypes from 'prop-types';

import { navigateToStartList } from 'general/UtilityFunctions';
import { Actions } from 'general/Actions';

import Button from '../../Button/Button';

import { PopupContainer } from '../PopupComponents';
import { StatusType } from 'general/TsEnums.gen';

class RemoveShopPopup extends Component {
  render() {
    const { settings, name, searchMode } = this.props;
    const { close } = settings;

    return (
      <PopupContainer
        close={close}
        headerText="Vill du ta bort verkstaden?"
        small
      >
        <div className="remove-popup-info">
          <div className="info-text">{name}</div>
        </div>
        <div className="popup-buttons-box">
          <Button
            yellow
            hLarge
            text="Ja, markera som stängd"
            loadingOnClick
            className="wide-popup-button"
            onClick={() => {
              Actions.removeRepairShop(StatusType.ProposedRemove, () => {
                navigateToStartList(searchMode);
              });
            }}
          />
          <Button
            yellow
            hLarge
            text="Ja, markera som dublett"
            loadingOnClick
            className="wide-popup-button"
            onClick={() => {
              Actions.removeRepairShop(StatusType.ProposedDuplicate, () => {
                navigateToStartList(searchMode);
              });
            }}
          />
          <Button
            black
            hLarge
            text="Nej, avbryt"
            className="wide-popup-button last"
            onClick={close}
          />
        </div>
      </PopupContainer>
    );
  }
}
RemoveShopPopup.propTypes = {
  settings: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  searchMode: PropTypes.string.isRequired,
};

export default RemoveShopPopup;
