import { EventEmitter } from 'events';

import { RepairShopAPI } from 'general/API';
import { ActionReturns, Actions } from 'general/Actions';
import { AppDispatcher, consoleLog } from 'general/General';
import { ActionTypes, CHANGE_EVENT, IdIsNew } from 'general/Constants';
import StoreMain from './StoreMain';
import { cleanDateNow, getListIndexByKeyID } from 'general/UtilityFunctions';
import GlobalRouter from 'views/routes/DEP_GlobalRouter';
import {
  GeographicPlaceModel,
  InfoTextModel,
  InfoTextType,
  RepairShopModel,
  StatusType,
} from 'general/api/models';
import { DepartmentType } from 'general/TsEnums.gen';
import { useEffect, useState } from 'react';

const getDefaultState = () => ({
  repairShop: null,
  unalteredRepairShop: null,
  repairShopIsChanged: false,
  customLocation: null,
  locationsFromAddress: [], // Used to show recommended locations while inputing into the AddressInput
  expectedTimestamp: cleanDateNow(),
});

export type GeographicPlaceModelWithIndex = GeographicPlaceModel & {
  index: number;
};

interface StoreRepairShopState {
  repairShop: RepairShopModel | null;
  unalteredRepairShop: RepairShopModel | null;
  repairShopIsChanged: boolean;
  customLocation: GeographicPlaceModel | null;
  /** @deprecated - we use google maps api directly in AddressInput component instead */
  locationsFromAddress: GeographicPlaceModelWithIndex[];
  expectedTimestamp: string;
}

class RepairShopStore extends EventEmitter {
  state: StoreRepairShopState = null!;

  init() {
    this.state = getDefaultState();
  }

  /** Note: this should never actually return false. Added this mostly as a type guard. */
  hasRepairShop(
    actionName: string
  ): this is { state: { repairShop: RepairShopModel } } {
    if (!this.state.repairShop) {
      console.error(`Error: No repairShop to ${actionName} on`);
      return false;
    }
    return true;
  }

  requestRepairShop(
    repairShopID: number,
    onSuccess: ((repairshop: RepairShopModel) => void) | null
  ) {
    // Decide if a new backend request needs to be made
    if (
      this.state.unalteredRepairShop === null ||
      this.state.unalteredRepairShop.id !== repairShopID
    ) {
      // Reset state completely
      this.state = getDefaultState();
      this.emitChange();
    } else {
      // Reset repairShop to it's original state (in case it's been edited but not saved)
      this.state.expectedTimestamp = cleanDateNow();
      this.setRepairShopToUnaltered();
      this.emitChange();
    }

    // Request repairShop from backend
    RepairShopAPI.getRepairShop(
      repairShopID,
      this.state.expectedTimestamp,
      this.getSuccess(onSuccess)
    );
  }

  createNewRepairShopTemplate() {
    this.state = getDefaultState();

    this.state.unalteredRepairShop = StoreMain.getRepairShopTemplate();
    this.setRepairShopToUnaltered();
    this.emitChange();

    GlobalRouter.push('editrepairshop/');
  }

  getRepairShopResult(repairShop: RepairShopModel) {
    this.state.unalteredRepairShop = repairShop;
    this.setRepairShopToUnaltered();
    this.emitChange();
  }

  saveRepairShop(
    onSuccess: ((repairshopModel: RepairShopModel) => void) | null
  ) {
    if (!this.hasRepairShop('saveRepairShop')) return;

    // Set NotReviewed status (this will be auto reviewd backend if user has review privileges)
    this.state.repairShop.status = StatusType.NotReviewed;

    // New timestamp for update
    this.state.expectedTimestamp = cleanDateNow();
    this.emitChange();

    RepairShopAPI.saveRepairShop(
      this.state.repairShop,
      this.state.expectedTimestamp,
      this.getSuccess(onSuccess)
    );
  }

  /** Status can be: ProposedRemove and ProposedDuplicate */
  removeRepairShop(
    status: StatusType,
    onSuccess: ((repairshop: RepairShopModel) => void) | null
  ) {
    this.setRepairShopToUnaltered();
    this.state.expectedTimestamp = cleanDateNow();
    this.emitChange();

    // Copy repairShop to avoid rendering repairShopView as removed before onSuccess
    const repairShopCopy = JSON.parse(
      JSON.stringify(this.state.unalteredRepairShop)
    );
    repairShopCopy.status = status;

    RepairShopAPI.saveRepairShop(
      repairShopCopy,
      this.state.expectedTimestamp,
      (result) => {
        if (onSuccess !== null) {
          onSuccess(result);
        }
      }
    );
  }

  resurrectRepairShop(
    onSuccess: ((repairshop: RepairShopModel) => void) | null
  ) {
    this.setRepairShopToUnaltered();

    if (!this.hasRepairShop('resurrectRepairShop')) return;

    this.state.repairShop.status = StatusType.Reviewed;
    this.state.expectedTimestamp = cleanDateNow();
    this.emitChange();

    RepairShopAPI.saveRepairShop(
      this.state.repairShop,
      this.state.expectedTimestamp,
      this.getSuccess(onSuccess)
    );
  }

  discardRepairShopChanges() {
    this.setRepairShopToUnaltered();
    this.emitChange();
  }

  setName(name: string) {
    if (!this.hasRepairShop('setName')) return;

    if (this.state.repairShop.name !== name) {
      this.state.repairShop.name = name;
      this.emitRepairShopChange();
    }
  }

  setAddress(address: string) {
    if (!this.hasRepairShop('setAddress')) return;

    if (this.state.repairShop.address !== address) {
      this.state.repairShop.address = address;
      this.emitRepairShopChange();
    }
  }

  setZip(zip: string) {
    if (!this.hasRepairShop('setZip')) return;

    if (this.state.repairShop.zip !== zip) {
      this.state.repairShop.zip = zip;
      this.emitRepairShopChange();
    }
  }

  setCity(city: string) {
    if (!this.hasRepairShop('setCity')) return;

    if (this.state.repairShop.city !== city) {
      this.state.repairShop.city = city;
      this.emitRepairShopChange();
    }
  }

  setLatitude(latitude: number) {
    if (!this.hasRepairShop('setLatitude')) return;

    if (this.state.repairShop.latitude !== latitude) {
      this.state.repairShop.latitude = latitude;
      this.emitRepairShopChange();
    }
  }

  setLongitude(longitude: number) {
    if (!this.hasRepairShop('setLongitude')) return;

    if (this.state.repairShop.longitude !== longitude) {
      this.state.repairShop.longitude = longitude;
      this.emitRepairShopChange();
    }
  }

  toggleRemoveItemAtIndex(
    list: { id: number; removeOnUpdate: boolean }[],
    index: number
  ) {
    if (list[index].id === IdIsNew) {
      // Models not yet commited to DB can be dropped without the need to track changes
      list.splice(index, 1);
    } else {
      list[index].removeOnUpdate = !list[index].removeOnUpdate;
    }
  }

  addInfoText(text: string, infoTextType: InfoTextType) {
    if (!this.hasRepairShop('addInfoText')) return;

    let trimmedText = text || '';
    trimmedText = trimmedText.trim();

    if (trimmedText !== '') {
      // Add new
      const info = StoreMain.getInfoTextTemplate(infoTextType);
      const timestamp = cleanDateNow();

      info.value = trimmedText;
      info.createdBy = StoreMain.getState().userInfo.name;
      info.modifiedBy = StoreMain.getState().userInfo.name;
      info.dateCreated = timestamp;
      info.dateModified = timestamp;
      info.editsIdentifier = `new-${timestamp}`;

      this.state.repairShop.infoTexts.push(info);
      this.emitRepairShopChange();
    }
  }

  setInfoText(text: string, infoText: InfoTextModel) {
    if (!this.hasRepairShop('setInfoText')) return;

    const infoTexts = this.state.repairShop.infoTexts;
    const index = infoTexts.findIndex(
      (info) => info.editsIdentifier === infoText.editsIdentifier
    );

    let trimmedText = text || '';
    trimmedText = trimmedText.trim();

    const currentValue = infoTexts[index].value || '';

    if (trimmedText !== currentValue) {
      if (trimmedText === '') {
        // Drop
        this.toggleRemoveItemAtIndex(infoTexts, index);
      } else {
        // Update
        infoTexts[index].value = trimmedText;
        infoTexts[index].removeOnUpdate = false;
        infoTexts[index].modifiedBy = StoreMain.getState().userInfo.name;
        infoTexts[index].dateModified = cleanDateNow();
      }

      this.emitRepairShopChange();
    }
  }

  toggleInfoTextIsPrioritized(infoText: InfoTextModel) {
    if (!this.hasRepairShop('toggleInfoTextIsPrioritized')) return;

    const infoTexts = this.state.repairShop.infoTexts;
    const index = infoTexts.findIndex(
      (info) =>
        info.editsIdentifier === infoText.editsIdentifier &&
        info.keyID === infoText.keyID
    );

    infoTexts[index].isPrioritized = !infoTexts[index].isPrioritized;
    this.emitRepairShopChange();
  }

  toggleDepartment(departmentType: DepartmentType) {
    if (!this.hasRepairShop('toggleDepartment')) return;

    const index = getListIndexByKeyID(
      this.state.repairShop.departments,
      departmentType
    );

    if (index === -1) {
      this.state.repairShop.departments.push(
        StoreMain.getDepartmentTemplate(departmentType)
      );
    } else {
      this.toggleRemoveItemAtIndex(this.state.repairShop.departments, index);
    }

    this.emitRepairShopChange();
  }

  toggleCarBrand(brandID: number) {
    if (!this.hasRepairShop('toggleCarBrand')) return;

    const index = getListIndexByKeyID(this.state.repairShop.carBrands, brandID);

    if (index === -1) {
      this.state.repairShop.carBrands.push(
        StoreMain.getCarBrandTemplate(brandID)
      );
    } else {
      this.toggleRemoveItemAtIndex(this.state.repairShop.carBrands, index);
    }

    this.emitRepairShopChange();
  }

  toggleAuthorized(brandID: number) {
    if (!this.hasRepairShop('toggleAuthorized')) return;

    const index = getListIndexByKeyID(this.state.repairShop.carBrands, brandID);
    this.state.repairShop.carBrands[index].isAuthorized =
      !this.state.repairShop.carBrands[index].isAuthorized;
    this.emitRepairShopChange();
  }

  toggleWarranty(brandID: number) {
    if (!this.hasRepairShop('toggleWarranty')) return;

    const index = getListIndexByKeyID(this.state.repairShop.carBrands, brandID);
    this.state.repairShop.carBrands[index].hasWarranty =
      !this.state.repairShop.carBrands[index].hasWarranty;
    this.emitRepairShopChange();
  }

  toggleInsuranceCompany(insuranceID: number) {
    if (!this.hasRepairShop('toggleInsuranceCompany')) return;

    const index = getListIndexByKeyID(
      this.state.repairShop.insuranceCompanies,
      insuranceID
    );

    if (index === -1) {
      this.state.repairShop.insuranceCompanies.push(
        StoreMain.getInsuranceCompanyTemplate(insuranceID)
      );
    } else {
      this.toggleRemoveItemAtIndex(
        this.state.repairShop.insuranceCompanies,
        index
      );
    }

    this.emitRepairShopChange();
  }

  toggleAllCarBrandsEngine(insuranceID: number) {
    if (!this.hasRepairShop('toggleAllCarBrandsEngine')) return;

    const index = getListIndexByKeyID(
      this.state.repairShop.insuranceCompanies,
      insuranceID
    );
    this.state.repairShop.insuranceCompanies[index].allowAllCarBrandsEngine =
      !this.state.repairShop.insuranceCompanies[index].allowAllCarBrandsEngine;
    this.emitRepairShopChange();
  }

  toggleAllCarBrandsPlate(insuranceID: number) {
    if (!this.hasRepairShop('toggleAllCarBrandsPlate')) return;

    const index = getListIndexByKeyID(
      this.state.repairShop.insuranceCompanies,
      insuranceID
    );
    this.state.repairShop.insuranceCompanies[index].allowAllCarBrandsPlate =
      !this.state.repairShop.insuranceCompanies[index].allowAllCarBrandsPlate;
    this.emitRepairShopChange();
  }

  setExternalCarBrandInfo(text: string, externalID: number) {
    if (!this.hasRepairShop('setExternalCarBrandInfo')) return;

    const external = this.state.repairShop.externalInsurance.filter(
      (ext) => ext.id === externalID
    )[0];

    external.carBrandInfo = text;
    external.modifiedBy = StoreMain.getState().userInfo.name;
    external.dateModified = cleanDateNow();
    this.emitRepairShopChange();
  }

  dropExternalFromRepairShop(externalID: number) {
    if (!this.hasRepairShop('dropExternalFromRepairShop')) return;

    const index = this.state.repairShop.externalInsurance.findIndex(
      (ext) => ext.id === externalID
    );

    this.state.repairShop.externalInsurance.splice(index, 1);
    this.emitRepairShopChange();
  }

  requestCustomLocation(latitude: number, longitude: number) {
    // API cals
    RepairShopAPI.getAddressFromPosition({ latitude, longitude }, (data) => {
      Actions.getCustomLocationResult(data);
    });
  }

  getCustomLocationResult(location: GeographicPlaceModel) {
    this.state.customLocation = location;
    this.emitChange();
  }

  findMatchingAddresses(addressString: string) {
    RepairShopAPI.findMatchingAddresses(
      addressString,
      '',
      Actions.getMatchingAddressesResults
    );
  }

  getMatchingAddressesResults(locations: GeographicPlaceModel[]) {
    if (locations) {
      this.state.locationsFromAddress = [];
      let index = 1;
      for (let i = 0; i < locations.length; i += 1) {
        const item = locations[i];
        if (
          item.address.length > 0 &&
          item.city !== null &&
          item.zip !== null &&
          !this.state.locationsFromAddress.some(
            (filtered) => filtered.completeInfo === item.completeInfo
          ) &&
          item.country === 'Sverige'
        ) {
          const itemWithIndex = item as GeographicPlaceModelWithIndex;
          itemWithIndex.index = index;
          index += 1;
          this.state.locationsFromAddress.push(itemWithIndex);
        }
      }
      this.emitChange();
    }
  }

  setRepairShopToUnaltered() {
    this.state.repairShop = JSON.parse(
      JSON.stringify(this.state.unalteredRepairShop)
    );
    this.state.repairShopIsChanged = false;
  }

  emitRepairShopChange() {
    this.state.repairShopIsChanged = true;
    this.emitChange();
  }

  emitChange() {
    consoleLog(this.state, 'Store Update: Repair Shop', '#FFFF99');
    this.emit(CHANGE_EVENT);
  }

  addChangeListener(callback: () => void) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback: () => void) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  getState() {
    return this.state;
  }

  // Generate complete update onSuccess functions (master function)
  getSuccess(
    onSuccess: ((repairshopModel: RepairShopModel) => void) | null = null
  ) {
    return (repairShopResult: RepairShopModel, timestamp: string) => {
      if (this.state.expectedTimestamp === timestamp) {
        // Always load the result into the store
        Actions.getRepairShopResult(repairShopResult);

        if (onSuccess !== null) {
          onSuccess(repairShopResult);
        }
      }
    };
  }
}

const StoreRepairShop = new RepairShopStore();

AppDispatcher.register((action: ActionReturns) => {
  switch (action.type) {
    case ActionTypes.REQUEST_REPAIRSHOP:
      StoreRepairShop.requestRepairShop(action.repairShopID, action.onSuccess);
      break;

    case ActionTypes.CREATE_NEW_REPAIRSHOP_TEMPLATE:
      StoreRepairShop.createNewRepairShopTemplate();
      break;

    case ActionTypes.GET_REPAIRSHOP_RESULT:
      StoreRepairShop.getRepairShopResult(action.repairShop);
      break;

    // Updade/Create
    case ActionTypes.SAVE_REPAIRSHOP:
      StoreRepairShop.saveRepairShop(action.onSuccess);
      break;

    case ActionTypes.REMOVE_REPAIRSHOP:
      StoreRepairShop.removeRepairShop(action.status, action.onSuccess);
      break;

    case ActionTypes.RESURRECT_REPAIRSHOP:
      StoreRepairShop.resurrectRepairShop(action.onSuccess);
      break;

    case ActionTypes.DISCARD_REPAIRSHOP_CHANGES:
      StoreRepairShop.discardRepairShopChanges();
      break;

    // Edit
    case ActionTypes.SET_NAME:
      StoreRepairShop.setName(action.name);
      break;

    case ActionTypes.SET_ADDRESS:
      StoreRepairShop.setAddress(action.address);
      break;

    case ActionTypes.SET_ZIP:
      StoreRepairShop.setZip(action.zip);
      break;

    case ActionTypes.SET_CITY:
      StoreRepairShop.setCity(action.city);
      break;

    case ActionTypes.SET_LATITUDE:
      StoreRepairShop.setLatitude(action.latitude);
      break;

    case ActionTypes.SET_LONGITUDE:
      StoreRepairShop.setLongitude(action.longitude);
      break;

    case ActionTypes.ADD_INFO_TEXT:
      StoreRepairShop.addInfoText(action.text, action.infoTextType);
      break;

    case ActionTypes.SET_INFO_TEXT:
      StoreRepairShop.setInfoText(action.text, action.infoText);
      break;

    case ActionTypes.TOGGLE_INFO_TEXT_IS_PRIORITIZED:
      StoreRepairShop.toggleInfoTextIsPrioritized(action.infoText);
      break;

    case ActionTypes.TOGGLE_DEPARTMENT:
      StoreRepairShop.toggleDepartment(action.departmentType);
      break;

    case ActionTypes.TOGGLE_CAR_BRAND:
      StoreRepairShop.toggleCarBrand(action.brandID);
      break;

    case ActionTypes.TOGGLE_AUTHORIZED:
      StoreRepairShop.toggleAuthorized(action.brandID);
      break;

    case ActionTypes.TOGGLE_WARRANTY:
      StoreRepairShop.toggleWarranty(action.brandID);
      break;

    case ActionTypes.TOGGLE_INSURANCE_COMPANY:
      StoreRepairShop.toggleInsuranceCompany(action.insuranceID);
      break;

    case ActionTypes.TOGGLE_ALL_CAR_BRANDS_ENGINE:
      StoreRepairShop.toggleAllCarBrandsEngine(action.insuranceID);
      break;

    case ActionTypes.TOGGLE_ALL_CAR_BRANDS_PLATE:
      StoreRepairShop.toggleAllCarBrandsPlate(action.insuranceID);
      break;

    case ActionTypes.SET_EXTERNAL_CAR_BRAND_INFO:
      StoreRepairShop.setExternalCarBrandInfo(action.text, action.externalID);
      break;

    case ActionTypes.DROP_EXTERNAL_FROM_REPAIRSHOP:
      StoreRepairShop.dropExternalFromRepairShop(action.externalID);
      break;

    case ActionTypes.REQUEST_CUSTOM_LOCATION:
      StoreRepairShop.requestCustomLocation(action.latitude, action.longitude);
      break;

    case ActionTypes.GET_CUSTOM_LOCATION_RESULT:
      StoreRepairShop.getCustomLocationResult(action.location);
      break;

    case ActionTypes.FIND_MATCHING_ADDRESS:
      StoreRepairShop.findMatchingAddresses(action.addressString);
      break;

    case ActionTypes.GET_MATCHING_ADDRESSES_RESULT:
      StoreRepairShop.getMatchingAddressesResults(action.locations);
      break;

    default:
      break; // Do nothing
  }
});

export const useStoreRepairShop = () => {
  const [state, setState] = useState(StoreRepairShop.getState());

  useEffect(() => {
    const handleChange = () => {
      setState(StoreRepairShop.getState());
    };

    StoreRepairShop.addChangeListener(handleChange);
    return () => {
      StoreRepairShop.removeChangeListener(handleChange);
    };
  }, []);

  return state;
};

export default StoreRepairShop;
