import { FC } from 'react';
import useSummarizedInsuranceAbilities from './useSummarizedInsuranceAbilities';
import { useStoreMain } from 'components/Stores/StoreMain';
import { SearchPreviewModel } from 'general/api/models';
import styled from 'styled-components';
import * as constants from 'globalStyles/constants';

const AlignBaseline = styled.div`
  &&& {
    display: flex;
    flex-direction: row;
    gap: ${constants.mini_distance};
  }
`;

interface Props {
  searchPreview: SearchPreviewModel;
  summarizedAbilities: ReturnType<typeof useSummarizedInsuranceAbilities>;
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
}

const InsuranceCell: FC<Props> = ({
  searchPreview,
  summarizedAbilities,
  onMouseEnter,
  onMouseLeave,
}) => {
  const mainStore = useStoreMain();

  const showInsuranceAbilities = summarizedAbilities.length > 0;

  return (
    <div
      className="insurance"
      onMouseEnter={showInsuranceAbilities ? undefined : onMouseEnter}
      onMouseLeave={showInsuranceAbilities ? undefined : onMouseLeave}
    >
      {!showInsuranceAbilities ? (
        <div
          className={onMouseEnter && onMouseLeave ? 'green-underline-text' : ''}
        >
          {searchPreview.insuranceText}
        </div>
      ) : (
        summarizedAbilities.map((summarizedAbility) => {
          return (
            <div
              key={summarizedAbility.insuranceCompanyId}
              className="company-row green-underline-text"
            >
              <AlignBaseline>
                {mainStore.basicData.insuranceCompanies.getValue(
                  summarizedAbility.insuranceCompanyId
                )}{' '}
                <b>
                  Prio:{' '}
                  {summarizedAbility.priority ??
                    summarizedAbility.carBrandAbilities
                      .map((cba) => cba.priority)
                      .flat()
                      .join(', ')}
                </b>
              </AlignBaseline>
            </div>
          );
        })
      )}
    </div>
  );
};

export default InsuranceCell;
