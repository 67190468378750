import * as constants from 'globalStyles/constants';
import { css } from 'styled-components';
import { vcenter_container } from 'globalStyles/general-structures';

const headerGlobalStyle = css`
  .ipad-header {
    width: 100%;
    max-width: ${constants.page_width};
    text-align: center;
    background-color: ${constants.main_yellow};
    box-shadow: ${constants.yellow_header_box_shadow};
    position: relative;
    z-index: 1;
    .inner {
      position: relative;
      height: calc(
        ${constants.ipad_height} - ${constants.page_height} -
          ${constants.ipad_statusbar}
      );
      ${vcenter_container};
    }
    .close-button-container {
      position: absolute;
      top: 0;
      right: 0;
      width: calc(40 + ${constants.small_distance});
      height: 100%;
      text-align: left;
      padding-left: ${constants.small_distance};
      ${vcenter_container};
    }
    .header-image {
      margin-top: -4px;
      height: 26px;
      line-height: 26px;
      font-size: 22px;
      font-weight: bold;
    }
  }
`;

export default headerGlobalStyle;
