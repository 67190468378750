import axios, { AxiosResponse } from 'axios';
import { Actions } from './Actions';
import { homeApiLoadCoreData } from './api/home-api';
import {
  SiteLoadRequestModel,
  SiteLoadCoreDataModel,
  SearchRequestModel,
  SearchResultModel,
  RepairShopModel,
  IdRequestModel,
  ReviewSearchRequestModel,
  ReviewSearchPreviewModel,
  PositionModel,
  GeographicPlaceModel,
  DistanceRequestModel,
  DrivingDistanceModel,
  CoupleExternalRequestModel,
  ExternalInsuranceModel,
  MidlandRepairShopRequest,
  MidlandRepairShopResponse,
  InsuranceSettingsRequest,
  ConnectInsuranceToAssistRequest,
} from './api/models';
import {
  repairShopConnectInsuranceToAssist,
  repairShopCoupleExternalToRepairShop,
  repairShopFindMatchingAddresses,
  repairShopGet,
  repairShopGetAddressFromPosition,
  repairShopGetDrivingDistance,
  repairShopGetMiscoupledExternalInsuranceModels,
  repairShopReviewSearch,
  repairShopSave,
  repairShopSearch,
  repairShopSearchMidlandRepairShop,
  repairShopUpdateInsuranceSettings,
} from './api/repair-shop';
import { consoleLog } from './General';

const postTimeoutMs = 30 * 1000;
axios.interceptors.request.use((config) => {
  consoleLog(
    { url: config.url, body: config.data },
    '   API Call   ',
    '#EBF2F9'
  );

  config.timeout = postTimeoutMs;
  config.withCredentials = true;

  return config;
});

const call = async <R>(
  promise: Promise<AxiosResponse<R>>,
  onSuccess: (response: R) => void,
  actionDescription: string
) => {
  try {
    const result = await promise;

    onSuccess(result.data);
  } catch (errorThrown) {
    console.error('API Error: ', errorThrown);

    Actions.setError(
      errorThrown,
      `Ett fel inträffade då sidan skulle ${actionDescription}.`
    );
  }
};

export type CachedDrivingDistanceModel = DrivingDistanceModel & {
  origin: PositionModel;
  timestamp: number;
};

export const RepairShopAPI = {
  loadCoreData(
    request: SiteLoadRequestModel,
    onSuccess: (response: SiteLoadCoreDataModel) => void
  ) {
    call(homeApiLoadCoreData(request), onSuccess, 'ladda verkstadsregistret');
  },

  searchRepairShops(
    searchRequest: SearchRequestModel,
    onSuccess: (response: SearchResultModel) => void
  ) {
    call(repairShopSearch(searchRequest), onSuccess, 'söka efter verkstäder');
  },

  getRepairShop(
    repairShopID: number,
    timestamp: string,
    onSuccess: (result: RepairShopModel, timestamp: string) => void
  ) {
    call(
      repairShopGet({ id: repairShopID }),
      (r) => onSuccess(r, timestamp),
      'hämta verkstadsdata'
    );
  },

  reviewSearchRepairShops(
    searchRequest: ReviewSearchRequestModel,
    onSuccess: (result: ReviewSearchPreviewModel[]) => void
  ) {
    call(
      repairShopReviewSearch(searchRequest),
      onSuccess,
      'ladda verkstäder i granska-vyn'
    );
  },

  saveRepairShop(
    repairShop: RepairShopModel,
    timestamp: string,
    onSuccess: (result: RepairShopModel, timestamp: string) => void
  ) {
    call(
      repairShopSave(repairShop),
      (r) => onSuccess(r, timestamp),
      'uppdatera verkstadsdata'
    );
  },

  getAddressFromPosition(
    position: PositionModel,
    onSuccess: (result: GeographicPlaceModel) => void
  ) {
    call(
      repairShopGetAddressFromPosition(position),
      (result) => {
        if (result.position) {
          // Special cleanup case: we want the locations coordinates to be the exact coordinates we selected
          result.position.latitude = position.latitude;
          result.position.longitude = position.longitude;
        }

        onSuccess(result);
      },
      'hämta adress-info för utplacerad positions koordinater'
    );
  },

  findMatchingAddresses(
    address: string,
    city: string,
    onSuccess: (result: GeographicPlaceModel[]) => void
  ) {
    call(
      repairShopFindMatchingAddresses({ address, city }),
      onSuccess,
      'hämta koordinater från adress-info'
    );
  },

  getDrivingDirections(
    directionRequest: DistanceRequestModel,
    onSuccess: (
      result: CachedDrivingDistanceModel,
      repairShopID: number
    ) => void,
    repairShopID: number
  ) {
    call(
      repairShopGetDrivingDistance(directionRequest),
      (result) => {
        const cachedModel: CachedDrivingDistanceModel = {
          ...result,

          // Add cache refresh tracking info
          origin: {
            latitude: directionRequest.origin?.latitude,
            longitude: directionRequest.origin?.longitude,
          },
          timestamp: Date.now(),
        };

        onSuccess(cachedModel, repairShopID);
      },
      'hämta körsträckor <silent>'
    );
  },

  coupleExternalToRepairShop(
    coupleExternalRequest: CoupleExternalRequestModel,
    onSuccess: (result: string) => void
  ) {
    call(
      repairShopCoupleExternalToRepairShop(coupleExternalRequest),
      onSuccess,
      'koppla extern verkstadsdata till registret'
    );
  },

  getMiscoupledExternalInsuranceModels(
    onSuccess: (result: ExternalInsuranceModel[]) => void
  ) {
    call(
      repairShopGetMiscoupledExternalInsuranceModels(),
      onSuccess,
      'hämta extern verkstadsdata som behöver kopplas till registret'
    );
  },

  searchMidland(
    searchRequest: MidlandRepairShopRequest,
    onSuccess: (response: MidlandRepairShopResponse) => void
  ) {
    call(
      repairShopSearchMidlandRepairShop(searchRequest),
      onSuccess,
      'Ett fel inträffade vid Midlandsökning'
    );
  },

  updateInsuranceSettings(
    settingsRequest: InsuranceSettingsRequest,
    onSuccess: (response: string) => void
  ) {
    call(
      repairShopUpdateInsuranceSettings(settingsRequest),
      onSuccess,
      'Ett fel inträffade vid uppdatering av försäkringsbolag-inställningar'
    );
  },

  connectInsuranceToAssist(
    connections: ConnectInsuranceToAssistRequest,
    onSuccess: (response: string) => void
  ) {
    call(
      repairShopConnectInsuranceToAssist(connections),
      onSuccess,
      'Ett fel inträffade vid koppling av försäkringsbolag (Assist till Verkstadsregister)'
    );
  },
};
