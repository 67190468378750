import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Actions } from 'general/Actions';
import { InfoTextType } from 'general/TsEnums.gen';

import ControlledInput from '../Input/InputComponent';
import Checkbox from '../Checkbox/Checkbox';

export default class EditInfoTexts extends Component {
  render() {
    const { infoTexts, infoTextType, handleRemove, showPrioCheckbox } =
      this.props;

    const hasInfoText = infoTexts.length > 0;
    let addNewInputIndex = -1;
    if (hasInfoText) {
      if (
        infoTextType === InfoTextType.PhoneNumber ||
        infoTextType === InfoTextType.Email
      ) {
        // Always display addNew for phone and email
        addNewInputIndex = infoTexts.length;
      }
    } else {
      // Always allow adding if infoText is missing
      addNewInputIndex = 0;
    }

    let wrapperClass = '';
    if (showPrioCheckbox) {
      wrapperClass = 'checkbox-line-left';
    }

    return (
      <div>
        {hasInfoText &&
          infoTexts.map((infoText, index) => (
            <div key={infoText.editsIdentifier}>
              {index > 0 && <div className="mini-padder-v"></div>}
              <div className={wrapperClass}>
                <ControlledInput
                  {...this.props}
                  value={infoText.value}
                  handleChange={(value) => Actions.setInfoText(value, infoText)}
                  handleRemove={() => handleRemove(infoText)}
                />
              </div>
              {showPrioCheckbox && (
                <div className="checkbox-line-right">
                  <Checkbox
                    checked={infoText.isPrioritized}
                    handleClick={() =>
                      Actions.toggleInfoTextIsPrioritized(infoText)
                    }
                    outerPadding
                  />
                </div>
              )}
            </div>
          ))}
        {addNewInputIndex !== -1 && (
          <div>
            {addNewInputIndex > 0 && <div className="mini-padder-v"></div>}
            <div className={wrapperClass}>
              <ControlledInput
                {...this.props}
                handleChange={(value) =>
                  Actions.addInfoText(value, infoTextType)
                }
                clearOnInput
              />
            </div>
            {showPrioCheckbox && (
              <div className="checkbox-line-right">
                <Checkbox outerPadding inactive />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

EditInfoTexts.propTypes = {
  infoTexts: PropTypes.array.isRequired,
  infoTextType: PropTypes.number.isRequired,
  handleRemove: PropTypes.func.isRequired,
  showPrioCheckbox: PropTypes.bool,
};
EditInfoTexts.defaultProps = {
  showPrioCheckbox: false,
};
