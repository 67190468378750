import { ContentLoader } from 'components/Loader/Loader';
import { MainStoreState } from 'components/Stores/StoreMain';
import StoreSearchList from 'components/Stores/StoreSearchList';
import { Actions } from 'general/Actions';
import { PositionType } from 'general/Constants';
import {
  getActivePositionData,
  getPositionData,
  precisionRound,
} from 'general/UtilityFunctions';
import { SearchPreviewModel } from 'general/api/models';
import { FC, useEffect, useRef, useState } from 'react';
import SearchListMapLeftColumn from './LeftColumn';
import { MapPin, getBounds } from '../MapUtility';
import useGoogleMapsMap from '../lib/useGoogleMapsMap';
import useGoogleMapsOnClick from '../lib/useGoogleMapsOnClick';
import GoogleMapsMarker from '../lib/GoogleMapsMarker';
import createMapPin from '../markerIcons/createMapPin';
import createMapPoint from '../markerIcons/createMapPoint';
import withGoogleMapsLibrary from '../lib/withGoogleMapsLibrary';

const maxPins = 10;

const redMapPointImg = createMapPoint();

/**
 * @param mainStoreState
 * @param repairShops Repairshops to show on the map
 * @param onlyBoundsPins If true, only the pins used to calculate the bounds will be returned.
 */
const getPins = (
  mainStoreState: MainStoreState,
  repairShops: SearchPreviewModel[],
  onlyBoundsPins?: boolean
) => {
  // Create the list of pins to print (with the correct depth sorting and all properties fixed)
  const pins: MapPin[] = [];

  const positions = onlyBoundsPins
    ? [mainStoreState.activePositionType]
    : mainStoreState.availablePositionTypes;
  positions.forEach((type) => {
    const data = getPositionData(type, mainStoreState)!;
    pins.push({
      id: -1,
      key: data.description,
      lat: data.latitude,
      lng: data.longitude,
      icon: data.icon,
    });
  });

  let pinsCount = repairShops.length;
  if (onlyBoundsPins && maxPins < pinsCount) {
    pinsCount = maxPins;
  }

  for (let i = 0; i < pinsCount; i += 1) {
    const shop = repairShops[i];
    pins.push({
      id: shop.id,
      key: `repairShopID: ${shop.id}`,
      lat: shop.position.latitude,
      lng: shop.position.longitude,
      icon: createMapPin(String(i + 1), '#56C04B'),
    });
  }

  pins.sort((a, b) => b.lat - a.lat);
  return pins;
};

interface Props {
  mainStore: MainStoreState;
  searchListStore: ReturnType<typeof StoreSearchList.getState>;
  openRepairShop(repairshop: SearchPreviewModel): void;
}

const SearchListMap: FC<Props> = withGoogleMapsLibrary(
  ['core', 'maps'],
  ({ mainStore, searchListStore, openRepairShop, coreLib, mapsLib }) => {
    const { activePositionType } = mainStore;
    const {
      searchState: { searchLoading },
    } = searchListStore;

    const pinsToRender = getPins(
      mainStore,
      searchListStore.searchResult.matches
    );
    const pinsToFitBoundsTo = getPins(
      mainStore,
      searchListStore.searchResult.matches,
      true
    );
    const bounds = getBounds(pinsToFitBoundsTo, coreLib);

    const [focusedRepairShopId, setFocusedRepairShopId] = useState<
      number | null
    >(null);

    const activePosition = getActivePositionData(mainStore);

    const mapDivRef = useRef<HTMLDivElement>(null);
    const map = useGoogleMapsMap(mapDivRef, {
      fullscreenControl: false,
      gestureHandling: 'greedy',
      minZoom: 4,
      maxZoom: 19,
    });

    useEffect(() => {
      if (map) {
        map.fitBounds(bounds);
      }
    }, [!!map]);

    useEffect(() => {
      if (!searchLoading) {
        map?.fitBounds(bounds);
      }
    }, [searchLoading]); // Reset bounds when search has completed

    useGoogleMapsOnClick(map, (event) => {
      Actions.setCustomPosition({
        latitude: precisionRound(event.lat, 7),
        longitude: precisionRound(event.lng, 7),
      });

      if (activePositionType === PositionType.Custom) {
        Actions.sendSearchRequest();
      }
    });

    const handleMarkerClick = (repairShopId: number) => {
      if (repairShopId !== -1) {
        setFocusedRepairShopId(repairShopId);
      }
    };

    return (
      <div className="search-list-map">
        {searchListStore.searchState.searchLoading && <ContentLoader overlay />}
        <SearchListMapLeftColumn
          mainStore={mainStore}
          searchListStore={searchListStore}
          focusedRepairShopId={focusedRepairShopId}
          onOpenRepairShop={openRepairShop}
        />

        <div className="right-column">
          {!searchLoading && (
            <div className="select-position-info">
              Klicka på ett tomt utrymme på kartan för att välja sökposition
            </div>
          )}
          <div className="inner" ref={mapDivRef} />
          {map && coreLib && activePosition && (
            <GoogleMapsMarker
              map={map}
              marker={{
                lat: activePosition.latitude,
                lng: activePosition.longitude,
                icon: {
                  url: redMapPointImg,
                  scaledSize: new coreLib.Size(12, 12),
                  anchor: new coreLib.Point(12, 6),
                },
              }}
            />
          )}
          {map &&
            coreLib &&
            pinsToRender.map((pin) => (
              <GoogleMapsMarker
                key={pin.key}
                map={map}
                marker={{
                  onClick: () => handleMarkerClick(pin.id),
                  lat: pin.lat,
                  lng: pin.lng,
                  opacity: 1,
                  icon: {
                    url: pin.icon,
                    scaledSize: new coreLib.Size(55, 55),
                  },
                }}
              />
            ))}
        </div>
      </div>
    );
  }
);

export default SearchListMap;
