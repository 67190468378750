import { css } from 'styled-components';
import {
  main_head_height,
  border_shade,
  main_content_height,
  main_item_height,
  dropdown_item_height,
  main_inner_content_height,
  large_distance,
  medium_distance,
  small_distance,
  mini_distance,
  background_shade,
  pure_white,
  subtle_box_shadow,
  main_border_radius,
  main_green,
  main_red,
  background_red_marker,
  background_green_marker,
  dropdown_shade,
  main_black,
  small_text,
  item_scroll_extension,
  second_head_height,
  border_red,
  main_foot_height,
  border_green,
} from './constants';
import {
  elipsis_overflow,
  header2,
  rightside_vcenter_container,
  vcenter_container,
} from './general-structures';

const general_marker_dot = css`
  width: ${small_distance};
  height: ${small_distance};
  border-radius: ${main_border_radius};
`;

const standardElements = css`
  //Containers
  .main-head {
    width: 100%;
    height: ${main_head_height};
    border-bottom: 1px solid ${border_shade};
    ${vcenter_container}
  }

  .list-area {
    position: relative;
    width: 100%;
    height: calc(
      ${main_content_height} - ${main_head_height} - ${small_distance}
    );
    padding-left: ${large_distance};
    padding-right: ${large_distance};
    &.no-padding {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .list-top-info-box {
    color: ${main_black};
    font-size: ${small_text};
    font-weight: bold;
    padding: ${small_distance};
    width: 100%;
    &.red {
      background-color: ${background_red_marker};
    }
    &.green {
      background-color: ${background_green_marker};
    }
  }

  .second-head {
    width: 100%;
    height: ${second_head_height};
    position: relative;
    border-bottom: 1px solid ${border_shade};

    ${header2}
    ${vcenter_container}

  &.medium-padding {
      padding-left: ${medium_distance};
    }
  }

  .scroll-padder {
    padding-left: ${large_distance};
    padding-right: ${large_distance};
    height: auto;
    width: 100%;
  }

  .main-list-container {
    position: relative;
    width: 100%;
    height: ${main_inner_content_height};
  }

  .list-container-8 {
    width: 100%;
    height: calc(${main_item_height} * 8 + ${item_scroll_extension});
  }

  .standard-scroll-area {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    height: 100%;
  }

  .main-item {
    width: 100%;
    height: ${main_item_height};
    display: block;
    position: relative;
    &.alternate-shade {
      &:nth-of-type(even) {
        background-color: ${background_shade};
      }
    }
    &:last-of-type {
      &:nth-of-type(odd) {
        border-bottom: 1px solid ${background_shade};
      }
    }
    &.shade {
      background-color: ${background_shade};
    }
    &.last {
      border-bottom: 1px solid ${background_shade};
    }
    &.dropdown {
      height: ${dropdown_item_height};
      padding-left: ${medium_distance};
      background-color: ${dropdown_shade};
      &:nth-of-type(even) {
        background-color: ${pure_white};
      }
    }
    &.dynamic-height {
      height: auto;
      min-height: ${main_item_height};
      * {
        display: inline-block;
        vertical-align: top;
      }
    }
    &:not(.dynamic-height) {
      ${vcenter_container}
    }
    .right {
      margin-right: ${medium_distance};
      ${rightside_vcenter_container}
    }
    .right-absolute {
      margin-right: ${medium_distance};
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      height: 100%;
      ${vcenter_container}
    }
    .left-border-highlight {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 5px;
      &.green {
        background-color: ${main_green};
        height: 100%;
      }
      &.red {
        background-color: ${main_red};
        height: 100%;
      }
    }
  }

  .main-card {
    width: 100%;
    height: auto;
    margin-bottom: ${small_distance};
    padding: ${small_distance};
    border-radius: ${main_border_radius};
    box-shadow: ${subtle_box_shadow};
    position: relative;
    &:last-of-type {
      margin-bottom: 3px;
    }
  }

  .add-item-container {
    padding-top: ${large_distance};
    padding-bottom: ${large_distance};
    text-align: center;
    ${vcenter_container}
  }

  .main-dropdown-wrapper {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    height: auto;
    width: 100%;
    border-radius: ${main_border_radius};
    overflow: hidden;
    text-align: left;

    &.open {
      box-shadow: 0 3px 12px 3px rgba(0, 0, 0, 0.75);
      &.map {
        box-shadow: 0 3px 12px 3px rgba(0, 0, 0, 0.1);
      }
    }
    .list-container {
      border-top: 1px solid ${border_shade};
      background-color: ${pure_white};
      width: 100%;
      &.eight {
        height: calc(${dropdown_item_height} * 8.43);
      }
      &.ten {
        height: calc(${dropdown_item_height} * 10.43);
      }
      &.seven {
        height: calc(${dropdown_item_height} * 7.43);
      }
    }
  }

  //Text
  .standard-header2 {
    margin-top: ${large_distance};
    margin-bottom: ${medium_distance};
    ${header2}
  }

  .italic {
    font-style: italic;
  }

  .bold {
    font-weight: bold;
  }

  .green-underline-text {
    border-bottom: 1px dashed ${main_green};
    line-height: 20px;
    padding-top: 2px;
  }

  //Margins
  .main-padder-v {
    height: ${large_distance};
    width: 100%;
  }

  .medium-padder-v {
    height: ${medium_distance};
    width: 100%;
  }

  .small-padder-v {
    height: ${small_distance};
    width: 100%;
  }

  .mini-padder-v {
    height: ${mini_distance};
    width: 100%;
  }

  .main-padder-h {
    width: ${large_distance};
    height: 100%;
  }

  .medium-padder-h {
    width: ${medium_distance};
    height: 100%;
  }

  .small-padder-h {
    width: ${small_distance};
    height: 100%;
  }

  .mini-padder-h {
    width: ${mini_distance};
    height: 100%;
  }

  //Footer
  .main-footer {
    width: 100%;
    height: ${main_foot_height};
    text-align: center;
    position: relative;
    .main-footer-container-three-buttons-info {
      .accept-info {
        left: 200px;
      }
    }
    ${vcenter_container}
    .footer-info-container {
      width: 0;
      height: 100%;
      position: relative;
      .inner {
        position: absolute;
        top: 0;
        bottom: 0;
        ${vcenter_container}
      }
    }
  }

  //Icons
  .arrow-icon-v {
    margin-left: ${small_distance};
    width: 14px;
    height: 10px;
  }

  .small-arrow-icon-v {
    margin-left: 5px;
    width: 12px;
    height: 9px;
  }

  //Special
  .clickable {
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .relative {
    position: relative;
  }

  .one-line-only {
    ${elipsis_overflow}
  }

  .red-marker-dot {
    background-color: ${main_red};
    border: 1px solid ${border_red};
    ${general_marker_dot}
  }

  .green-marker-dot {
    background-color: ${main_green};
    border: 1px solid ${border_green};
    ${general_marker_dot}
  }
`;

export default standardElements;
