import { Component } from 'react';
import PropTypes from 'prop-types';

import { Actions } from 'general/Actions';

import Button from '../../Button/Button';
import Checkbox from '../../Checkbox/Checkbox';
import { DepartmentText } from '../../Utility/UtilityComponents';

import { PopupContainer } from '../PopupComponents';

class SetInitialDepartmentPopup extends Component {
  constructor(props) {
    super(props);

    let departmentID = this.props.suggestedDepartmentID;
    if (departmentID === null) {
      departmentID = -1; // Null matches to NoFilter, don't check this as default, let user click it
    }

    this.state = {
      departmentID,
    };

    this.selectDepartment = this.selectDepartment.bind(this);
  }

  selectDepartment(departmentID) {
    this.setState({ departmentID });
  }

  render() {
    const { departmentID } = this.state;
    const { settings, basicData } = this.props;
    const { close } = settings;
    const { departments } = basicData;

    return (
      <PopupContainer
        close={() => {
          Actions.setDepartmentFilter(null);
          close();
        }}
        headerText="Välj verkstadstyp för sökning"
        small
      >
        <div className="select-department-popup">
          <div
            className="main-item clickable alternate-shade"
            onClick={() => {
              this.selectDepartment(null);
            }}
          >
            <Checkbox checked={departmentID === null} radio outerPadding />
            <div className="text">Inget filter</div>
          </div>
          {departments.sortedKeys.map((key) => (
            <div
              className="main-item clickable alternate-shade"
              key={key}
              onClick={() => {
                this.selectDepartment(key);
              }}
            >
              <Checkbox checked={departmentID === key} radio outerPadding />
              <div className="text">
                <DepartmentText departmentID={key} basicData={basicData} />
              </div>
            </div>
          ))}
        </div>
        <div className="popup-buttons-box">
          <Button
            yellow
            wMain
            hMain
            text="OK"
            onClick={() => {
              Actions.setDepartmentFilter(departmentID);
              close();
            }}
            disabled={departmentID === -1}
          />
        </div>
      </PopupContainer>
    );
  }
}
SetInitialDepartmentPopup.propTypes = {
  settings: PropTypes.object.isRequired,
  basicData: PropTypes.object.isRequired,
  suggestedDepartmentID: PropTypes.number,
};
SetInitialDepartmentPopup.defaultProps = {
  suggestedDepartmentID: null,
};

export default SetInitialDepartmentPopup;
