import { SwedenBounds } from 'general/Constants';

export type MapPin = {
  id: number;
  key: string;
  lat: number;
  lng: number;
  icon: string;
};

export const getBounds = (
  pins: google.maps.LatLngLiteral[],
  coreLib: google.maps.CoreLibrary
): google.maps.LatLngBounds => {
  if (pins.length > 1) {
    const bounds = new coreLib.LatLngBounds();

    pins.forEach((pin) => {
      bounds.extend(pin);
    });

    return bounds;
  }

  // Defaults
  return new coreLib.LatLngBounds(
    new coreLib.LatLng(SwedenBounds.south, SwedenBounds.west),
    new coreLib.LatLng(SwedenBounds.north, SwedenBounds.east)
  );
};
