/**
 * Generated by orval v6.18.1 🍺
 * Do not edit manually.
 * My Title
 * OpenAPI spec version: 1.0.0
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import type { SiteLoadCoreDataModel, SiteLoadRequestModel } from './models';

export const homeApiLoadCoreData = <
  TData = AxiosResponse<SiteLoadCoreDataModel>,
>(
  siteLoadRequestModel: SiteLoadRequestModel,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(`/Home/LoadCoreData`, siteLoadRequestModel, options);
};
