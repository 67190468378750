import { Component } from 'react';
import PropTypes from 'prop-types';

import { AssignmentInsuranceInfo } from '../../Information/InsuranceInfo';

import { PopupContainer } from '../PopupComponents';

class AssignmentInsuranceInfoPopup extends Component {
  render() {
    const { settings, insuranceName, openRepairShopID } = this.props;
    const { close, back } = settings;

    let headerText = `Ditt aktiva uppdrag har försäkringsbolaget: ${insuranceName}`;
    if (openRepairShopID) {
      // In search view
      headerText = `Valt försäkringsbolag: ${insuranceName}`;
    }

    return (
      <PopupContainer
        close={close}
        back={back}
        headerText={headerText}
        pagesize
      >
        <AssignmentInsuranceInfo {...this.props} close={close} />
      </PopupContainer>
    );
  }
}
AssignmentInsuranceInfoPopup.propTypes = {
  settings: PropTypes.object.isRequired,
  insuranceName: PropTypes.string.isRequired,
  openRepairShopID: PropTypes.number,
};
AssignmentInsuranceInfoPopup.defaultProps = {
  openRepairShopID: null,
};

export default AssignmentInsuranceInfoPopup;
