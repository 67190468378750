import { Component } from 'react';

import Button from '../../Button/Button';

import { PopupContainer } from '../PopupComponents';
import { PopupProps } from '../PopupTypes';

interface Address {
  address: string;
  zip: string;
  city: string;
}

interface Props extends PopupProps {
  currentAddress: Address;
  suggestedAddress: Address;
  saveAddress(address: Address): void;
}

class SetAddressFromMapPopup extends Component<Props> {
  render() {
    const { settings, currentAddress, suggestedAddress, saveAddress } =
      this.props;
    const { close } = settings;

    return (
      <PopupContainer
        close={close}
        headerText="Vilken address ska verkstaden ha?"
        medium
      >
        <div className="set-address-from-map-popup">
          <div className="info-text">
            Adressen Google Maps föreslår för verkstadens nya position skiljer
            sig från verkstadens inskrivna adress.
          </div>
          <Button wFull onClick={close} className="popup-button">
            <div className="button-info">Inskriven address</div>
            <div className="address">
              <div className="main">{currentAddress.address}</div>
              <div className="second">
                {currentAddress.zip} {currentAddress.city}
              </div>
            </div>
          </Button>
          <Button
            wFull
            className="popup-button"
            onClick={() => {
              saveAddress(suggestedAddress);
              close();
            }}
          >
            <div className="button-info">Address från Google Maps</div>
            <div className="address">
              <div className="main">{suggestedAddress.address}</div>
              <div className="second">
                {suggestedAddress.zip} {suggestedAddress.city}
              </div>
            </div>
          </Button>
        </div>
      </PopupContainer>
    );
  }
}

export default SetAddressFromMapPopup;
