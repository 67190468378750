import classNames from 'classnames';
import ScrollArea from 'components/Scrollbar';
import { GeographicPlaceModel } from 'general/api/models';
import { Images } from 'general/Constants';
import { FC, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import * as constants from 'globalStyles/constants';
import withGoogleMapsLibrary from 'components/GoogleMaps/lib/withGoogleMapsLibrary';
import PlaceResultItem from './PlaceResultItem';
import useAutoCompletePlacesSearch, {
  AutoCompletePlaceResult,
} from './useAutoCompletePlacesSearch';

const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  width: 100%;
  max-height: 300px;
  background-color: ${constants.pure_white};
`;

const SearchOnMapItem = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0 ${constants.medium_distance};
  width: 100%;
  height: ${constants.dropdown_item_height};

  appearance: none;
  font: inherit;
  text-align: left;
  font-weight: bold;
  background-color: ${constants.dropdown_shade};
  border: none;
  border-bottom: 1px solid ${constants.border_dark};
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;
    background-color: ${constants.dark_shade};
  }
`;

const Icon = styled.div<{ $icon: string }>`
  width: 1.2em;
  height: 1.2em;
  margin-left: auto;

  background-image: url(${({ $icon }) => $icon});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

const GoogleMapsAttribution = styled.div`
  width: 100%;
`;

function AutoCompletePlaceResultToGeographicPlaceModel(
  result: AutoCompletePlaceResult
): GeographicPlaceModel {
  let route = '';
  let streetNumber = '';
  let city = '';
  let zip = '';
  let country = '';

  result.placeResult?.address_components?.forEach((component) => {
    if (component.types.includes('route')) {
      route = component.long_name;
    }
    if (component.types.includes('street_number')) {
      streetNumber = component.long_name;
    }
    if (component.types.includes('postal_code')) {
      zip = component.long_name;
    }
    if (component.types.includes('postal_town')) {
      city = component.long_name;
    }
    if (component.types.includes('country')) {
      country = component.long_name;
    }
  });

  return {
    completeInfo:
      result.placeResult?.name ??
      result.autoCompleteResult.description ??
      result.autoCompleteResult.place_id,
    address: `${route} ${streetNumber}`,
    city: city,
    zip: zip,
    country: country,
    position: {
      latitude: result.placeResult?.geometry?.location?.lat() ?? 0,
      longitude: result.placeResult?.geometry?.location?.lng() ?? 0,
    },
  };
}

interface Props {
  value: string;
  displayRequired?: boolean;
  showResultNumbers?: boolean;
  map?: boolean;
  onContinueSearchInMap?(searchString: string): void;
  onLocationPicked: (location: GeographicPlaceModel) => void;
  includePlacesSearch?: boolean;
  onSearchResultsChanged?(results: GeographicPlaceModel[]): void;
}

const AddressInput: FC<Props> = withGoogleMapsLibrary(
  ['places'],
  ({
    value,
    displayRequired,
    showResultNumbers,
    map,
    onContinueSearchInMap,
    onLocationPicked,
    includePlacesSearch,
    onSearchResultsChanged,
    placesLib,
  }) => {
    const [inputValue, setInputValue] = useState<string>(value);
    const [editMode, setEditMode] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const gMapAttribution = useRef<HTMLDivElement>(null);

    const { searchResults, placesService } = useAutoCompletePlacesSearch(
      placesLib,
      gMapAttribution,
      inputValue,
      includePlacesSearch,
      onSearchResultsChanged
        ? (results) =>
            onSearchResultsChanged(
              results.map(AutoCompletePlaceResultToGeographicPlaceModel)
            )
        : undefined
    );

    // Update input value when value prop changes
    useEffect(() => {
      setInputValue(value);
    }, [value]);

    // Set editMode to false when pressing escape
    useEffect(() => {
      if (!editMode) return;

      const handleEscape = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          setEditMode(false);
        }
      };

      document.addEventListener('keydown', handleEscape);
      return () => {
        document.removeEventListener('keydown', handleEscape);
      };
    }, [editMode]);

    // Set editMode to false when clicking outside of the component
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (
          wrapperRef.current &&
          event.target instanceof Node &&
          !wrapperRef.current.contains(event.target)
        ) {
          setEditMode(false);
        }
      };

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    const showRequired = displayRequired && !value && !map;
    const showInputButton = editMode && !showRequired && !map;
    const showLocationList = editMode || map;
    const noResults = searchResults.length === 0;

    const wrapperClass = classNames({
      'input-field-wrapper': true,
      dropdown: true,
      'map-input-container': map,
    });
    const dropdownWrapperClass = classNames({
      'main-dropdown-wrapper': true,
      'input-dropdown-frame': true,
      open: showLocationList,
      map,
    });
    const inputClass = classNames({
      'edit-field-input': !map,
      'input-with-dropdown': true,
      'has-list-attached': showLocationList,
      'search-field-input': map,
      'map-input': map,
      'has-rightside-button': showInputButton,
    });

    return (
      <div className={wrapperClass} ref={wrapperRef}>
        <input
          className={inputClass}
          style={
            map ? { backgroundImage: `url(${Images.SearchGlass})` } : undefined
          }
          placeholder="Fyll i adress"
          value={editMode || map ? inputValue : value}
          onChange={(event) => editMode && setInputValue(event.target.value)}
          onFocus={() => setEditMode(true)}
          onKeyUp={(e) => e.key === 'Escape' && setEditMode(false)}
          type="text"
          data-css="override"
          ref={inputRef}
        />

        {!map && (
          <div
            className="input-rightside-button shaded"
            onClick={(eve) => {
              eve.stopPropagation();
              setEditMode(!editMode);
              if (!editMode) inputRef.current?.focus();
              else setInputValue(value);
            }}
          >
            {editMode ? (
              <div>Avbryt</div>
            ) : (
              <Icon $icon={Images.EditPenBlack} />
            )}
          </div>
        )}
        {showRequired && (
          <div className="input-required-background">
            <div className="red-marker-dot"></div>
          </div>
        )}

        <div className={dropdownWrapperClass}>
          <PopoverContent
            style={{
              display: showLocationList ? undefined : 'none',
            }}
          >
            <ScrollArea useFlex>
              {onContinueSearchInMap && (
                <SearchOnMapItem
                  onClick={() => onContinueSearchInMap(inputValue)}
                >
                  {noResults ? 'Inga resultat, sök på karta' : 'Sök på karta'}

                  <Icon $icon={Images.TargetCoordinate} />
                </SearchOnMapItem>
              )}
              {!onContinueSearchInMap && noResults && (
                <div className="main-item dropdown clickable">
                  Inga resultat
                </div>
              )}

              {searchResults.map((item, index) => {
                return (
                  <PlaceResultItem
                    key={item.autoCompleteResult.place_id}
                    autoCompletePrediction={item.autoCompleteResult}
                    resultNumber={showResultNumbers ? index + 1 : undefined}
                    onClick={() => {
                      setEditMode(false);

                      if (item.placeResult) {
                        onLocationPicked(
                          AutoCompletePlaceResultToGeographicPlaceModel(item)
                        );
                        return;
                      }

                      // Get more details about the place if we don't have them already
                      if (placesService.current) {
                        placesService.current.getDetails(
                          {
                            placeId: item.autoCompleteResult.place_id,
                            fields: ['address_component', 'geometry'],
                          },
                          (placeResult, status) => {
                            if (placeResult && status === 'OK') {
                              onLocationPicked(
                                AutoCompletePlaceResultToGeographicPlaceModel({
                                  ...item,
                                  placeResult: placeResult,
                                })
                              );
                            }
                          }
                        );
                      }
                    }}
                  />
                );
              })}
            </ScrollArea>

            <GoogleMapsAttribution ref={gMapAttribution} />
          </PopoverContent>
        </div>
      </div>
    );
  }
);

export default AddressInput;
