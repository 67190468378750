import { Component } from 'react';
import PropTypes from 'prop-types';

import { AssistancePackageInternalId } from '../../Information/InsuranceInfo';

import { PopupContainer } from '../PopupComponents';
import { PopupType } from '../Popup';

class InsuranceInfoListPopup extends Component {
  render() {
    const { settings, mainStore } = this.props;
    const { close, back, openOtherPopup } = settings;
    const { insuranceCompanies } = mainStore.basicData;

    return (
      <PopupContainer
        close={close}
        back={back}
        headerText="Välj försäkringsbolag:"
        small
      >
        <div className="popup-info-list-container">
          <div className="popup-horizontal-divider"></div>
          <div>
            {insuranceCompanies.sortedKeys.map((key) => (
              <div
                className="main-item alternate-shade clickable popup-item"
                key={key}
                onClick={() => {
                  openOtherPopup(PopupType.InsuranceInfoPopup, {
                    mainStore,
                    insuranceID: key,
                    headerText: `Information: ${insuranceCompanies.getValue(
                      key
                    )}`,
                  });
                }}
              >
                <div>{insuranceCompanies.getValue(key)}</div>
              </div>
            ))}
            <div
              className="main-item alternate-shade clickable popup-item"
              onClick={() => {
                openOtherPopup(PopupType.InsuranceInfoPopup, {
                  mainStore,
                  insuranceID: AssistancePackageInternalId,
                  headerText: 'Information: Assistanspaket',
                });
              }}
            >
              <div>Assistanspaket</div>
            </div>
          </div>
        </div>
      </PopupContainer>
    );
  }
}
InsuranceInfoListPopup.propTypes = {
  settings: PropTypes.object.isRequired,
  mainStore: PropTypes.object.isRequired,
};

export default InsuranceInfoListPopup;
