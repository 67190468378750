import React, { Component } from 'react';

import { getOnlyActiveOrAllExternal } from 'general/UtilityFunctions';
import { MainItemHeight } from 'general/Constants';
import { ExternalPreview, SearchPreviewModel } from 'general/api/models';
import styled, { css } from 'styled-components';
import { small_info_text } from 'globalStyles/general-structures';
import * as constants from 'globalStyles/constants';
import InfoBoxItem from './InfoBoxItem';

const Wrapper = styled.div`
  position: fixed;
  padding: 0;
  margin: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 4;
  height: 256px;
  outline: none;
  overflow: hidden;
  text-align: center;
`;

const ExternalInsurance = styled.div`
  position: relative;
  display: inline-block;
  background-color: ${constants.background_light};
  border-radius: ${constants.main_border_radius};
  box-shadow: ${constants.heavy_box_shadow};
  border: 2px solid ${constants.background_green_marker};
  width: calc(
    ${constants.page_width} -
      (${constants.large_distance} + ${constants.small_distance})
  );
  height: 252px;
  white-space: pre-wrap;
`;

const KeybindInfo = styled.div`
  position: absolute;
  bottom: 12px;
  right: 290px;
  color: ${constants.green_text};
  font-weight: bold;
  ${small_info_text};
`;

interface ColumnProps {
  $first?: boolean;
  $second?: boolean;
  $shaded?: boolean;
}

const Column = styled.div<ColumnProps>`
  text-align: left;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  padding-left: ${constants.large_distance};
  padding-right: ${constants.large_distance};
  padding-top: calc(
    ${constants.large_distance} -
      (${constants.standard_line_height} - ${constants.standard_text}) / 2
  );
  ${({ $first }) =>
    $first &&
    css`
      width: 73%;
      border-right: 1px solid ${constants.border_shade};
    `}

  ${({ $second }) =>
    $second &&
    css`
      width: 27%;
    `}
        
        ${({ $shaded }) =>
    $shaded &&
    css`
      opacity: ${constants.light_transparent};
    `}
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const MultiplesWarning = styled.div`
  &&& {
    color: ${constants.red_text};
    margin-bottom: ${constants.mini_distance};
    font-weight: bold;
    font-size: ${constants.small_text};
  }
`;

interface Props {
  /** Parent scrolling element */
  listNode: HTMLDivElement;
  shop: SearchPreviewModel;
  isPopup: boolean;
  hoverItemTop: number;
}

const getDefaultState = () => ({
  externalIndex: 0,
});

export default class ExternalInsuranceHoverCard extends Component<Props> {
  state = getDefaultState();
  static defaultProps = { shop: null, listNode: null };

  hoverCard: HTMLDivElement | null = null;

  constructor(props: Props) {
    super(props);

    this.onKeyDown = this.onKeyDown.bind(this);
  }

  componentWillReceiveProps() {
    this.setState(getDefaultState());
  }

  componentDidUpdate() {
    if (this.hoverCard) {
      this.hoverCard.focus();
    }
  }

  onKeyDown(e: React.KeyboardEvent<HTMLDivElement>) {
    if (e.key === ' ') {
      // Space
      this.setState({
        externalIndex: this.state.externalIndex + 1,
      });
    }
    if (e.key === 'ArrowUp') {
      // Up Arrow
      this.props.listNode.scrollTop -= MainItemHeight;
    }
    if (e.key === 'ArrowDown') {
      // Down Arrow
      this.props.listNode.scrollTop += MainItemHeight;
    }
  }

  render() {
    const { shop, hoverItemTop, isPopup } = this.props;
    const { externalIndex } = this.state;

    if (shop === null) {
      return null;
    }

    const insuranceName = shop.insuranceText;

    let top = hoverItemTop - MainItemHeight - 2 - 256;
    if (hoverItemTop < 768 * 0.5 && !isPopup) {
      top = hoverItemTop + 2 + MainItemHeight * 2;
    }

    const externalsToShow = getOnlyActiveOrAllExternal(shop.externalInsurance);

    const count = externalsToShow.length;
    const index = externalIndex % count;
    let external: ExternalPreview | null = null;
    if (count > 0) {
      external = externalsToShow[index];
    }

    return (
      <Wrapper
        style={{ top }}
        ref={(el) => {
          this.hoverCard = el;
        }}
        tabIndex={-1}
        onKeyDown={this.onKeyDown}
      >
        <ExternalInsurance>
          <KeybindInfo>Piltangent upp/ner för nästa</KeybindInfo>

          <Column $first>
            {external !== null ? (
              <InfoBox>
                {external.isActive ? (
                  <InfoBoxItem header="Bilmärken:">
                    <div>{external.carBrandInfo}</div>
                  </InfoBoxItem>
                ) : (
                  <InfoBoxItem
                    headerWarning
                    header={`${insuranceName} har inte en aktiv koppling till
                      verkstaden. Troligen samarbetar {insuranceName} inte med
                      verkstaden.`}
                  ></InfoBoxItem>
                )}
                <InfoBoxItem header={`Generell info (från ${insuranceName}):`}>
                  <div>{external.generalInfo}</div>
                </InfoBoxItem>
                {count > 1 && (
                  <MultiplesWarning>
                    OBS: Visar endast koppling {index + 1} av {count}{' '}
                    {insuranceName} har för verkstaden (tryck mellanslag för att
                    visa nästa).
                  </MultiplesWarning>
                )}
              </InfoBox>
            ) : (
              <InfoBox>
                <InfoBoxItem header="">
                  <div>
                    OBS: Vi saknar info från {insuranceName}. Intern
                    försäkringsinfo för {insuranceName} gäller.
                  </div>
                </InfoBoxItem>
              </InfoBox>
            )}
          </Column>

          <Column $second>
            <div className="popup-small-info-box">
              {shop.inhouseInsuranceInfo !== null ? (
                <div className="item">
                  <div className="name">Intern försäkringsinfo: </div>
                  <div>{shop.inhouseInsuranceInfo}</div>
                </div>
              ) : (
                <div className="item">
                  <div className="name shaded">
                    Ingen intern försäkringsinfo
                  </div>
                </div>
              )}
            </div>
          </Column>
        </ExternalInsurance>
      </Wrapper>
    );
  }
}
