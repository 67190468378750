import * as constants from 'globalStyles/constants';
import { css } from 'styled-components';

//Special map constants
const main_marker_width = 56;
const main_marker_svg_width = 86.04;
const main_marker_hcenter_ratio = 33.08 / main_marker_svg_width;
const main_marker_height_ratio = 101 / main_marker_svg_width;
const main_marker_height = main_marker_width * main_marker_height_ratio;

const googleMapsGlobalStyle = css`
  .main-map-marker {
    position: absolute;
    width: ${main_marker_width}px;
    height: ${main_marker_height}px;
    left: -${main_marker_width * main_marker_hcenter_ratio}px;
    top: -${main_marker_height}px;
    .pin-text {
      position: absolute;
      left: 0;
      top: ${main_marker_height / 6}px;
      width: ${main_marker_width * main_marker_hcenter_ratio * 2}px;
      text-align: center;
      font-size: ${constants.header2};
    }
  }

  .main-map-point {
    position: absolute;
    width: 12px;
    height: 12px;
    left: -6px;
    top: -6px;
    border-radius: 6px;
    border: 1px solid ${constants.border_red};
    background-color: ${constants.main_red};
  }

  .main-map-marker-mirror {
    position: absolute;
    width: ${main_marker_width * main_marker_hcenter_ratio * 2}px;
    height: ${main_marker_width * main_marker_hcenter_ratio * 2}px;
    border-radius: ${main_marker_width * main_marker_hcenter_ratio}px;
    border: 6px solid ${constants.main_yellow};
    &.orange {
      border-color: ${constants.main_orange};
    }
    &.green {
      border-color: ${constants.main_green};
    }
    .pin-text {
      position: absolute;
      left: -6px;
      top: 5px;
      width: ${main_marker_width * main_marker_hcenter_ratio * 2}px;
      text-align: center;
      height: 20px;
      line-height: 20px;
      vertical-align: middle;
      font-size: ${constants.header1};
      font-weight: normal;
    }
  }

  .main-map-point-mirror {
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 6px;
    border: 1px solid ${constants.border_red};
    background-color: ${constants.main_red};
  }

  .map-location-circle {
    width: 30px;
    height: 30px;
    border: 3px solid ${constants.main_yellow};
    border-radius: 15px;
    line-height: 18px;
    text-align: center;
    margin-left: 8px;
    margin-right: 8px;
    background-color: ${constants.pure_white};
    .pin-text {
      font-size: 15px;
      margin-top: 3.5px;
    }
    &.green {
      border: 3px solid ${constants.main_green};
    }
    &.min-margin {
      margin-left: -8px;
    }
  }
`;

export default googleMapsGlobalStyle;
