import React, { Component } from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import { Images } from 'general/Constants';

export default class Checkbox extends Component {
  render() {
    const { checked, radio, inactive, handleClick, outerPadding } = this.props;

    const main = classNames({
      'main-checkbox': true,
      radio,
      clickable: true,
      active: !checked && !inactive,
      'active-checked': checked && !inactive,
      inactive,
    });

    const outer = classNames({
      'checkbox-outer-padding': outerPadding,
      active: !inactive,
    });

    let onClick = null;
    if (!inactive) {
      onClick = handleClick;
    }

    return (
      <div className={outer} onClick={onClick}>
        <div className={main}>
          {checked && !radio && (
            <img
              src={
                inactive ? Images.CheckboxCheckGrey : Images.CheckboxCheckBlack
              }
              alt="X"
              className="check-icon"
            />
          )}
          {checked && radio && <div className="center-point"></div>}
        </div>
      </div>
    );
  }
}
Checkbox.propTypes = {
  checked: PropTypes.bool,
  radio: PropTypes.bool,
  inactive: PropTypes.bool,
  outerPadding: PropTypes.bool,
  handleClick: PropTypes.func,
};
Checkbox.defaultProps = {
  checked: false,
  radio: false,
  inactive: false,
  outerPadding: false,
  handleClick: null,
};
