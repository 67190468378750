import { Component } from 'react';
import PropTypes from 'prop-types';

import { Actions } from 'general/Actions';

import { PopupContainer } from '../PopupComponents';
import { PopupType } from '../Popup';
import GlobalRouter from 'views/routes/DEP_GlobalRouter';

class AdminOptionsPopup extends Component {
  render() {
    const { settings, searchListStore, mainStore } = this.props;
    const { close, openOtherPopup } = settings;
    const { showRemoved } = searchListStore.searchRequest;
    const { canReview, canAdmin } = mainStore.userInfo.access;

    const removedToggleText = showRemoved
      ? 'Avsluta sökning: borttagna'
      : 'Sök borttagna verkstäder';

    return (
      <PopupContainer close={close} headerText="Välj admin verktyg:" small>
        <div className="popup-info-list-container">
          <div className="popup-horizontal-divider"></div>
          <div>
            {canReview && (
              <div
                className="main-item alternate-shade clickable popup-item"
                onClick={() => {
                  GlobalRouter.push('reviewlist/');
                }}
              >
                <div>Granska verkstäder</div>
              </div>
            )}
            {canReview && (
              <div
                className="main-item alternate-shade clickable popup-item"
                onClick={() => {
                  Actions.toggleShowRemoved();
                  close();
                }}
              >
                <div>{removedToggleText}</div>
              </div>
            )}
            {canAdmin && (
              <div
                className="main-item alternate-shade clickable popup-item"
                onClick={() => {
                  GlobalRouter.push('coupleexternal/');
                }}
              >
                <div>Hantera externt kopplad info</div>
              </div>
            )}
            {canAdmin && (
              <div
                className="main-item alternate-shade clickable popup-item"
                onClick={() => {
                  openOtherPopup(PopupType.UpdateInsuranceSettingsPopup, {
                    mainStore,
                  });
                }}
              >
                <div>Inställningar för försäkringsbolag</div>
              </div>
            )}
            {canAdmin && (
              <div
                className="main-item alternate-shade clickable popup-item"
                onClick={() => {
                  openOtherPopup(PopupType.ConnectInsuranceToAssistPopup, {
                    mainStore,
                  });
                }}
              >
                <div>Koppla försäkringsbolag från Assist</div>
              </div>
            )}
          </div>
        </div>
      </PopupContainer>
    );
  }
}

AdminOptionsPopup.propTypes = {
  settings: PropTypes.object.isRequired,
  searchListStore: PropTypes.object.isRequired,
  mainStore: PropTypes.object.isRequired,
};

export default AdminOptionsPopup;
