import { FC } from 'react';
import Checkbox from 'components/Checkbox/Checkbox';
import { PopupType } from 'components/Popup/Popup';
import { PopupTypeStr } from 'components/Popup/PopupTypes';
import { Actions } from 'general/Actions';
import {
  ExternalyConnectedInsuranceCompanies,
  Images,
} from 'general/Constants';
import { getListByKeyID, hasKeyID } from 'general/UtilityFunctions';
import { InsuranceCompanyModel, RepairShopModel } from 'general/api/models';
import { RepairShopExternalInsurancePopupProps } from 'components/Popup/AllPopups/RepairShopExternalInsurancePopup';

interface Props {
  editMode: boolean;
  lockEdit: boolean;

  insurance: InsuranceCompanyModel | null;
  insuranceCompanyKey: number;
  insuranceCompanyName: string;
  repairShop: RepairShopModel;
  setPopup: (popup: PopupTypeStr, popupProps?: any) => void;

  onToggleAllCarBrandsEngine: (insuranceCompany: InsuranceCompanyModel) => void;
  onToggleAllCarBrandsPlate: (insuranceCompany: InsuranceCompanyModel) => void;
}

const InsuranceRow: FC<Props> = ({
  editMode,
  lockEdit,
  insurance,
  insuranceCompanyKey,
  insuranceCompanyName,
  repairShop,
  setPopup,

  onToggleAllCarBrandsEngine,
  onToggleAllCarBrandsPlate,
}) => {
  const hasInsurance = insurance !== null;

  const hasExternal =
    insuranceCompanyKey in ExternalyConnectedInsuranceCompanies &&
    hasKeyID(repairShop.externalInsurance, insuranceCompanyKey);

  const handleClickExternal = () => {
    setPopup(PopupType.RepairShopExternalInsurancePopup, {
      externalInsurance: getListByKeyID(
        repairShop.externalInsurance,
        insuranceCompanyKey
      ),
      insuranceCompanyKey,
      editMode,
    } satisfies RepairShopExternalInsurancePopupProps);
  };

  return (
    <div className="main-item alternate-shade">
      <Checkbox
        checked={hasInsurance}
        handleClick={
          editMode
            ? () => Actions.toggleInsuranceCompany(insuranceCompanyKey)
            : undefined
        }
        outerPadding
        inactive={lockEdit}
      />

      {hasExternal ? (
        <div
          className="insurance-name-with-external clickable"
          onClick={handleClickExternal}
        >
          <div className="green-underline-text">{insuranceCompanyName}</div>
          <img src={Images.DocumentIcon} alt="icon" className="icon" />
        </div>
      ) : (
        <div>{insuranceCompanyName}</div>
      )}

      {hasInsurance && (
        <div className="insurance-rightside">
          <div className="engine">
            <Checkbox
              checked={insurance.allowAllCarBrandsEngine}
              handleClick={
                editMode
                  ? () => onToggleAllCarBrandsEngine(insurance)
                  : undefined
              }
              outerPadding={editMode}
              inactive={lockEdit}
            />
          </div>
          <div className="plate">
            <Checkbox
              checked={insurance.allowAllCarBrandsPlate}
              handleClick={
                editMode
                  ? () => onToggleAllCarBrandsPlate(insurance)
                  : undefined
              }
              outerPadding={editMode}
              inactive={lockEdit}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default InsuranceRow;
