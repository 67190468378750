import * as constants from 'globalStyles/constants';
import { css } from 'styled-components';
import {
  header1,
  header2,
  small_info_text,
  rightside_vcenter_container,
  vcenter_container,
  header3,
  header4,
} from 'globalStyles/general-structures';

const repairShopViewGlobalStyle = css`
  .repair-shop-view {
    .title {
      position: relative;
      margin-left: ${constants.large_distance};
      max-width: 350px;
      ${header1};
    }
    .proposed-remove-text {
      text-align: right;
      .head {
        width: 100%;
        ${header2};
        color: ${constants.red_text};
      }
      .by {
        margin-top: -2px;
        width: 100%;
        ${small_info_text};
      }
    }
    .edit-title-info {
      width: 200px;
      margin-left: ${constants.medium_distance};
      ${small_info_text};
      &.wide {
        width: 283px;
      }
    }
    .head-rightside {
      margin-right: ${constants.large_distance};
      ${rightside_vcenter_container};
      .remove-info {
        width: 190px;
        margin-right: ${constants.medium_distance};
        text-align: right;
        ${small_info_text};
      }
    }
    .info-area {
      height: calc(
        ${constants.main_content_height} - ${constants.main_head_height}
      );
      .column {
        width: 33.333%;
        height: 100%;
        display: inline-block;
        vertical-align: top;
        &:first-of-type {
          border-right: 1px solid ${constants.border_shade};
        }
        &:last-of-type {
          border-left: 1px solid ${constants.border_shade};
        }
        &.main-info {
          padding-right: 0;
          padding-left: 0;
          padding-bottom: ${constants.small_distance};
        }
        .column-head {
          width: 100%;
          padding-left: ${constants.large_distance};
          padding-right: ${constants.large_distance};
        }
      }
      .edit-line {
        margin-top: ${constants.medium_distance};
        &.tight {
          margin-top: ${constants.mini_distance};
        }
        &.department {
          ${vcenter_container};
        }
        .input-header {
          margin-bottom: 4px;
          margin-left: ${constants.mini_distance};
          ${header3};
          &.full-line {
            width: 100%;
          }
          .right {
            margin-right: ${constants.mini_distance};
            float: right;
          }
        }
        .department-container {
          width: 50%;
        }
        .department-text {
          ${header3};
        }
        .description {
          margin-top: ${constants.mini_distance};
          margin-left: ${constants.mini_distance};
          ${small_info_text};
        }
      }
      .edit-info-textarea {
        width: 100%;
        height: 140px;
      }
      .edit-position-info-container {
        width: 50%;
        height: ${constants.main_item_height};
        display: inline-block;
        padding-left: ${constants.small_distance};
        vertical-align: top;
        ${vcenter_container};
        .info-text {
          width: 85%;
          display: inline-block;
          ${small_info_text};
          &.red {
            padding-left: ${constants.small_distance};
            color: ${constants.main_red};
            opacity: 1;
          }
        }
      }
      .find-coordinate-button {
        display: inline-block;
      }
      .separator {
        margin-top: 35px;
        width: 100%;
        height: 1px;
        border-bottom: 1px solid ${constants.border_shade};
      }
      .split-line {
        display: inline-block;
        width: 48.5%;
        &.right {
          float: right;
        }
      }
      .checkbox-line-left {
        display: inline-block;
        width: 85%;
      }
      .checkbox-line-right {
        height: 100%;
        ${rightside_vcenter_container};
      }
      .item-left {
        padding-left: ${constants.small_distance};
      }
      .header-extra-info {
        margin-left: ${constants.small_distance};
        opacity: ${constants.main_transparent};
        font-size: ${constants.small_text};
      }
      .brand-rightside {
        ${rightside_vcenter_container};
        .auth {
          width: 61px;
          text-align: center;
        }
        .vsg {
          width: 44px;
          text-align: center;
          margin-right: ${constants.small_distance};
        }
      }
      .insurance-rightside {
        position: relative;
        ${rightside_vcenter_container};
        .engine {
          width: 61px;
          text-align: center;
        }
        .plate {
          width: 44px;
          text-align: center;
          margin-right: ${constants.small_distance};
        }
        .header {
          margin-top: 20px;
          font-size: ${constants.standard_text};
        }
        .framing {
          position: absolute;
          top: 8px;
          left: 5px;
          width: 96px;
          height: 19px;
          padding-left: 6px;
          border-bottom: 1px solid ${constants.border_dark};
          .text {
            font-size: ${constants.small_text};
          }
        }
      }
    }
    .last-edited-field {
      position: absolute;
      top: 5px;
      right: calc(${constants.small_distance} + ${constants.large_distance});
      width: 225px;
      text-align: right;
      ${small_info_text};
    }
    .required-fields-info {
      text-align: right;
      width: 220px;
      right: ${constants.medium_distance};
      .text {
        margin-left: ${constants.mini_distance};
        margin-right: ${constants.mini_distance};
        font-size: ${constants.small_text};
      }
    }
    .insurance-name-with-external {
      height: 100%;
      ${vcenter_container};
      .icon {
        margin-top: 12px;
        margin-left: 4px;
        width: 12px;
        position: absolute;
      }
    }
    .remove-shop-info {
      text-align: right;
      width: 220px;
      right: 14px;
      font-size: ${constants.small_text};
    }
  }

  .repair-shop-info-component {
    .department-line {
      display: inline-block;
      width: 50%;
      height: ${constants.standard_line_height};
      ${vcenter_container};
      &.even {
        padding-left: 7px;
      }
      &.odd {
        padding-right: 7px;
      }
      .text {
        ${vcenter_container};
        padding-left: ${constants.small_distance};
        height: 100%;
      }
    }
    .line-header {
      width: 100%;
      padding-top: 8px;
      display: inline-block;
      ${header4};
    }
    .line {
      width: 100%;
      padding-top: ${constants.min_line_padding};
      min-height: calc(
        ${constants.standard_line_height} - ${constants.min_line_padding} * 2
      );
      display: inline-block;
    }
    .small-line {
      width: 100%;
      font-size: ${constants.small_text};
      margin-top: ${constants.small_distance};
      &.opaque {
        opacity: ${constants.dark_transparent};
      }
      .left-margin {
        margin-left: ${constants.medium_distance};
      }
    }
    .missing {
      opacity: ${constants.main_transparent};
    }
    .break {
      margin-bottom: ${constants.standard_line_break};
    }
    .info-description {
      width: 53px;
      display: inline-block;
      &.wide {
        width: 75px;
      }
    }
  }
`;

export default repairShopViewGlobalStyle;
