import { Component } from 'react';
import PropTypes from 'prop-types';

import { ReviewInfo } from '../../Information/GeneralInfo';

import { PopupContainer } from '../PopupComponents';

class ReviewInfoPopup extends Component {
  render() {
    const { settings } = this.props;
    const { close, back } = settings;

    return (
      <PopupContainer
        close={close}
        back={back}
        headerText="Granska verkstäder - Information"
        large
      >
        <ReviewInfo />
      </PopupContainer>
    );
  }
}
ReviewInfoPopup.propTypes = {
  settings: PropTypes.object.isRequired,
};

export default ReviewInfoPopup;
