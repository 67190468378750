import { Component, FC } from 'react';
import PropTypes from 'prop-types';

import {
  toDateTimeStr,
  getOnlyActiveOrAllExternal,
} from 'general/UtilityFunctions';

import Button from '../../../Button/Button';

import { PopupContainer } from '../../PopupComponents';
import { PopupProps, PopupType } from '../../Popup';
import ScrollArea, { ScrollbarFlags } from '../../../Scrollbar';
import { ExternalInsuranceModel } from 'general/api/models';
import { useStoreMain } from 'components/Stores/StoreMain';
import { useStoreRepairShop } from 'components/Stores/StoreRepairShop';
import InsuranceAbilitiesTable from 'components/Popup/AllPopups/RepairShopExternalInsurancePopup/InsuranceAbilitiesTable';

export interface RepairShopExternalInsurancePopupProps {
  externalInsurance: ExternalInsuranceModel[];
  insuranceCompanyKey: number;
  editMode: boolean;
}

const RepairShopExternalInsurancePopup: FC<
  RepairShopExternalInsurancePopupProps & PopupProps
> = ({
  settings,
  externalInsurance,
  insuranceCompanyKey,
  editMode = false,
}) => {
  const { close, openOtherPopup } = settings;
  const { basicData, userInfo } = useStoreMain();
  const { repairShop } = useStoreRepairShop();

  const canEdit = editMode && userInfo.access.canAdmin;

  const insuranceName =
    basicData.insuranceCompanies.getValue(insuranceCompanyKey);
  const filteredExternals = getOnlyActiveOrAllExternal(externalInsurance);
  const count = filteredExternals.length;

  const insuranceAbilities = repairShop?.insuranceAbilities.filter(
    (a) => a.insuranceCompanyId === insuranceCompanyKey
  );

  let externalListing = (
    <div className="popup-small-info-box">
      {count > 1 && (
        <div className="item">
          <div className="mini-padder-v"></div>
          <div className="name">
            OBS: {insuranceName} har {count} unika kopplingar till verkstaden
            (alla är listade)
          </div>
          <div className="item-break-line"></div>
        </div>
      )}
      {filteredExternals.map((external, index) => (
        <div key={external.id} className="popup-small-info-box">
          <div className="item">
            <div className="name">{insuranceName} - Generell info:</div>
            <div>{external.generalInfo}</div>
          </div>

          {external.isActive ? (
            <div className="item">
              <div className="name">{insuranceName} - Bilmärken:</div>

              {insuranceAbilities?.length ? (
                <InsuranceAbilitiesTable
                  insuranceAbilities={insuranceAbilities}
                />
              ) : (
                <div>{external.carBrandInfo}</div>
              )}
            </div>
          ) : (
            <div className="item">
              <div className="name warning">
                {insuranceName} har inte en aktiv koppling till verkstaden.
                Troligen samarbetar {insuranceName} inte med verkstaden.
              </div>
            </div>
          )}
          <div className="mini-padder-v"></div>
          <div className="item">
            <div className="info">
              Senast ändrad av {external.modifiedBy} |{' '}
              {toDateTimeStr(external.dateModified)}
            </div>
            <div className="info">
              {insuranceName} - Externt ID: {external.externalRepairShopID}
            </div>
          </div>
          {canEdit && (
            <div>
              <div className="mini-padder-v"></div>
              <Button
                yellow
                hSmall
                text="Redigera"
                onClick={() => {
                  openOtherPopup(PopupType.EditExternalInfoPopup, {
                    external,
                    insuranceName,
                  });
                }}
              />
            </div>
          )}
          {count - index > 1 && <div className="item-break-line"></div>}
        </div>
      ))}
    </div>
  );

  externalListing = (
    <div className="popup-small-info-box medium-height">
      <ScrollArea
        rightPadding={ScrollbarFlags.Padding.Main}
        leftPadding={ScrollbarFlags.Padding.Main}
      >
        {externalListing}
      </ScrollArea>
    </div>
  );

  return (
    <PopupContainer
      close={close}
      headerText="Extern försäkringsinfo"
      medium
      scrollable={count > 1}
    >
      {externalListing}
    </PopupContainer>
  );
};

export default RepairShopExternalInsurancePopup;
