import { Component } from 'react';
import PropTypes from 'prop-types';

import { Actions } from 'general/Actions';
import { RepairShopAPI } from 'general/API';

import Button from '../../Button/Button';
import ControlledInput from '../../Input/InputComponent';

import { PopupContainer } from '../PopupComponents';

class EditExternalInfoPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.external.carBrandInfo,
    };
  }

  render() {
    const { settings, external, insuranceName } = this.props;
    const { close, back } = settings;

    return (
      <PopupContainer
        close={close}
        back={back}
        headerText="Redigera extern info:"
        medium
      >
        <div className="popup-small-info-box">
          <div className="item">
            <div className="name">{insuranceName} - Generell info:</div>
            <div>{external.generalInfo}</div>
          </div>
          {external.isActive ? (
            <div className="item">
              <div className="name">{insuranceName} - Bilmärken:</div>
              <ControlledInput
                placeholder="Skriv bilmärkes info försäkringsbolaget har..."
                value={this.state.value}
                handleChange={(value) => this.setState({ value })}
                autoInputTimer={1} // Special iPad fix (onBlur fails when pressing a button)
                textArea
                className="comment-textarea"
              />
            </div>
          ) : (
            <div className="item">
              <div className="name warning">
                {insuranceName} har inte en aktiv koppling till verkstaden.
                Troligen samarbetar {insuranceName} inte med verkstaden.
              </div>
            </div>
          )}
          {external.isActive && (
            <Button
              yellow
              hSmall
              text="Spara bilmärkesinfo"
              onClick={() => {
                Actions.setExternalCarBrandInfo(this.state.value, external.id);
                back();
              }}
            />
          )}
        </div>
        <div className="main-padder-v"></div>
        <div className="popup-horizontal-divider"></div>
        <div className="main-padder-v"></div>
        <div className="list-top-info-box red">
          Om kopplingen till verkstaden är felaktig: 1. Koppla bort här 2.
          Koppla infon till annan verkstad från admin-verktygen. OBS: Koppla
          inte bort om generell info stämmer men försäkringsbolaget inte aktivt
          samarbetar med verkstaden -- isåfall kan du istället tömma texten för
          bilmärken.
        </div>
        <div className="small-padder-v"></div>
        <Button
          black
          hSmall
          text="Koppla bort extern info från verkstaden"
          loadingOnClick
          onClick={() => {
            const request = {
              externalInsuranceID: external.id,
              repairShopID: null,
            };
            RepairShopAPI.coupleExternalToRepairShop(request, () => {
              Actions.dropExternalFromRepairShop(external.id);
              close();
            });
          }}
        />
      </PopupContainer>
    );
  }
}
EditExternalInfoPopup.propTypes = {
  settings: PropTypes.object.isRequired,
  external: PropTypes.object.isRequired,
  insuranceName: PropTypes.string.isRequired,
};

export default EditExternalInfoPopup;
