import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { getDistanceString } from 'general/UtilityFunctions';

// #region DepartmentText

export class DepartmentText extends Component {
  render() {
    const { departmentID, basicData } = this.props;

    const characters = basicData.departments.getValue(departmentID).split('');
    const short = basicData.departmentsShort.getValue(departmentID);
    let matchIndex = 0;
    let key = 0;

    return (
      <div className="department-text-wrapper">
        {characters.map((character) => {
          key += 1;

          if (
            matchIndex < short.length &&
            character.toLowerCase() === short[matchIndex].toLowerCase()
          ) {
            matchIndex += 1;
            return (
              <div key={key} className="character">
                {character}
                <div className="underline"></div>
              </div>
            );
          }

          return (
            <div key={key} className="character">
              {character}
            </div>
          );
        })}
      </div>
    );
  }
}
DepartmentText.propTypes = {
  departmentID: PropTypes.number.isRequired,
  basicData: PropTypes.object.isRequired,
};

// #endregion DepartmentText

// #region DrivingDirections

export class DrivingDirections extends Component {
  render() {
    const { drivingDirections, icon } = this.props;

    return (
      <div className="directionContainer">
        <img src={icon} alt="ico" className="icon" />
        <div className="texts">
          <div className="main-text">
            Körtid: {drivingDirections.drivingTime}
          </div>
          <div className="sub-text">
            Körsträcka: {getDistanceString(drivingDirections.drivingDistanceKm)}
          </div>
          <div className="sub-text">
            Fågelväg: {getDistanceString(drivingDirections.linearDistanceKm)}
          </div>
        </div>
      </div>
    );
  }
}
DrivingDirections.propTypes = {
  drivingDirections: PropTypes.object,
  icon: PropTypes.string.isRequired,
};
DrivingDirections.defaultProps = {
  drivingDirections: null,
};

// #endregion DrivingDirections
