import { FC } from 'react';
import styled from 'styled-components';
import * as constants from 'globalStyles/constants';

const Wrapper = styled.button`
  display: flex;
  flex-direction: row;
  padding: ${constants.small_distance} ${constants.medium_distance};
  gap: ${constants.mini_distance};
  width: 100%;

  border: none;
  font: inherit;
  appearance: none;
  text-align: left;
  cursor: pointer;

  background-color: ${constants.background_shade};
  &:nth-of-type(even) {
    background-color: ${constants.pure_white};
  }

  &:hover,
  &:focus {
    background-color: ${constants.dark_shade};
    outline: none;
  }
`;

const NumberCircle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 1000px;

  border: 3px solid ${constants.border_yellow};
  background-color: ${constants.background_light};
`;

const Texts = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${constants.mini_distance};
`;

const MainText = styled.div`
  font-weight: bold;
`;

const SecondaryText = styled.div``;

interface Props {
  autoCompletePrediction: google.maps.places.AutocompletePrediction;
  resultNumber?: number;
  onClick?(): void;
}

const PlaceResultItem: FC<Props> = ({
  autoCompletePrediction,
  resultNumber,
  onClick,
}) => {
  return (
    <Wrapper onClick={onClick ? () => onClick() : undefined}>
      {resultNumber !== undefined && (
        <NumberCircle>{resultNumber}</NumberCircle>
      )}

      <Texts>
        <MainText>
          {autoCompletePrediction.structured_formatting.main_text}
        </MainText>
        <SecondaryText>
          {autoCompletePrediction.structured_formatting.secondary_text}
        </SecondaryText>
      </Texts>
    </Wrapper>
  );
};

export default PlaceResultItem;
