import { createGlobalStyle } from 'styled-components';
import {
  standard_text,
  dark_grey,
  background_shade,
  background_light,
  page_width,
  page_height,
  main_content_height,
  main_content_width,
  small_distance,
  main_border_radius,
  main_box_shadow,
} from './constants';
import pageOverrides from './page-overrides';
import standardElements from './standard-elements';
import buttonGlobalStyle from 'components/Button/buttonStyle';
import checkBoxGlobalStyle from 'components/Checkbox/checkboxStyle';
import commentListGlobalStyle from 'components/CommentList/commentListStyle';
import googleMapsGlobalStyle from 'components/GoogleMaps/googleMapsStyle';
import filterGlobalStyle from 'components/Filter/filterStyle';
import findRepairShopMapGlobalStyle from 'components/GoogleMaps/findRepairhopStyle';
import searchListMapGlobalStyle from 'components/GoogleMaps/searchListMapStyle';
import headerGlobalStyle from 'components/Header/headerStyle';
import generalInfoGlobalStyle from 'components/Information/generalInfoStyle';
import insuranceInfoGlobalStyle from 'components/Information/insuranceInfoStyle';
import inputComponentGlobalStyle from 'components/Input/inputComponentStyle';
import loaderGlobalStyle from 'components/Loader/loaderStyle';
import popupGlobalStyle from 'components/Popup/popupStyle';
import repairShopHistoryGlobalStyle from 'components/RepairShopHistory/repairShopHistoryStyle';
import repairShopListGlobalStyle from 'components/RepairShopList/repairShopListStyle';
import utilityComponentsGlobalStyle from 'components/Utility/utilityComponentsStyle';
import repairShopViewGlobalStyle from 'views/RepairShop/repairShopViewStyle';
import searchListViewGlobalStyle from 'views/SearchList/searchListViewStyle';

const GlobalStyles = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
  }

  .repair-shop-app {
    /*
      NOTE: page-overrides and main-page-continer is intended to override other projects css,
      but never RepairShop css. All other imports should be placed after .main-page-container
    */
    ${pageOverrides}
    font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Verdana, Arial, Helvetica, sans-serif;
    font-size: ${standard_text};
    color: ${dark_grey};
    line-height: normal;
    box-sizing: border-box;
    word-wrap: break-word;
    &.is-tablet {
      width: 100%;
      height: 100%;
      position: relative;
    }
    .main-page-container {
      width: ${page_width};
      height: ${page_height};
      display: inline-block;
      position: relative;
      background-color: ${background_shade};
      .main-content-container {
        width: ${main_content_width};
        height: ${main_content_height};
        margin-left: ${small_distance};
        margin-right: ${small_distance};
        margin-top: ${small_distance};
        background-color: ${background_light};
        border-radius: ${main_border_radius};
        box-shadow: ${main_box_shadow};
      }
    }
    
    ${standardElements}

    ${searchListViewGlobalStyle}
    ${repairShopViewGlobalStyle}
    ${buttonGlobalStyle}
    ${filterGlobalStyle}
    ${repairShopListGlobalStyle}
    ${checkBoxGlobalStyle}
    ${commentListGlobalStyle}
    ${popupGlobalStyle}
    ${headerGlobalStyle}
    ${loaderGlobalStyle}
    ${inputComponentGlobalStyle}
    ${repairShopHistoryGlobalStyle}
    ${findRepairShopMapGlobalStyle}
    ${searchListMapGlobalStyle}
    ${googleMapsGlobalStyle}
    ${insuranceInfoGlobalStyle}
    ${generalInfoGlobalStyle}
    ${utilityComponentsGlobalStyle}
  }
`;

export default GlobalStyles;
