// Standard search - start view

import { EventEmitter } from 'events';

import { RepairShopAPI } from 'general/API';
import { ActionReturns, Actions } from 'general/Actions';
import { AppDispatcher, consoleLog } from 'general/General';
import {
  separateSearchFilters,
  getActivePositionData,
  isDepartmentCarBrandDependent,
} from 'general/UtilityFunctions';
import { DepartmentType, InsuranceCompanyType } from 'general/TsEnums.gen';
import StoreMain from './StoreMain';
import {
  ActionTypes,
  ListDisplayCap,
  CHANGE_EVENT,
  ExternalyConnectedInsuranceCompanies,
  InsuranceCompaniesWithInsuranceAbilities,
} from 'general/Constants';
import { SiteLoadCoreDataModel } from 'general/IFrameCommunication';
import {
  CarBrandSpec,
  ExternalInsuranceModel,
  MidlandRepairShopRequest,
  MidlandRepairShopResponse,
  SearchRequestModel,
  SearchResultModel,
} from 'general/api/models';
import { useEffect, useState } from 'react';

export interface SearchListStoreState {
  searchRequest: {
    searchString: string;
    filteredSearchStrings: string[];
    brandID: number | null | undefined;
    carBrandSpec: CarBrandSpec;
    insuranceID: number | null | undefined;
    departmentID: number | null;
    showRemoved: boolean;
  };

  searchState: {
    shouldSelectInitialDepartment: boolean;
    searchLoading: boolean;
    bookmarkedScrollPosition: number;
    displayCount: number;
  };

  /** @deprecated Midland special search disabled as of 27-09-2023 */
  midland: {
    showPopup: boolean;
    loading: boolean;
    valid: boolean;
    errorMessage: string;
    result: MidlandRepairShopResponse | null;
  };

  searchResult: SearchResultModel;

  externalState: {
    externalLoading: boolean;
    externalInsurance: null | ExternalInsuranceModel[];
  };
}

const getDefaultState = (
  coreData: SiteLoadCoreDataModel
): SearchListStoreState => {
  const defaultState: SearchListStoreState = {
    searchRequest: {
      searchString: '',
      filteredSearchStrings: [],
      brandID: coreData.assignment.carBrandID,
      carBrandSpec: coreData.assignment.carBrandSpec,
      insuranceID: coreData.assignment.insuranceCompanyID,
      departmentID: null,
      showRemoved: false,
    },
    searchState: {
      shouldSelectInitialDepartment:
        coreData.assignment.isActive &&
        coreData.assignment.repairShopID === null,
      searchLoading: true,
      bookmarkedScrollPosition: 0,
      displayCount: ListDisplayCap,
    },

    /** @deprecated Midland special search disabled as of 27-09-2023 */
    midland: {
      showPopup: false,
      loading: false,
      valid: false,
      errorMessage: '',
      result: null,
    },

    searchResult: getInitialSearchResult(),
    externalState: {
      externalLoading: true,
      externalInsurance: null,
    },
  };

  if (
    defaultState.searchRequest.insuranceID !== undefined &&
    defaultState.searchRequest.insuranceID !== null &&
    defaultState.searchRequest.insuranceID in
      ExternalyConnectedInsuranceCompanies &&
    !(
      defaultState.searchRequest.insuranceID in
      InsuranceCompaniesWithInsuranceAbilities
    )
  ) {
    // Default to no carBrandSerach
    defaultState.searchRequest.brandID = null;
  }

  return defaultState;
};

const getInitialSearchResult = (): SearchResultModel => ({
  matches: [],
  matchesHeader: '',
  remaining: [],
  remainingHeader: '',
});

class SearchListStore extends EventEmitter {
  state: SearchListStoreState = null!;

  init(coreData: SiteLoadCoreDataModel) {
    this.state = getDefaultState(coreData);
  }

  sendSearchRequest(displayBookmarkIndex = 0) {
    this.state.searchState.searchLoading = true;
    this.state.searchState.displayCount = ListDisplayCap + displayBookmarkIndex;

    if (displayBookmarkIndex === 0) {
      // Reset bookmark
      this.state.searchState.bookmarkedScrollPosition = 0;
    }

    this.state.searchResult = getInitialSearchResult();
    this.emitChange();

    // Get the current active position from mainStore
    const position = getActivePositionData(StoreMain.getState());

    const sendSearchRequest: SearchRequestModel = {
      fullSearchString: this.state.searchRequest.searchString,
      searchTerms: this.state.searchRequest.filteredSearchStrings,
      carBrandID: this.state.searchRequest.brandID ?? undefined,
      carBrandSpec: this.state.searchRequest.carBrandSpec,
      insuranceCompanyID: this.state.searchRequest.insuranceID ?? undefined,
      departmentID: this.state.searchRequest.departmentID ?? undefined,
      position: {
        latitude: position!.latitude,
        longitude: position!.longitude,
      },
      showRemoved: this.state.searchRequest.showRemoved,
    };

    RepairShopAPI.searchRepairShops(sendSearchRequest, Actions.getSearchResult);
  }

  getSearchResult(searchResult: SearchResultModel) {
    this.state.searchResult = searchResult;
    this.state.searchState.searchLoading = false;

    this.emitChange();
  }

  extendSearchList() {
    this.state.searchState.displayCount += ListDisplayCap * 2;
    this.emitChange();
  }

  bookmarkSearchScroll(scrollPosition: number) {
    this.state.searchState.bookmarkedScrollPosition = scrollPosition;
    this.emitChange();
  }

  setCarBrandFilter(brandID: number, carBrandSpec: CarBrandSpec) {
    if (
      brandID !== this.state.searchRequest.brandID ||
      carBrandSpec !== this.state.searchRequest.carBrandSpec
    ) {
      this.state.searchRequest.carBrandSpec = carBrandSpec;
      this.state.searchRequest.brandID = brandID;
      this.sendSearchRequest();
    }
  }

  setInsuranceCompanyFilter(insuranceID: number) {
    if (insuranceID !== this.state.searchRequest.insuranceID) {
      this.state.searchRequest.insuranceID = insuranceID;
      this.checkMidlandPopup();
      this.sendSearchRequest();
    }
  }

  setDepartmentFilter(departmentID: number) {
    if (this.state.searchState.shouldSelectInitialDepartment) {
      this.state.searchState.shouldSelectInitialDepartment = false;
    }

    if (departmentID !== this.state.searchRequest.departmentID) {
      // Automatically remove carBrandFilter if the department does not rely on specific brands
      if (
        departmentID !== null &&
        !isDepartmentCarBrandDependent(departmentID) &&
        this.state.searchRequest.carBrandSpec !== CarBrandSpec.Authorized
      ) {
        this.state.searchRequest.brandID = null;
        this.state.searchRequest.carBrandSpec = CarBrandSpec.None;
      }
      // Automatically clear InsuranceFilter if Midland and Plate
      if (
        departmentID === DepartmentType.Plate &&
        this.state.searchRequest.insuranceID === InsuranceCompanyType.Midland
      ) {
        this.state.searchRequest.insuranceID = null;
      }

      this.state.searchRequest.departmentID = departmentID;
      this.checkMidlandPopup();
      this.sendSearchRequest();
    }
  }

  setSearchString(searchString: string) {
    // Separate filters from search string
    if (searchString !== this.state.searchRequest.searchString) {
      this.state.searchRequest.searchString = searchString;

      const { carBrands, insuranceCompanies, departments } =
        StoreMain.getState().basicData;
      const filters = separateSearchFilters(
        searchString,
        carBrands,
        insuranceCompanies,
        departments
      );

      this.state.searchRequest.filteredSearchStrings =
        filters.filteredSearchStrings;

      if (
        filters.brandID !== null &&
        filters.brandID !== this.state.searchRequest.brandID
      ) {
        this.state.searchRequest.brandID = filters.brandID;
        this.state.searchRequest.carBrandSpec = CarBrandSpec.None;
      }

      if (filters.insuranceID !== null) {
        this.state.searchRequest.insuranceID = filters.insuranceID;
      }

      if (filters.departmentID !== null) {
        this.state.searchRequest.departmentID = filters.departmentID;
      }

      this.sendSearchRequest();
    }
  }

  toggleShowRemoved() {
    this.state.searchRequest.showRemoved =
      !this.state.searchRequest.showRemoved;
    this.sendSearchRequest();
  }

  /** @deprecated Midland special search disabled as of 27-09-2023 */
  checkMidlandPopup() {
    // // Special midland search if Engine and Midland
    // this.state.midland.showPopup = this.state.searchRequest.insuranceID === InsuranceCompanyType.Midland && this.state.searchRequest.departmentID === DepartmentType.Engine;
    // if (this.state.midland.showPopup) {
    //   this.state.midland.valid = false;
    //   const assignment = StoreMain.getState().assignment;
    //   if (!assignment.registrationNumber) {
    //     this.state.midland.errorMessage = 'Du måste ange regnr för att kunna göra en midlandssökning';
    //   } else if (!assignment.stationID) {
    //     this.state.midland.errorMessage = 'Du måste ange en station för att kunna göra en midlandssökning';
    //   } else if (
    //     !assignment.position ||
    //     !assignment.position.latitude ||
    //     !assignment.position.longitude ||
    //     assignment.position.latitude === 0 ||
    //     assignment.position.longitude === 0) {
    //     this.state.midland.errorMessage = 'Du måste ange en position för att kunna göra en midlandssökning';
    //   } else {
    //     this.state.midland.valid = true;
    //     this.state.midland.errorMessage = '';
    //     // clear carbrand-filter
    //     this.state.searchRequest.brandID = null;
    //     this.state.searchRequest.carBrandSpec = CarBrandSpec.None;
    //   }
    // }
  }
  closeMidlandPopup() {
    this.state.midland.showPopup = false;
    this.state.midland.loading = false;
    this.emitChange();
  }
  setMidlandResult(result: MidlandRepairShopResponse) {
    this.state.midland.result = result;
    this.state.midland.loading = false;
    const assignment = StoreMain.getState().assignment;
    if (
      result.validRepairShop &&
      result.repairShopID === null &&
      !assignment.requestedDropOffLocation
    ) {
      assignment.requestedDropOffLocation = result.repairShopDescription;
    }
    this.emitChange();
  }
  searchMidland() {
    this.state.midland.showPopup = false;
    this.state.midland.loading = true;
    this.state.midland.result = null;

    const assignment = StoreMain.getState().assignment;
    const request: MidlandRepairShopRequest = {
      serviceCaseID: assignment.serviceCaseID!,
      registrationNumber: assignment.registrationNumber,
      stationID: assignment.stationID!,
      latitude: assignment.position.latitude,
      longitude: assignment.position.longitude,
    };
    RepairShopAPI.searchMidland(request, Actions.setMidlandResult);

    this.emitChange();
  }

  requestMiscoupledExternal() {
    RepairShopAPI.getMiscoupledExternalInsuranceModels(
      Actions.getMiscoupledExternalResult
    );

    this.state.externalState.externalInsurance = null;
    this.state.externalState.externalLoading = true;

    this.emitChange();
  }

  getMiscoupledExternalResult(externalInsurance: ExternalInsuranceModel[]) {
    this.state.externalState.externalInsurance = externalInsurance;
    this.state.externalState.externalLoading = false;

    this.emitChange();
  }

  coupleRepairShopToExternal(
    repairShopID: number,
    externalInsuranceID: number,
    onSuccess: () => void
  ) {
    const request = {
      externalInsuranceID,
      repairShopID,
    };

    RepairShopAPI.coupleExternalToRepairShop(request, () => {
      const index = this.state.externalState.externalInsurance!.findIndex(
        (external) => external.id === request.externalInsuranceID
      );

      this.state.externalState.externalInsurance!.splice(index, 1);

      onSuccess();

      this.emitChange();
    });
  }

  emitChange() {
    consoleLog(this.state, 'Store Update: Search List', '#CCFA99');
    this.emit(CHANGE_EVENT);
  }

  addChangeListener(callback: () => void) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback: () => void) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  getState() {
    return this.state;
  }
}

const StoreSearchList = new SearchListStore();

AppDispatcher.register((action: ActionReturns) => {
  switch (action.type) {
    case ActionTypes.SEND_SEARCH_REQUEST:
      StoreSearchList.sendSearchRequest(action.displayBookmarkIndex);
      break;

    case ActionTypes.GET_SEARCH_RESULTS:
      StoreSearchList.getSearchResult(action.searchResult);
      break;

    case ActionTypes.EXTEND_SEARCH_LIST:
      StoreSearchList.extendSearchList();
      break;

    case ActionTypes.BOOKMARK_SEARCH_SCROLL:
      StoreSearchList.bookmarkSearchScroll(action.scrollPosition);
      break;

    case ActionTypes.SET_CAR_BRAND_FILTER:
      StoreSearchList.setCarBrandFilter(action.brandID, action.carBrandSpec);
      break;

    case ActionTypes.SET_INSURANCE_COMPANY_FILTER:
      StoreSearchList.setInsuranceCompanyFilter(action.insuranceID);
      break;

    case ActionTypes.SET_DEPARTMENT_FILTER:
      StoreSearchList.setDepartmentFilter(action.departmentID);
      break;

    case ActionTypes.SET_SEARCH_STRING:
      StoreSearchList.setSearchString(action.searchString);
      break;

    case ActionTypes.TOGGLE_SHOW_REMOVED:
      StoreSearchList.toggleShowRemoved();
      break;

    case ActionTypes.CLOSE_MIDLAND_POPUP:
      StoreSearchList.closeMidlandPopup();
      break;
    case ActionTypes.SET_MIDLAND_RESULTS:
      StoreSearchList.setMidlandResult(action.searchResult);
      break;
    case ActionTypes.SEND_MIDLAND_REQUEST:
      StoreSearchList.searchMidland();
      break;

    case ActionTypes.REQUEST_MISCOUPLED_EXTERNAL:
      StoreSearchList.requestMiscoupledExternal();
      break;

    case ActionTypes.GET_MISCOUPLED_EXTERNAL_RESULT:
      StoreSearchList.getMiscoupledExternalResult(action.externalInsurance);
      break;

    case ActionTypes.COUPLE_REPAIR_SHOP_TO_EXTERNAL:
      StoreSearchList.coupleRepairShopToExternal(
        action.repairShopID,
        action.externalInsuranceID,
        action.onSuccess
      );
      break;

    default:
      break; // Do nothing
  }
});

export const useStoreSearhList = () => {
  const [state, setState] = useState(StoreSearchList.getState());

  useEffect(() => {
    const onChange = () => {
      setState(StoreSearchList.getState());
    };

    StoreSearchList.addChangeListener(onChange);
    return () => StoreSearchList.removeChangeListener(onChange);
  }, []);

  return state;
};

export default StoreSearchList;
