import { css } from 'styled-components';
import * as constants from 'globalStyles/constants';
import {
  vcenter_container,
  small_info_text,
} from 'globalStyles/general-structures';

const buttonGlobalStyle = css`
  //Dimensions are not set by default as some buttons will have custom classes
  .button-component {
    width: auto;
    height: auto;
    font-size: ${constants.standard_text};
    border-radius: ${constants.main_border_radius};
    text-align: center;
    overflow: hidden;
    ${vcenter_container};

    &.yellow {
      background-color: ${constants.main_yellow};
      border: 2px solid ${constants.border_yellow};
      color: ${constants.main_black};
      font-weight: bold;
      &.disabled {
        background-color: ${constants.disabled_yellow};
        border: 1px solid ${constants.disabled_border_yellow};
        color: ${constants.mid_grey};
        font-weight: normal;
      }
    }
    &.black {
      background-color: ${constants.main_black};
      border: 2px solid ${constants.border_black};
      color: ${constants.background_light};
      &.disabled {
        background-color: ${constants.disabled_black};
        border: 1px solid ${constants.disabled_border_black};
        color: ${constants.mid_grey};
      }
    }
    &.white {
      background-color: ${constants.background_light};
      border: 1px solid ${constants.border_dark};
      ${small_info_text};

      &.disabled {
        background-color: ${constants.background_shade};
        border: 1px solid ${constants.background_inactive};
        color: ${constants.mid_grey};
      }
    }
    &.red {
      background-color: ${constants.main_red};
    }
    &.outline {
      border: 1px solid #8c7e37;
      box-shadow: ${constants.subtle_box_shadow};
    }
    &.disabled {
      cursor: default;
    }
    &.w-main {
      width: ${constants.main_button_width};
    }
    &.w-large {
      width: ${constants.large_button_width};
    }
    &.w-full {
      width: 100%;
    }
    &.w-small {
      width: ${constants.small_button_width};
    }
    &.h-main {
      height: ${constants.main_item_height};
      &.square {
        width: ${constants.main_item_height};
      }
      .inner-icon {
        height: ${constants.medium_icon_size};
        width: ${constants.medium_icon_size};
      }
    }
    &.h-large {
      height: ${constants.large_item_height};
      &.square {
        width: ${constants.large_item_height};
      }
      .inner-icon {
        height: ${constants.small_item_height};
        width: ${constants.small_item_height};
      }
    }
    &.h-small {
      height: ${constants.small_item_height};
      &.square {
        width: ${constants.small_item_height};
      }
      .inner-icon {
        height: ${constants.mini_icon_size};
        width: ${constants.mini_icon_size};
      }
    }
    &.h-mini {
      height: 30px;
      &.square {
        width: 30px;
      }
      .inner-icon {
        height: ${constants.mini_icon_size};
        width: ${constants.mini_icon_size};
      }
    }
    &.inner-padding-medium {
      padding-left: ${constants.medium_distance};
      padding-right: ${constants.medium_distance};
    }
    &.inner-padding-small {
      padding-left: ${constants.small_distance};
      padding-right: ${constants.small_distance};
    }
    &.footer-margin-right {
      margin-right: ${constants.small_distance};
    }
    &.margin-right {
      margin-right: 25px;
    }
  }
`;

export default buttonGlobalStyle;
