import { RefObject, useEffect, useState } from 'react';
import useGoogleMapsLibrary from './useGoogleMapsLibrary';

/**
 * @param mapElementRef Ref to the element that should contain the map.
 * @param mapOptions Map initialization options (optional)
 * @param mapRef Ref that will be set to the map instance on mount (optional)
 * @returns The map instance
 */
const useGoogleMapsMap = (
  mapElementRef: RefObject<HTMLElement>,
  mapOptions?: google.maps.MapOptions | undefined,
  mapRef?: React.ForwardedRef<google.maps.Map>
) => {
  const { library: mapsLib } = useGoogleMapsLibrary('maps');
  const [map, setMap] = useState<google.maps.Map | undefined>(undefined);

  useEffect(() => {
    if (mapElementRef.current && mapsLib) {
      const map = new mapsLib.Map(mapElementRef.current, mapOptions);
      setMap(map);

      if (typeof mapRef === 'function') {
        mapRef(map);
      } else if (mapRef) {
        mapRef.current = map;
      }
    }
  }, [mapsLib]);

  return map;
};

export default useGoogleMapsMap;
