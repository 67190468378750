import { Component } from 'react';
import PropTypes from 'prop-types';

import Button from '../../Button/Button';

import { PopupContainer } from '../PopupComponents';

class ErrorPopup extends Component {
  render() {
    const { settings, description, basicDataHasLoaded, exitRegister, onClose } =
      this.props;
    const { close } = settings;

    const firstButtonClass = `wide-popup-button${
      !basicDataHasLoaded ? ' last' : ''
    }`;

    return (
      <PopupContainer headerText="Tyvärr har ett fel inträffat" small>
        <div className="error-popup-text">{description}</div>
        <div className="error-popup-text last">
          Kontakta gärna Assistancekårens support.
        </div>
        <div className="popup-buttons-box">
          <Button
            black
            hMain
            text="Stäng verkstadsregistret"
            className={firstButtonClass}
            onClick={exitRegister}
          />
          {basicDataHasLoaded && (
            <Button
              yellow
              hMain
              text="Stanna kvar"
              className="wide-popup-button last"
              onClick={() => {
                onClose();
                close();
              }}
            />
          )}
        </div>
      </PopupContainer>
    );
  }
}
ErrorPopup.propTypes = {
  settings: PropTypes.object.isRequired,
  description: PropTypes.string.isRequired,
  basicDataHasLoaded: PropTypes.bool.isRequired,
  exitRegister: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ErrorPopup;
