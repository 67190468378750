import { FC, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router';
import { EventEmitter } from 'events';
import { consoleLog } from 'general/General';

class GlobalRouterClass extends EventEmitter {
  /** Push route to router history */
  push(path: string) {
    consoleLog(path, 'GlobalRouter.push', '#ffff00');
    this.emit('push', path);
  }
}

export const GlobalRouterProvider: FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    GlobalRouter.on('push', navigate);
    return () => {
      GlobalRouter.off('push', navigate);
    };
  }, [navigate]);

  return <Outlet />;
};

/**
 * @deprecated This is a hack to allow access to the router from outside the React component tree.
 * Please migrate to using the React Router hooks instead.
 */
const GlobalRouter = new GlobalRouterClass();

export default GlobalRouter;
