// #region [IMPORTS]

import React, { Component } from 'react';
import { Waypoint } from 'react-waypoint';

import { Actions } from 'general/Actions';
import StoreMain from 'components/Stores/StoreMain';
import StoreSearchList from 'components/Stores/StoreSearchList';
import { getActivePositionData } from 'general/UtilityFunctions';

import Button from 'components/Button/Button';
import SearchListRow from 'components/RepairShopList/SearchListRow';
import { ContentLoader, SmallLoader } from 'components/Loader/Loader';
import FiltersItem from 'components/Filter/Filter';
import ControlledInput from 'components/Input/InputComponent';
import SearchListMap from 'components/GoogleMaps/SearchListMap';
import { Popup, PopupType, setPopup } from 'components/Popup/Popup';

import {
  Images,
  MainItemHeight,
  CarBrandSpec,
  ExternalyConnectedInsuranceCompanies,
  SearchMode,
} from 'general/Constants';
import ExternalInsuranceHoverCard from 'components/HoverCard/ExternalInsuranceHoverCard';
import { IFrameWindowType } from 'general/IFrameCommunication';
import GlobalRouter from 'views/routes/DEP_GlobalRouter';
import withRouter from 'views/routes/DEP_withRouter';

// #endregion

// #region [1] Default State

const getStateFromStores = () => ({
  mainStore: StoreMain.getState(),
  searchListStore: StoreSearchList.getState(),
});

/**
 * @typedef State
 * @property {import('components/Stores/StoreMain').MainStoreState} mainStore
 * @property {import('components/Stores/StoreSearchList').SearchListStoreState} searchListStore
 * @property {boolean} mapMode
 * @property {boolean} midlandPopupLoaded
 * @property {import('components/Popup/Popup').PopupProps} popupProps
 * @property {number} hoverItemTop
 * @property {unknown | null} externalHoverShop
 * @property {number} awaitingDrivingDistanceCount
 */

/** @returns {State}  */
const getDefaultState = () =>
  Object.assign({}, getStateFromStores(), {
    mapMode: false,
    midlandPopupLoaded: false,
    popupProps: {},
    hoverItemTop: 0,
    externalHoverShop: null,
    awaitingDrivingDistanceCount: 0,
  });

// #endregion

class SearchListView extends Component {
  // #region [2] Constructor and initial actions

  constructor() {
    super();

    /** @type {ReturnType<typeof getDefaultState>} */
    this.state = getDefaultState();

    this.scrollPositionOnRender = 0;

    this.onChange = this.onChange.bind(this);
    this.toggleMapMode = this.toggleMapMode.bind(this);
    this.openRepairShop = this.openRepairShop.bind(this);
    this.setExternalHoverCard = this.setExternalHoverCard.bind(this);
    this.closeExternalHoverCard = this.closeExternalHoverCard.bind(this);

    // On load view actions
    const { searchState } = this.state.searchListStore;

    this.scrollPositionOnRender = searchState.bookmarkedScrollPosition;
    const aproximateBookmarkIndex = Math.floor(
      searchState.bookmarkedScrollPosition / MainItemHeight
    );

    // Initial actions
    Actions.setSearchMode(SearchMode.Main);
    Actions.sendSearchRequest(aproximateBookmarkIndex);
  }

  // #endregion

  // #region [4] Update and Mount functionality

  componentDidMount() {
    StoreMain.addChangeListener(this.onChange);
    StoreSearchList.addChangeListener(this.onChange);

    // Display suggested department filter to user (based on assignment)
    const { mainStore, searchListStore } = this.state;
    const { searchState } = searchListStore;
    const { basicData, assignment } = mainStore;
    const { suggestedDepartmentID, position } = assignment;
    if (searchState.shouldSelectInitialDepartment) {
      const openDepartmentPopup = () => {
        setPopup(this, PopupType.SetInitialDepartmentPopup, {
          basicData,
          suggestedDepartmentID,
        });
      };

      if (position === null) {
        setPopup(this, PopupType.MissingPositionInfoPopup, {
          mainStore,
          onClose: openDepartmentPopup,
        });
      } else {
        openDepartmentPopup();
      }
    }
  }

  componentDidUpdate() {
    if (this.scrollPositionOnRender > 0 && this.listNode) {
      this.listNode.scrollTop = this.scrollPositionOnRender;
      this.scrollPositionOnRender = 0;
    }
    if (
      this.state.searchListStore.midland.showPopup &&
      this.state.popupProps.type === PopupType.None &&
      !this.state.midlandPopupLoaded
    ) {
      this.state.midlandPopupLoaded = true;
      setPopup(this, PopupType.MidlandPopup);
    } else if (
      this.state.midlandPopupLoaded &&
      !this.state.searchListStore.midland.showpopup
    ) {
      this.state.midlandPopupLoaded = false;
    }
  }

  componentWillUnmount() {
    if (this.listNode) {
      Actions.bookmarkSearchScroll(this.listNode.scrollTop);
    }

    StoreMain.removeChangeListener(this.onChange);
    StoreSearchList.removeChangeListener(this.onChange);
  }

  onChange() {
    this.closeExternalHoverCard();
    this.setState(getStateFromStores());
  }

  // #endregion

  // #region [3] Private functions

  /**
   * @param {import('general/api/models').SearchPreviewModel} shop
   * @param {number} hoverItemTop
   */
  setExternalHoverCard(shop, hoverItemTop) {
    this.setState({
      externalHoverShop: shop,
      hoverItemTop,
    });
  }

  closeExternalHoverCard(shop = null) {
    if (
      this.state.externalHoverShop !== null &&
      (shop === null || shop.id === this.state.externalHoverShop.id)
    ) {
      this.setState({
        externalHoverShop: null,
      });
    }
  }

  toggleMapMode() {
    this.setState({ mapMode: !this.state.mapMode });
  }

  openRepairShop(repairShop) {
    const { insuranceID } = this.state.searchListStore.searchRequest;
    const { insuranceCompanies } = this.state.mainStore.basicData;
    const isTablet =
      this.state.mainStore.settings.frameType === IFrameWindowType.Tablet;

    // Check if AssignmentInsurancePopup should be shown
    if (
      insuranceID !== null &&
      insuranceID in ExternalyConnectedInsuranceCompanies &&
      isTablet
    ) {
      const insuranceName = insuranceCompanies.getValue(insuranceID);

      setPopup(this, PopupType.AssignmentInsuranceInfoPopup, {
        insuranceID,
        insuranceName,
        repairShopName: repairShop.name,
        openRepairShopID: repairShop.id,
        inhouseInsuranceInfo: repairShop.inhouseInsuranceInfo,
        externalInsurance: repairShop.externalInsurance,
      });
    } else {
      GlobalRouter.push(`repairshop/${repairShop.id}`);
    }
  }

  // #endregion

  // #region [1] Render

  render() {
    // #region [2] Data and basic Conditions

    /** @type {State} */
    const {
      popupProps,
      hoverItemTop,
      externalHoverShop,
      mapMode,
      mainStore,
      searchListStore,
      awaitingDrivingDistanceCount,
    } = this.state;
    const { assignment, userInfo, basicData, drivingDirectionCache, settings } =
      mainStore;
    const { carBrands, insuranceCompanies, departments } = basicData;
    const { searchString, brandID, insuranceID, departmentID, carBrandSpec } =
      searchListStore.searchRequest;
    const { searchLoading, displayCount, bookmarkedScrollPosition } =
      searchListStore.searchState;
    const { matches, matchesHeader, remaining, remainingHeader } =
      searchListStore.searchResult;
    const isTablet = settings.frameType === IFrameWindowType.Tablet;

    const assignmentBrandId = assignment.carBrandID;
    const activePosition = getActivePositionData(mainStore);
    const { canEdit, canReview } = userInfo.access;
    const hasMatching = matches.length > 0;

    let releaseSettingsStr = settings.releaseVersion;

    if (settings.buildSettings !== 'Production') {
      releaseSettingsStr = (
        <div>
          {releaseSettingsStr}
          <br />
          {settings.buildSettings}
        </div>
      );
    }

    // #endregion

    // #region [3] CarBrand and Insurance specials

    let carBrandFilterText = null;
    if (brandID !== null) {
      carBrandFilterText = carBrands.getValue(brandID);
      if (carBrandSpec === CarBrandSpec.Authorized) {
        carBrandFilterText += ' (Aukt.)';
      } else if (carBrandSpec === CarBrandSpec.Warranty) {
        carBrandFilterText += ' (VSG)';
      }
    }

    const showExternalInfo =
      insuranceID in ExternalyConnectedInsuranceCompanies;

    // #endregion

    // #region [1] Manage driving distances

    const isLoadingDrivingDistances = awaitingDrivingDistanceCount > 0;

    let drivingDistanceMode = false;
    let hasLoadedAllDrivingDistances = false;

    // If the first 5 matching have cached driving distance the list is displayed in drivingDistanceMode
    if (hasMatching) {
      const size = Math.min(matches.length, 5);
      drivingDistanceMode = matches
        .slice(0, size)
        .every((repairShop) => repairShop.id in drivingDirectionCache);
    }

    // Normal sort of matching based on distanceKm
    let compareMatching = (a, b) => {
      if (a.distanceKm < b.distanceKm) {
        return -1;
      }

      return 1;
    };

    if (drivingDistanceMode && hasMatching) {
      // Redefine sorting to prioritize those who have loaded
      compareMatching = (a, b) => {
        if (a.id in drivingDirectionCache && b.id in drivingDirectionCache) {
          if (
            drivingDirectionCache[a.id].drivingDistanceKm <
            drivingDirectionCache[b.id].drivingDistanceKm
          ) {
            return -1;
          }
        } else if (a.id in drivingDirectionCache) {
          return -1;
        } else if (!(b.id in drivingDirectionCache)) {
          if (a.distanceKm < b.distanceKm) {
            return -1;
          }
        }

        return 1;
      };

      hasLoadedAllDrivingDistances = matches.every(
        (repairShop) => repairShop.id in drivingDirectionCache
      );
    }

    // #endregion

    // #region [2] Build list content

    /**
     * @argument {import('general/api/models').SearchPreviewModel} shop
     * @returns {{
     *   onMouseEnter: React.MouseEventHandler<HTMLDivElement>,
     *   onMouseLeave: React.MouseEventHandler<HTMLDivElement>,
     * } | null}
     */
    const buildHoverPropsForRow = (shop) => {
      if (showExternalInfo && shop.insurance && !isTablet) {
        return {
          onMouseEnter: (event) => {
            const currentTargetRect =
              event.currentTarget.getBoundingClientRect();
            this.setExternalHoverCard(shop, currentTargetRect.top);
          },
          onMouseLeave: () => {
            this.closeExternalHoverCard(shop);
          },
        };
      }

      return null;
    };

    const renderSearchResultsList = () => {
      if (searchLoading) {
        return (
          <div className="list-area">
            <ContentLoader />
          </div>
        );
      }

      if (matches.length + remaining.length > 0) {
        const matchesCount = Math.min(matches.length, displayCount);
        const remainingCount = Math.max(
          0,
          Math.min(remaining.length, displayCount - matchesCount)
        );
        const matchesSlice = matches
          .slice(0, matchesCount)
          .sort(compareMatching);
        const remainingSlice = remaining.slice(0, remainingCount);
        const directionsToSearchForCount = 15;

        return (
          <div className="list-area">
            <div
              className="standard-scroll-area relative"
              ref={(node) => {
                this.listNode = node;
              }}
            >
              <div className="distance-info">
                <div className="text">
                  Sorterat efter{' '}
                  {drivingDistanceMode ? 'körsträcka ' : 'fågelvägen '}
                  från {activePosition.description.toLowerCase()}
                </div>
                {hasMatching && !hasLoadedAllDrivingDistances && (
                  <Button
                    outline
                    wMain
                    hMain
                    text={`Hämta ${directionsToSearchForCount}st körsträckor`}
                    loading={isLoadingDrivingDistances}
                    onClick={() => {
                      const shopsToLoad = [];

                      for (let i = 0; i < matches.length; i += 1) {
                        const shop = matches[i];

                        if (!(shop.id in drivingDirectionCache)) {
                          shopsToLoad.push(shop);

                          // Break?
                          if (
                            shopsToLoad.length === directionsToSearchForCount
                          ) {
                            i = matches.length;
                          }
                        }
                      }

                      let delay = 30;
                      this.setState({
                        awaitingDrivingDistanceCount: shopsToLoad.length,
                      });

                      const sendDelayedRequest = () => {
                        setTimeout(() => {
                          const shop = shopsToLoad.shift();

                          StoreMain.requestDrivingDirectionsUpdate(
                            shop.id,
                            shop.position
                          );
                          this.setState({
                            awaitingDrivingDistanceCount: shopsToLoad.length,
                          });

                          if (shopsToLoad.length > 0) {
                            delay = Math.min(300, delay + 30);
                            sendDelayedRequest();
                          }
                        }, delay);
                      };

                      sendDelayedRequest();
                    }}
                  />
                )}

                <div className="small-padder-h"></div>
                <img src={activePosition.iconFlat} alt="ico" className="icon" />
              </div>
              {matchesCount > 0 && (
                <div className="second-head medium-padding matching">
                  <div>{matchesHeader}</div>
                </div>
              )}
              {matchesSlice.map((shop, index) => {
                let drivingDirections = null;
                if (shop.id in drivingDirectionCache) {
                  drivingDirections = drivingDirectionCache[shop.id];
                }

                const hoverProps = buildHoverPropsForRow(shop);

                return (
                  <SearchListRow
                    key={shop.id}
                    shop={shop}
                    index={index}
                    openRepairShop={this.openRepairShop}
                    isMatching
                    isLast={matchesSlice.length - 1 === index}
                    drivingDistanceMode={drivingDistanceMode}
                    drivingDirections={drivingDirections}
                    buildHoverPropsForRow={buildHoverPropsForRow}
                    onInsuranceCellMouseEnter={hoverProps?.onMouseEnter}
                    onInsuranceCellMouseLeave={hoverProps?.onMouseLeave}
                  />
                );
              })}
              {remainingCount > 0 && (
                <div
                  className={`second-head medium-padding ${
                    matchesCount > 0 && 'remaining'
                  }`}
                >
                  <div>{remainingHeader}</div>
                </div>
              )}
              {remainingSlice.map((shop, index) => {
                const hoverProps = buildHoverPropsForRow(shop);

                return (
                  <SearchListRow
                    key={shop.id}
                    shop={shop}
                    index={index}
                    openRepairShop={this.openRepairShop}
                    isLast={remainingSlice.length - 1 === index}
                    buildHoverPropsForRow={buildHoverPropsForRow}
                    onInsuranceCellMouseEnter={hoverProps?.onMouseEnter}
                    onInsuranceCellMouseLeave={hoverProps?.onMouseLeave}
                  />
                );
              })}
              {displayCount < matches.length + remaining.length && (
                <div className="waypoint-container">
                  <div className="list-waypoint">
                    <SmallLoader />
                  </div>
                  <Waypoint onEnter={Actions.extendSearchList} />
                  <div className="main-padder-v"></div>
                </div>
              )}
            </div>
          </div>
        );
      }
      // Display 0 rows info
      return (
        <div className="list-area">
          <div className="second-head medium-padding">
            <div>{remainingHeader}</div>
          </div>
        </div>
      );
    };

    // #endregion

    // #region [3] Return react tree

    return (
      <div className="main-page-container search-list-view">
        <div className="main-content-container">
          <div className="main-head">
            <div className="search-area">
              <ControlledInput
                value={searchString}
                handleChange={Actions.setSearchString}
                autoInputTimer={1333}
                placeholder="Sök verkstad"
                enableQuickClear
                style={{ backgroundImage: `url(${Images.SearchGlass})` }}
                className="search-field-input"
                noEditingButton
              />
              {assignment.isActive && assignment.requestedDropOffLocation && (
                <div
                  className="requested-dropoff clickable"
                  title={assignment.requestedDropOffLocation}
                  onClick={() => {
                    // Take name | address | city
                    let str = assignment.requestedDropOffLocation;
                    let index = 0;
                    for (let i = 0; i < 3; i += 1) {
                      if (index > -1 && index < str.length) {
                        index = str.indexOf('|', index) + 1;
                      }
                    }
                    index -= 1;
                    if (index > 0) {
                      str = str.slice(0, index);
                    }
                    Actions.setSearchString(str);
                  }}
                >
                  <span className="head">Önskad transport till:</span>
                  <span className="text">
                    {assignment.requestedDropOffLocation}
                  </span>
                </div>
              )}
            </div>
            <div className="head-rightside">
              <FiltersItem
                text={carBrandFilterText}
                placeholder="Bilmärke"
                items={carBrands}
                selectItem={Actions.setCarBrandFilter}
                marginRight
                assignmentBrandId={assignmentBrandId}
              />
              <FiltersItem
                text={
                  insuranceID === null
                    ? null
                    : insuranceCompanies.getValue(insuranceID)
                }
                placeholder="Försäkring"
                items={insuranceCompanies}
                selectItem={Actions.setInsuranceCompanyFilter}
                marginRight
              />
              <FiltersItem
                text={
                  departmentID === null
                    ? null
                    : departments.getValue(departmentID)
                }
                placeholder="Verkstadstyp"
                items={departments}
                selectItem={Actions.setDepartmentFilter}
                basicData={basicData}
              />
            </div>
          </div>
          {mapMode ? (
            <div className="map-area">
              <SearchListMap
                mainStore={mainStore}
                searchListStore={searchListStore}
                openRepairShop={this.openRepairShop}
              />
            </div>
          ) : (
            renderSearchResultsList()
          )}
        </div>

        <div className="main-footer">
          {canEdit && (
            <Button
              yellow
              wMain
              hLarge
              footerMargin
              text="Ny verkstad"
              onClick={Actions.createNewRepairShopTemplate}
            />
          )}
          <Button
            yellow
            wMain
            hLarge
            footerMargin
            text="Info"
            onClick={() => {
              setPopup(this, PopupType.MasterInfoPopup, {
                mainStore,
              });
            }}
          />
          {assignment.isActive && (
            <Button
              yellow
              wMain
              hLarge
              footerMargin
              text="Uppdrag"
              onClick={() => {
                setPopup(this, PopupType.AssignmentInfoPopup, {
                  assignment,
                  carBrands,
                });
              }}
            />
          )}
          {canReview && (
            <Button
              yellow
              wMain
              hLarge
              footerMargin
              text="Admin +"
              onClick={() => {
                setPopup(this, PopupType.AdminOptionsPopup, {
                  searchListStore,
                  mainStore,
                });
              }}
            />
          )}
          <Button
            yellow={!mapMode}
            black={mapMode}
            text={mapMode ? 'Stäng karta' : 'Karta'}
            wMain
            hLarge
            onClick={() => {
              if (!mapMode) {
                if (this.listNode) {
                  Actions.bookmarkSearchScroll(this.listNode.scrollTop);
                }
              } else {
                this.scrollPositionOnRender = bookmarkedScrollPosition;
              }
              this.toggleMapMode();
            }}
          />
          <div className="release-settings">{releaseSettingsStr}</div>
        </div>

        <ExternalInsuranceHoverCard
          shop={externalHoverShop}
          hoverItemTop={hoverItemTop}
          listNode={this.listNode}
          isPopup={settings.frameType === IFrameWindowType.Popup}
        />
        <Popup {...popupProps} />
      </div>
    );

    // #endregion
  }
  // #endregion
}

export default withRouter(SearchListView);
