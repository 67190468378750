import { Component } from 'react';
import PropTypes from 'prop-types';

import StoreMain from 'components/Stores/StoreMain';
import StoreRepairShop from 'components/Stores/StoreRepairShop';
import { Actions } from 'general/Actions';
import {
  prettifyLink,
  getInfoName,
  isRepairShopCarBrandDependent,
  getActivePositionData,
  navigateToStartList,
  hasKeyID,
  toDateTimeStr,
  getItemByKeyID,
  getListByKeyID,
} from 'general/UtilityFunctions';
import { selectRepairShopAndExitRegister } from 'general/IFrameCommunication';

import Button from 'components/Button/Button';
import RepairShopHistory from 'components/RepairShopHistory/RepairShopHistory';
import ScrollArea, { ScrollbarFlags } from 'components/Scrollbar';
import { PageLoader } from 'components/Loader/Loader';
import Checkbox from 'components/Checkbox/Checkbox';
import CommentList from 'components/CommentList/CommentList';
import {
  DrivingDirections,
  DepartmentText,
} from 'components/Utility/UtilityComponents';

import { Popup, PopupType, setPopup } from 'components/Popup/Popup';
import {
  Images,
  AllBrandsId,
  ActiveStatusListing,
  ProposedRemoveStatusListing,
  DuplicateStatusListing,
} from 'general/Constants';
import { StatusType, InfoTextType, ChangeLogType } from 'general/TsEnums.gen';
import withRouter from 'views/routes/DEP_withRouter';
import InsurancesPanel from './InsurancesPanel';
import CarBrandsPanel from './CarBrandsPanel';

const getStateFromStores = () => ({
  mainStore: StoreMain.getState(),
  repairShopStore: StoreRepairShop.getState(),
});

const getCleanState = () => ({
  historyMode: false,
  resetHandlerList: [],
  popupProps: {},
});

const getDefaultState = () =>
  Object.assign({}, getStateFromStores(), getCleanState(), {
    id: -1,
  });

class RepairShopView extends Component {
  constructor(props) {
    super(props);

    this.state = getDefaultState();

    this.onChange = this.onChange.bind(this);
    this.toggleHistoryMode = this.toggleHistoryMode.bind(this);
    this.toggleChangeReset = this.toggleChangeReset.bind(this);
    this.resetRepairShop = this.resetRepairShop.bind(this);
    this.openRemoveInfoPopup = this.openRemoveInfoPopup.bind(this);

    // Start in history mode?
    if (this.props.params.history === 'history') {
      this.state.historyMode = true;
    }

    // On load view actions
    if (this.props.params.id) {
      // Load repairShop by Url:id (string)
      this.state.id = parseInt(this.props.params.id, 10);
      Actions.requestRepairShop(this.state.id);
    } else if (this.state.mainStore.assignment.repairShopID !== null) {
      // Special case: load repairShop by assignment id
      this.state.id = this.state.mainStore.assignment.repairShopID;
      Actions.requestRepairShop(this.state.id);
    }
  }

  componentDidMount() {
    StoreMain.addChangeListener(this.onChange);
    StoreRepairShop.addChangeListener(this.onChange);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.params.id && this.props.params.id !== nextProps.params.id) {
      this.setState(getCleanState());

      const id = parseInt(nextProps.params.id, 10);
      this.setState({ id });

      Actions.requestRepairShop(id);
    }
  }

  componentWillUnmount() {
    StoreMain.removeChangeListener(this.onChange);
    StoreRepairShop.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState(getStateFromStores());
  }

  toggleHistoryMode() {
    this.setState({ historyMode: !this.state.historyMode });
  }

  toggleChangeReset(resetHandler) {
    const index = this.state.resetHandlerList.findIndex(
      (reset) => reset.change.id === resetHandler.change.id
    );
    const resetHandlerList = this.state.resetHandlerList;

    if (index !== -1) {
      resetHandlerList.splice(index, 1);
    } else {
      resetHandlerList.push(resetHandler);
    }

    this.setState({ resetHandlerList });
  }

  resetRepairShop() {
    const { resetHandlerList } = this.state;
    const { repairShop } = this.state.repairShopStore;
    let actionCount = 0;

    // Perform each reset in order - ignore VSG / Auth if car brand doesn't exist
    resetHandlerList.forEach((resetHandler) => {
      if (resetHandler.change.changeType === ChangeLogType.CarBrandWarranty) {
        if (hasKeyID(repairShop.carBrands, resetHandler.change.keyID)) {
          resetHandler.resetAction();
          actionCount += 1;
        }
      } else if (
        resetHandler.change.changeType === ChangeLogType.CarBrandAuthorized
      ) {
        if (hasKeyID(repairShop.carBrands, resetHandler.change.keyID)) {
          resetHandler.resetAction();
          actionCount += 1;
        }
      } else {
        resetHandler.resetAction();
        actionCount += 1;
      }
    });

    // If any actions where performed, update the repairShop
    if (actionCount > 0) {
      Actions.saveRepairShop(() => {
        this.setState(getCleanState());
      });
    }
  }

  openRemoveInfoPopup(infoText) {
    setPopup(this, PopupType.RemoveItemPopup, {
      headerText: `Vill du ta bort ${getInfoName(infoText.keyID, true)}?`,
      infoText: infoText.value,
      handleRemove: (close) => {
        Actions.setInfoText('', infoText);
        Actions.saveRepairShop(close);
      },
    });
  }

  render() {
    const { historyMode, resetHandlerList, popupProps, mainStore, id } =
      this.state;
    const { settings, assignment, userInfo, basicData, drivingDirectionCache } =
      mainStore;
    const { carBrands, insuranceCompanies, departments } = mainStore.basicData;

    /* Note always render unalteredRepairShop: this avoids special frontend artefacts on edited reairShop
     * Ex: state has updated to reviewed, but ajaxCall has not completed, and no changeLogOperations exist */
    const repairShop = this.state.repairShopStore.unalteredRepairShop;

    if (repairShop === null || id !== repairShop.id) {
      return <PageLoader />;
    }

    const isActive = repairShop.status in ActiveStatusListing;
    const hasProposedRemove = repairShop.status in ProposedRemoveStatusListing;

    // Prepped select options
    const carBrandDependent = isRepairShopCarBrandDependent(repairShop);
    const canReview = userInfo.access.canReview;
    const canEdit = userInfo.access.canEdit && isActive;

    const showAcceptNeverReviewedButton =
      canReview &&
      isActive &&
      (repairShop.dateReviewed === null ||
        repairShop.changeLogOperations.length === 0);
    const showHistoryToggle =
      historyMode || (canReview && isActive && !showAcceptNeverReviewedButton);
    const showResetButton = resetHandlerList.length > 0 && historyMode;
    const showAcceptButton =
      repairShop.status === StatusType.NotReviewed &&
      !showResetButton &&
      historyMode;
    const adminRemovedRepairShop = canReview && !isActive;
    const showSelectButton =
      settings.repairShopIsSelectable && !historyMode && isActive;
    const isSelectedToAssignment = assignment.repairShopID === repairShop.id;

    // Prepped select logic
    const selectRepairShop = () => {
      selectRepairShopAndExitRegister(repairShop.preview);
    };
    let openPopupOnSelect = false;
    /** @type {number} */
    let addBrandId = null;
    /** @type {number} */
    let addInsuranceId = null;

    if (assignment.isActive && canEdit) {
      /* Steps in order: (onSuccess for each backend opperation)
        1. Option to add assignment insurance and brand to repairShop
        2. Exit register and return repairShopData */
      if (
        assignment.carBrandID !== null &&
        !hasKeyID(repairShop.carBrands, assignment.carBrandID) &&
        !hasKeyID(repairShop.carBrands, AllBrandsId) && // Special case: Allbil
        carBrandDependent
      ) {
        addBrandId = assignment.carBrandID;
        openPopupOnSelect = true;
      }
      if (
        assignment.insuranceCompanyID !== null &&
        !hasKeyID(repairShop.insuranceCompanies, assignment.insuranceCompanyID)
      ) {
        addInsuranceId = assignment.insuranceCompanyID;
        openPopupOnSelect = true;
      }
    }

    // Prepped display info texts
    const inhouseInsuranceInfo = getItemByKeyID(
      repairShop.infoTexts,
      InfoTextType.InsuranceInfo
    );

    const displayInfo = (infoTextType, defaultIfMissing) => {
      const texts = getListByKeyID(repairShop.infoTexts, infoTextType);
      if (texts.length > 0) {
        let text = '';
        let title = '';

        for (let i = 0; i < texts.length; i += 1) {
          if (i > 0) {
            text += ', ';
          }
          const prio = texts[i].isPrioritized ? ' (prio)' : '';
          text += `${texts[i].value}${prio}`;
          if (prio !== '') {
            title =
              'Följesedlar går i första hand till prioriterade e-postadresser.';
          }
        }

        if (infoTextType === InfoTextType.Website) {
          text = prettifyLink(text);
        }

        return <span title={title}>{text}</span>;
      }

      return <span className="missing">{defaultIfMissing}</span>;
    };

    // Printing aid
    let departmentPrintCount = 0;

    const activePosition = getActivePositionData(mainStore);

    let drivingDirections = null;
    if (repairShop.id in drivingDirectionCache) {
      drivingDirections = drivingDirectionCache[repairShop.id];
    }

    const showDrivingDirections =
      !historyMode && isActive && drivingDirections !== null;
    const showGetDrivingDirections =
      !historyMode && isActive && drivingDirections === null;

    // Prepped footer text
    let lastChangeFooterString = 'Verkstaden har aldrig godkänts';
    let lastChangeFooterDate = '';

    if (historyMode && repairShop.dateReviewed !== null) {
      lastChangeFooterString = `Senast godkänd av ${repairShop.reviewedBy}`;
      lastChangeFooterDate = toDateTimeStr(repairShop.dateReviewed);
    } else if (historyMode && repairShop.dateReviewed === null) {
      lastChangeFooterString = 'Verkstaden har aldrig godkänts';
    } else {
      lastChangeFooterString = `Senast ändrad av ${repairShop.modifiedBy}`;
      lastChangeFooterDate = toDateTimeStr(repairShop.dateModified);
    }

    return (
      <div className="main-page-container repair-shop-view">
        <div className="main-content-container">
          <div className="main-head">
            <div
              className="title"
              title={`ID: ${repairShop.id} - ${repairShop.preview.shorthandInfoString}`}
            >
              {historyMode && 'Ändringshistorik: '}
              {repairShop.name}
            </div>
            {showDrivingDirections && (
              <DrivingDirections
                drivingDirections={drivingDirections}
                icon={activePosition.iconFlat}
              />
            )}
            {showGetDrivingDirections && (
              <Button
                outline
                hMain
                padSmall
                text="Hämta körsträcka"
                loadingOnClick
                className="get-driving-direction-button"
                onClick={() => {
                  Actions.requestDrivingDirectionsUpdate(repairShop.id, {
                    latitude: repairShop.latitude,
                    longitude: repairShop.longitude,
                  });
                }}
              />
            )}

            {!isActive && (
              <div className="head-rightside">
                <div className="proposed-remove-text">
                  <div className="head">
                    OBS! markerad som borttagen
                    {repairShop.status in DuplicateStatusListing
                      ? ' (dubblett)'
                      : ' (stängd)'}
                    {hasProposedRemove ? ' - saknar admins godkännande' : ''}
                  </div>
                  {repairShop.changeLogOperations.length > 0 && (
                    <div className="by">
                      Av {repairShop.changeLogOperations[0].createdBy}{' '}
                      {toDateTimeStr(
                        repairShop.changeLogOperations[0].dateCreated
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}

            {canEdit && (
              <div className="head-rightside">
                <div className="remove-info">
                  Ta bort verkstad som stängt eller finns som dubblett i
                  registret!
                </div>
                <Button
                  black
                  hMain
                  square
                  icon={Images.TrashCanWhite}
                  onClick={() => {
                    setPopup(this, PopupType.RemoveShopPopup, {
                      name: repairShop.name,
                      searchMode: mainStore.searchMode,
                    });
                  }}
                />
                <div className="medium-padder-h"></div>
                <Button
                  yellow
                  hMain
                  padSmall
                  icon={Images.EditPenBlack}
                  text="Redigera"
                  linkTo={`editrepairshop/${repairShop.id}`}
                />
              </div>
            )}
          </div>

          {historyMode && (
            <RepairShopHistory
              mainStore={mainStore}
              repairShop={repairShop}
              resetHandlerList={resetHandlerList}
              toggleChangeReset={this.toggleChangeReset}
            />
          )}

          {!historyMode && (
            <div className="info-area">
              <div className="column main-info">
                <ScrollArea
                  rightPadding={ScrollbarFlags.Padding.Main}
                  leftPadding={ScrollbarFlags.Padding.Main}
                >
                  <div className="repair-shop-info-component">
                    <div className="small-line opaque">
                      <div>Verkstads-ID: {repairShop.id}</div>
                    </div>
                    <div className="line-header">
                      <div>Adress:</div>
                    </div>
                    <div className="line">
                      <div>{repairShop.address}</div>
                    </div>
                    <div className="line">
                      <div>
                        {repairShop.zip} {repairShop.city}
                      </div>
                    </div>
                    <div className="line-header">
                      <div>Kontakt:</div>
                    </div>
                    <div className="line">
                      {displayInfo(
                        InfoTextType.PhoneNumber,
                        'Telefonnummer saknas'
                      )}
                    </div>
                    <div className="line">
                      {displayInfo(InfoTextType.Email, 'E-post saknas')}
                    </div>
                    <div className="line break">
                      {displayInfo(InfoTextType.Website, 'Hemsida saknas')}
                    </div>

                    {departments.sortedKeys.map((key) => {
                      if (hasKeyID(repairShop.departments, key)) {
                        departmentPrintCount += 1;

                        return (
                          <div
                            key={key}
                            className={`department-line ${
                              departmentPrintCount % 2 === 0 ? 'even' : 'odd'
                            }`}
                          >
                            <Checkbox inactive checked />
                            <div className="text">
                              <DepartmentText
                                departmentID={key}
                                basicData={basicData}
                              />
                            </div>
                          </div>
                        );
                      }
                      return null;
                    })}
                    {repairShop.departments.length === 0 && (
                      <div className="missing">Verkstadstyp saknas</div>
                    )}

                    <div className="main-padder-v"></div>

                    <div className="line">
                      <span className="info-description">Lås:</span>
                      {displayInfo(InfoTextType.LockInfo, '---')}
                    </div>
                    <div className="line">
                      <span className="info-description">Larm:</span>
                      {displayInfo(InfoTextType.AlarmInfo, '---')}
                    </div>
                    <div className="line">
                      <span className="info-description">Kod:</span>
                      {displayInfo(InfoTextType.PassCode, '---')}
                    </div>
                    {hasKeyID(
                      repairShop.infoTexts,
                      InfoTextType.VacationDates
                    ) && (
                      <div className="line">
                        <div className="main-padder-v"></div>
                        <span className="info-description wide">Semester:</span>
                        {displayInfo(InfoTextType.VacationDates, '')}
                      </div>
                    )}
                    <div className="separator"></div>
                    <CommentList
                      comments={getListByKeyID(
                        repairShop.infoTexts,
                        InfoTextType.Comment
                      )}
                      handleClickAdd={() => {
                        setPopup(this, PopupType.AddCommentPopup);
                      }}
                      handleRemove={this.openRemoveInfoPopup}
                      allowEdit={canEdit}
                    />
                  </div>
                </ScrollArea>
              </div>

              <CarBrandsPanel repairShop={repairShop} />

              <InsurancesPanel
                setPopup={(popupType, popupProps) =>
                  setPopup(this, popupType, popupProps)
                }
              />
            </div>
          )}
        </div>

        <div className="main-footer">
          {adminRemovedRepairShop && (
            <div className="footer-info-container">
              <div className="inner remove-shop-info">
                {hasProposedRemove ? (
                  <div>
                    Ta bort verkstaden permanent eller återställ verkstaden till
                    registret
                  </div>
                ) : (
                  <div>
                    OBS! Borttagning av verkstaden har godkänts av admin.
                  </div>
                )}
              </div>
            </div>
          )}
          {showAcceptNeverReviewedButton && (
            <div className="footer-info-container">
              <div className="inner required-fields-info">
                <div className="red-marker-dot"></div>
                <div className="text">Har aldrig godkänts</div>
                <div className="red-marker-dot"></div>
              </div>
            </div>
          )}
          {adminRemovedRepairShop && (
            <div>
              {hasProposedRemove && (
                <Button
                  black
                  wMain
                  hLarge
                  footerMargin
                  text="Godkänn borttagning"
                  loadingOnClick
                  onClick={() => {
                    Actions.saveRepairShop(() => {
                      navigateToStartList(mainStore.searchMode);
                    });
                  }}
                />
              )}
              <Button
                yellow
                wMain
                hLarge
                footerMargin
                text="Återställ verkstad"
                loadingOnClick
                onClick={() => {
                  Actions.resurrectRepairShop();
                }}
              />
            </div>
          )}
          {showResetButton && (
            <Button
              yellow
              wMain
              hLarge
              footerMargin
              text="Återställ"
              loadingOnClick
              onClick={this.resetRepairShop}
            />
          )}
          {showAcceptNeverReviewedButton && (
            <Button
              yellow
              wMain
              hLarge
              footerMargin
              text="Godkänn"
              loadingOnClick
              onClick={() => {
                Actions.saveRepairShop();
              }}
            />
          )}
          {showAcceptButton && (
            <Button
              yellow
              wMain
              hLarge
              footerMargin
              text="Godkänn"
              loadingOnClick
              onClick={() => {
                Actions.saveRepairShop();
              }}
            />
          )}
          {showHistoryToggle && (
            <Button
              yellow
              wMain
              hLarge
              footerMargin
              text={historyMode ? 'Visa verkstad' : 'Ändringar'}
              onClick={this.toggleHistoryMode}
            />
          )}
          {showSelectButton && (
            <Button
              loadingOnClick={!openPopupOnSelect}
              yellow
              wMain
              hLarge
              footerMargin
              text={isSelectedToAssignment ? 'Vald' : 'Välj'}
              disabled={isSelectedToAssignment}
              onClick={() => {
                if (openPopupOnSelect) {
                  setPopup(this, PopupType.AssignmentDataToRepairShopPopup, {
                    conitnueSelect: selectRepairShop,
                    name: repairShop.name,
                    addBrandId,
                    addInsuranceId,
                    basicData,
                  });
                } else {
                  selectRepairShop();
                }
              }}
            />
          )}
          <Button
            yellow
            wMain
            hLarge
            footerMargin
            text="Info"
            onClick={() => {
              setPopup(this, PopupType.MasterInfoPopup, {
                mainStore,
                repairShop,
              });
            }}
          />
          <Button
            black
            wMain
            hLarge
            text="Verkstadslista"
            onClick={() => {
              navigateToStartList(mainStore.searchMode);
            }}
          />
          <div className="last-edited-field">
            {lastChangeFooterString}
            <br />
            {lastChangeFooterDate}
          </div>
        </div>

        <Popup {...popupProps} />
      </div>
    );
  }
}

RepairShopView.propTypes = {
  params: PropTypes.object.isRequired,
};

export default withRouter(RepairShopView);
