import React, { Component } from 'react';
import { Waypoint } from 'react-waypoint';

import { Actions } from 'general/Actions';
import StoreMain from 'components/Stores/StoreMain';
import StoreReviewList from 'components/Stores/StoreReviewList';

import Button from 'components/Button/Button';
import ReviewListRow from 'components/RepairShopList/ReviewListRow';
import { ContentLoader, SmallLoader } from 'components/Loader/Loader';
import FiltersItem from 'components/Filter/Filter';
import ControlledInput from 'components/Input/InputComponent';
import { Popup, PopupType, setPopup } from 'components/Popup/Popup';

import {
  ReviewListSort,
  Images,
  MainItemHeight,
  SearchMode,
} from 'general/Constants';
import { inputParseIntDefault0 } from 'general/UtilityFunctions';
import withRouter from 'views/routes/DEP_withRouter';

const getStateFromStores = () => ({
  mainStore: StoreMain.getState(),
  reviewListStore: StoreReviewList.getState(),
});

const getDefaultState = () =>
  Object.assign({}, getStateFromStores(), {
    popupProps: {},
  });

class ReviewListView extends Component {
  constructor() {
    super();

    this.state = getDefaultState();

    this.onChange = this.onChange.bind(this);

    // On load view actions
    this.scrollPositionOnRender =
      this.state.reviewListStore.searchState.bookmarkedScrollPosition;
    const aproximateBookmarkIndex = Math.floor(
      this.state.reviewListStore.searchState.bookmarkedScrollPosition /
        MainItemHeight
    );

    Actions.setSearchMode(SearchMode.Review);
    Actions.sendReviewSearchRequest(aproximateBookmarkIndex);
  }

  componentDidMount() {
    StoreMain.addChangeListener(this.onChange);
    StoreReviewList.addChangeListener(this.onChange);
  }

  componentDidUpdate() {
    if (
      this.scrollPositionOnRender > 0 &&
      this.listNode &&
      this.listNode !== null
    ) {
      this.listNode.scrollTop = this.scrollPositionOnRender;
      this.scrollPositionOnRender = 0;
    }
  }

  componentWillUnmount() {
    if (this.listNode && this.listNode !== null) {
      Actions.bookmarkReviewSearchScroll(this.listNode.scrollTop);
    }

    StoreMain.removeChangeListener(this.onChange);
    StoreReviewList.removeChangeListener(this.onChange);
  }

  onChange() {
    this.setState(getStateFromStores());
  }

  render() {
    const { userStations, reviewFilters } = this.state.mainStore.basicData;
    const { stationID, distanceKm, filter } =
      this.state.reviewListStore.searchRequest;
    const { searchLoading, displayCount, reviewListSort, sortDescending } =
      this.state.reviewListStore.searchState;
    const repairShops = this.state.reviewListStore.searchResult;
    const { popupProps, mainStore } = this.state;

    // Build list content
    let searchContent = null;
    if (searchLoading) {
      searchContent = (
        <div className="list-area">
          <ContentLoader />
        </div>
      );
    } else if (repairShops.length > 0) {
      const itemsCount = Math.min(repairShops.length, displayCount);
      const sortIcon = (
        <img
          src={sortDescending ? Images.MenuArrowUp : Images.MenuArrowDown}
          alt="V"
          className="small-arrow-icon-v"
        />
      );

      searchContent = (
        <div className="list-area">
          <div
            className="standard-scroll-area relative"
            ref={(node) => {
              this.listNode = node;
            }}
          >
            <div className="second-head search-list-row">
              <div
                className="name review header-item clickable"
                onClick={() => Actions.setReviewListSort(ReviewListSort.Name)}
              >
                <div className="underline">Namn ({repairShops.length} st)</div>
                {reviewListSort === ReviewListSort.Name && sortIcon}
              </div>
              <div
                className="address review header-item clickable"
                onClick={() =>
                  Actions.setReviewListSort(ReviewListSort.Address)
                }
              >
                <div className="underline">Adress</div>
                {reviewListSort === ReviewListSort.Address && sortIcon}
              </div>
              <div
                className="changed-by review header-item clickable"
                onClick={() =>
                  Actions.setReviewListSort(ReviewListSort.ModifiedBy)
                }
              >
                <div className="underline">Ändrad av</div>
                {reviewListSort === ReviewListSort.ModifiedBy && sortIcon}
              </div>
              <div
                className="last-changed review header-item clickable"
                onClick={() =>
                  Actions.setReviewListSort(ReviewListSort.DateModified)
                }
              >
                <div className="underline">Datum</div>
                {reviewListSort === ReviewListSort.DateModified && sortIcon}
              </div>
              <div
                className="status review header-item clickable"
                onClick={() => Actions.setReviewListSort(ReviewListSort.Status)}
              >
                <div className="underline">Status</div>
                {reviewListSort === ReviewListSort.Status && sortIcon}
              </div>
              <div className="header-right review">
                <div
                  className="distance review header-item clickable"
                  onClick={() =>
                    Actions.setReviewListSort(ReviewListSort.Distance)
                  }
                >
                  <div className="underline">Avst.</div>
                  {reviewListSort === ReviewListSort.Distance && sortIcon}
                </div>
              </div>
            </div>
            {repairShops.slice(0, itemsCount).map((shop, index, slicedList) => (
              <ReviewListRow
                key={shop.id}
                shop={shop}
                index={index}
                isLast={slicedList.length - 1 === index}
              />
            ))}
            {displayCount < repairShops.length && (
              <div className="waypoint-container">
                <div className="list-waypoint">
                  <SmallLoader />
                </div>
                <Waypoint onEnter={Actions.extendReviewSearchList} />
                <div className="main-padder-v"></div>
              </div>
            )}
          </div>
        </div>
      );
    } else {
      searchContent = (
        <div className="list-area">
          <div className="second-head medium-padding">
            <div>Inga träffar för valt filter och sökavstånd</div>
          </div>
        </div>
      );
    }

    return (
      <div className="main-page-container search-list-view">
        <div className="main-content-container">
          <div className="main-head">
            <div className="search-area">
              <FiltersItem
                text={`Station: ${userStations.getValue(stationID)}`}
                items={userStations}
                selectItem={Actions.setStationFilter}
                marginRight
              />
              <FiltersItem
                text={`Visa: ${reviewFilters.getValue(filter)}`}
                items={reviewFilters}
                selectItem={Actions.setReviewFilter}
              />
            </div>
            <div className="head-rightside">
              <div className="station-distance-info">
                Fyll i sökavstånd för stationens område (mil):
              </div>
              <ControlledInput
                value={distanceKm}
                noEditingButton
                restrict={inputParseIntDefault0}
                handleChange={Actions.setReviewDistance}
                autoInputTimer={1333}
                className="short-number-input"
              />
            </div>
          </div>
          {searchContent}
        </div>

        <div className="main-footer">
          <Button
            yellow
            wMain
            hLarge
            footerMargin
            text="Ny verkstad"
            onClick={Actions.createNewRepairShopTemplate}
          />
          <Button
            yellow
            wMain
            hLarge
            footerMargin
            text="Info"
            onClick={() => {
              setPopup(this, PopupType.MasterInfoPopup, {
                mainStore,
              });
            }}
          />
          <Button yellow wMain hLarge text="Startsidan" linkTo="list/" />
        </div>

        <Popup {...popupProps} />
      </div>
    );
  }
}

export default withRouter(ReviewListView);
