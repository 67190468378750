import { FC } from 'react';
import { Library } from '@googlemaps/js-api-loader';
import useGoogleMapsLibrary, { LibLookup } from './useGoogleMapsLibrary';
import { ContentLoader } from 'components/Loader/Loader';

type withGoogleLibraryProps<Lib extends Library> = {
  [key in `${Lib}Lib`]: LibLookup<key extends `${infer L}Lib` ? L : never>;
};

type AnyProps = {
  [key: string]: any;
};

const withGoogleMapsLibrary = <Lib extends Library, Props extends AnyProps>(
  libNames: Lib[],
  WrappedComponent: FC<Props & withGoogleLibraryProps<Lib>>
): FC<Props> => {
  return (props: Props) => {
    const libs = libNames.map(
      (libName) => [libName, useGoogleMapsLibrary(libName)] as const
    );

    if (libs.some(([name, lib]) => !lib.library)) return <ContentLoader />;

    return (
      <WrappedComponent
        {...props}
        {...libs.reduce((acc, [libName, lib]) => {
          (acc as any)[`${libName}Lib`] = lib.library!;
          return acc;
        }, {} as withGoogleLibraryProps<Lib>)}
      />
    );
  };
};

export default withGoogleMapsLibrary;
