// Main store: basic data, settings, custom position, drivingDirections

import { useEffect, useState } from 'react';
import { EventEmitter } from 'events';

import { CachedDrivingDistanceModel, RepairShopAPI } from 'general/API';
import { ActionReturns, Actions } from 'general/Actions';
import { AppDispatcher, basicDataStructure, consoleLog } from 'general/General';
import { getActivePositionData } from 'general/UtilityFunctions';
import {
  ActionTypes,
  PositionType,
  CHANGE_EVENT,
  SearchMode,
} from 'general/Constants';
import {
  AssignmentModel,
  ConnectInsuranceToAssistRequest,
  InfoTextType,
  InsuracenSettingsAssistToRepairShopInsuranceIDs,
  InsuracenSettingsInsuranceOptions,
  PositionModel,
  SettingsModel,
  SiteLoadCoreDataModel,
  TemplatesModel,
  UserInfoModel,
} from 'general/api/models';

const getInitialPositionTypes = (coreData: SiteLoadCoreDataModel) => {
  const types: PositionType[] = [];

  // First prio
  if (coreData.assignment.position !== null) {
    types.push(PositionType.Assignment);
  }

  // Second prio
  if (coreData.userInfo.userPosition !== null) {
    types.push(PositionType.User);
  } else if (coreData.userInfo.mainStationPosition !== null) {
    types.push(PositionType.Station);
  } else {
    types.push(PositionType.DefaultFallback);
  }

  return types;
};

type BDStructure = ReturnType<typeof basicDataStructure>;

export interface MainStoreState {
  basicData: {
    carBrands: BDStructure;
    insuranceCompanies: BDStructure;
    departments: BDStructure;
    departmentsShort: BDStructure;
    reviewFilters: BDStructure;
    allStations: BDStructure;
    userStations: BDStructure;
    insuranceSettings: {
      assistToRepairShop_InsuranceIDs: InsuracenSettingsAssistToRepairShopInsuranceIDs;
      insuranceOptions: InsuracenSettingsInsuranceOptions;
      assistInsuranceCompanies: BDStructure;
      allRepairShopInsuranceCompanies: BDStructure;
    };
  };

  templates: TemplatesModel;
  settings: SettingsModel;
  assignment: AssignmentModel;
  userInfo: UserInfoModel;
  searchMode: keyof typeof SearchMode;

  // Active position used for searchRequest, and distance calculation in repairShop
  activePositionType: PositionType;
  availablePositionTypes: PositionType[];
  customPosition: PositionModel | null;

  // Cached drivingDirections from activePositionType to selected repairShops
  drivingDirectionCache: {
    [repairShopId: string]: CachedDrivingDistanceModel;
  };
}

const getDefaultState = (coreData: SiteLoadCoreDataModel): MainStoreState => ({
  basicData: {
    carBrands: basicDataStructure(coreData.basicData.carBrands),
    insuranceCompanies: basicDataStructure(
      coreData.basicData.insuranceCompanies
    ),
    departments: basicDataStructure(coreData.basicData.departments),
    departmentsShort: basicDataStructure(coreData.basicData.departmentsShort),
    reviewFilters: basicDataStructure(coreData.basicData.reviewFilters),
    allStations: basicDataStructure(coreData.basicData.stations),
    userStations: basicDataStructure(coreData.userInfo.stations),
    insuranceSettings: {
      assistToRepairShop_InsuranceIDs:
        coreData.basicData.insuranceSettings.assistToRepairShop_InsuranceIDs,
      insuranceOptions: coreData.basicData.insuranceSettings.insuranceOptions,
      assistInsuranceCompanies: basicDataStructure(
        coreData.basicData.insuranceSettings.assistInsuranceCompanies
      ),
      allRepairShopInsuranceCompanies: basicDataStructure(
        coreData.basicData.insuranceSettings.allRepairShopInsuranceCompanies
      ),
    },
  },
  templates: coreData.templates,
  settings: coreData.settings,
  assignment: coreData.assignment,
  userInfo: coreData.userInfo,
  searchMode: SearchMode.Main,
  // Active position used for searchRequest, and distance calculation in repairShop
  activePositionType: getInitialPositionTypes(coreData)[0],
  availablePositionTypes: getInitialPositionTypes(coreData),
  customPosition: null,
  // Cached drivingDirections from activePositionType to selected repairShops
  drivingDirectionCache: {},
});

const drivingDirectionsLifespan = 20 * 60 * 1000; // ms

class MainStore extends EventEmitter {
  state: ReturnType<typeof getDefaultState> = null!;

  init(coreData: SiteLoadCoreDataModel) {
    this.state = getDefaultState(coreData);

    // Init cache checking intervall (30 sec)
    setInterval(() => {
      const valid = this.verifyDrivingDirectionsCache();
      if (!valid) {
        consoleLog(
          'Outdated driving directions have been cleared',
          'Store Auto Interval: Main',
          '#99CCFF'
        );
      }
    }, 30 * 1000);
  }

  setSearchMode(searchMode: keyof typeof SearchMode) {
    this.state.searchMode = searchMode;
    this.emitChange();
  }

  setCustomPosition(position: PositionModel) {
    this.state.customPosition = position;

    // Make sure the custom position is available
    if (this.state.availablePositionTypes.indexOf(PositionType.Custom) === -1) {
      this.state.availablePositionTypes.push(PositionType.Custom);
    }

    this.verifyDrivingDirectionsCache();

    this.emitChange();
  }

  setActivePositionType(positionType: PositionType) {
    this.state.activePositionType = positionType;

    this.verifyDrivingDirectionsCache();

    this.emitChange();
  }

  verifyDrivingDirectionsCache() {
    const cache = this.state.drivingDirectionCache;
    const currentPos = getActivePositionData(this.getState());
    const cutoffTimestamp = Date.now() - drivingDirectionsLifespan;
    let valid = true;

    Object.keys(cache).forEach((key) => {
      const model = cache[key];

      if (
        model.timestamp < cutoffTimestamp ||
        model.origin.latitude !== currentPos!.latitude ||
        model.origin.longitude !== currentPos!.longitude
      ) {
        valid = false;
        delete cache[key];
      }
    });

    if (!valid) {
      this.emitChange();
    }

    return valid;
  }

  requestDrivingDirectionsUpdate(
    repairShopID: number,
    position: PositionModel
  ) {
    if (!(repairShopID in this.state.drivingDirectionCache)) {
      // Request new directions
      const currentPos = getActivePositionData(this.getState());
      const request = {
        origin: {
          latitude: currentPos!.latitude,
          longitude: currentPos!.longitude,
        },
        destination: position,
      };

      RepairShopAPI.getDrivingDirections(
        request,
        Actions.getDrivingDirectionsResult,
        repairShopID
      );
    }
  }

  getDrivingDirectionsResult(
    result: CachedDrivingDistanceModel,
    repairShopID: number
  ) {
    this.state.drivingDirectionCache[repairShopID] = result;
    this.emitChange();
  }

  connectInsuranceToAssist(
    connections: InsuracenSettingsAssistToRepairShopInsuranceIDs,
    onSuccess: (response: string) => void
  ) {
    const connectionsRequest: ConnectInsuranceToAssistRequest = {
      connections:
        this.state.basicData.insuranceSettings.assistInsuranceCompanies.sortedKeys.map(
          (assistIcID) => {
            let rsIcID = null;
            if (connections[assistIcID]) {
              rsIcID = connections[assistIcID];
            }

            return {
              assistInsuranceCompanyID: assistIcID,
              repairShopIncuranceCompanyID: rsIcID ?? undefined,
            };
          }
        ),
    };

    RepairShopAPI.connectInsuranceToAssist(connectionsRequest, onSuccess);

    this.state.basicData.insuranceSettings.assistToRepairShop_InsuranceIDs =
      connections;
    this.emitChange();
  }

  updateInsuranceSettings(
    insuranceOptions: InsuracenSettingsInsuranceOptions,
    onSuccess: (response: string) => void
  ) {
    const settingsRequest = {
      settings:
        this.state.basicData.insuranceSettings.allRepairShopInsuranceCompanies.sortedKeys.map(
          (id) => {
            return {
              id,
              isDisabled: insuranceOptions[id].isDisabled,
              lockedOnRepairShop: insuranceOptions[id].lockedOnRepairShop,
            };
          }
        ),
    };

    RepairShopAPI.updateInsuranceSettings(settingsRequest, onSuccess);

    this.state.basicData.insuranceSettings.insuranceOptions = insuranceOptions;
    this.emitChange();
  }

  emitChange() {
    consoleLog(this.state, 'Store Update: Main', '#99CCFF');
    this.emit(CHANGE_EVENT);
  }

  addChangeListener(callback: () => void) {
    this.on(CHANGE_EVENT, callback);
  }

  removeChangeListener(callback: () => void) {
    this.removeListener(CHANGE_EVENT, callback);
  }

  getState() {
    return this.state;
  }

  getRepairShopTemplate() {
    return JSON.parse(JSON.stringify(this.state.templates.repairShopModel));
  }

  getCarBrandTemplate(keyID: number) {
    const model = JSON.parse(
      JSON.stringify(this.state.templates.carBrandModel)
    );
    model.keyID = keyID;
    return model;
  }

  getInsuranceCompanyTemplate(keyID: number) {
    const model = JSON.parse(
      JSON.stringify(this.state.templates.insuranceCompanyModel)
    );
    model.keyID = keyID;
    return model;
  }

  getDepartmentTemplate(keyID: number) {
    const model = JSON.parse(
      JSON.stringify(this.state.templates.departmentModel)
    );
    model.keyID = keyID;
    return model;
  }

  getInfoTextTemplate(keyID: (typeof InfoTextType)[keyof typeof InfoTextType]) {
    const model = JSON.parse(
      JSON.stringify(this.state.templates.infoTextModel)
    );
    model.keyID = keyID;
    return model;
  }
}

const StoreMain = new MainStore();

AppDispatcher.register((action: ActionReturns) => {
  switch (action.type) {
    case ActionTypes.SET_SEARCH_MODE:
      StoreMain.setSearchMode(action.searchMode);
      break;

    case ActionTypes.SET_CUSTOM_POSITION:
      StoreMain.setCustomPosition(action.position);
      break;

    case ActionTypes.SET_ACTIVE_POSITION_TYPE:
      StoreMain.setActivePositionType(action.positionType);
      break;

    case ActionTypes.REQUEST_DRIVING_DIRECTIONS_UPDATE:
      StoreMain.requestDrivingDirectionsUpdate(
        action.repairShopID,
        action.position
      );
      break;

    case ActionTypes.GET_DRIVING_DIRECTIONS_RESULT:
      StoreMain.getDrivingDirectionsResult(action.result, action.repairShopID);
      break;

    case ActionTypes.UPDATE_INSURANCE_SETTINGS:
      StoreMain.updateInsuranceSettings(
        action.insuranceOptions,
        action.onSuccess
      );
      break;

    case ActionTypes.CONNECT_INSURANCE_TO_ASSIST:
      StoreMain.connectInsuranceToAssist(action.connections, action.onSuccess);
      break;

    default:
      break; // Do nothing
  }
});

export const useStoreMain = () => {
  const [storeState, setStoreState] = useState(StoreMain.getState());

  useEffect(() => {
    const changeListener = () => {
      setStoreState(StoreMain.getState());
    };

    StoreMain.addChangeListener(changeListener);

    return () => {
      StoreMain.removeChangeListener(changeListener);
    };
  }, []);

  return storeState;
};

export default StoreMain;
