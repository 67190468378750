import * as constants from 'globalStyles/constants';
import { css } from 'styled-components';
import {
  vcenter_container,
  rightside_vcenter_container,
  elipsis_overflow,
} from 'globalStyles/general-structures';

const filterGlobalStyle = css`
  .filters-item {
    height: ${constants.small_item_height};
    position: relative;
    border-radius: ${constants.main_border_radius};
    &:focus {
      outline: 0;
    }
    ${vcenter_container};
    &.margin-left {
      margin-left: ${constants.small_distance};
    }
    &.margin-right {
      margin-right: ${constants.small_distance};
    }
    &.open {
      width: 160px;
    }
    .head {
      height: ${constants.small_item_height};
      width: 100%;
      padding-right: ${constants.medium_distance};
      padding-left: ${constants.medium_distance};
      ${vcenter_container};
      .right-icon {
        ${rightside_vcenter_container};
      }
    }
    .placeholder {
      opacity: ${constants.main_transparent};
    }
    .text {
      ${elipsis_overflow};
    }
  }

  .filter-dropdown-frame {
    box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.17);
    .head-overlay {
      width: 100%;
      height: ${constants.small_item_height};
    }
  }

  @keyframes filter-animation {
    0% {
      opacity: 0.001;
      background-color: ${constants.main_yellow};
    }
    33% {
      opacity: 1;
      background-color: ${constants.main_yellow};
    }
    100% {
      background-color: ${constants.pure_white};
    }
  }

  .filter-animation {
    animation-name: filter-animation;
    animation-duration: 1s;
  }
`;

export default filterGlobalStyle;
