import * as constants from 'globalStyles/constants';
import { css } from 'styled-components';
import {
  vcenter_container,
  elipsis_overflow,
} from 'globalStyles/general-structures';

const inputComponentGlobalStyle = css`
  .input-field-wrapper {
    position: relative;
    &.dropdown {
      .input-required-background,
      .input-rightside-button {
        z-index: 3;
      }
    }
    .search-field-input {
      width: 290px;
      padding-left: calc(
        ${constants.search_glass_size} + ${constants.small_distance} +
          ${constants.mini_distance}
      );
      background-repeat: no-repeat;
      background-position: ${constants.small_distance} center;
      background-size: ${constants.search_glass_size}
        ${constants.search_glass_size};
      height: ${constants.main_item_height};
      &.wide {
        width: 360px;
      }
    }
    .edit-field-input {
      width: 100%;
      padding-left: ${constants.medium_distance};
      height: ${constants.main_item_height};
    }
    .short-number-input {
      width: 72px;
      padding-left: ${constants.medium_distance};
      height: ${constants.small_item_height};
    }
    .input-with-dropdown {
      z-index: 3;
      position: relative;
      &.has-list-attached {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        &:focus {
          box-shadow: none;
        }
      }
    }
    .edit-header-input {
      width: calc(
        ${constants.main_content_width} / 3 - ${constants.large_distance} * 2 -
          1px
      );
      padding-left: ${constants.medium_distance};
      height: ${constants.main_item_height};
    }
    .input-required-background {
      position: absolute;
      z-index: 2;
      right: 0;
      top: 0;
      bottom: 0;
      width: ${constants.large_distance};
      ${vcenter_container};
    }
  }

  .input-dropdown-frame {
    padding-top: ${constants.main_item_height};
    z-index: 1;

    .main {
      margin-right: ${constants.small_distance};
      font-weight: bold;
      max-width: 53%;
      ${elipsis_overflow};
    }
    .second {
      font-size: ${constants.small_text};
      opacity: ${constants.dark_transparent};
      width: 30%;
      ${elipsis_overflow};
    }
  }

  .input-rightside-button {
    position: absolute;
    z-index: 2;
    background-color: ${constants.pure_white};
    right: 5px;
    top: 5px;
    height: 34px;
    min-width: 30px;
    padding-left: ${constants.mini_distance};
    padding-right: ${constants.mini_distance};
    cursor: pointer;
    ${vcenter_container};
    &.shaded {
      background-color: ${constants.background_shade};
      border-radius: ${constants.main_border_radius};
      text-align: center;
      font-weight: bold;
      font-size: ${constants.small_text};
      opacity: 0.9;
    }
    .remove-icon {
      height: ${constants.small_icon_size};
      margin-left: ${constants.mini_distance};
    }
  }
`;

export default inputComponentGlobalStyle;
