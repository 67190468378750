// Colors
export const pure_black = '#000000';
export const pure_white = '#FFFFFF';
export const red_text = '#D52535';
export const green_text = '#409948';
export const main_yellow = '#FFDC2A';
export const border_yellow = '#F8D730';
export const main_black = '#191919';
export const border_black = '#262222';
export const dark_grey = '#404040';
export const main_red = '#ED4A3F';
export const border_red = '#D85143';
export const main_green = '#56C04B';
export const border_green = '#60B058';
export const main_orange = '#FFA02A';
export const background_light = '#FDFDFD';
export const background_shade = '#EFEFEF';
export const border_shade = '#EDEBED';
export const border_dark = '#DCD9DC';
export const dark_shade = '#D0CCD0';
export const mid_grey = '#8F8F8F';
export const background_red_marker = '#FCC1BC';
export const background_yellow_marker = '#FEF2B8';
export const background_blue_marker = '#EAF8F9';
export const background_green_marker = '#C0F9B5';

// Special colors
export const popup_background = 'rgba(0, 0, 0, .73)';
export const disabled_yellow = '#F2EBC7';
export const disabled_border_yellow = '#EEE2C2';
export const disabled_black = '#CECECE';
export const disabled_border_black = '#C3C4C5';
export const background_inactive = '#EBE8EB';
export const dropdown_shade = '#F8F8F8';
export const transparant_light = 'rgba(253, 253, 253, 0)';

// Distances
export const mini_distance = '7px';
export const small_distance = '10px';
export const medium_distance = '14px';
export const large_distance = '20px';

// General sizes
export const page_width = '1024px';
export const page_height = '712px';
export const main_head_height = '66px';
export const second_head_height = '54px';

// NOTE: drop_down_item_height, main_item_height
// is mirrored in Constants.js to enable dynamic height
export const large_item_height = '48px';
export const main_item_height = '44px';
export const small_item_height = '34px';
export const dropdown_item_height = '39px';
export const main_foot_height = `calc(${large_item_height} + 2 * ${large_distance})`;
export const main_content_height = `calc(${page_height} - ${small_distance} - ${main_foot_height})`;
export const main_content_width = `calc(${page_width} - 2 * ${small_distance})`;
export const main_inner_content_height = `calc(${main_content_height} - ${main_head_height} - ${second_head_height} - ${small_distance})`;
export const small_popup_width = '340px';
export const medium_popup_width = '410px';
export const large_popup_width = '530px';

// Item widths
export const small_button_width = '72px';
export const main_button_width = '120px';
export const large_button_width = '143px';
export const checkbox_size = '21px';
export const medium_icon_size = '26px';
export const small_icon_size = '20px';
export const mini_icon_size = '12px';
export const search_glass_size = '17px';

// Special
export const light_transparent = 0.43;
export const main_transparent = 0.666;
export const dark_transparent = 0.8;
export const main_border_radius = '5px';
export const main_box_shadow = '0 1px 2px 1px rgba(22, 22, 22, .13)';
export const subtle_box_shadow = '0 1px 2px 1px rgba(22, 22, 22, .0943)';
export const extra_subtle_box_shadow = '0 1px 2px 1px rgba(22, 22, 22, .0333)';
export const heavy_box_shadow = '0 1px 2px 1px rgba(19, 19, 19, .8)';
export const input_box_shadow = '0 1px 1px 1px rgba(19, 19, 19, .0666)';
export const yellow_header_box_shadow = '0 0 1px 1px rgba(242, 217, 97, .5)';
export const item_scroll_extension = '13px';
export const scroll_fade_height = '23px';
export const scrollthumb_width = '6px';
export const scrollthumb_grab_padding = '7px';

// Text
export const header1 = '19px';
export const header2 = '17px';
export const standard_text = '15px';
export const small_text = '13px';
export const standard_line_height = '25px';
export const min_line_padding = '3px';
export const standard_line_break = '20px';

// iPad specific
export const ipad_statusbar = '20px';
export const ipad_height = '768px';
