import * as constants from 'globalStyles/constants';
import { css } from 'styled-components';
import {
  elipsis_overflow,
  header4,
  vcenter_container,
  small_info_text,
} from 'globalStyles/general-structures';

const generalInfoGlobalStyle = css`
  .popup-small-info-box {
    width: 100%;
    text-align: left;
    white-space: pre-wrap;
    &.page-height {
      height: calc(
        ${constants.page_height} * 0.92 - 2 * ${constants.large_distance} -
          ${constants.large_item_height}
      );
    }
    &.medium-height {
      height: ${constants.medium_popup_width};
    }
    .item {
      margin-bottom: ${constants.medium_distance};
      position: relative;
      display: block;
      &:last-of-type {
        margin-bottom: 0;
      }
      .name {
        width: 100%;
        font-weight: bold;
        margin-bottom: 6px;
      }
      .warning {
        opacity: ${constants.main_transparent};
        color: ${constants.red_text};
      }
      .repair-shop-name {
        max-width: 225px;
        ${elipsis_overflow};
      }
    }
    .header {
      margin-bottom: 4px;
      ${header4};
    }
    .paragraph-header {
      width: 100%;
      font-weight: bold;
      margin-bottom: 4px;
    }
    .paragraph {
      width: 100%;
      margin-bottom: ${constants.medium_distance};
      white-space: pre-wrap;
      &:last-of-type {
        margin-bottom: 0;
      }
      .bulleted-line {
        padding-left: ${constants.medium_distance};
        width: 100%;
        height: ${constants.header1};
        ${vcenter_container};
      }
    }
    .info {
      ${small_info_text};
    }
    .item-break-line {
      margin-top: ${constants.large_distance};
      border-bottom: 1px solid ${constants.background_shade};
      margin-bottom: ${constants.large_distance};
    }
    .rightside-button {
      position: absolute;
      top: 2px;
      left: 230px;
    }
  }
`;

export default generalInfoGlobalStyle;
