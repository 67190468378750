import * as constants from 'globalStyles/constants';
import { css } from 'styled-components';
import {
  vcenter_container,
  header1,
  header2,
  header4,
  small_info_text,
} from 'globalStyles/general-structures';

const insuranceInfoGlobalStyle = css`
  .assignment-insurance-info {
    width: 100%;
    height: 100%;
    white-space: pre-wrap;
    display: flex;
    .column {
      width: 50%;
      height: 100%;
      position: relative;
      display: inline-block;
      &:first-of-type {
        border-right: 1px solid ${constants.border_shade};
      }
      .top {
        width: 100%;
        height: 90%;
      }
      .bottom {
        position: absolute;
        bottom: 0;
        right: 0;
        ${vcenter_container};
      }
    }
    .break-line {
      width: 100%;
      padding-bottom: ${constants.large_distance};
      border-bottom: 1px solid ${constants.background_shade};
      margin-bottom: ${constants.large_distance};
    }
    .missing {
      opacity: ${constants.light_transparent};
    }
    .header1 {
      margin-top: 4px;
      width: 100%;
      ${header1};
    }
    .header2 {
      width: 100%;
      ${header2};
    }
    .header-info {
      ${header4};
      &.red {
        color: ${constants.red_text};
      }
      &.green {
        color: ${constants.green_text};
      }
    }
    .timestamp {
      width: 100%;
      ${small_info_text};
    }
    .text {
      margin-top: ${constants.medium_distance};
    }
  }
`;

export default insuranceInfoGlobalStyle;
