import { Component } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';

export interface WithRouterProps {
  params: { [key: string]: string };
}

/**
 * @depricated Please create function components instead of class components.
 */
function withRouter<P extends WithRouterProps>(
  Component: new (...args: any[]) => Component<P>
) {
  function ComponentWithRouterProp(props: P) {
    // let location = useLocation();
    // let navigate = useNavigate();
    let params = useParams();
    return <Component {...props} params={params} />;
  }

  return ComponentWithRouterProp;
}

export default withRouter;
