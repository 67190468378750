import { Component } from 'react';
import PropTypes from 'prop-types';

import { hasKeyID } from 'general/UtilityFunctions';
import { Actions } from 'general/Actions';

import Button from '../../Button/Button';
import Checkbox from '../../Checkbox/Checkbox';

import { PopupContainer } from '../PopupComponents';
import { DepartmentType } from 'general/TsEnums.gen';
import { AuthorizedDepartments, WarrantyDepartments } from 'general/Constants';

class RecommendRepairShopDepartmentsPopup extends Component {
  constructor(props) {
    super(props);

    let departmentsToAdd = [];
    let typeInfo = '';
    if (props.auth) {
      typeInfo = 'auktoriserade verkstäderna';
      departmentsToAdd = AuthorizedDepartments;
    } else if (props.warranty) {
      typeInfo = 'verkstäderna med vagnskadegaranti';
      departmentsToAdd = WarrantyDepartments;
    } else if (props.insuranceEngine) {
      typeInfo = 'verkstäderna med "Försäkring: Alla bilmärken Motor"';
      departmentsToAdd = [DepartmentType.Engine];
    } else if (props.insurancePlate) {
      typeInfo = 'verkstäderna med "Försäkring: Alla bilmärken Plåt"';
      departmentsToAdd = [DepartmentType.Plate];
    }

    this.state = {
      departmentsToAdd,
      typeInfo,
    };

    // Add all reccommended by default
    departmentsToAdd.forEach((key) => {
      if (!hasKeyID(props.repairShop.departments, key)) {
        Actions.toggleDepartment(key);
      }
    });
  }

  render() {
    const { settings, basicData, repairShop } = this.props;
    const { close } = settings;
    const { departments } = basicData;
    const { departmentsToAdd, typeInfo } = this.state;

    const info = `De flesta ${typeInfo} har också dessa verkstadstyper:`;

    return (
      <PopupContainer
        close={close}
        headerText="Lägg till verkstadstyper"
        medium
      >
        <div className="list-top-info-box green">{info}</div>
        <div className="small-padder-v"></div>
        {departmentsToAdd.map((key) => (
          <div key={key} className="popup-line">
            <Checkbox
              checked={hasKeyID(repairShop.departments, key)}
              handleClick={() => Actions.toggleDepartment(key)}
              outerPadding
            />
            <b>{departments.getValue(key)}</b>
          </div>
        ))}
        <div className="main-padder-v"></div>
        <div className="popup-horizontal-divider"></div>
        <div className="popup-buttons-box">
          <Button yellow wLarge hMain text="OK" onClick={close} />
        </div>
      </PopupContainer>
    );
  }
}
RecommendRepairShopDepartmentsPopup.propTypes = {
  settings: PropTypes.object.isRequired,
  repairShop: PropTypes.object.isRequired,
  basicData: PropTypes.object.isRequired,
  warranty: PropTypes.bool,
  auth: PropTypes.bool,
  insurancePlate: PropTypes.bool,
  insuranceEngine: PropTypes.bool,
};
RecommendRepairShopDepartmentsPopup.defaultProps = {
  warranty: false,
  auth: false,
  insurancePlate: false,
  insuranceEngine: false,
};

export default RecommendRepairShopDepartmentsPopup;
