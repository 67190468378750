import { css } from 'styled-components';
import * as constants from './constants';

// Centering
export const vcenter_container = css`
  &:after {
    content: '';
    height: 100%;
  }
  *,
  &:after {
    display: inline-block;
    vertical-align: middle;
  }
  .block {
    display: block;
  }
`;

export const vcenter_single_level = css`
  &:after {
    content: '';
    height: 100%;
  }
  .centered-level,
  &:after {
    display: inline-block;
    vertical-align: middle;
  }
  .block {
    display: block;
  }
`;

export const rightside_vcenter_container = css`
  float: right;
  width: auto;
  height: 100%;
  ${vcenter_container};
`;

// Place inside a OuterContainer that has a min-height defined (OuterContainer should have paddings)
export const dynamic_vcenter_container = css`
  display: table;
  table-layout: fixed;
  .dynamic-vcenter-content {
    display: table-cell;
    vertical-align: middle;
  }
`;

// Text
export const general_header = css`
  font-weight: bold;
  color: ${constants.main_black};
`;

export const header1 = css`
  font-size: ${constants.header1};
  ${general_header};
`;

export const header2 = css`
  font-size: ${constants.header2};
  ${general_header};
`;

export const header3 = css`
  ${general_header};
`;

export const header4 = css`
  font-size: ${constants.small_text};
  ${general_header};
`;

export const small_info_text = css`
  font-size: ${constants.small_text};
  opacity: ${constants.main_transparent};
`;

export const elipsis_overflow = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
