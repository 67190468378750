import { FC, useState } from 'react';

import { RepairShopModel } from 'general/api/models';
import { PopupTypeStr } from 'components/Popup/PopupTypes';
import AKCarBrandsView from './AKCarBrandsView';
import styled from 'styled-components';
import ViewSwitcher from './ViewSwitcher';
import useSummarizedInsuranceAbilities from 'components/RepairShopList/SearchListRow/useSummarizedInsuranceAbilities';
import InsuranceCompanyAbilityView from './InsuranceCompanyAbilityView';
import { useStoreSearhList } from 'components/Stores/StoreSearchList';

const Wrapper = styled.div`
  &&&&& {
    display: inline-flex;
    flex-direction: column;
    overflow: hidden;
  }
`;

interface Props {
  repairShop: RepairShopModel;
  editMode?: boolean;
  setPopup: (popup: PopupTypeStr, popupProps?: any) => void;
}

const CarBrandsPanel: FC<Props> = ({ repairShop, editMode, setPopup }) => {
  const {
    searchRequest: { insuranceID },
  } = useStoreSearhList();

  const summary = useSummarizedInsuranceAbilities(
    repairShop.insuranceAbilities
  );
  const selectableInsuranceCompanyIds = summary.map(
    (s) => s.insuranceCompanyId
  );

  const [selectedInsuranceCompanyId, setSelectedInsuranceCompanyId] = useState<
    number | null
  >(
    typeof insuranceID === 'number' &&
      selectableInsuranceCompanyIds.includes(insuranceID)
      ? insuranceID
      : null
  );

  return (
    <Wrapper className="column">
      {!editMode && selectableInsuranceCompanyIds.length > 0 && (
        <ViewSwitcher
          onSelectInsuranceCompany={setSelectedInsuranceCompanyId}
          selectableInsuranceCompanyIds={selectableInsuranceCompanyIds}
          selectedInsuranceCompanyId={selectedInsuranceCompanyId}
        />
      )}

      {editMode || selectedInsuranceCompanyId === null ? (
        <AKCarBrandsView
          repairShop={repairShop}
          editMode={editMode}
          setPopup={setPopup}
        />
      ) : (
        <InsuranceCompanyAbilityView
          insuranceAbilities={repairShop.insuranceAbilities}
          insuranceCompanyId={selectedInsuranceCompanyId}
        />
      )}
    </Wrapper>
  );
};

export default CarBrandsPanel;
