import useSummarizedInsuranceAbilities from 'components/RepairShopList/SearchListRow/useSummarizedInsuranceAbilities';
import { useStoreMain } from 'components/Stores/StoreMain';
import { InsuranceAbilityModel } from 'general/api/models';
import { FC } from 'react';
import styled from 'styled-components';
import * as constants from 'globalStyles/constants';
import { Images } from 'general/Constants';

const Table = styled.table`
  width: 100%;
  border-spacing: 0;

  &&& {
    font-size: ${constants.small_text};
  }

  th,
  td {
    margin: 0;
    padding: 0;
  }

  tr:nth-child(even) {
    background-color: ${constants.background_shade};
  }
`;

export const Checkmark = styled.span`
  display: inline-block;
  width: 1em;
  height: 1em;
  background-color: ${constants.border_green};
  border-radius: 1000px;

  &::before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    background-image: url(${Images.CheckboxCheckBlack});
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: center;
    filter: invert();
  }
`;

interface Props {
  insuranceAbilities: InsuranceAbilityModel[];
}

const InsuranceAbilitiesTable: FC<Props> = ({ insuranceAbilities }) => {
  const mainStore = useStoreMain();
  const summarized = useSummarizedInsuranceAbilities(insuranceAbilities);

  return (
    <>
      {summarized.map((s, index) => (
        <Table key={index}>
          <tbody>
            <tr>
              <th>Bilmärke</th>
              <th>Avdelningar</th>
              <th title="Vagnskadegaranti">VSG</th>
              <th title="Auktoriserad">Aukt.</th>
              <th>Prio</th>
            </tr>

            {s.carBrandAbilities
              .sort((a, b) =>
                a.carBrand > b.carBrand ? 1 : a.carBrand === b.carBrand ? 0 : -1
              )
              .map((c) => (
                <tr key={c.carBrand}>
                  <td>{c.carBrand}</td>
                  <td>
                    {c.departments
                      .map((d) => mainStore.basicData.departments.getValue(d))
                      .join(', ')}
                  </td>
                  <td>{c.hasWagonDamageWarranty && <Checkmark />}</td>
                  <td>{c.isAuthorized && <Checkmark />}</td>
                  <td>{c.priority.join(', ')}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      ))}
    </>
  );
};

export default InsuranceAbilitiesTable;
