import { Component } from 'react';
import PropTypes from 'prop-types';

import { AssignmentInfo } from '../../Information/GeneralInfo';

import { PopupContainer } from '../PopupComponents';

class AssignmentInfoPopup extends Component {
  render() {
    const { settings } = this.props;
    const { close, back } = settings;

    return (
      <PopupContainer
        close={close}
        back={back}
        headerText="Info för ditt aktiva uppdrag"
        small
      >
        <AssignmentInfo {...this.props} />
      </PopupContainer>
    );
  }
}
AssignmentInfoPopup.propTypes = {
  settings: PropTypes.object.isRequired,
};

export default AssignmentInfoPopup;
