import * as constants from 'globalStyles/constants';
import { css } from 'styled-components';
import {
  vcenter_container,
  rightside_vcenter_container,
  header2,
} from 'globalStyles/general-structures';

const change_info_width = '240px';
const change_from_to_width = '300px';
const change_reset_width = '80px';

const repairShopHistoryGlobalStyle = css`
  .repair-shop-history-card {
    width: 100%;
    height: auto;
    border-radius: ${constants.main_border_radius};
    box-shadow: ${constants.subtle_box_shadow};
    position: relative;
    margin-bottom: ${constants.large_distance};
    &:first-of-type {
      margin-top: ${constants.large_distance};
    }
    .card-header {
      height: ${constants.medium_icon_size};
      padding-left: ${constants.medium_distance};
      padding-right: ${constants.medium_distance};
      background-color: ${constants.main_yellow};
      box-shadow: ${constants.yellow_header_box_shadow};
      overflow: hidden;
      border-top-right-radius: ${constants.main_border_radius};
      border-top-left-radius: ${constants.main_border_radius};
      ${vcenter_container};
      .right {
        ${rightside_vcenter_container};
      }
      .review-text {
        margin-left: ${constants.small_distance};
        ${header2};
      }
      .user-date-text {
        margin-left: ${constants.small_distance};
        font-size: ${constants.small_text};
      }
    }
    .sub-card-header {
      width: 100%;
      margin-top: 5px;
      height: ${constants.medium_icon_size};
      position: relative;
      font-style: italic;
      font-size: ${constants.standard_text};
      opacity: ${constants.dark_transparent};
      border-bottom: 1px solid ${constants.border_shade};
      ${vcenter_container};
    }
    .changes-list {
      padding-right: ${constants.medium_distance};
      padding-left: ${constants.medium_distance};
      padding-bottom: ${constants.small_distance};
      .change-info {
        width: ${change_info_width};
        padding-left: ${constants.medium_distance};
      }
      .change-from-to {
        width: ${change_from_to_width};
        padding-left: ${constants.large_distance};
        white-space: pre-wrap;
      }
      .change-reset {
        width: ${change_reset_width};
        text-align: center;
        &.row-padding {
          padding-top: 2px;
        }
      }
      .change-item {
        min-height: ${constants.main_item_height};
        padding-top: ${constants.medium_distance};
        padding-bottom: ${constants.medium_distance};
      }
    }
  }
`;

export default repairShopHistoryGlobalStyle;
